import React from 'react'

interface IIconBendApe {
  color?: string
  size?: number
}

const IconBendApeV2 = React.forwardRef<SVGSVGElement, IIconBendApe>(({ color = 'currentColor', size = 30 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.262 25.298' ref={ref}>
      <g id='Group_17017' data-name='Group 17017' transform='translate(-266.738 -181)'>
        <g id='Group_10135' data-name='Group 10135' transform='translate(266.913 185.877)'>
          <path
            id='Path_34'
            data-name='Path 34'
            d='M25.676,13.585a3.161,3.161,0,0,0-3.85-3.082,10.184,10.184,0,0,0-6.6-5.17.5.5,0,0,0-.236.975,9.122,9.122,0,0,1,2.037,16.966,5.3,5.3,0,0,0,1.157-3.324,5.4,5.4,0,0,0-.082-.936,4.49,4.49,0,1,0-4.545-7.677,1.194,1.194,0,0,1-1.441,0,4.49,4.49,0,1,0-4.545,7.677,5.4,5.4,0,0,0-.082.971,5.313,5.313,0,0,0,1.181,3.3A9.122,9.122,0,0,1,10.69,6.307a.5.5,0,0,0-.235-.975A10.185,10.185,0,0,0,3.85,10.5a3.159,3.159,0,1,0-1.017,6.224,10.14,10.14,0,0,0,10,8.57h.035a10.14,10.14,0,0,0,9.97-8.57A3.163,3.163,0,0,0,25.676,13.585ZM1,13.585A2.158,2.158,0,0,1,3.158,11.43a2.185,2.185,0,0,1,.26.015,10.079,10.079,0,0,0-.7,3.728q0,.264.013.524A2.159,2.159,0,0,1,1,13.585ZM12.777,24.294A4.349,4.349,0,0,1,8.563,19.17.968.968,0,0,0,8,18.107a3.488,3.488,0,0,1,1.312-6.679H9.4a3.448,3.448,0,0,1,2.106.709,2.2,2.2,0,0,0,2.655,0,3.45,3.45,0,0,1,2.2-.708,3.488,3.488,0,0,1,1.312,6.679l-.006,0a.965.965,0,0,0-.558,1.059,4.385,4.385,0,0,1,.07.781,4.344,4.344,0,0,1-4.35,4.344h-.055Zm10.171-8.6q.013-.26.013-.524a10.079,10.079,0,0,0-.7-3.728,2.184,2.184,0,0,1,.26-.015,2.156,2.156,0,0,1,.431,4.268'
            transform='translate(0 -5.051)'
            fill={color}
            stroke={color}
            strokeWidth='0.35'
          />
          <path
            id='Path_35'
            data-name='Path 35'
            d='M246.061.691A.5.5,0,0,0,247,.645a.506.506,0,0,0-.188-.553.507.507,0,0,0-.563-.014.5.5,0,0,0-.192.613'
            transform='translate(-233.686 0)'
            fill={color}
            stroke={color}
            strokeWidth='0.35'
          />
          <path
            id='Path_36'
            data-name='Path 36'
            d='M223.417,275.364a.745.745,0,0,1,.744-.744.5.5,0,1,0,0-1,1.741,1.741,0,0,0-1.245.523,1.741,1.741,0,0,0-1.245-.523.5.5,0,1,0,0,1,.745.745,0,0,1,.744.744.5.5,0,1,0,1,0'
            transform='translate(-210.078 -259.896)'
            fill={color}
            stroke={color}
            strokeWidth='0.35'
          />
          <path
            id='Path_37'
            data-name='Path 37'
            d='M177.49,188.18a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,1,0v-1a.5.5,0,0,0-.5-.5'
            transform='translate(-168.113 -178.743)'
            fill={color}
            stroke={color}
            strokeWidth='0.35'
          />
          <path
            id='Path_38'
            data-name='Path 38'
            d='M315.513,190.182a.5.5,0,0,0,.5-.5v-1a.5.5,0,1,0-1,0v1a.5.5,0,0,0,.5.5'
            transform='translate(-299.214 -178.743)'
            fill={color}
            stroke={color}
            strokeWidth='0.35'
          />
        </g>
        <text id='_2' data-name='2' transform='translate(289 187)' fill={color} fontSize='8' fontFamily='Metropolis-Bold, Metropolis' fontWeight='700'>
          <tspan x='0' y='0'>
            2
          </tspan>
        </text>
      </g>
    </svg>
  )
})

export default IconBendApeV2
