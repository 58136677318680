import React from 'react'

interface IIconProfile {
  color?: string
  size?: number
}

const IconProfileSettings = React.forwardRef<SVGSVGElement, IIconProfile>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path 
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      d='M10,20c-0.5,0-1.1,0-1.6-0.1l-0.5-0.1l-0.5-1.6c-0.4-0.1-0.9-0.3-1.3-0.5l-1.5,0.8l-0.4-0.3
        c-0.4-0.3-0.8-0.7-1.2-1c-0.4-0.4-0.7-0.8-1-1.2l-0.3-0.4l0.8-1.5c-0.2-0.4-0.4-0.8-0.5-1.3l-1.6-0.5l-0.1-0.5C0,11.1,0,10.5,0,10
        c0-0.5,0-1.1,0.1-1.6l0.1-0.5l1.6-0.5C2,6.9,2.1,6.5,2.4,6.1L1.6,4.6l0.3-0.4c0.6-0.9,1.4-1.6,2.2-2.2l0.4-0.3l1.5,0.8
        C6.5,2.1,6.9,2,7.4,1.8l0.5-1.6l0.5-0.1c1-0.2,2.1-0.2,3.2,0l0.5,0.1l0.5,1.6c0.4,0.1,0.9,0.3,1.3,0.5l1.5-0.8l0.4,0.3
        c0.9,0.6,1.6,1.4,2.2,2.2l0.3,0.4l-0.8,1.5c0.2,0.4,0.4,0.8,0.5,1.3l1.6,0.5l0.1,0.5C20,8.9,20,9.5,20,10c0,0.5,0,1.1-0.1,1.6
        l-0.1,0.5l-1.6,0.5c-0.1,0.4-0.3,0.9-0.5,1.3l0.8,1.5l-0.3,0.4c-0.3,0.4-0.7,0.8-1,1.2c-0.4,0.4-0.8,0.7-1.2,1l-0.4,0.3l-1.5-0.8
        c-0.4,0.2-0.8,0.4-1.3,0.5l-0.5,1.6l-0.5,0.1C11.1,19.9,10.5,20,10,20z M6.1,15.9L6.1,15.9l0.6,0.3c0.4,0.2,0.8,0.4,1.2,0.5l0.7,0.2
        l0.2,0.7l0.3,0.8c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6,0,0.9-0.1l0.3-0.8l0.2-0.7l0.7-0.2c0.4-0.1,0.8-0.3,1.2-0.5l0.6-0.3l1.4,0.7
        c0.2-0.2,0.5-0.4,0.7-0.6l0,0c0.2-0.2,0.4-0.4,0.6-0.7l-0.7-1.4l0.3-0.6c0.2-0.4,0.4-0.8,0.5-1.2l0.2-0.7l0.7-0.2l0.8-0.3
        c0-0.3,0.1-0.6,0.1-0.9v0c0-0.3,0-0.6-0.1-0.9l-0.8-0.3l-0.7-0.2l-0.2-0.7c-0.1-0.4-0.3-0.8-0.5-1.2l-0.3-0.6l0.7-1.4
        c-0.4-0.5-0.8-0.9-1.3-1.3l-1.4,0.7l-0.6-0.3c-0.4-0.2-0.8-0.4-1.2-0.5l-0.7-0.2l-0.5-1.5c-0.3,0-0.6-0.1-0.9-0.1
        c-0.3,0-0.6,0-0.9,0.1L8.8,2.4L8.6,3.1L7.9,3.3C7.5,3.4,7.1,3.6,6.7,3.8L6.1,4.1L4.7,3.4C4.3,3.8,3.8,4.3,3.4,4.7l0.4,0.7l0.3,0.6
        L3.8,6.7C3.6,7.1,3.4,7.5,3.3,7.9L3.1,8.6L1.6,9.1c0,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0.1,0.9l1.5,0.5l0.2,0.7
        c0.1,0.4,0.3,0.8,0.5,1.2l0.3,0.6l-0.7,1.4c0.2,0.2,0.4,0.5,0.6,0.7c0.2,0.2,0.4,0.4,0.7,0.6l0.7-0.4L6.1,15.9L6.1,15.9z'/>
      <path 
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      d='M10,13.8c-2.1,0-3.8-1.7-3.8-3.8S7.9,6.2,10,6.2s3.8,1.7,3.8,3.8c0,0,0,0,0,0
        C13.8,12.1,12.1,13.8,10,13.8z M10,7.8c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2c0-0.6-0.2-1.2-0.7-1.6
        C11.2,8,10.6,7.8,10,7.8z'/>
    </svg>
  )
})

export default IconProfileSettings
