import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconTelegram = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='icon-social-telegram-white'
        d='M0.3,9.3l4.2,1.6l1.6,5.2c0.1,0.3,0.4,0.4,0.6,0.3c0.1,0,0.1,0,0.2-0.1l2.3-1.9c0.2-0.2,0.6-0.2,0.9,0
        l4.2,3.1c0.2,0.2,0.5,0.1,0.7-0.1c0-0.1,0.1-0.1,0.1-0.2l3.1-14.9c0.1-0.3-0.1-0.5-0.4-0.6c-0.1,0-0.2,0-0.3,0L0.3,8.3
        C0.1,8.4-0.1,8.7,0,9C0.1,9.1,0.2,9.2,0.3,9.3z M5.9,10l8.2-5.1c0.1,0,0.2,0,0.2,0c0,0.1,0,0.1,0,0.2l-6.8,6.3
        c-0.2,0.2-0.4,0.5-0.4,0.8L6.8,14c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2l-0.9-3.1C5.4,10.6,5.6,10.2,5.9,10L5.9,10z'
      />
    </svg>
  )
})

export default IconTelegram
