import React from 'react'

interface IIconLiquidate {
  color?: string
  size?: number
}

const IconAuction = React.forwardRef<SVGSVGElement, IIconLiquidate>(({ color = 'currentColor', size = 22, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 22.161 20.1722' {...rest}>
      <g id='Group_8533' data-name='Group 8533' transform='translate(-703.719 -30.291)'>
        <g id='Group_3266' data-name='Group 3266' transform='translate(-5 0.574)'>
          <path
            id='Path_1173'
            data-name='Path 1173'
            d='M9.581,429.722H1.15A1.151,1.151,0,0,1,0,428.572v-.766a1.151,1.151,0,0,1,1.15-1.15H9.581a1.151,1.151,0,0,1,1.15,1.15v.766a1.151,1.151,0,0,1-1.15,1.15m-8.432-2.3a.384.384,0,0,0-.383.383v.766a.384.384,0,0,0,.383.383H9.581a.384.384,0,0,0,.383-.383v-.766a.384.384,0,0,0-.383-.383Z'
            transform='translate(709.094 -380.208)'
            fill={color}
            stroke={color}
            strokeWidth='0.75'
          />
          <path
            id='Path_1174'
            data-name='Path 1174'
            d='M138.184,58.191a.783.783,0,0,1-.577-.227L134.442,54.8c-.554-.554.055-1.511.254-1.825a14.073,14.073,0,0,1,3.618-3.619c.315-.2,1.274-.81,1.827-.256l3.164,3.164c.554.554-.056,1.514-.256,1.828a14.073,14.073,0,0,1-3.62,3.618A2.608,2.608,0,0,1,138.184,58.191Zm1.322-8.454a7.583,7.583,0,0,0-2.526,1.9c-1.379,1.379-1.893,2.369-1.861,2.593l3.127,3.1a7.574,7.574,0,0,0,2.528-1.9,6.5,6.5,0,0,0,1.861-2.564Z'
            transform='translate(580.322 -16.611)'
            fill={color}
            stroke={color}
            strokeWidth='0.75'
          />
          <path
            id='Path_1175'
            data-name='Path 1175'
            d='M89.844,171.223a1.344,1.344,0,0,1-.952-.394l-3.164-3.164a1.345,1.345,0,0,1,0-1.9l.633-.633a1.347,1.347,0,0,1,1.9,0l3.164,3.164a1.347,1.347,0,0,1,0,1.9l-.633.633A1.345,1.345,0,0,1,89.844,171.223Zm-2.532-5.587a.447.447,0,0,0-.315.131l-.632.632a.444.444,0,0,0,0,.629l3.164,3.164a.446.446,0,0,0,.628,0l.633-.633a.445.445,0,0,0,0-.628l-3.164-3.164A.447.447,0,0,0,87.312,165.636Z'
            transform='translate(627.139 -127.577)'
            fill={color}
            stroke={color}
            strokeWidth='0.75'
          />
          <path
            id='Path_1176'
            data-name='Path 1176'
            d='M254.59,6.487a1.343,1.343,0,0,1-.952-.394l-3.165-3.164a1.346,1.346,0,0,1,0-1.9l.633-.633a1.347,1.347,0,0,1,1.9,0l3.164,3.164a1.347,1.347,0,0,1,0,1.9l-.633.633A1.341,1.341,0,0,1,254.59,6.487ZM252.058.9a.446.446,0,0,0-.315.13l-.633.633a.444.444,0,0,0,0,.627l3.164,3.164a.447.447,0,0,0,.628,0l.633-.632a.445.445,0,0,0,0-.628l-3.164-3.164a.443.443,0,0,0-.314-.13m3.8,4.241h0Z'
            transform='translate(469.354 30.197)'
            fill={color}
            stroke={color}
            strokeWidth='0.75'
          />
          <path
            id='Path_1177'
            data-name='Path 1177'
            d='M280.857,197.205a2.614,2.614,0,0,1-1.751-.933c-.622-.622-4.28-4.647-6.55-7.324a.451.451,0,0,1,.688-.582c2.368,2.791,5.962,6.731,6.5,7.269,1.015,1.017,1.411.621,1.647.387a.792.792,0,0,0,.278-.468c.031-.307-.2-.715-.664-1.18-.533-.534-4.467-4.123-7.269-6.5a.451.451,0,0,1,.583-.687c2.688,2.28,6.708,5.933,7.325,6.55a2.473,2.473,0,0,1,.923,1.907,1.92,1.92,0,0,1-1.71,1.562Z'
            transform='translate(447.93 -148.975)'
            fill={color}
            stroke={color}
            strokeWidth='0.75'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconAuction
