import React from 'react'

interface IIconUniswap {
  color?: string
  size?: string | number
}

const IconUniswap = React.forwardRef<SVGSVGElement, IIconUniswap>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18.87 21.734' fill={color} {...rest}>
      <path id='Path_2541' data-name='Path 2541' d='M90.6,61.328c-.235-.034-.247-.045-.135-.056a3.7,3.7,0,0,1,1.054.09,4.53,4.53,0,0,1,2.309,1.547l.2.235.292-.045a6.249,6.249,0,0,1,3.587.448,4.643,4.643,0,0,1,.807.471,1.083,1.083,0,0,1,.078.314,1.9,1.9,0,0,1-.123,1.222.328.328,0,0,0-.034.359.338.338,0,0,0,.3.191c.269,0,.549-.426.684-1.02l.056-.235.1.112a4.413,4.413,0,0,1,1.087,2.152l.022.168-.1-.146a1.894,1.894,0,0,0-.538-.572A3.366,3.366,0,0,0,98.4,66.17a7.575,7.575,0,0,1-2.051-.314c-.919-.3-1.39-.695-2.477-2.141a10.3,10.3,0,0,0-1.087-1.278A3.8,3.8,0,0,0,90.6,61.328Z' transform='translate(-83.203 -56.384)' fill={color}/>
      <path id='Path_2542' data-name='Path 2542' d='M180.364,66.64a2.427,2.427,0,0,1,.2-.964.773.773,0,0,1,.1-.19c.011,0-.011.078-.045.168a2.142,2.142,0,0,0-.045.986c.078.5.112.572.65,1.121.247.258.538.583.65.717l.19.247-.19-.179a6.819,6.819,0,0,0-.9-.706c-.078-.045-.09-.045-.146.011s-.056.112-.056.437a2.4,2.4,0,0,1-.247,1.143c-.09.168-.1.135-.022-.056a1.633,1.633,0,0,0,.067-.695c0-.975-.112-1.211-.8-1.6-.168-.1-.46-.247-.628-.325a2.5,2.5,0,0,1-.3-.146,5.011,5.011,0,0,1,.942.28c.392.157.46.168.5.157.034-.034.056-.123.067-.4' transform='translate(-164.615 -60.273)' fill={color}/>
      <path id='Path_2543' data-name='Path 2543' d='M90.453,70.384a3.96,3.96,0,0,1-.706-2.41l.022-.235.112.022a2.633,2.633,0,0,1,.717.269c.448.269.65.639.841,1.558.056.269.135.583.168.684a4.039,4.039,0,0,0,.448.807c.123.179.045.269-.235.247A2.3,2.3,0,0,1,90.453,70.384Z' transform='translate(-82.595 -62.347)' fill={color}/>
      <path id='Path_2544' data-name='Path 2544' d='M155.95,123.737c-2.219-.9-3-1.67-3-2.982,0-.191.011-.348.011-.348a2.109,2.109,0,0,1,.191.146,4.481,4.481,0,0,0,2.354.717,9.757,9.757,0,0,1,1.715.359,3.274,3.274,0,0,1,2.4,2.612,4.534,4.534,0,0,1-.067,1.4,2.808,2.808,0,0,1-.359.807c-.011,0-.034-.056-.034-.146a1.824,1.824,0,0,0-.65-1.267A8.954,8.954,0,0,0,155.95,123.737Z' transform='translate(-140.773 -110.824)' fill={color}/>
      <path id='Path_2545' data-name='Path 2545' d='M115.873,159.494a2.663,2.663,0,0,0-.112-.471l-.056-.168.1.123a1.975,1.975,0,0,1,.359.65,1.4,1.4,0,0,1,.078.628,1.429,1.429,0,0,1-.078.605,2.025,2.025,0,0,1-.471.751,2.7,2.7,0,0,1-1.681.74c-.135.011-.516.045-.852.067a6.574,6.574,0,0,0-1.905.314c-.067.022-.135.045-.146.034a2.54,2.54,0,0,1,.605-.359,6.1,6.1,0,0,1,1.681-.448,7.691,7.691,0,0,0,1-.2A1.937,1.937,0,0,0,115.873,159.494Z' transform='translate(-102.265 -146.211)' fill={color}/>
      <path id='Path_2546' data-name='Path 2546' d='M180.5,167.261a2.615,2.615,0,0,1-.2-1.816c.022-.056.045-.112.067-.112a.483.483,0,0,1,.157.078c.134.09.415.246,1.132.639a5.725,5.725,0,0,1,1.782,1.311,2.772,2.772,0,0,1,.594,1.356,4.447,4.447,0,0,1-.056,1.334,3.3,3.3,0,0,1-1.625,2.152,1.555,1.555,0,0,1-.235.112.893.893,0,0,1,.1-.246,2.225,2.225,0,0,0,.1-1.737,7.14,7.14,0,0,0-.9-1.614A10.646,10.646,0,0,1,180.5,167.261Z' transform='translate(-165.873 -152.174)' fill={color}/>
      <path id='Path_2547' data-name='Path 2547' d='M76.148,216.65a6.093,6.093,0,0,1,2.791-1.345,4.155,4.155,0,0,1,1.446.056,2.5,2.5,0,0,1,1.412.908,3.348,3.348,0,0,1,.516,1.524c.045.3.1.616.112.684a1.5,1.5,0,0,0,.493.886,1.706,1.706,0,0,0,1.547.045.761.761,0,0,1,.191-.056,1.718,1.718,0,0,1-.482.325,1.587,1.587,0,0,1-.807.191A1.618,1.618,0,0,1,82,219.026a6.314,6.314,0,0,1-.37-.74,2.7,2.7,0,0,0-1.054-1.48,1.381,1.381,0,0,0-1.311-.123.844.844,0,0,0-.291,1.311,1.147,1.147,0,0,0,.639.336.684.684,0,0,0,.773-.684.537.537,0,0,0-.37-.549c-.359-.157-.751.022-.74.37a.308.308,0,0,0,.213.3c.09.045.09.045.022.034a.416.416,0,0,1-.146-.729.807.807,0,0,1,1.2.258,1.175,1.175,0,0,1,.022.762,1.048,1.048,0,0,1-1.278.605,1.827,1.827,0,0,1-.975-.661,2.979,2.979,0,0,0-2.208-1.132l-.213-.034Z' transform='translate(-69.871 -198.132)' fill={color}/>
      <path id='Path_2548' data-name='Path 2548' d='M.381.482c2.612,3.172,6.636,8.1,6.838,8.373.168.224.1.437-.179.594a1.715,1.715,0,0,1-.639.179.809.809,0,0,1-.538-.235c-.1-.1-.538-.74-1.524-2.275-.751-1.177-1.39-2.152-1.4-2.163A17.891,17.891,0,0,0,4.259,7.376,19.614,19.614,0,0,1,5.4,9.528c0,.146-.045.224-.224.426a2.494,2.494,0,0,0-.538,1.513,4.69,4.69,0,0,1-1.278,2.578,4.605,4.605,0,0,0-.706,1,1.9,1.9,0,0,0-.224.9,2.8,2.8,0,0,0,.146,1.1,3.742,3.742,0,0,0,.538,1.054,2.612,2.612,0,0,1,.415.818c0,.09.022.09.426,0a4.434,4.434,0,0,0,2.2-1.076,1.015,1.015,0,0,0,.336-.852.914.914,0,0,0-.078-.471,2.012,2.012,0,0,0-.785-.762,1.772,1.772,0,0,1-.93-1.2A2.109,2.109,0,0,1,5.044,13.2a4.02,4.02,0,0,0,.493-1.771c.034-.471.09-.661.224-.807a.836.836,0,0,1,.617-.258,2.054,2.054,0,0,0,1.233-.5,1.054,1.054,0,0,0,.381-.818L8,8.788l-.146-.157C7.331,8.026.034,0,0,0-.011,0,.168.213.381.482ZM3.823,16.421a.463.463,0,0,0-.146-.616c-.191-.123-.482-.067-.482.1a.114.114,0,0,0,.09.112c.1.056.112.112.034.235s-.078.235.022.314A.32.32,0,0,0,3.823,16.421Z' transform='translate(0)' fill={color} fillRule='evenodd'/>
      <path id='Path_2549' data-name='Path 2549' d='M97.8,131.922a.994.994,0,0,0-.605.661.966.966,0,0,0,.056.605c.123.157.235.2.549.2.617,0,1.143-.269,1.2-.594a.866.866,0,0,0-.5-.807A1.139,1.139,0,0,0,97.8,131.922Zm.717.56a.252.252,0,0,0-.112-.381c-.3-.19-.762-.034-.762.258,0,.146.235.3.46.3A.619.619,0,0,0,98.518,132.482Z' transform='translate(-89.439 -121.397)' fill={color} fillRule='evenodd'/>
    </svg>

  )
})

export default IconUniswap
