import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMedium = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 18, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18.429 15.825'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='icon-social-medium-white' transform='translate(0)'>
        <path id='Path_577' data-name='Path 577' d='M127.683,19.4l5.811,2.906-5.6,9.089L124.1,25.211Z' transform='translate(-115.171 -19.4)' />
        <path id='Path_578' data-name='Path 578' d='M96,90.165V84.9l4.683,7.588Z' transform='translate(-89.118 -80.13)' />
        <path
          id='Path_579'
          data-name='Path 579'
          d='M189.347,97.836,184.3,95.324,189.4,87V97.865C189.434,97.865,189.4,97.865,189.347,97.836Z'
          transform='translate(-170.987 -82.077)'
        />
        <path
          id='Path_580'
          data-name='Path 580'
          d='M7.217,35.872v.095a.09.09,0,0,1-.058-.029l-5.476-2.76a.348.348,0,0,1-.182-.306V20.2l5.717,2.906Z'
          transform='translate(-1.5 -20.142)'
        />
      </g>
    </svg>
  )
})

export default IconMedium
