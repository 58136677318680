import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconUserProxy = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20.979 20.98' fill={color} stroke={color} {...rest}>
      <g id='Group_6489' data-name='Group 6489' transform='translate(0.125 0.125)'>
        <path
          id='Path_2675'
          data-name='Path 2675'
          d='M20.194,6.524a1.824,1.824,0,0,0-2.577,0L15.79,8.353V3.54a1.81,1.81,0,0,0-.534-1.288L13.539.534A1.81,1.81,0,0,0,12.251,0H1.822A1.824,1.824,0,0,0,0,1.822V18.908A1.824,1.824,0,0,0,1.822,20.73H13.968a1.824,1.824,0,0,0,1.822-1.822v-5.4l4.4-4.408a1.821,1.821,0,0,0,0-2.577M12.147,1.215a.663.663,0,0,1,.534.178L14.4,3.11a.663.663,0,0,1,.178.534H12.147Zm2.429,17.693a.608.608,0,0,1-.607.607H1.822a.608.608,0,0,1-.607-.607V1.822a.608.608,0,0,1,.607-.607h9.11V4.251a.607.607,0,0,0,.607.607h3.037v4.71l-1.792,1.793-.858.859a.607.607,0,0,0-.147.237l-.859,2.577a.607.607,0,0,0,.768.768l2.577-.859A.607.607,0,0,0,14.5,14.8l.074-.074ZM13.214,12.65l.859.859-.328.328-1.288.429.429-1.288Zm1.718,0-.859-.859,2.919-2.922.859.859Zm4.4-4.408-.626.627-.859-.859.626-.627a.607.607,0,1,1,.859.859'
          transform='translate(0 0)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2676'
          data-name='Path 2676'
          d='M69.11,150h-8.5a.607.607,0,1,0,0,1.215h8.5a.607.607,0,1,0,0-1.215'
          transform='translate(-57.571 -143.927)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2677'
          data-name='Path 2677'
          d='M66.681,210H60.607a.607.607,0,1,0,0,1.215h6.073a.607.607,0,1,0,0-1.215'
          transform='translate(-57.571 -201.497)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2678'
          data-name='Path 2678'
          d='M66.681,270H60.607a.607.607,0,1,0,0,1.215h6.073a.607.607,0,1,0,0-1.215'
          transform='translate(-57.571 -259.068)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2679'
          data-name='Path 2679'
          d='M66.681,330H60.607a.607.607,0,0,0,0,1.215h6.073a.607.607,0,0,0,0-1.215'
          transform='translate(-57.571 -316.639)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2680'
          data-name='Path 2680'
          d='M184.251,422h-3.644a.607.607,0,1,0,0,1.215h3.644a.607.607,0,1,0,0-1.215'
          transform='translate(-172.712 -404.914)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconUserProxy
