import { useEffect } from 'react'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import { getEtherscanLink } from 'modules/wallet-module'
import { useTranslation } from 'next-i18next'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Avatar, Button, Container, IconWallet, Notification } from 'theme/ui'

import { useModalContext } from '../modal/components/context'

export enum NotificationState {
  CONFIRM_AWAIT = 0,
  TX_CONFIRMING = 1,
  SUCCESS = 2,
  ERROR = 3,
  AWAIT_ACTION = 4
}

type TransactionModalComponentProps = {
  notificationState: NotificationState
  txAwaitText?: string
  txConfirmingText?: string
  txSuccessText?: string
  txErrorText?: string
  txId?: string | null
  title?: string
}

const TransactionModal: React.FC<TransactionModalComponentProps> = ({
  notificationState,
  txAwaitText,
  txConfirmingText,
  txSuccessText,
  txErrorText,
  txId = null,
  title
}) => {
  const { isTablet } = useResponsive()
  const { t } = useTranslation()
  const { handleClose } = useModalContext()
  const { enableKiosk, disableKiosk } = useModalContext()

  useEffect(() => {
    if (notificationState === NotificationState.CONFIRM_AWAIT || notificationState === NotificationState.TX_CONFIRMING) {
      enableKiosk?.()
    } else {
      disableKiosk?.()
    }
  }, [disableKiosk, enableKiosk, notificationState])

  return (
    <Container variant='card-body-modal' flexDirection='column' maxWidth={['', '', 350]} sx={{ padding: 0 }} justifyContent='center'>
      <Box variant={!isTablet ? 'card5-modal' : 'card5'} flexDirection='column' alignItems='center' minWidth={['100%', '100%', 350]} pt={[10, 10, 0]}>
        <TransactionModalHeader title={title} />
        <Box px={20}>
          {notificationState === NotificationState.CONFIRM_AWAIT && (
            <Notification loading reducePadding width='100%' fontSize='sm'>
              <Text dangerouslySetInnerHTML={{ __html: txAwaitText ? txAwaitText : t('tx-modal-await-tx') }}></Text>
            </Notification>
          )}

          {notificationState === NotificationState.TX_CONFIRMING && (
            <Notification loading reducePadding width='100%' fontSize='sm'>
              <Text dangerouslySetInnerHTML={{ __html: txConfirmingText ? txConfirmingText : t('tx-modal-await-tx-1') }}></Text>
            </Notification>
          )}

          {notificationState === NotificationState.SUCCESS && (
            <Notification type='success' reducePadding width='100%' fontSize='sm'>
              <Text dangerouslySetInnerHTML={{ __html: txSuccessText ? txSuccessText : t('tx-modal.success') }}></Text>
            </Notification>
          )}

          {notificationState === NotificationState.ERROR && (
            <Notification type='fail' reducePadding width='100%' fontSize='sm'>
              <Text dangerouslySetInnerHTML={{ __html: txErrorText ? txErrorText : t('tx-modal.error') }}></Text>
            </Notification>
          )}

          {txId && notificationState === NotificationState.SUCCESS ? (
            <Button
              width='100%'
              mt={20}
              color='black'
              size='xl'
              outlined
              href={getEtherscanLink(Number(DEFAULT_CHAIN_ID), txId, 'transaction')}
              mr={10}
              newWindow
            >
              {t('button.view-on-block-explorer')}
            </Button>
          ) : (
            <Button
              width='100%'
              mt={20}
              color='black'
              size='xl'
              outlined
              disabled={notificationState === NotificationState.CONFIRM_AWAIT || notificationState === NotificationState.TX_CONFIRMING}
              onClick={handleClose}
              mr={10}
            >
              {t('button.close')}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default TransactionModal

type TransactionModalHeaderProps = {
  title?: string
}

export const TransactionModalHeader: React.FC<TransactionModalHeaderProps> = ({ title }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  return (
    <Flex variant='info-display' px={[20, 20, 25]} pt={[0]}>
      <Flex alignItems='center'>
        <Avatar
          mr={10}
          minWidth={40}
          iconSize={40}
          backgroundColor='primary'
          sx={{ borderRadius: 'sm' }}
          icon={
            <Flex justifyContent='center' alignItems='center'>
              <IconWallet color={colors.white} size={20} />
            </Flex>
          }
        />
        <Box lineHeight={1.3}>
          <Text fontSize='xl' fontWeight='bold' as='span'>
            {title ? title : t('label.transaction-1')}
          </Text>
          <Text color='grey.500' fontSize='sm'>
            {t('label.check-your-wallet-confirm')}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
