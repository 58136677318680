import React from 'react'

interface IIconVerify {
  color?: string
  size?: number
}

const IconVerify = React.forwardRef<SVGSVGElement, IIconVerify>(({ color = 'currentColor', size = 53, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 53 53' fill={color} stroke={color} {...rest}>
      <g id='Group_3552' data-name='Group 3552' transform='translate(-154.5 -95.279)'>
        <path
          id='Subtraction_128'
          data-name='Subtraction 128'
          d='M26,52A26,26,0,0,1,7.616,7.613,26,26,0,0,1,44.387,44.385,25.819,25.819,0,0,1,26,52ZM26,3.652a22.346,22.346,0,1,0,0,44.692A22.275,22.275,0,0,0,41.8,41.8,22.244,22.244,0,0,0,48.348,26a22.323,22.323,0,0,0-6.546-15.8,22.517,22.517,0,0,0-7.108-4.787A22.223,22.223,0,0,0,26,3.652Z'
          transform='translate(155 95.779)'
          fill='#0057ff'
          stroke='rgba(0,0,0,0)'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
        <g id='Group_5400' data-name='Group 5400' transform='translate(168.901 109.172)'>
          <g id='Group_5401' data-name='Group 5401' transform='translate(-2 0)'>
            <path
              id='Path_2411'
              data-name='Path 2411'
              d='M157.822,5.062a.589.589,0,0,0-.789.274l-.577,1.188-1.907-.81.608-1.251s0-.007,0-.01.007-.017.01-.024a3.23,3.23,0,0,0,.24-.817,3.173,3.173,0,0,0-.942-2.606,3.585,3.585,0,0,0-2.686-1,3.062,3.062,0,0,0-.65.118,3.163,3.163,0,0,0-1.039.539,2.851,2.851,0,0,0-.792.924s0,.007,0,.01a.012.012,0,0,1,0,.007l-2.154,4.444c-.01.017-.021.035-.031.056a.3.3,0,0,0-.024.059l-5.58,11.5a1.207,1.207,0,0,0-.118.563v0l-.474,4.726a1.571,1.571,0,0,0,.375.962l-.33.667a.591.591,0,1,0,1.06.525l.32-.65c.049,0,.094.007.142.007a1.539,1.539,0,0,0,.921-.3L147,20.95a1.239,1.239,0,0,0,.174-.156c.01-.01.017-.021.028-.031a1.23,1.23,0,0,0,.167-.254l6.668-13.735,1.907.81L152.968,13.7a.589.589,0,0,0,.275.789.568.568,0,0,0,.257.059.587.587,0,0,0,.532-.334l3.19-6.57a.611.611,0,0,0,.049-.08.759.759,0,0,0,.031-.08l.8-1.64a.589.589,0,0,0-.275-.785M142.7,23.216a.392.392,0,0,1-.365.049c-.017-.01-.035-.021-.056-.031l-.028-.01a.381.381,0,0,1-.167-.3l.516-3.434a7.172,7.172,0,0,0,2.641,1.289Zm3.705-3.44a5.964,5.964,0,0,1-3.735-1.821l5.24-10.8,3.742,1.81Zm7.831-16.313a2.118,2.118,0,0,1-.153.5L152.173,7.9l-3.746-1.81,1.918-3.954a1.732,1.732,0,0,1,.469-.535,2.027,2.027,0,0,1,.643-.341,1.826,1.826,0,0,1,.393-.073,2.413,2.413,0,0,1,1.779.657,2.015,2.015,0,0,1,.608,1.619'
              transform='translate(-129.756 0)'
              fill={color}
              stroke={color}
              strokeWidth='1'
            />
            <path
              id='Path_2412'
              data-name='Path 2412'
              d='M7.913,124.119c-.149,0-.323,0-.5,0-.945.01-2.707.028-3.269-.6a.827.827,0,0,1-.177-.705A10.3,10.3,0,0,1,5.3,119.429l.094-.177c.184-.344.375-.691.563-1.032a30.7,30.7,0,0,0,1.536-3.04A6.539,6.539,0,0,0,8.028,113a3.463,3.463,0,0,0-.66-2.3,5.144,5.144,0,0,0-2.557-1.727,17.976,17.976,0,0,0-2.925-.653c-.417-.069-.813-.132-1.178-.208a.59.59,0,0,0-.233,1.157c.389.08.8.146,1.223.215,1.755.288,3.746.611,4.725,1.921.58.775.573,1.894-.024,3.325a29.526,29.526,0,0,1-1.48,2.922c-.188.341-.382.695-.57,1.046l-.094.174a11.086,11.086,0,0,0-1.466,3.8,1.993,1.993,0,0,0,.473,1.633c.817.907,2.432,1,3.686,1,.163,0,.323,0,.473,0,.177,0,.341,0,.48,0h.007a.589.589,0,0,0,.007-1.178'
              transform='translate(0 -99.861)'
              fill='#0057ff'
              stroke='#0057ff'
              strokeWidth='1'
            />
          </g>
        </g>
      </g>
    </svg>
  )
})

export default IconVerify
