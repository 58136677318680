import React from 'react'

interface IIconSignature {
  color?: string
  size?: number
}

const IconSignature = React.forwardRef<SVGSVGElement, IIconSignature>(({ color = 'currentColor', size = 28, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 29.562 29.122' fill={color} stroke={color} {...rest}>
      <g id='Group_5404' data-name='Group 5404' transform='translate(0.252 0.252)'>
        <g id='Group_5401' data-name='Group 5401'>
          <path
            id='Path_2411'
            data-name='Path 2411'
            d='M158.858,5.218a.687.687,0,0,0-.92.32l-.673,1.386-.539-.259.709-1.459s0-.008,0-.012.008-.02.012-.028a3.765,3.765,0,0,0,.28-.952,3.7,3.7,0,0,0-1.1-3.039A4.181,4.181,0,0,0,153.5.007a3.571,3.571,0,0,0-.758.138,3.688,3.688,0,0,0-1.211.628,3.324,3.324,0,0,0-.924,1.078s0,.008,0,.012a.014.014,0,0,1,0,.008l-2.512,5.182c-.012.02-.024.041-.036.065a.347.347,0,0,0-.028.069L141.517,20.6a1.407,1.407,0,0,0-.138.656v0l.146,4.323a1.832,1.832,0,0,0,.438,1.122l-.385.778a.689.689,0,1,0,1.236.612l.373-.758c.057,0,.109.008.166.008a1.794,1.794,0,0,0,1.074-.352l3.493-2.561a1.448,1.448,0,0,0,.2-.182c.012-.012.02-.024.032-.036a1.434,1.434,0,0,0,.195-.3L156.123,7.9l.539.259L153.2,15.294a.687.687,0,0,0,.32.92.663.663,0,0,0,.3.069.685.685,0,0,0,.62-.389l3.719-7.662a.714.714,0,0,0,.057-.093.891.891,0,0,0,.036-.093l.928-1.912a.687.687,0,0,0-.32-.916M143.607,25.885a.457.457,0,0,1-.425.057c-.02-.012-.04-.024-.065-.036l-.032-.012a.444.444,0,0,1-.195-.352l-.1-2.816a8.364,8.364,0,0,0,3.079,1.5Zm3.622-2.824a6.955,6.955,0,0,1-4.356-2.123l6.11-12.593,4.364,2.111Zm9.133-19.023a2.471,2.471,0,0,1-.178.583l-2.228,4.591L149.588,7.1l2.237-4.611a2.02,2.02,0,0,1,.547-.624,2.364,2.364,0,0,1,.75-.4,2.129,2.129,0,0,1,.458-.085,2.814,2.814,0,0,1,2.074.766,2.35,2.35,0,0,1,.709,1.888'
            transform='translate(-130.19 0)'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.5'
          />
          <path
            id='Path_2412'
            data-name='Path 2412'
            d='M9.228,126.782c-.174,0-.377,0-.587,0-1.1.012-3.156.032-3.813-.7a.964.964,0,0,1-.207-.823,12.015,12.015,0,0,1,1.556-3.946l.109-.207c.215-.4.438-.806.656-1.2a35.807,35.807,0,0,0,1.791-3.545,7.625,7.625,0,0,0,.628-2.545,4.038,4.038,0,0,0-.77-2.686,6,6,0,0,0-2.982-2.014A20.961,20.961,0,0,0,2.2,108.35c-.486-.081-.948-.154-1.374-.243a.688.688,0,0,0-.271,1.349c.454.093.928.17,1.426.251,2.046.336,4.368.713,5.51,2.241.677.9.669,2.208-.028,3.877a34.432,34.432,0,0,1-1.726,3.407c-.219.4-.446.81-.664,1.22l-.109.2a12.928,12.928,0,0,0-1.71,4.437A2.324,2.324,0,0,0,3.8,127c.952,1.057,2.836,1.163,4.3,1.163.19,0,.377,0,.551,0,.207,0,.4,0,.559,0H9.22a.687.687,0,0,0,.008-1.373'
            transform='translate(0 -99.539)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconSignature
