import React from 'react'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { shortenTxHash } from 'modules/bend/utils/shortenTxHash'
import { getEtherscanLink } from 'modules/wallet-module'
import { useTranslation } from 'next-i18next'
import { Flex, Link, Text } from 'rebass/styled-components'
import { Button, Notification } from 'theme/ui'

export const WrapStep6: React.FC<any> = ({ handleBack, errorMsg, txHash }) => {
  const { t: tc } = useTranslation('common')
  return (
    <Flex flexDirection='column' flex={1} mt={20}>
      <Flex flex={1} mb={18}>
        <Notification type='fail' reducePadding width='100%'>
          <Text fontWeight='normal'>
            <strong>{tc('label.error')}:</strong> {errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)}
          </Text>
          {txHash && (
            <Link href={getEtherscanLink(Number(DEFAULT_CHAIN_ID), txHash, 'transaction')} target='_blank' rel='noopener noreferrer' variant='link'>
              <Text fontWeight='bold' color='red.1000'>
                {shortenTxHash(txHash!, 5)}
              </Text>
            </Link>
          )}
        </Notification>
      </Flex>
      <Button color='black' outlined size='md' fontWeight='bold' onClick={() => handleBack()}>
        {tc('button.back')}
      </Button>
    </Flex>
  )
}
