import { useQuery, UseQueryResult } from 'react-query'
import { ApolloQueryResult, gql } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { clientBendProtocol } from 'clients'
import { ETH } from 'constants/index'
import { DataReducer } from 'constants/types'
import { useDataContext } from 'contexts/data'
import { find, get, orderBy } from 'lodash'
import { LEND_POOL_ADDRESSES_PROVIDER, UI_POOL_DATA_PROVIDER } from 'modules/bend/constants'
import { useUiPoolDataProviderContract } from 'modules/bend/hooks'
import { getUserReservesData } from 'modules/bend/utils/UiPoolDataProvider'
import { decimalsByTicker, isWeth } from 'utils'

/**
 * Interfaces & types
 */
interface Price {
  priceInEth: BigNumber
  oracle: {
    usdPriceEth: BigNumber
  }
}
export interface Reserve extends UserReserve {
  id: string
  symbol: string
  name: string
  underlyingAsset: string
  totalLiquidity: BigNumber
  liquidityRate: BigNumber
  variableBorrowRate: BigNumber
  decimals: number
  price: Price
  userReserves?: UserReserve[]
  totalBTokenSupply: BigNumber
  totalCurrentVariableDebt: BigNumber
  bToken: {
    id: string
  }
  debtToken: {
    id: string
  }
  distributionManager: string
  isActive: boolean
  isFrozen: boolean
}

interface DistributionManagers {
  id: string
}

/**
 * GraphQL Queries
 */
const QUERY_RESERVES = gql`
  query UserReserves($user: String!) {
    userReserves(where: { user: $user }) {
      scaledBTokenBalance
      currentBTokenBalance
      scaledVariableDebt
      currentVariableDebt
      currentTotalDebt
      variableBorrowIndex
      lifetimeDeposits
      lifetimeWithdrawals
      reserve {
        id
        symbol
        name
        underlyingAsset
        totalLiquidity
        liquidityRate
        variableBorrowRate
        decimals
        totalBTokenSupply
        totalCurrentVariableDebt
        bToken {
          id
        }
        debtToken {
          id
        }
        price {
          priceInEth
          oracle {
            usdPriceEth
          }
        }
        bToken {
          underlyingAssetAddress
          underlyingAssetDecimals
        }
        debtToken {
          underlyingAssetAddress
          underlyingAssetDecimals
        }
        isActive
        isFrozen
      }
    }
    distributionManagers {
      id
    }
  }
`

type UserReserve = {
  currentBTokenBalance: BigNumber
  scaledBTokenBalance: BigNumber
  scaledVariableDebt: BigNumber
  currentVariableDebt: BigNumber
  variableBorrowIndex: BigNumber
  currentTotalDebt: BigNumber
  reserve: Reserve
  lifetimeDeposits: BigNumber
  lifetimeWithdrawals: BigNumber
}
type QueryResult = {
  distributionManagers: DistributionManagers[]
  userReserves: Array<UserReserve>
}

type UseUserReservesArgs =
  | {
      symbol?: string
    }
  | undefined

/**
 * Hook useUserReserves
 * @summary Fetches userReserve data.
 * @return {UseQueryResult}
 */
export default function useUserReserves(args: UseUserReservesArgs = undefined): UseQueryResult<any, any> {
  const { account } = useWeb3React()
  const contract = useUiPoolDataProviderContract(UI_POOL_DATA_PROVIDER, false)
  const { dispatch } = useDataContext()

  /**
   * @summary Get user reserves
   */
  const userReservesQuery: UseQueryResult<any, any> = useQuery(
    ['reserves query data context', account, args?.symbol],
    async () => {
      if (!account) return []
      const {
        data: { distributionManagers, userReserves }
      }: ApolloQueryResult<QueryResult> = await clientBendProtocol.query({
        query: QUERY_RESERVES,
        variables: {
          user: account.toLocaleLowerCase()
        }
      })

      if (!userReserves || !distributionManagers) return []

      const payloadData: any = []
      let totalDeposited = new BigNumber(0)
      for (let i = 0; i < userReserves.length; i += 1) {
        const underlyingAsset = userReserves[i].reserve.underlyingAsset
        // const currentBTokenBalance = new BigNumber(userReserves[i].currentBTokenBalance).dividedBy(`1e${userReserves[i].reserve.decimals}`)

        payloadData.push({
          key: userReserves[i].reserve.id,
          id: userReserves[i].reserve.id,
          symbol: isWeth(underlyingAsset) ? ETH.symbol : userReserves[i].reserve.symbol,
          name: isWeth(underlyingAsset) ? ETH.name : userReserves[i].reserve.name,
          underlyingAsset: underlyingAsset,
          totalLiquidity: isWeth(underlyingAsset)
            ? new BigNumber(userReserves[i].reserve.totalBTokenSupply)
                .dividedBy(`1e${userReserves[i].reserve.decimals}`)
                .dp(decimalsByTicker(underlyingAsset), 1)
            : new BigNumber(userReserves[i].reserve.totalBTokenSupply)
                .dividedBy(`1e${userReserves[i].reserve.decimals}`)
                .dp(decimalsByTicker(underlyingAsset), 1),
          liquidityRate: new BigNumber(100).multipliedBy(new BigNumber(userReserves[i].reserve.liquidityRate).dividedBy(1e27)).dp(2),
          variableBorrowRate: new BigNumber(100).multipliedBy(new BigNumber(userReserves[i].reserve.variableBorrowRate).dividedBy(1e27)).dp(2),
          decimals: userReserves[i].reserve.decimals,
          scaledBTokenBalance: userReserves[i].scaledBTokenBalance,
          currentBTokenBalance: new BigNumber(0),
          scaledVariableDebt: userReserves[i].scaledVariableDebt,
          currentVariableDebt: userReserves[i].currentVariableDebt,
          variableBorrowIndex: userReserves[i].variableBorrowIndex,
          currentTotalDebt: new BigNumber(userReserves[i].currentTotalDebt).dividedBy(`1e${userReserves[i].reserve.decimals}`),
          totalBTokenSupply: isWeth(underlyingAsset)
            ? new BigNumber(userReserves[i].reserve.totalBTokenSupply)
                .dividedBy(`1e${userReserves[i].reserve.decimals}`)
                .dp(decimalsByTicker(underlyingAsset), 1)
            : new BigNumber(userReserves[i].reserve.totalBTokenSupply).dividedBy(1e18).dp(decimalsByTicker(underlyingAsset), 1),
          totalCurrentVariableDebt: isWeth(underlyingAsset)
            ? new BigNumber(userReserves[i].reserve.totalCurrentVariableDebt)
                .dividedBy(`1e${userReserves[i].reserve.decimals}`)
                .dp(decimalsByTicker(underlyingAsset), 1)
            : new BigNumber(userReserves[i].reserve.totalCurrentVariableDebt)
                .dividedBy(`1e${userReserves[i].reserve.decimals}`)
                .dp(decimalsByTicker(underlyingAsset), 1),
          price: {
            priceInEth: new BigNumber(userReserves[i].reserve.price.priceInEth).dividedBy(1e18),
            oracle: {
              usdPriceEth: new BigNumber(userReserves[i].reserve.price.oracle.usdPriceEth).dividedBy(1e8)
            }
          },
          bToken: userReserves[i].reserve.bToken.id,
          debtToken: userReserves[i].reserve.debtToken.id,
          distributionManager: get(distributionManagers, '0')?.id,
          isActive: userReserves[i].reserve.isActive,
          isFrozen: userReserves[i].reserve.isFrozen,
          lifetimeDeposits: new BigNumber(userReserves[i].lifetimeDeposits).dividedBy(`1e${userReserves[i].reserve.decimals}`),
          lifetimeWithdrawals: new BigNumber(userReserves[i].lifetimeWithdrawals).dividedBy(`1e${userReserves[i].reserve.decimals}`),
          orderBy: {
            totalLiquidityInEth: Number(
              new BigNumber(userReserves[i].reserve.totalLiquidity)
                .dividedBy(`1e${userReserves[i].reserve.decimals}`)
                .multipliedBy(new BigNumber(userReserves[i].reserve.price.priceInEth).dividedBy(1e18))
                .dp(decimalsByTicker(underlyingAsset), 1)
                .toFixed()
            )
          }
        })
      }

      const userReservesData = get(await getUserReservesData(contract, LEND_POOL_ADDRESSES_PROVIDER, account), 0)

      const userReservesPayload = []
      for (let i = 0; i < payloadData.length; i += 1) {
        const userReserve = find(userReservesData, (reserve: any) => reserve.underlyingAsset.toLowerCase() === payloadData[i].underlyingAsset)
        const currentBTokenBalance = new BigNumber(userReserve.bTokenBalance._hex).dividedBy(`1e${payloadData[Object.keys(payloadData)[i]].decimals}`)
        totalDeposited = totalDeposited.plus(
          currentBTokenBalance
            .multipliedBy(new BigNumber(userReserves[i].reserve.price.priceInEth).dividedBy(1e18))
            .dp(decimalsByTicker(userReserve.underlyingAsset))
        )
        const totalPrincipal = payloadData[i].lifetimeWithdrawals.gte(payloadData[i].lifetimeDeposits)
          ? new BigNumber(0)
          : payloadData[i].lifetimeDeposits.minus(payloadData[i].lifetimeWithdrawals)
        const totalInterest = currentBTokenBalance.minus(totalPrincipal)
        userReservesPayload.push({
          ...payloadData[i],
          totalPrincipal,
          totalInterest,
          currentBTokenBalance: new BigNumber(userReserve.bTokenBalance._hex)
            .dividedBy(`1e${payloadData[Object.keys(payloadData)[i]].decimals}`)
            .dp(decimalsByTicker(userReserve.underlyingAsset))
        })
      }

      dispatch({
        action: DataReducer.addToReservesBalance,
        payload: totalDeposited
      })

      if (args?.symbol) {
        return find(userReservesPayload, (reserve: any) => reserve.symbol?.toLowerCase() === args.symbol?.toLowerCase())
      }

      return orderBy(userReservesPayload, ['orderBy.totalLiquidityInEth'], ['desc'])
    },
    {
      onError: error => {
        console.log('❌ reserves query data context graphql error', error)
      }
    }
  )

  return userReservesQuery
}
