import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

const LinkComponent: React.FC<LinkProps & { skipLocaleHandling?: boolean }> = ({ children, skipLocaleHandling, ...rest }) => {
  const router = useRouter()
  const locale = rest.locale || router.query.locale || ''

  let href = rest.href || router.asPath
  if ((href as string).indexOf('http') === 0) skipLocaleHandling = true
  if (locale && !skipLocaleHandling) {
    href = href ? `/${locale}${href}` : router.pathname.replace('[locale]', locale as string)
  }

  return (
    <>
      <Link {...rest} href={href}>
        {children}
      </Link>
    </>
  )
}

export default LinkComponent
