import React from 'react'
import { useDebounce } from 'use-debounce'

/**
 * Interface & types
 */
type SetSearchType = React.Dispatch<React.SetStateAction<string>>
export interface IState {
  search: string
  searchDebounced: string
  setSearch: SetSearchType
}
export type SearchOnClickType = (x: string) => void

export interface UseSearch {
  search: string
  searchDebounced: string
  setSearch: SetSearchType
  onClick: SearchOnClickType
  clearSearch: () => React.EffectCallback | void
}

/**
 * Breadcrumbs context init
 * @param search
 */
export const SearchContext = React.createContext<IState>({ search: '', searchDebounced: '', setSearch: () => null })

/**
 * useSearch hook
 * @summary changes state of the search param
 * @param {SearchOnClickType} onClick
 * @return {UseSearch}
 */
export const useSearch = (onClick: SearchOnClickType = () => undefined): UseSearch => {
  const { search, setSearch, searchDebounced } = React.useContext(SearchContext)
  const clearSearch = React.useCallback(() => setSearch(''), [setSearch])
  return {
    search,
    searchDebounced,
    setSearch,
    onClick,
    clearSearch
  }
}

/**
 * SearchContextProvider
 * @param {ReactElement} children
 * @return {ReactElement}
 */
const SearchContextProvider: React.FC = ({ children }) => {
  const [search, setSearch] = React.useState<string>('')
  const [searchDebounced] = useDebounce(search, 500)
  return (
    <SearchContext.Provider
      value={{
        search,
        searchDebounced,
        setSearch
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContextProvider
