import React from 'react'
import dynamic from 'next/dynamic'
import { Flex } from 'rebass/styled-components'

import { ICircleIcon, IIcon } from './interfaces'

const CircleIcon: React.FC<ICircleIcon> = ({ icon, size = 24, border, color = 'currentColor', filled = false, ...restprops }) => {
  const ImportedIcon: React.FC<IIcon> = React.useMemo((): any => dynamic(() => import('../bend-icons').then((module: any) => module[icon])), [icon])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        borderWidth: border ? border : 2,
        borderStyle: 'solid',
        borderColor: color === 'currentColor' ? 'black' : color,
        backgroundColor: filled ? color : 'transparent',
        borderRadius: 9999
      }}
      {...restprops}
    >
      {ImportedIcon && <ImportedIcon size={size / 2} color={filled ? 'white' : color} />}
    </Flex>
  )
}

export default CircleIcon
