import React from 'react';

interface IIcon {
  color?: string;
  size?: number;
}

const IconOffer2 = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'white', size = 26, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 26 26'
      fill={color}
      strokeWidth={0}
      {...rest}
    >
      <g>
        <path d='M9.9,12.2L9.9,12.2L9.9,12.2c0.2-0.2,0.3-0.3,0.3-0.5c0-0.2,0-0.4-0.1-0.6c-0.7-1-1-2.1-1-3.3c0-3.2,2.6-5.8,5.8-5.8
          c0,0,0,0,0,0c1.5,0,3,0.6,4.1,1.7c1.1,1.1,1.7,2.5,1.7,4.1c0,1.5-0.6,3-1.7,4.1c-1.1,1.1-2.5,1.7-4.1,1.7c0,0,0,0,0,0
          c-1.2,0-2.3-0.3-3.2-1l0,0l-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.3,0.4-0.2,0.9,0.2,1.2l0.1,0
          c1.3,0.9,2.7,1.3,4.3,1.3c4.1,0,7.4-3.3,7.4-7.4c0-4.1-3.3-7.4-7.4-7.4c-4.1,0-7.4,3.3-7.4,7.4c0,1.5,0.5,3,1.4,4.3
          C9,12.3,9.5,12.4,9.9,12.2z' />
        <path d='M25.8,19.9c-0.2-0.8-0.9-1.7-1.6-1.6c-2,0.3-4,0.7-5.9,1.2c-0.4,0.1-0.7,0.6-0.6,1c0.1,0.2,0.2,0.4,0.4,0.5
          c0.2,0.1,0.4,0.2,0.6,0.1c1.8-0.5,3.6-0.8,5.4-1.1c0,0.1,0.1,0.2,0.1,0.3c-2.9,1.1-7.2,3.2-7.5,3.4l-8,0.5
          c-0.6-0.2-1.3-0.5-1.9-0.7c-0.4-0.2-0.9,0-1.1,0.4c-0.2,0.4,0,0.9,0.4,1.1c0.6,0.3,1.3,0.6,2,0.8c0.2,0.1,0.4,0.1,0.6,0.1h0
          l8.2-0.5c0.1,0,0.2,0,0.3-0.1c0.1,0,5.1-2.4,7.9-3.5C25.8,21.4,26,20.7,25.8,19.9z' />
        <path d='M8.7,17.9c0.6,0.4,1.3,0.8,2,1.1c0.7,0.2,1.6,0.3,2.6,0.4c0.6,0.1,1.3,0.1,1.9,0.2c0.1,0.1,0.1,0.3,0.2,0.4l-4.1,0
          c0,0-0.1,0-0.1,0c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.6,0.2
          h5c0.5,0,0.8-0.4,0.8-0.8c0-1.8-0.6-2.7-1.6-2.9c-0.8-0.1-1.7-0.2-2.4-0.3c-0.8-0.1-1.6-0.1-1.9-0.2c-0.1,0-0.4-0.2-0.7-0.4
          c-0.3-0.2-0.6-0.4-0.9-0.5c-0.8-0.6-1.7-0.9-2.7-1H4.1c0,0,0,0,0,0c-0.5,0-0.8,0.4-0.8,0.8v7.8H1.7v-8.4l0.5,0c0,0,0.1,0,0.1,0
          c0.4,0,0.8-0.4,0.8-0.8c0,0,0-0.1,0-0.1c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0H0.9c-0.5,0-0.8,0.4-0.8,0.8v10c0,0.2,0.1,0.4,0.2,0.6
          c0.2,0.2,0.4,0.2,0.6,0.2c0,0,0,0,0,0h3.2c0.5,0,0.8-0.4,0.8-0.8v-7.8h1.9C7.5,17.3,8.1,17.5,8.7,17.9z' />
        <path d='M15.5,9.1c-0.3,0.2-0.9,0.2-1.5,0c-0.3-0.1-0.5-0.3-0.7-0.4c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.2l-0.3,0.4
          c-0.1,0.2-0.1,0.5,0.1,0.6c0.3,0.3,0.6,0.5,1,0.6c0.2,0.1,0.5,0.2,0.8,0.2v0.5c0,0.3,0.2,0.5,0.5,0.5H15c0.3,0,0.5-0.2,0.5-0.5
          l0-0.5c0.4-0.1,0.8-0.3,1.1-0.5c0.4-0.3,0.7-0.8,0.7-1.4c0-0.4-0.1-0.8-0.4-1.1c-0.2-0.3-0.5-0.5-0.8-0.6c-0.3-0.1-0.6-0.2-1-0.3
          c-0.3-0.1-0.5-0.2-0.7-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.2c0,0,0-0.1,0.1-0.2c0.3-0.2,0.7-0.2,1.3,0
          c0.2,0.1,0.4,0.1,0.6,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.3-0.2L17,5.6c0.1-0.2,0-0.5-0.2-0.7c-0.3-0.2-0.5-0.3-0.9-0.4
          c-0.2,0-0.3-0.1-0.5-0.1l0-0.4c0-0.3-0.2-0.5-0.5-0.5h-0.4c-0.3,0-0.5,0.2-0.5,0.5v0.4c-0.4,0.1-0.7,0.2-1,0.5
          c-0.4,0.3-0.6,0.8-0.6,1.3c0,0.4,0.1,0.8,0.4,1.1c0.2,0.3,0.5,0.4,0.8,0.6c0.2,0.1,0.6,0.2,1,0.3c0.3,0.1,0.6,0.2,0.7,0.2
          c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2C15.6,8.9,15.6,9,15.5,9.1z' />
      </g>
    </svg>
  );
});

export default IconOffer2;
