import { forwardRef, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { permalink } from 'constants/routes'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import useWindowPosition from 'hooks/common/useWindowPosition'
import isEmpty from 'lodash/isEmpty'
import useENSAvatar from 'modules/bend/hooks/useENSAvatar'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Box, Flex, FlexProps, Image, Link as LinkRebass, Text } from 'rebass/styled-components'
import Button from 'theme/ui/forms/button'
import { IconDashboard } from 'theme/ui/icons'

import { LANDING_HEADER_BOTTOM, LANDING_HEADER_HEIGHT, WINDOW_POSITION_TRIGGER } from 'components/common/header/landing-header'
import Link from 'components/common/link'
import MobileAccountSidebar from 'components/common/mobile-navigation-sidebar/account'
import SpacerDash from 'components/common/spacer-dash'

import DropDown from '../../../../components/common/dropw-down'
import { IRoutes } from '../../../../constants/types'
import Icon from '../../icons/icon'

const HEADER_HEIGHT = 88

interface INavButton extends IRoutes {}

const NavButtonAccountLanding: React.FC<INavButton> = props => {
  const { onClick, type } = props
  const { isTouch } = useResponsive()
  return type === 'separator' ? null : (
    <Flex alignItems='center'>{isTouch ? <LinkComponent onClick={onClick} as='a' {...props} /> : <LinkComponent onClick={onClick} {...props} />}</Flex>
  )
}

export default NavButtonAccountLanding

const LinkComponent = forwardRef<HTMLElement, INavButton & FlexProps>(({ route, icon, name, children, ...restprops }, ref) => {
  const { colors } = useTheme()
  const { pathname } = useRouter()
  const { isDesktop, isLaptop } = useResponsive()
  const [showMobileMenu, toggleMobileMenu] = useState(false)

  const { account } = useWeb3React()
  const { data: avatar } = useENSAvatar(account)

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    return `/${path[2]}/${path[3]}`
  }, [pathname])

  const windowPosition = useWindowPosition()
  return (
    <Flex sx={{ position: 'relative', flex: !isDesktop ? '0 0 74px' : undefined, justifyContent: 'center' }} ref={ref} {...restprops}>
      {children && !isEmpty(children) ? (
        !isLaptop ? (
          <>
            <MobileAccountSidebar showMobileMenu={showMobileMenu} toggleMobileMenu={toggleMobileMenu} />
            <Button
              size='icon'
              color={windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'header-label'}
              p={avatar ? 0 : 4.5}
              mr={15}
              outlined
              buttonStyles={{
                transition: 'all 0.3s',
                '&:hover': {
                  opacity: 1,
                  borderColor: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                  svg: {
                    transition: 'all 0.3s',
                    fill: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                    stroke: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                    path: {
                      fill: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                      stroke: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
                    }
                  }
                }
              }}
              onClick={() => toggleMobileMenu(state => !state)}
            >
              {avatar ? (
                <Image src={avatar} size={31} sx={{ borderRadius: 'sm' }} />
              ) : (
                <IconDashboard size={22} color={windowPosition > LANDING_HEADER_BOTTOM ? colors['primary'] : colors['header-label']} />
              )}
            </Button>
          </>
        ) : (
          <DropDown
            trigger={isDesktop ? 'hover' : 'click'}
            title='More'
            buttonComponent={
              <Flex
                sx={{
                  alignItems: 'center',
                  height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT
                }}
              >
                <Button
                  type='button'
                  size='icon'
                  color={windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'header-label'}
                  p={avatar ? 0 : 4.5}
                  outlined
                  buttonStyles={{
                    borderColor: !!avatar ? 'transparent' : windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'header-label',
                    transition: 'all 0.3s',
                    '&:hover': {
                      opacity: 1,
                      borderColor: !!avatar ? 'transparent' : windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                      transition: 'all 0.3s',
                      svg: {
                        transition: 'all 0.3s',
                        fill: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                        stroke: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                        path: {
                          fill: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                          stroke: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
                        }
                      }
                    }
                  }}
                >
                  {avatar ? (
                    <Image src={avatar} size={33} sx={{ borderRadius: 'sm' }} />
                  ) : (
                    <IconDashboard size={22} color={windowPosition > LANDING_HEADER_BOTTOM ? colors['primary'] : colors['header-label']} />
                  )}
                </Button>
              </Flex>
            }
            menuComponent={
              <Flex flexDirection='column'>
                {children.map(child => {
                  return child.type === 'separator' ? (
                    <Box key={child.name} my={10}>
                      <SpacerDash width='100%' height={1} bgColor={windowPosition > LANDING_HEADER_BOTTOM ? 'grey.100' : 'separator-dark'} />
                    </Box>
                  ) : child.external ? (
                    <LinkRebass
                      key={child.name}
                      href={child.route}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={child.onClick}
                      sx={{
                        '&:hover': {
                          opacity: 1
                        }
                      }}
                    >
                      <SubmenuButton child={child} />
                    </LinkRebass>
                  ) : (
                    <Link key={child.name} href={child.route} passHref>
                      <SubmenuButton as='a' child={child} />
                    </Link>
                  )
                })}
              </Flex>
            }
            menuStyle={{
              minWidth: 230,
              maxWidth: 280,
              borderRadius: 0,
              zIndex: 1001,
              top: HEADER_HEIGHT - 20,
              right: -45
            }}
            menuWrapperStyle={{
              bg: windowPosition > LANDING_HEADER_BOTTOM ? 'white' : 'header1',
              boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.16)',
              px: 20,
              borderRadius: 0
            }}
            disableModal
          />
        )
      ) : (
        <Link key={name} href={route} passHref>
          <Flex
            as='a'
            sx={{
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'center',
              px: [0, 0, 0, 14, 15],
              color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white',
              transition: 'all 0.3s',
              height: windowPosition > WINDOW_POSITION_TRIGGER ? LANDING_HEADER_HEIGHT - 40 : LANDING_HEADER_HEIGHT,
              '& svg': {
                transition: 'all 0.3s',
                color: activePathname === route ? (windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white') : 'header-label'
              },
              '&:hover': {
                cursor: 'pointer',
                color: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                '& svg': {
                  color: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
                }
              }
            }}
          >
            {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
            <Text
              as='span'
              fontSize={['sm', 'sm', 'md']}
              lineHeight='md'
              fontWeight={['700']}
              mt={['10px', '10px', '10px', '10px', 0]}
              ml={[0, 0, 0, 0, 10]}
              textAlign='center'
            >
              {name}
            </Text>
          </Flex>
        </Link>
      )}
    </Flex>
  )
})

type SubmenuProps = { child: INavButton }

const SubmenuButton = forwardRef<HTMLElement, SubmenuProps & FlexProps>(({ child, ...restprops }, ref) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    if (
      `/${path[1]}/${path[2]}` === permalink.liquidity ||
      `/${path[1]}/${path[2]}` === permalink.liquidity ||
      `/${path[1]}/${path[2]}` === permalink.auction
    ) {
      return `/${path[1]}/${path[2]}/${path[3]}`
    }
    if (`/${path[1]}/${path[2]}` === permalink.dashboard) {
      return `/${path[1]}/${path[2]}/${path[3]}`
    }
    if (`/${path[1]}/${path[2]}` === permalink.yielding) {
      return `/${path[1]}/${path[2]}/${path[3]}`
    }
    return `/${path[1]}/${path[2]}`
  }, [pathname])

  const windowPosition = useWindowPosition()

  return (
    <Flex
      fontSize={['lg', null, null, null, 'md', 'md']}
      lineHeight='md'
      fontWeight={['700']}
      my={[10]}
      alignItems='center'
      px={[10, 30, 30, 30, 0]}
      sx={{
        color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'header-label',
        transition: 'all 0.3s',
        '& svg': {
          transition: 'all 0.3s',
          color:
            activePathname === child.route
              ? windowPosition > LANDING_HEADER_BOTTOM
                ? 'primary'
                : 'white'
              : windowPosition > LANDING_HEADER_BOTTOM
              ? 'grey.300'
              : 'header-label'
        },
        '&:hover': {
          color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white',
          cursor: 'pointer',
          '& svg': {
            transition: 'all 0.3s',
            color: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
          }
        }
      }}
      ref={ref}
      {...restprops}
    >
      <Icon icon={child.icon} size={20} />
      <Text ml={10}>{child.name}</Text>
      {child.soon && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 16,
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 10
          }}
        >
          <Text fontSize={8}>{t('label.soon').toUpperCase()}</Text>
        </Flex>
      )}
      {child.isNew && (
        <Flex
          sx={{
            bg: 'green.1000',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'xs',
            px: 6,
            py: 1.5,
            // height: 14,
            ml: 15
          }}
        >
          <Text fontSize={8}>{t('label.new').toUpperCase()}</Text>
        </Flex>
      )}
    </Flex>
  )
})
