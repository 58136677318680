import React from 'react'

interface IIconStake {
  color?: string
  size?: number
}

const IconStake = React.forwardRef<SVGSVGElement, IIconStake>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='stake'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M16.8,0.3H3.2c-1.6,0-2.9,1.3-2.9,2.9v11.6c0,1.2,0.8,2.3,1.9,2.7v0.2c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9h7.7
          c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9c0,0,0,0,0,0v-0.2c1.2-0.4,1.9-1.5,1.9-2.7V3.2C19.7,1.6,18.4,0.3,16.8,0.3z
          M17.7,14.8c0,0.5-0.4,1-1,1H3.2c-0.5,0-1-0.4-1-1V3.2c0-0.5,0.4-1,1-1c0,0,0,0,0,0h13.5c0.5,0,1,0.4,1,1L17.7,14.8z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M10,4.2L10,4.2c-1.9,0-3.4,1.5-3.4,3.4c0,1.5,1,2.8,2.4,3.2v2.1c0,0.5,0.4,1,0.9,1c0.5,0,1-0.4,1-0.9c0,0,0,0,0-0.1v-2.1
          c1.8-0.5,2.8-2.4,2.3-4.2C12.8,5.2,11.5,4.2,10,4.2z M10,9C9.2,9,8.6,8.4,8.6,7.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
          C11.4,8.4,10.8,9,10,9z'
        />
      </g>
    </svg>
  )
})

export default IconStake
