import React, { forwardRef } from 'react'
import { Flex, Image, Text } from 'rebass/styled-components'

import { IAvatar } from './interfaces'

const padding = 0

const Avatar = forwardRef<HTMLElement, IAvatar>(
  (
    { hasBackground = false, iconBackground = 'primary', icon, hasBorderRadius = 16, iconSize = 48, text, align = 'left', ...restprops },
    ref
  ): React.ReactElement => {
    return (
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: align === 'left' ? 'row' : 'row-reverse'
        }}
        ref={ref}
        {...restprops}
      >
        <Flex
          sx={{
            bg: hasBackground ? iconBackground : undefined,
            padding: hasBackground ? padding : undefined,
            borderRadius: hasBorderRadius ? hasBorderRadius : 16,
            height: iconSize,
            width: iconSize,
            minWidth: iconSize,
            mr: text ? (align === 'left' ? [10, 10, 10, 20] : undefined) : undefined,
            ml: text ? (align === 'right' ? [10, 10, 10, 20] : undefined) : undefined,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {React.isValidElement(icon) ? icon : typeof icon === 'string' && <Image src={icon} width={iconSize} height={iconSize} sx={{ borderRadius: 'sm' }} />}
        </Flex>
        {React.isValidElement(text) ? (
          <Flex flexDirection='column' alignItems={align === 'left' ? 'flex-start' : 'flex-end'} justifyContent='center'>
            {text}
          </Flex>
        ) : (
          <Text fontWeight='bold'>{text}</Text>
        )}
      </Flex>
    )
  }
)

export default Avatar
