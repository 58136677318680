import React from 'react'

interface IIconShowMore {
  color?: string
  size?: number
}

const IconShowMore = React.forwardRef<SVGSVGElement, IIconShowMore>(({ color = 'currentColor', size = 32, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 32 32' fill={color} stroke={color} {...rest}>
      <g id='Group_6824' data-name='Group 6824'>
        <g id='ellipsis' transform='translate(6.842 13.711)'>
          <path id='Shape' d='M0,2.29A2.29,2.29,0,1,0,2.29,0,2.29,2.29,0,0,0,0,2.29Z' transform='translate(0)' fill={color} />
          <path id='Shape-2' data-name='Shape' d='M0,2.29A2.29,2.29,0,1,0,2.29,0,2.29,2.29,0,0,0,0,2.29Z' transform='translate(6.869)' fill={color} />
          <path id='Shape-3' data-name='Shape' d='M0,2.29A2.29,2.29,0,1,0,2.29,0,2.29,2.29,0,0,0,0,2.29Z' transform='translate(13.737)' fill={color} />
        </g>
        <g id='Ellipse_399' data-name='Ellipse 399' fill='none' stroke={color} strokeWidth='2'>
          <circle cx='16' cy='16' r='16' stroke='none' />
          <circle cx='16' cy='16' r='15' fill='none' />
        </g>
      </g>
    </svg>
  )
})

export default IconShowMore
