import React from 'react'

interface IIconStakingUserGuide {
  color?: string
  size?: number
}

const IconStakingUserGuide = React.forwardRef<SVGSVGElement, IIconStakingUserGuide>(({ color = 'currentColor', size, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 15.304 15.859' {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_3921' data-name='Rectangle 3921' width='15.304' height='15.859' transform='translate(0 0)' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_12427' data-name='Group 12427' transform='translate(0 0)' clipPath='url(#clip-path)'>
        <path
          id='Path_3971'
          data-name='Path 3971'
          d='M14.116,13.4H12.26a1.85,1.85,0,0,0-3.507,0H.6A.6.6,0,0,0,.6,14.6H8.755a1.855,1.855,0,0,0,1.753,1.257,1.875,1.875,0,0,0,.521-.075A1.845,1.845,0,0,0,12.26,14.6h1.854a.6.6,0,0,0,0-1.207m-2.953.6a.655.655,0,1,1-.655-.655.655.655,0,0,1,.655.655'
          fill={color}
        />
        <path
          id='Path_3972'
          data-name='Path 3972'
          d='M13.627,0H1.684A1.686,1.686,0,0,0,0,1.681V9.6a1.687,1.687,0,0,0,1.681,1.682H13.627A1.688,1.688,0,0,0,15.3,9.6V1.681A1.688,1.688,0,0,0,13.627,0m0,10.074H1.684A.476.476,0,0,1,1.21,9.6V1.681a.476.476,0,0,1,.474-.474H13.626a.476.476,0,0,1,.474.474l0,7.919a.476.476,0,0,1-.474.474'
          fill={color}
        />
        <path
          id='Path_3973'
          data-name='Path 3973'
          d='M10.493,5.124,6.466,2.609a.584.584,0,0,0-.452-.081.592.592,0,0,0-.377.262.6.6,0,0,0-.1.327V8.151a.6.6,0,0,0,.593.608h0a.6.6,0,0,0,.325-.1l4.028-2.513a.6.6,0,0,0,0-1.027m-1.459.514L6.749,7.069V4.208Z'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconStakingUserGuide
