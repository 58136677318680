import React, { useCallback, useState } from 'react'
import { isMobile, isMobileSafari } from 'react-device-detect'
import { createPortal } from 'react-dom'
import { AlertCircle, CheckCircle, HelpCircle, Info, X } from 'react-feather'
import useTheme from 'hooks/common/useTheme'
import isEmpty from 'lodash/isEmpty'
import { Box, Flex, FlexProps, Link } from 'rebass/styled-components'
import { AnimatePresence, Button, MotionFlex } from 'theme/ui'
import NotificationLoader from 'theme/ui/common/notification-loader'

import { AppNotification, useAppNotificationContext } from './context'

const getTransition = ({ duration = 0.3 }) => ({
  transition: {
    duration: duration,
    ease: 'easeInOut'
  }
})

const motionVariants = {
  initial: {
    opacity: 0,
    x: 260,
    scale: 0.3
  },
  animate: {
    opacity: 1,
    x: 0,
    scale: 1,
    ...getTransition({ duration: 0.3 })
  },
  exit: {
    opacity: 0,
    x: 180,
    scale: 0.5,
    ...getTransition({ duration: 0.3 })
  },
  badgeInitial: {
    scale: 0
  },
  badgeAnimate: {
    scale: 1,
    ...getTransition({ duration: 0.2 })
  },
  badgeExit: {
    scale: 0,
    ...getTransition({ duration: 0.2 })
  }
}

const AppNotificationsRight: React.FC = () => {
  const { notificationsRight } = useAppNotificationContext()

  return createPortal(
    <AnimatePresence>
      {!isEmpty(notificationsRight) && notificationsRight.map(notification => <Notification key={notification.key} notification={notification} />)}
    </AnimatePresence>,
    document.getElementById('app-notifications-right') as Element
  )
}

export default AppNotificationsRight

const Notification: React.FC<{ notification: AppNotification }> = ({ notification }) => {
  const { colors } = useTheme()
  const [hover, setHover] = useState(isMobile || isMobileSafari)
  const { removeAppNotification } = useAppNotificationContext()

  const getNotificationSettings = useCallback(
    type => {
      switch (type) {
        default:
        case 'default':
          return {
            bg: 'blue.1000',
            button: 'white',
            text: 'white',
            icon: <Info size={12} />
          }
        case 'loading':
          return {
            bg: 'blue.1000',
            button: 'white',
            text: 'white',
            icon: <NotificationLoader size={12} color={colors.blue[1000]} />
          }
        case 'success':
          return {
            bg: 'green.1000',
            button: 'white',
            text: 'white',
            icon: <CheckCircle size={12} />
          }
        case 'fail':
          return {
            bg: 'red.1000',
            button: 'white',
            text: 'white',
            icon: <AlertCircle size={12} />
          }
        case 'verify':
          return {
            bg: 'yellow.1000',
            button: 'black',
            text: 'black',
            icon: <HelpCircle size={14} color={colors.black} />
          }
      }
    },
    [colors.black, colors.blue]
  )
  return (
    <MotionFlex
      variant='app-notification-right'
      variants={motionVariants}
      initial='initial'
      animate='animate'
      exit='exit'
      key={notification.key}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        color: getNotificationSettings(notification.type).text,
        bg: getNotificationSettings(notification.type).bg
      }}
    >
      <AnimatePresence>
        {hover && (
          <MotionFlex
            as={Link}
            variant='button-badge'
            variants={motionVariants}
            initial='badgeInitial'
            animate='badgeAnimate'
            exit='badgeExit'
            sx={{
              top: -7,
              right: -7,
              bg: getNotificationSettings(notification.type).bg,
              color: getNotificationSettings(notification.type).button,
              borderColor: getNotificationSettings(notification.type).button,
              borderWidth: 2,
              borderStyle: 'solid',
              cursor: 'pointer'
            }}
            onClick={e => {
              e.preventDefault()
              removeAppNotification(notification.key)
            }}
          >
            <X size={12} />
          </MotionFlex>
        )}
      </AnimatePresence>
      <Flex sx={{ flexDirection: 'column', gap: 10 }}>
        <Flex sx={{ gap: 'sm', alignItems: 'flex-start' }}>
          <NotificationIcon spin={notification.type === 'loading'} sx={{ color: getNotificationSettings(notification.type).bg }}>
            {getNotificationSettings(notification.type).icon}
          </NotificationIcon>
          {React.isValidElement(notification.message) ? (
            notification.message
          ) : (
            <Box
              sx={{
                flex: 1,
                lineHeight: 'lg',
                mt: 1
              }}
              dangerouslySetInnerHTML={{
                __html: notification.message as string
              }}
            />
          )}
        </Flex>

        {notification.button && (
          <Button
            onClick={notification.onClick}
            color={getNotificationSettings(notification.type).button}
            size='sm'
            fontWeight='bold'
            outlined
            buttonStyles={{ mt: [10, 10, 10, 0], color: getNotificationSettings(notification.type).text }}
          >
            {notification.button}
          </Button>
        )}
      </Flex>
    </MotionFlex>
  )
}

const NotificationIcon: React.FC<FlexProps & { spin?: boolean }> = ({ children, spin = false, ...restprops }) => {
  return (
    <Flex
      sx={{
        width: 16,
        height: 16,
        bg: 'white',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        svg: {
          strokeWidth: spin ? 4 : 2.5
        },
        ...restprops.sx
      }}
    >
      {children}
    </Flex>
  )
}
