import React, { useMemo } from 'react'
import { BEND_DOCS_LINK } from 'constants/index'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { Box, Flex, FlexProps } from 'rebass/styled-components'
import { Loader } from 'theme/ui'
import { IconInfoXl, IconSuccess, IconVerify } from 'theme/ui/icons/bend-icons'
import IconError1 from 'theme/ui/icons/bend-icons/icons/icon-error-1'

import Tooltip from '../tooltip'

interface NotificationProps extends FlexProps {
  type?: 'default' | 'success' | 'fail' | 'verify' | 'warning'
  loading?: boolean
  tooltipText?: string
  tooltipLink?: string
  noicon?: boolean
  infoicon?: boolean
  reducePadding?: boolean
  loaderSize?: number
  hasBorder?: boolean
}

const Notification: React.FC<NotificationProps> = ({
  children,
  type = 'default',
  loading = false,
  tooltipText,
  tooltipLink,
  noicon = false,
  infoicon = false,
  reducePadding = false,
  loaderSize,
  hasBorder = false,
  ...restprops
}): React.ReactElement => {
  const [showLoader, setShowLoader] = React.useState(false)
  const { colors } = useTheme()
  const { t: tc } = useTranslation()
  React.useEffect(() => {
    if (loading) {
      setShowLoader(true)
    }

    if (!loading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [loading, showLoader])

  const config = useMemo(() => {
    switch (type) {
      default:
        return {
          style: {
            color: 'primary',
            bg: 'blue.100',
            borderColor: 'primary'
          },
          icon: <IconInfoXl />
        }
      case 'warning':
        return {
          style: {
            color: 'yellow.20',
            bg: 'yellow.100',
            borderColor: 'yellow.20'
          },
          icon: <IconInfoXl />
        }
      case 'fail':
        return {
          style: {
            color: 'red.1000',
            bg: 'red.200',
            borderColor: 'red.1000'
          },
          icon: <IconError1 color={colors.red[1000]} />
        }
      case 'success':
        return {
          style: {
            color: 'green.1000',
            bg: 'green.200',
            borderColor: 'green.1000'
          },
          icon: <IconSuccess />
        }
      case 'verify':
        return {
          style: {
            color: 'primary',
            bg: 'blue.200',
            borderColor: 'primary'
          },
          icon: <IconVerify />
        }
    }
  }, [colors.red, type])

  return (
    <Flex
      alignItems='center'
      flexDirection='column'
      textAlign='center'
      fontSize={13}
      minHeight={reducePadding ? undefined : 75}
      lineHeight={1.5}
      {...restprops}
      sx={{
        ...config.style,
        borderStyle: 'solid',
        borderWidth: hasBorder ? 1 : 0,
        borderRadius: 'sm',
        wordBreak: 'break-word',
        WebkitHyphens: 'auto',
        MozHyphens: 'auto',
        /*   hyphens: 'auto', */
        position: 'relative',
        m: !reducePadding ? [20, 20, 25] : 0,
        px: !reducePadding ? [20, 20, 25] : [20, 20, 25],
        py: !reducePadding ? 30 : 25,
        ...restprops.sx
      }}
    >
      {infoicon && (
        <Box
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: 12,
            right: 12
          }}
        >
          <Tooltip text={tooltipText ?? tc('label.read-more')} link={tooltipLink ?? BEND_DOCS_LINK} infoIconColor={config.style.color} />
        </Box>
      )}
      <Box mb={!noicon ? 10 : undefined}>{!loading ? !noicon ? config.icon : null : <Loader color={colors.primary} size={loaderSize ? loaderSize : 52} />}</Box>
      {children}
    </Flex>
  )
}

export default Notification
