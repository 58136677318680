import { useContext, useEffect } from 'react'
import useHasNotifications from 'hooks/common/useHasNotifications'
// Components
import { Flex, FlexProps } from 'rebass/styled-components'
import { ModalContext } from 'theme/ui'

import AppNotificationsContent from 'components/common/app-notofications/app-notifications-content'
import FooterApp from 'components/common/footer-app'
import { HEADER_HEIGHT } from 'components/common/header'
import WalletCheck from 'components/common/wallet-check'
import HomepageNotifications from 'components/home/homepage-notifications'

interface App {
  header?: React.ReactNode
}

const App: React.FC<App & FlexProps> = ({ children, header, ...restprops }): React.ReactElement => {
  const { isOpen: isModalOpen } = useContext(ModalContext)

  useEffect(() => {
    if (!isModalOpen) {
      document.querySelector('body')!.classList.remove('modal-open')
      return
    }
    document.querySelector('body')!.classList.add('modal-open')
  }, [isModalOpen])

  const hasNotifications = useHasNotifications()

  return (
    <Flex flex='auto' flexDirection='column' mt={[HEADER_HEIGHT - 20]} minHeight={`calc(100vh - ${HEADER_HEIGHT - 20}px)`}>
      {header && header}
      <Flex flexDirection='column' alignItems='center' width={['100%', '100%', 'auto']} height='100%' flex={1} {...restprops}>
        {hasNotifications && (
          <Flex flexDirection='column' width='100%' alignItems='center'>
            <HomepageNotifications />
            {/* <Breadcrumbs /> */}
            <WalletCheck />
            <AppNotificationsContent />
          </Flex>
        )}
        <Flex flexDirection='column' alignItems='center' flexGrow={1} width='100%'>
          {children}
        </Flex>
        <Flex width='100%' mt={40}>
          <FooterApp />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default App
