import React from 'react'

interface IIconBorrow {
  color?: string
  size?: number
}

const IconBorrow = React.forwardRef<SVGSVGElement, IIconBorrow>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='borrow'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M7.9,13h2.6c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.4l2.7-2.7c0.4-0.4,0.4-0.9,0-1.3c0,0,0,0,0,0
          c-0.4-0.4-0.9-0.4-1.3,0c0,0,0,0,0,0L8.8,9.9V9.6c0-0.5-0.4-0.9-0.9-0.9C7.4,8.6,7,9,7,9.6v2.6c0,0.2,0.1,0.5,0.3,0.6
          C7.4,12.9,7.6,13,7.9,13z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M10,20c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10S0,4.5,0,10C0,15.5,4.5,20,10,20z M10,1.8L10,1.8c4.5,0,8.2,3.7,8.2,8.2
          c0,0,0,0,0,0c0,4.5-3.7,8.2-8.2,8.2c0,0,0,0,0,0c-4.5,0-8.2-3.7-8.2-8.2c0,0,0,0,0,0C1.8,5.5,5.5,1.8,10,1.8z'
        />
      </g>
    </svg>
  )
})

export default IconBorrow
