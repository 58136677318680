import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDoc = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 14, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 13.844 14.108'
      fill={color}
      stroke={color}
      strokeWidth={0.2}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='Group_4934' data-name='Group 4934' transform='translate(0.5 0.751)'>
        <path
          id='Path'
          d='M10.217,6.9a.515.515,0,0,0-.514.514V9.727a.267.267,0,0,1-.267.267H1.293a.267.267,0,0,1-.267-.267V1.3a.267.267,0,0,1,.267-.267H3.51A.514.514,0,0,0,3.51,0H1.294A1.3,1.3,0,0,0,0,1.294V9.725a1.3,1.3,0,0,0,1.294,1.293H9.438a1.3,1.3,0,0,0,1.294-1.294V7.411A.518.518,0,0,0,10.217,6.9Z'
          transform='translate(0 1.839)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='1'
        />
        <path
          id='Path-2'
          data-name='Path'
          d='M2.467,8.984a.517.517,0,0,0,.729,0l2.32-2.32a.514.514,0,1,0-.726-.728L3.348,7.379V.515a.515.515,0,0,0-1.03,0V7.379L.877,5.937a.514.514,0,0,0-.728.728Z'
          transform='translate(6.135 10.466) rotate(-135)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconDoc
