import React from 'react'
import dynamic from 'next/dynamic'

import { IIcon } from './interfaces'

const Icon: React.FC<IIcon> = ({ icon, size = 24, color = 'currentColor', filled = false, style, className }) => {
  const ImportedIcon: React.FC<any> = React.useMemo((): any => dynamic(() => import('../bend-icons').then((module: any) => module[icon])), [icon])
  return ImportedIcon && <ImportedIcon size={size} color={filled ? 'white' : color} style={style} className={className} />
}

export default Icon
