import React from 'react'

interface IIconBendApe {
  color?: string
  size?: number
}

const IconApecoinPool = React.forwardRef<SVGSVGElement, IIconBendApe>(({ color = 'currentColor', size = 40 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 30.411 39.768' ref={ref}>
      <g id='Group_17312' data-name='Group 17312' transform='translate(-21.137 2.289)'>
        <g id='Group_17176' data-name='Group 17176' transform='translate(30.227 17.525)'>
          <g id='Group_17177' data-name='Group 17177' transform='translate(0 0)'>
            <path
              id='Path_4472'
              data-name='Path 4472'
              d='M6.116,0a6.116,6.116,0,1,0,6.116,6.116A6.123,6.123,0,0,0,6.116,0m0,11.358a5.242,5.242,0,1,1,5.242-5.242,5.248,5.248,0,0,1-5.242,5.242'
              fill={color}
              stroke={color}
              strokeWidth='0.5'
            />
            <path
              id='Path_4473'
              data-name='Path 4473'
              d='M132.886,130.265l-.628-.342a1.744,1.744,0,0,1-.7-.693l-.344-.626a.681.681,0,0,0-1.2,0l-.344.626a1.744,1.744,0,0,1-.7.693l-.628.342a.682.682,0,0,0,0,1.2l.628.342a1.744,1.744,0,0,1,.7.693l.344.626a.681.681,0,0,0,1.2,0l.344-.626a1.744,1.744,0,0,1,.7-.693l.628-.342a.682.682,0,0,0,0-1.2m-.727.6-.318.174a2.614,2.614,0,0,0-1.044,1.04l-.176.32-.176-.32a2.614,2.614,0,0,0-1.044-1.04l-.318-.174.318-.174a2.614,2.614,0,0,0,1.044-1.04l.176-.32.176.32a2.613,2.613,0,0,0,1.044,1.04Z'
              transform='translate(-124.505 -124.748)'
              fill={color}
              stroke={color}
              strokeWidth='0.5'
            />
          </g>
        </g>
        <g id='Group_17215' data-name='Group 17215' transform='translate(21.387 -2.039)'>
          <g id='Group_17221' data-name='Group 17221' transform='translate(0 0)'>
            <path
              id='Path_4474'
              data-name='Path 4474'
              d='M25.61,17.276a16.313,16.313,0,0,0-4.646-3.986,3.458,3.458,0,0,0-1.708-5.562l2.937-5.121A1.151,1.151,0,0,0,21.516.93,23.6,23.6,0,0,0,8.4.93a1.151,1.151,0,0,0-.677,1.677l2.937,5.121A3.456,3.456,0,0,0,8.948,13.29,16.313,16.313,0,0,0,4.3,17.276,18.868,18.868,0,0,0,0,28.914c0,8.367,6.676,10.354,14.956,10.354s14.956-1.99,14.956-10.354a18.868,18.868,0,0,0-4.3-11.638M10.469,2.778a21.347,21.347,0,0,1,8.974,0L16.909,7.2A14.111,14.111,0,0,0,13,7.2Zm.752,7.185a11.9,11.9,0,0,1,6.065-.37h.007a12.144,12.144,0,0,1,1.4.368,1.151,1.151,0,0,1-.234,2.237,11.578,11.578,0,0,0-6.992,0,1.149,1.149,0,0,1-.243-2.235m3.735,27.02C5.967,36.983,2.3,34.634,2.3,28.914A16.765,16.765,0,0,1,6.087,18.728c2.5-3.075,5.65-4.769,8.869-4.769,3.544,0,6.643,2.031,8.869,4.769A16.765,16.765,0,0,1,27.61,28.914c0,5.72-3.666,8.068-12.655,8.068'
              transform='translate(0 0)'
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  )
})

export default IconApecoinPool
