import React from 'react'

interface IIconLiquidate {
  color?: string
  size?: number
}

const IconLiquidate = React.forwardRef<SVGSVGElement, IIconLiquidate>(({ color = 'currentColor', size = 33, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 36.083 32.785' fill={color} {...rest}>
      <g id='Group_6710' data-name='Group 6710' transform='translate(-708.719 -29.663)'>
        <path
          id='Path_1173'
          data-name='Path 1173'
          d='M15.811,431.716H1.9a1.9,1.9,0,0,1-1.9-1.9v-1.265a1.9,1.9,0,0,1,1.9-1.9H15.811a1.9,1.9,0,0,1,1.9,1.9v1.265a1.9,1.9,0,0,1-1.9,1.9M1.9,427.921a.634.634,0,0,0-.632.633v1.265a.634.634,0,0,0,.632.633H15.811a.634.634,0,0,0,.632-.633v-1.265a.633.633,0,0,0-.632-.633Z'
          transform='translate(709.094 -369.642)'
          fill={color}
          stroke={color}
          strokeWidth='0.75'
        />
        <path
          id='Path_1174'
          data-name='Path 1174'
          d='M140.764,64.25a1.293,1.293,0,0,1-.952-.375l-5.222-5.222c-.914-.913.091-2.493.419-3.011a23.224,23.224,0,0,1,5.97-5.973c.521-.332,2.1-1.336,3.016-.423l5.222,5.222c.915.915-.092,2.5-.423,3.017a23.224,23.224,0,0,1-5.973,5.97A4.3,4.3,0,0,1,140.764,64.25ZM142.945,50.3c-.26.01-1.892.857-4.169,3.132s-3.124,3.91-3.071,4.278l5.16,5.115c.262-.01,1.894-.857,4.172-3.133,2.19-2.191,3.056-3.785,3.071-4.231Z'
          transform='translate(583.862 -15.269)'
          fill={color}
          stroke={color}
          strokeWidth='0.75'
        />
        <path
          id='Path_1175'
          data-name='Path 1175'
          d='M92.776,175.441a2.217,2.217,0,0,1-1.571-.65l-5.222-5.222a2.219,2.219,0,0,1,0-3.14l1.044-1.044a2.223,2.223,0,0,1,3.141,0l5.222,5.222a2.223,2.223,0,0,1,0,3.141l-1.044,1.044A2.22,2.22,0,0,1,92.776,175.441ZM88.6,166.222a.737.737,0,0,0-.519.216l-1.043,1.043a.733.733,0,0,0,0,1.038l5.222,5.222a.736.736,0,0,0,1.037,0L94.34,172.7a.734.734,0,0,0,0-1.037l-5.222-5.222A.737.737,0,0,0,88.6,166.222Z'
          transform='translate(629.335 -123.05)'
          fill={color}
          stroke={color}
          strokeWidth='0.75'
        />
        <path
          id='Path_1176'
          data-name='Path 1176'
          d='M257.523,10.7a2.217,2.217,0,0,1-1.571-.65l-5.222-5.222a2.221,2.221,0,0,1,0-3.139L251.774.649a2.224,2.224,0,0,1,3.141,0l5.222,5.222a2.223,2.223,0,0,1,0,3.14l-1.044,1.044A2.212,2.212,0,0,1,257.523,10.7Zm-4.178-9.216a.736.736,0,0,0-.519.214l-1.044,1.044a.733.733,0,0,0,0,1.035L257,9a.737.737,0,0,0,1.037,0l1.044-1.043a.734.734,0,0,0,0-1.037L253.863,1.7a.73.73,0,0,0-.518-.214m6.265,7h0Z'
          transform='translate(476.078 30.197)'
          fill={color}
          stroke={color}
          strokeWidth='0.75'
        />
        <path
          id='Path_1177'
          data-name='Path 1177'
          d='M286.324,203.79a4.314,4.314,0,0,1-2.889-1.54c-1.026-1.027-7.062-7.669-10.809-12.086a.744.744,0,0,1,1.135-.961c3.907,4.606,9.839,11.108,10.725,11.995,1.675,1.678,2.328,1.025,2.718.638a1.306,1.306,0,0,0,.458-.772c.051-.507-.329-1.18-1.1-1.947-.879-.881-7.372-6.8-12-10.728a.744.744,0,0,1,.963-1.134c4.435,3.762,11.07,9.79,12.088,10.809a4.081,4.081,0,0,1,1.523,3.146,3.169,3.169,0,0,1-2.822,2.578Z'
          transform='translate(455.268 -143.835)'
          fill={color}
          stroke={color}
          strokeWidth='0.75'
        />
      </g>
    </svg>
  )
})

export default IconLiquidate
