import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { ConnectionType, getConnectionName, injectedConnection } from 'wallet-module'

import Option from './option'

const INJECTED_PROPS = {
  color: '#010101',
  icon: '/images/arrow-right.svg',
  id: 'injected'
}

const METAMASK_PROPS = {
  color: '#E8831D',
  icon: '/images/metamask.png',
  id: 'metamask'
}

export function InstallMetaMaskOption() {
  return <Option {...METAMASK_PROPS} header='Install MetaMask' link='https://metamask.io/' />
}

export function MetaMaskOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = injectedConnection.hooks.useIsActive()
  return (
    <Option
      {...METAMASK_PROPS}
      active={isActive}
      header={getConnectionName({ connectionType: ConnectionType.INJECTED, isMetaMask: true })}
      onClick={() =>
        tryActivation({
          connector: injectedConnection.connector,
          type: ConnectionType.INJECTED
        })
      }
    />
  )
}

export function InjectedOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = injectedConnection.hooks.useIsActive()
  return (
    <Option
      {...INJECTED_PROPS}
      active={isActive}
      header={getConnectionName({ connectionType: ConnectionType.INJECTED, isMetaMask: false })}
      onClick={() =>
        tryActivation({
          connector: injectedConnection.connector,
          type: ConnectionType.INJECTED
        })
      }
    />
  )
}
