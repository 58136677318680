import React from 'react'

interface IIconArrow {
  color?: string
  size?: number
}

const IconArrow = React.forwardRef<SVGSVGElement, IIconArrow>(({ color = 'currentColor', size = 42, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 70.001 70.001' fill={color} stroke={color} {...rest}>
      <path
        id='Path_2399'
        data-name='Path 2399'
        d='M49.236,47.972,3.053,1.788h1.7A.894.894,0,1,0,4.752,0H.894A.894.894,0,0,0,0,.894V4.753a.894.894,0,1,0,1.788,0v-1.7L47.972,49.236a.894.894,0,0,0,1.265-1.264Z'
        transform='translate(70.001 35) rotate(135)'
      />
    </svg>
  )
})

export default IconArrow
