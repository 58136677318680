import React from 'react'

interface IIconDownPayment {
  color?: string
  size?: number
}

const IconDownPayment = React.forwardRef<SVGSVGElement, IIconDownPayment>(({ color = 'white', size = 28, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.293 27.28' {...rest}>
      <g id='Group_6379' data-name='Group 6379' transform='translate(0.251 0.25)'>
        <g id='Group_6380' data-name='Group 6380' transform='translate(0)'>
          <path
            id='Path_2640'
            data-name='Path 2640'
            d='M220.72,0a.954.954,0,0,0-.954.954V12.436a2.87,2.87,0,0,0,2.87,2.87H234.11a.954.954,0,0,0,.954-.954s0-.006,0-.008A14.36,14.36,0,0,0,220.72,0Zm1.916,13.39a.954.954,0,0,1-.962-.946s0-.006,0-.008V1.95a12.452,12.452,0,0,1,11.44,11.44Z'
            transform='translate(-208.272)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
          <path
            id='Path_2641'
            data-name='Path 2641'
            d='M23.579,63.9a.954.954,0,0,0-1.219.578l0,.008a10.436,10.436,0,0,1-10.5,7A10.6,10.6,0,0,1,1.93,61.436a10.461,10.461,0,0,1,7-10.377A.958.958,0,1,0,8.3,49.251,12.36,12.36,0,0,0,.013,61.486,12.553,12.553,0,0,0,11.729,73.4h.7a12.327,12.327,0,0,0,11.716-8.285.954.954,0,0,0-.569-1.222'
            transform='translate(0 -46.623)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconDownPayment
