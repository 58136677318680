import { createContext, Fragment, useContext, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { permalink } from 'constants/routes'
import { useUserContext } from 'contexts/user'
import useNotifications from 'hooks/common/useNotifications'
import useResponsive from 'hooks/common/useResponsive'
import isEmpty from 'lodash/isEmpty'
import SimpleBar from 'modules/simplebar-react'
import { useTranslation } from 'next-i18next'
import { Box, Flex } from 'rebass/styled-components'
import { Button, DrawerContext, IconMore, Spacer } from 'theme/ui'

import NotificationCard from 'components/app/dashboard/details/notification-card'
import { NotificationSkeleton } from 'components/app/dashboard/details/skeletons'

import DropDown from '../dropw-down'
import NoRecords from '../no-records'
import SpacerDash from '../spacer-dash'

import { SidebarNavButton } from './details/sidebar-nav-button'
import { VerifyAddressComponent } from './details/verify-address'
import { QuickLinks, WalletMenu, WrapWizard } from './details'

export type SidebarProps = {
  onDismiss?: () => void
}

export type SidebarContextProps = SidebarProps

export const SidebarContext = createContext<SidebarContextProps>({
  onDismiss: () => {}
})
interface NavbarRoute {
  key: number
  name: string
  icon: string
  route: string
  isActive: boolean
  hidden: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ onDismiss }) => {
  const { t: tc } = useTranslation('common')
  const { account } = useWeb3React()
  const { activePane } = useContext(DrawerContext)
  const [activeTab, setActiveTab] = useState<number>(activePane ?? 0)

  const navbarRoutes = useMemo(
    (): NavbarRoute[] => [
      { key: 0, name: tc('label.account'), icon: 'IconDashboard', route: '', isActive: activeTab === 0, hidden: false },
      { key: 1, name: tc('label.notifications'), icon: 'IconNotification', route: '', isActive: activeTab === 1, hidden: !!!account }
    ],
    [tc, activeTab, account]
  )

  const tabContent = useMemo(() => [<WalletTab key={0} />, <NotificationsTab key={1} />], [])

  return (
    <SidebarContext.Provider value={{ onDismiss }}>
      <Flex flexDirection='column' flex={1}>
        <Flex mt={-30}>
          {navbarRoutes.map((navbarRoute: NavbarRoute) => {
            if (navbarRoute.hidden) return null
            return (
              <SidebarNavButton
                key={`sidebar-nav-button-${navbarRoute.key}`}
                id={navbarRoute.key}
                name={navbarRoute.name}
                icon={navbarRoute.icon}
                onClick={() => setActiveTab(navbarRoute.key)}
                active={navbarRoute.isActive}
              />
            )
          })}
        </Flex>
        <Box mt={-18}>
          <SidebarDash />
        </Box>
        {tabContent[activeTab]}
      </Flex>
    </SidebarContext.Provider>
  )
}

export default Sidebar

export const SidebarDash: React.FC<any> = ({ py, mx, height }) => {
  return (
    <Flex py={py ? py : [15]} mx={mx ? mx : -30}>
      <SpacerDash bgColor='grey.100' height={height ? height : 4} flex={1} />
    </Flex>
  )
}

const WalletTab: React.FC = () => (
  <Fragment>
    <WalletMenu />
    <SidebarDash height={1}/>
    <QuickLinks />
    <SidebarDash height={1} />
    <WrapWizard />
    <SidebarDash py={30} height={1}/>
  </Fragment>
)

export const NotificationsTab: React.FC = () => {
  const { t: tc } = useTranslation('common')
  const [showAllNotifications, setShowAllNotifications] = useState<boolean>(true)
  const { onDismiss } = useContext(DrawerContext)
  const {
    notifications: { data, isLoading, isFetching, refetch },
    handleMarkAsRead,
    handleMarkAllAsRead
  } = useNotifications({ first: 30, isRead: showAllNotifications ? null : showAllNotifications })
  const { isLoggedIn, handleLogin } = useUserContext()
  const markAsRead = useMutation(handleMarkAllAsRead, {
    onSuccess: () => {
      refetch()
    }
  })

  return (
    <Box>
      {!isLoggedIn ? (
        <VerifyAddressComponent handleLogin={handleLogin} />
      ) : (
        <>
          <Flex justifyContent='space-between' alignItems='center' my={10} width='100%'>
            <Button flex={1} color='primary' outlined={!showAllNotifications} fontWeight='bold' onClick={() => setShowAllNotifications(true)}>
              {tc('button.show-all')}
            </Button>
            <Spacer />
            <Button flex={1} color='primary' outlined={showAllNotifications} fontWeight='bold' onClick={() => setShowAllNotifications(false)}>
              {tc('button.unread')}
            </Button>
            <Spacer />

            <Box sx={{ width: 21, height: 21 }}>
              <DropDown
                title='Mark all as read'
                buttonComponent={<MarkAsReadAllButton />}
                menuComponent={
                  <Box>
                    <Button
                      size='sm'
                      color='primary'
                      onClick={() => markAsRead.mutate()}
                      loading={markAsRead.isLoading}
                      // disabled={markAsRead.isLoading}
                      width={155}
                      buttonStyles={{ lineHeight: '14px' }}
                    >
                      Mark all as read
                    </Button>
                  </Box>
                }
                menuStyle={{
                  left: -155
                }}
                disableModal
              />
            </Box>
          </Flex>
          {isLoading || isFetching ? (
            <>
              <SidebarDash />
              <NotificationSkeleton />
              <NotificationSkeleton />
              <NotificationSkeleton />
              <NotificationSkeleton />
              <NotificationSkeleton />
            </>
          ) : (
            <>
              <SidebarDash />

              <Box sx={{ flex: 1, mx: -30 }}>
                <SimpleBar sx={{ flexDirection: 'column', maxHeight: 'calc(100vh - 268px)', px: 30 }}>
                  {data && isEmpty(data) && <NoRecords />}
                  {data?.map((notification: any) => (
                    <NotificationCard key={notification.uniqueKey} handleMarkAsRead={handleMarkAsRead} notification={notification} />
                  ))}
                </SimpleBar>
                <Flex sx={{ width: '100%', justifyContent: 'center' }}>
                  <Button
                    href={permalink.myNotifications}
                    onClick={() => {
                      if (onDismiss) onDismiss()
                    }}
                    color='primary'
                    buttonStyles={{ mt: 20, fontWeight: 'bold' }}
                    outlined
                  >
                    {tc('label.all-notifications')}
                  </Button>
                </Flex>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}

const MarkAsReadAllButton: React.FC = () => {
  const { isTouch } = useResponsive()
  const { onDismiss } = useContext(SidebarContext)
  return (
    <Button
      size='icon'
      color='transparent'
      fontColor='primary'
      onClick={() => {
        if (isTouch && onDismiss) onDismiss()
      }}
    >
      <IconMore />
    </Button>
  )
}
