import React from 'react'

interface IIconRanking {
  color?: string
  size?: number
}

const IconRanking = React.forwardRef<SVGSVGElement, IIconRanking>(({ size = 12.02, color = 'currentColor' }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12.273 12.273' ref={ref}>
      <g id='Group_7473' data-name='Group 7473' transform='translate(0.125 0.125)'>
        <g id='Group_7307' data-name='Group 7307'>
          <path
            id='Path_2752'
            data-name='Path 2752'
            d='M1.619,293.776A1.621,1.621,0,0,0,0,295.4v1.886a1.619,1.619,0,1,0,3.239,0V295.4a1.621,1.621,0,0,0-1.619-1.619m.68,3.505a.68.68,0,1,1-1.36,0V295.4a.68.68,0,0,1,1.36,0v1.886'
            transform='translate(0 -286.877)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2753'
            data-name='Path 2753'
            d='M188.656,0a1.621,1.621,0,0,0-1.619,1.619V10.4a1.619,1.619,0,1,0,3.239,0V1.619A1.621,1.621,0,0,0,188.656,0m.68,10.4a.68.68,0,1,1-1.36,0V1.619a.68.68,0,1,1,1.36,0Z'
            transform='translate(-182.645)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2754'
            data-name='Path 2754'
            d='M375.694,146.888a1.621,1.621,0,0,0-1.619,1.619v5.335a1.619,1.619,0,0,0,3.239,0v-5.335a1.621,1.621,0,0,0-1.619-1.619m.68,6.954a.68.68,0,1,1-1.36,0v-5.335a.68.68,0,1,1,1.36,0Z'
            transform='translate(-365.291 -143.439)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconRanking
