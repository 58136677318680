import React from 'react'

interface IIconYielding {
  color?: string
  size?: number
}

const IconYielding = React.forwardRef<SVGSVGElement, IIconYielding>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20.35 20.35' stroke={color} fill={color} {...rest}>
      <g id='Group_8848' data-name='Group 8848' transform='translate(12.175 7.175)'>
        <path
          id='Path_2779'
          data-name='Path 2779'
          d='M303.758,244.344a.583.583,0,0,1,.586.578h1.172a1.753,1.753,0,0,0-1.172-1.65V242h-1.172v1.273a1.758,1.758,0,0,0,.586,3.415.586.586,0,1,1-.586.586H302a1.761,1.761,0,0,0,1.172,1.657V250.2h1.172v-1.265a1.758,1.758,0,0,0-.586-3.415.586.586,0,1,1,0-1.172'
          transform='translate(-302.203 -239.547)'
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.35'
        />
        <path
          id='Path_2780'
          data-name='Path 2780'
          d='M13.555,7.109c-.2,0-.393.009-.586.027V3.516a3.093,3.093,0,0,0-2.04-2.6A10.077,10.077,0,0,0,6.523,0,10.308,10.308,0,0,0,2.083.918C.74,1.578,0,2.5,0,3.516V14.141c0,1.016.74,1.938,2.083,2.6a10.308,10.308,0,0,0,4.441.918A11.874,11.874,0,0,0,8.462,17.5a6.444,6.444,0,1,0,5.093-10.39M2.6,1.97a9.257,9.257,0,0,1,3.924-.8c3.108,0,5.273,1.235,5.273,2.344S9.631,5.859,6.523,5.859a9.257,9.257,0,0,1-3.924-.8c-.907-.445-1.428-1.009-1.428-1.546s.52-1.1,1.428-1.546M1.172,5.557a5.468,5.468,0,0,0,.911.556,10.308,10.308,0,0,0,4.441.918,10.077,10.077,0,0,0,4.405-.92,5.363,5.363,0,0,0,.868-.536V7.353a6.456,6.456,0,0,0-3,1.857,10.352,10.352,0,0,1-2.274.243,9.257,9.257,0,0,1-3.924-.8C1.692,8.21,1.172,7.646,1.172,7.109Zm0,3.594a5.468,5.468,0,0,0,.911.556,10.308,10.308,0,0,0,4.441.918,12.165,12.165,0,0,0,1.329-.072,6.4,6.4,0,0,0-.715,2.4c-.2.011-.409.017-.614.017a9.257,9.257,0,0,1-3.924-.8c-.907-.445-1.428-1.009-1.428-1.546Zm5.352,7.333a9.257,9.257,0,0,1-3.924-.8c-.907-.445-1.428-1.009-1.428-1.546V12.667a5.468,5.468,0,0,0,.911.556,10.308,10.308,0,0,0,4.441.918c.2,0,.409-.005.611-.015a6.4,6.4,0,0,0,.644,2.287,10.959,10.959,0,0,1-1.255.072m7.031,2.344a5.273,5.273,0,1,1,5.273-5.273,5.279,5.279,0,0,1-5.273,5.273'
          transform='translate(-12 -7)'
          fill={color}
          strokeLinecap='round'
          strokeWidth='0.35'
        />
      </g>
    </svg>
  )
})

export default IconYielding
