import React from 'react'

interface IIconGridList {
  color?: string
  size?: number
}

const IconGridList = React.forwardRef<SVGSVGElement, IIconGridList>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='gridlist'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M8.5,4.2h8.6c0,0,0,0,0,0c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H8.5c-0.5,0-0.9,0.4-0.9,0.9
            C7.7,3.9,8.1,4.2,8.5,4.2z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M17.1,8.9H8.5c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h8.6c0.5,0,0.9-0.4,0.9-0.9C18,9.3,17.6,8.9,17.1,8.9z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M17.1,15.4H8.5c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h8.6c0,0,0,0,0,0c0.5,0,0.9-0.4,0.9-0.9
            C18,15.8,17.6,15.4,17.1,15.4z'
        />
        <path fill={color} stroke={color} strokeWidth='0.0' d='M3.5,2C2.7,2,2,2.7,2,3.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C5.1,2.7,4.4,2,3.5,2z' />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M3.5,8.5C2.7,8.5,2,9.2,2,10c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C5.1,9.2,4.4,8.5,3.5,8.5z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M3.5,14.9c-0.8,0-1.5,0.7-1.5,1.5C2,17.3,2.7,18,3.5,18c0.8,0,1.5-0.7,1.5-1.5C5.1,15.6,4.4,14.9,3.5,14.9z'
        />
      </g>
    </svg>
  )
})

export default IconGridList
