import { createContext, useReducer } from 'react'
import { BreadcrumbsReducer, ReducerActions } from 'constants/types'

/**
 * Interfaces & types
 */
export interface IState {
  breadcrumbTitle: string
  dispatch?: any
}

/** @constant {IState} */
const initialState: IState = {
  breadcrumbTitle: ''
}

/**
 * Breadcrumbs context init
 * @param initialState
 */
export const BreadcrumbsContext = createContext(initialState)

/**
 * Context reducer
 * @param {IState} state
 * @param {string} action
 * @param {any} payload
 */
const reducer = (state: IState, { action, payload }: ReducerActions<BreadcrumbsReducer>) => {
  switch (action) {
    default:
      return state
    case BreadcrumbsReducer.update:
      return { ...state, breadcrumbTitle: payload }
  }
}

/**
 * DataContextProvider
 * @param {ReactElement} children
 * @return {ReactElement}
 */
const BreadcrumbsContextProvider: React.FC = ({ children }): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <BreadcrumbsContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export default BreadcrumbsContextProvider
