import { Contract } from '@ethersproject/contracts'
import { calculateGasMargin } from 'wallet-module'

import { DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT } from '../constants'
/**
 * @dev Deposits an `amount` of underlying asset into the reserve, receiving in return overlying bTokens.
 * - E.g. User deposits 100 USDC and gets in return 100 aUSDC
 * @param contract The contract
 * @param amount The amount to be deposited
 * @param onBehalfOf The address that will receive the bTokens, same as msg.sender if the user
 *   wants to receive them on his own wallet, or a different address if the beneficiary of bTokens
 *   is a different wallet
 * @param referralCode Code used to register the integrator originating the operation, for potential rewards.
 *   0 if the action is executed directly by the user, without any middle-man
 **/
export const depositETH = async (contract: any, amount: string, onBehalfOf_address: string, referralCode: string) => {
  console.debug('util depositETH data', amount, onBehalfOf_address, referralCode)
  const estimatedGas = await contract.estimateGas
    .depositETH(onBehalfOf_address, referralCode, {
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.depositETH(onBehalfOf_address, referralCode, {
        value: amount
      })
    })

  return contract
    .depositETH(onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.depositETH),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to depositETH', error)
      throw error
    })
}

/**
 * @dev Withdraws an `amount` of underlying asset from the reserve, burning the equivalent bTokens owned
 * E.g. User has 100 aUSDC, calls withdraw() and receives 100 USDC, burning the 100 aUSDC
 * @param contract The contract
 * @param amount The underlying amount to be withdrawn
 *   - Send the value type(uint256).max in order to withdraw the whole bToken balance
 * @param to Address that will receive the underlying, same as msg.sender if the user
 *   wants to receive it on his own wallet, or a different address if the beneficiary is a
 *   different wallet
 * @return The final amount withdrawn
 **/
export const withdrawETH = async (contract: any, amount: string, to_address: string) => {
  console.debug('util withdrawETH data', amount, to_address)
  const estimatedGas = await contract.estimateGas.withdrawETH(amount, to_address).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.withdrawETH(amount, to_address)
  })

  return contract
    .withdrawETH(amount, to_address, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.withdrawETH)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to withdrawETH', error)
      throw error
    })
}

/**
 * @dev Allows users to borrow a specific `amount` of the reserve underlying asset
 * - E.g. User borrows 100 USDC, receiving the 100 USDC in his wallet
 *   and lock collateral asset in contract
 * @param contract The contract
 * @param amount The amount to be borrowed
 * @param nftAsset The address of the underlying nft used as collateral
 * @param nftTokenId The token ID of the underlying nft used as collateral
 * @param onBehalfOf Address of the user who will receive the loan. Should be the address of the borrower itself
 * calling the function if he wants to borrow against his own collateral
 * @param referralCode Code used to register the integrator originating the operation, for potential rewards.
 *   0 if the action is executed directly by the user, without any middle-man
 **/
export const borrowETH = async (
  contract: any,
  amount: string,
  nftAsset_address: string,
  nftTokenId: string,
  onBehalfOf_address: string,
  referralCode: string
) => {
  console.debug('util borrowETH data', amount, onBehalfOf_address, referralCode, nftAsset_address, nftTokenId)
  const estimatedGas = await contract.estimateGas.borrowETH(amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.borrowETH(amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
  })

  return contract
    .borrowETH(amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.borrowETH)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to borrowETH', error)
      throw error
    })
}

/**
 * @notice Repays a borrowed `amount` on a specific reserve, burning the equivalent loan owned
 * - E.g. User repays 100 USDC, burning loan and receives collateral asset
 * @param contract The contract
 * @param nftAsset The address of the underlying NFT used as collateral
 * @param nftTokenId The token ID of the underlying NFT used as collateral
 * @param amount The amount to repay
 **/
export const repayETH = async (contract: any, nftAsset_address: string, nftTokenId: string, amount: string) => {
  console.debug('util repayETH data', nftAsset_address, nftTokenId, amount)
  const estimatedGas = await contract.estimateGas
    .repayETH(nftAsset_address, nftTokenId, amount, {
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.repayETH(nftAsset_address, nftTokenId, amount, {
        value: amount
      })
    })

  return contract
    .repayETH(nftAsset_address, nftTokenId, amount, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.repayETH),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to repayETH', error)
      throw error
    })
}

/**
 * It's a function that takes in a contract, an address, a tokenId, an amount, a bidFine, and a value,
 * and then returns a promise that resolves to a response
 * @param {Contract} contract - Contract
 * @param {string} nftAsset_address - address of the NFT contract
 * @param {string} nftTokenId - the nft token ID,
 * @param {string} amount - the amount of ETH to redeem
 * @param {string} bidFine - bid fine amount,
 * @param {string} value - the amount of ETH to send to the contract
 * @returns The response is a transaction hash.
 */
export const redeemETH = async (contract: Contract, nftAsset_address: string, nftTokenId: string, amount: string, bidFine: string, value: string) => {
  console.debug('util redeemETH data', nftAsset_address, nftTokenId, amount, bidFine, value)
  const estimatedGas = await contract.estimateGas
    .redeemETH(nftAsset_address, nftTokenId, amount, bidFine, {
      value
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.redeemETH(nftAsset_address, nftTokenId, amount, bidFine, {
        value
      })
    })

  return contract
    .redeemETH(nftAsset_address, nftTokenId, amount, bidFine, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.redeemETH),
      value
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to redeemETH', error)
      throw error
    })
}

/**
 * @dev Function to liquidate a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting liquidated, and receives
 *   the collateral asset
 * @param nftAsset_address The address of the underlying NFT used as collateral
 * @param nftTokenId The token ID of the underlying NFT used as collateral
 * @param amount Amount to pay when the total borrowed debt value is greater than the bid price
 * @return The liquidate amount, payback amount
 **/
export const liquidateETH = async (contract: any, nftAsset_address: string, nftTokenId: string, amount: string) => {
  console.debug('util liquidateETH data', nftAsset_address, nftTokenId, amount)
  const estimatedGas = await contract.estimateGas
    .liquidateETH(nftAsset_address, nftTokenId, {
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.liquidateETH(nftAsset_address, nftTokenId, {
        value: amount
      })
    })

  return contract
    .liquidateETH(nftAsset_address, nftTokenId, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.liquidateETH),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to liquidateETH', error)
      throw error
    })
}

/**
 * @dev Function to auction a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting auctiond, and receives
 *   the collateral asset
 * @param nftAsset The address of the underlying NFT used as collateral
 * @param nftTokenId The token ID of the underlying NFT used as collateral
 * @param onBehalfOf Address of the user who will get the underlying NFT, same as msg.sender if the user
 *   wants to receive them on his own wallet, or a different address if the beneficiary of NFT
 *   is a different wallet
 * @return The auction amount, payback amount
 **/
export const auctionETH = async (contract: any, nftAsset_address: string, nftTokenId: string, amount: string, onBehalfOf_address: string) => {
  console.debug('util auctionETH data', nftAsset_address, nftTokenId, amount, onBehalfOf_address)
  const estimatedGas = await contract.estimateGas
    .auctionETH(nftAsset_address, nftTokenId, onBehalfOf_address, {
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.auctionETH(nftAsset_address, nftTokenId, onBehalfOf_address, {
        value: amount
      })
    })

  return contract
    .auctionETH(nftAsset_address, nftTokenId, onBehalfOf_address, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.auctionETH),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to auctionETH', error)
      throw error
    })
}

/**
 * It takes in an array of amounts, an array of nftAsset_address, an array of nftTokenId, a
 * onBehalfOf_address, and a referralCode.
 * It then calls the batchBorrowETH function on the contract, passing in the above parameters.
 * @param {Contract} contract - Contract
 * @param {string[]} amounts - string[],
 * @param {string[]} nftAsset_address - string[],
 * @param {string[]} nftTokenId - string[],
 * @param {string} onBehalfOf_address - address of the user who is borrowing
 * @param {string} referralCode - string
 * @returns The response is a transaction hash.
 */
export const batchBorrowETH = async (
  contract: Contract,
  amounts: string[],
  nftAsset_address: string[],
  nftTokenId: string[],
  onBehalfOf_address: string,
  referralCode: string
) => {
  console.debug('util batchBorrowETH data', amounts, onBehalfOf_address, referralCode, nftAsset_address, nftTokenId)
  const estimatedGas = await contract.estimateGas.batchBorrowETH(amounts, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.batchBorrowETH(amounts, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
  })

  return contract
    .batchBorrowETH(amounts, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, nftTokenId.length * RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.borrowETH)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to batchBorrowETH', error)
      throw error
    })
}

/**
 * It takes in an array of addresses, an array of tokenIds, and an array of amounts, and then calls the
 * batchRepayETH function on the contract
 * @param {Contract} contract - Contract
 * @param {string[]} nftAsset_address - an array of addresses of the NFT assets
 * @param {string[]} nftTokenId - string[],
 * @param {string[]} amounts - string[]
 * @param {string} value - string
 * @returns The response is a transaction hash.
 */
export const batchRepayETH = async (contract: Contract, nftAsset_address: string[], nftTokenId: string[], amounts: string[], value: string) => {
  console.debug('util batchRepayETH data', nftAsset_address, nftTokenId, amounts)
  const estimatedGas = await contract.estimateGas
    .batchRepayETH(nftAsset_address, nftTokenId, amounts, {
      value
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.batchRepayETH(nftAsset_address, nftTokenId, amounts, {
        value
      })
    })

  return contract
    .batchRepayETH(nftAsset_address, nftTokenId, amounts, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, nftTokenId.length * RECOMMENDED_MIN_GAS_LIMIT.WETHGateway.repayETH),
      value
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to batchRepayETH', error)
      throw error
    })
}
