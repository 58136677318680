import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconSoloStaking = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 16 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14.297 16.508' ref={ref}>
      <path
        id='Path_4519'
        data-name='Path 4519'
        d='M1.234,11.453V4.766l5.79-3.342,3.489,2.014.008.013.008,0,2.285,1.32v6.685L7.023,14.795ZM7.023,0,0,4.055v8.11l7.023,4.055,7.023-4.056V4.055Z'
        transform='translate(0.125 0.144)'
        fill={color}
        stroke={color}
        strokeWidth='0.25'
      />
    </svg>
  )
})

export default IconSoloStaking
