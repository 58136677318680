import React from 'react'

const AvatarIconETH = React.forwardRef<SVGSVGElement>(({ ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={17.534}
      height={25.504}
      viewBox='0 0 17.534 25.504'
      fill='none'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='surface1' transform='translate(-88)'>
        <path id='Path_1145' data-name='Path 1145' d='M105.534,14.395l-8.767,3.1L88,14.395,96.767,0Z' transform='translate(0)' fill='#fff' />
        <path id='Path_1146' data-name='Path 1146' d='M112.12,323.7l-8.384,9.38-8.384-9.38,8.384,2.969Z' transform='translate(-6.969 -307.575)' fill='#fff' />
        <path id='Path_1147' data-name='Path 1147' d='M256.281,326.668l8.384-2.969-8.384,9.38Z' transform='translate(-159.514 -307.575)' fill='#7faaff' />
        <path id='Path_1148' data-name='Path 1148' d='M265.048,14.395l-8.767,3.1V0Z' transform='translate(-159.514)' fill='#7faaff' />
      </g>
    </svg>
  )
})

export default AvatarIconETH
