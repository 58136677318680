import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DATE_FORMAT_AND_TIME } from 'constants/index'
import { NotificationResult, useNotificationProps } from 'hooks/common/useNotifications'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components'
import { IconActiveAuctions, IconHFWarning, IconLoanRepay, IconMyAuctions, IconMyStakings } from 'theme/ui/icons/bend-icons'

interface NotificationProps extends FlexProps {
  text?: string | React.ReactElement
  handleMarkAsRead: any
  xxl?: boolean
  notification: Notification
}
interface Notification {
  id: number
  uniqueKey: string
  topic:
    | 'loan_warn_3'
    | 'loan_warn_2'
    | 'loan_warn_1a'
    | 'loan_warn_1b'
    | 'loan_warn_4a'
    | 'loan_warn_5a'
    | 'loan_warn_5b'
    | 'ape_staking_staked'
    | 'ape_staking_un_staked'
  readAt: string
  loading?: boolean
  createdAt: string
}

const NotificationCard: React.FC<NotificationProps> = ({ notification, handleMarkAsRead, xxl = false, ...restprops }): React.ReactElement => {
  const { id, topic, readAt, loading, createdAt } = notification
  const date = moment(createdAt).format(DATE_FORMAT_AND_TIME)
  const [showLoader, setShowLoader] = useState(false)
  const { colors } = useTheme()
  const [read, setRead] = useState(!!readAt)
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')
  const { isLaptop } = useResponsive()
  const markAsReadRef = useRef<any>()
  const notificationProps: NotificationResult = useNotificationProps(notification)

  useEffect(() => {
    if (loading) {
      setShowLoader(true)
    }

    if (!loading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [loading, showLoader])

  const config = useMemo(() => {
    switch (topic) {
      case 'loan_warn_3':
        return {
          icon: <IconActiveAuctions color={colors.primary} />
        }
      case 'loan_warn_2':
        return {
          icon: <IconMyAuctions strokeWidth='0.45' color={colors.primary} size={25} />
        }
      case 'loan_warn_5a':
        return {
          icon: <IconMyAuctions strokeWidth='0.45' color={colors.green[1000]} size={25} />
        }
      case 'loan_warn_1a':
        return {
          icon: <IconHFWarning color={colors.red[1000]} />
        }
      case 'loan_warn_1b':
        return {
          icon: <IconHFWarning color={colors.yellow[1000]} />
        }
      case 'loan_warn_5b':
        return {
          icon: <IconMyAuctions strokeWidth='0.45' color={colors.red[1000]} size={25} />
        }
      case 'loan_warn_4a':
        return {
          icon: <IconLoanRepay color={colors.green[1000]} />
        }
      case 'ape_staking_staked':
        return {
          icon: <IconMyStakings color={colors.green[1000]} size={25} />
        }
      case 'ape_staking_un_staked':
        return {
          icon: <IconMyStakings color={colors.red[1000]} size={25} />
        }
    }
  }, [colors.green, colors.primary, colors.red, colors.yellow, topic])

  if (xxl)
    return (
      <Flex variant='notification-card' sx={{ background: read ? 'grey.50' : 'white' }}>
        <Flex
          width='100%'
          flexDirection='row'
          fontSize={13}
          onMouseEnter={
            !read
              ? () => {
                  markAsReadRef.current = setTimeout(async () => {
                    const result = await handleMarkAsRead(id)
                    setRead(result)
                  }, 500)
                }
              : undefined
          }
          onMouseLeave={
            !read
              ? () => {
                  if (markAsReadRef.current) {
                    clearTimeout(markAsReadRef.current)
                  }
                }
              : undefined
          }
          sx={{
            ...restprops.sx,
            '&:hover': { cursor: !read ? 'pointer' : undefined },
            borderRadius: 'sm'
          }}
          {...restprops}
        >
          <Flex minWidth={40} justifyContent='center' mt={5} mr={[20, 20, 20, 20, 30]}>
            {config.icon}
          </Flex>
          <Flex justifyContent='space-between' width='100%' flexDirection={['column', 'column', 'column', 'row']}>
            <Flex flexDirection='column' flex={1}>
              <Text
                pr={[20, 20, 40, 60, 70]}
                as='span'
                dangerouslySetInnerHTML={{
                  __html: t(`dashboard.notification.${topic}`, { ...notificationProps })
                }}
              />
              <Flex width='100%' justifyContent='space-between' alignItems='center'>
                <Text color='grey.300' fontSize={12} mt={10}>
                  {date}
                </Text>
                {!isLaptop && (
                  <Flex color={!read ? 'primary' : 'grey.300'} fontSize={12} fontWeight='bold' alignItems='center' alignSelf='start' mt={10}>
                    {read ? tc('label.read') : tc('label.unread')}
                    <Box backgroundColor={!read ? 'primary' : 'grey.300'} sx={{ borderRadius: 'sm' }} width={10} height={10} ml={10} />
                  </Flex>
                )}
              </Flex>
            </Flex>
            {isLaptop && (
              <Flex color={!read ? 'primary' : 'grey.300'} fontSize={12} fontWeight='bold' alignItems='center' alignSelf='start'>
                {read ? tc('label.read') : tc('label.unread')}
                <Box backgroundColor={!read ? 'primary' : 'grey.300'} sx={{ borderRadius: 'sm' }} width={10} height={10} mb={[2, 2, 2, 0]} ml={10} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    )

  return (
    <Flex
      mb={10}
      flexDirection='row'
      fontSize={13}
      minHeight={75}
      onMouseEnter={
        !read
          ? () => {
              markAsReadRef.current = setTimeout(async () => {
                const result = await handleMarkAsRead(id)
                setRead(result)
              }, 500)
            }
          : undefined
      }
      onMouseLeave={
        !read
          ? () => {
              if (markAsReadRef.current) {
                clearTimeout(markAsReadRef.current)
              }
            }
          : undefined
      }
      sx={{
        ...restprops.sx,
        borderRadius: 'sm',
        '&:hover': { cursor: !read ? 'pointer' : undefined }
      }}
      {...restprops}
    >
      <Flex minWidth={40} justifyContent='flex-start' mt={5}>
        {config?.icon}
      </Flex>
      <Flex flexDirection='column' mt={5}>
        <Text
          as='span'
          dangerouslySetInnerHTML={{
            __html: t(`dashboard.notification.${topic}`, { ...notificationProps })
          }}
        />
        <Flex alignItems='center'>
          <Box backgroundColor={!read ? 'primary' : 'grey.300'} sx={{ borderRadius: 'sm' }} width={10} height={10} mr={7} mb={[2, 2, 2, 0]} />
          <Text color='grey.300' fontSize={12}>
            {date}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NotificationCard
