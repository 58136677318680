import React from 'react'

interface IIconFlashClaimHeader {
  color?: string
  size?: number
}

const IconFlashClaimHeader = React.forwardRef<SVGSVGElement, IIconFlashClaimHeader>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 11.944 20.043' fill={color} stroke={color} {...rest}>
      <path
        id='Path_2486'
        data-name='Path 2486'
        d='M3.774,19.455a.571.571,0,0,0,.708-.221L11.338,8.341a.571.571,0,0,0-.484-.876H6.278L7.981.712A.571.571,0,0,0,6.943.268L.088,11.16a.571.571,0,0,0,.484.876H5.148l-1.7,6.754A.571.571,0,0,0,3.774,19.455ZM1.606,10.893,5.993,3.923l-1,3.974a.571.571,0,0,0,.554.711H9.82L5.433,15.579l1-3.974a.571.571,0,0,0-.554-.711Z'
        transform='translate(0.259 0.271)'
        fill={color}
        stroke={color}
        strokeWidth='0.5'
      />
    </svg>
  )
})

export default IconFlashClaimHeader
