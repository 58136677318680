import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDuneAnalytics = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 16 }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 17.384 17.384' fill={color}>
      <path
        id='Path_2646'
        data-name='Path 2646'
        d='M8.692,0a8.692,8.692,0,1,0,8.692,8.692A8.7,8.7,0,0,0,8.692,0m0,16.124A7.419,7.419,0,0,1,3,13.463L16.114,9.086a7.442,7.442,0,0,1-7.422,7.038'
        fill={color}
      />
    </svg>
  )
})

export default IconDuneAnalytics
