import React, { Fragment, useCallback } from 'react'
import { X } from 'react-feather'
import isEmpty from 'lodash/isEmpty'
import { Flex, Text } from 'rebass/styled-components'
import { Button, Container, Notification } from 'theme/ui'

import { useWalletCheckContext } from '../wallet-check/context'

import { useAppNotificationContext } from './context'

const AppNotificationsContent: React.FC = () => {
  const { notificationsContent, removeAppNotification } = useAppNotificationContext()
  const { style } = useWalletCheckContext()

  const getColor = useCallback(type => {
    switch (type) {
      default:
      case 'default':
        return {
          button: 'primary',
          text: 'white'
        }
      case 'success':
        return {
          button: 'green.1000',
          text: 'white'
        }
      case 'fail':
        return {
          button: 'red.1000',
          text: 'white'
        }
      case 'verify':
        return {
          button: 'yellow.1000',
          text: 'black'
        }
    }
  }, [])

  if (isEmpty(notificationsContent)) return null

  return (
    <Fragment>
      {notificationsContent.map(notification => (
        <Container key={notification.key} variant='card-body' styles={{ maxWidth: 'content', mt: [30], mb: 10, position: 'relative', ...style }}>
          <Notification
            type={notification.type ? (notification.type === 'loading' ? 'default' : notification.type) : 'default'}
            noicon
            reducePadding
            hasBorder
            sx={{ py: [12], px: [15], pr: [35, 35, 15] }}
          >
            <Flex flexDirection={['column', 'column', 'column', 'row']} alignItems={['center']} width='100%' justifyContent={['space-between']}>
              {React.isValidElement(notification.message) ? (
                notification.message
              ) : (
                <Text
                  as='span'
                  lineHeight={['lg', 'lg', 'lg', 'md']}
                  dangerouslySetInnerHTML={{
                    __html: notification.message as string
                  }}
                />
              )}

              <Flex>
                {notification.button && (
                  <Button
                    onClick={notification.onClick}
                    color={getColor(notification.type).button}
                    size='sm'
                    fontWeight='bold'
                    buttonStyles={{ mt: [10, 10, 10, 0], color: getColor(notification.type).text }}
                  >
                    {notification.button}
                  </Button>
                )}
                <Button
                  size='icon'
                  color='transparent'
                  buttonStyles={{
                    position: ['absolute', null, null, 'relative'],
                    top: [10, null, null, 'auto'],
                    right: [10, null, null, 'auto'],
                    ml: 15,
                    color: getColor(notification.type).button
                  }}
                  onClick={() => [notification?.onClose?.(), removeAppNotification(notification.key)]}
                >
                  <X size={20} />
                </Button>
              </Flex>
            </Flex>
          </Notification>
        </Container>
      ))}
    </Fragment>
  )
}

export default AppNotificationsContent
