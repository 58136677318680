import React from 'react'

interface IIconForum {
  color?: string
  size?: number
}

const IconForum = React.forwardRef<SVGSVGElement, IIconForum>(({ color = 'currentColor', size = 18, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 17.931 17.931' fill={color} {...rest}>
      <path
        id='Path_2724'
        data-name='Path 2724'
        d='M15.241,217.979a2.848,2.848,0,0,0,.652-1.793,2.853,2.853,0,0,0-5.705,0,2.848,2.848,0,0,0,.652,1.793,6.028,6.028,0,0,0-1.875,1.63,4.481,4.481,0,0,0-1.875-1.63,2.848,2.848,0,0,0,.652-1.793,2.888,2.888,0,0,0-2.853-2.853,2.888,2.888,0,0,0-2.853,2.853,2.848,2.848,0,0,0,.652,1.793A4.9,4.9,0,0,0,0,222.3a.77.77,0,0,0,.815.815h16.3a.77.77,0,0,0,.815-.815,4.8,4.8,0,0,0-2.69-4.32M4.89,214.963h0a1.223,1.223,0,1,1,0,2.445,1.223,1.223,0,1,1,0-2.445m-3.179,6.52a3.236,3.236,0,0,1,6.276,0Zm11.329-6.52a1.223,1.223,0,1,1-1.223,1.223,1.253,1.253,0,0,1,1.223-1.223m-3.179,6.52a3.236,3.236,0,0,1,6.276,0Z'
        transform='translate(0 -205.183)'
        fill={color}
      />
      <path
        id='Path_2725'
        data-name='Path 2725'
        d='M107.481,6.519h2.364l1.141,1.3a.764.764,0,0,0,1.223,0l1.06-1.3h2.364a.77.77,0,0,0,.815-.815V.814A.77.77,0,0,0,115.631,0h-8.15a.77.77,0,0,0-.815.815V5.7a.77.77,0,0,0,.815.815m.815-4.89h6.52v3.26h-1.875a.741.741,0,0,0-.652.326l-.734.815-.734-.9a.925.925,0,0,0-.652-.245H108.3Z'
        transform='translate(-102.591 0.001)'
        fill={color}
      />
    </svg>
  )
})

export default IconForum
