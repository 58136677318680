import React from 'react'

interface IIconStake {
  color?: string
  size?: number
}

const IconDepositBorrow = React.forwardRef<SVGSVGElement, IIconStake>(({ color = 'currentColor', size = 53, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 53 53' fill={color} {...rest}>
      <g id='Group_6132' data-name='Group 6132' transform='translate(-1095.5 -663.5)'>
        <g id='Group_6126' data-name='Group 6126' transform='translate(837 555)'>
          <g id='Group_5214' data-name='Group 5214' transform='translate(60.465 233.308) rotate(-90)'>
            <path
              id='Path_1142'
              data-name='Path 1142'
              d='M-914.193-489.5l-5.983-5.983h.837a2.022,2.022,0,0,0,2.022-2.022,2.022,2.022,0,0,0-2.022-2.022h-5.717a2.019,2.019,0,0,0-1.43.592,2.019,2.019,0,0,0-.592,1.43v5.718a2.022,2.022,0,0,0,2.022,2.022,2.022,2.022,0,0,0,2.021-2.022v-.837l5.984,5.983a2.022,2.022,0,0,0,2.859,0A2.022,2.022,0,0,0-914.193-489.5Z'
              transform='translate(1025.038 723.686)'
              fill={color}
              stroke='#fff'
              strokeWidth='1.25'
            />
            <path
              id='Path_1143'
              data-name='Path 1143'
              d='M-951.946-550.393a26,26,0,0,0-26-26,26,26,0,0,0-26,26,26,26,0,0,0,26,26A26,26,0,0,0-951.946-550.393Zm-4.718,0a21.282,21.282,0,0,1-21.281,21.281,21.281,21.281,0,0,1-21.281-21.281,21.281,21.281,0,0,1,21.281-21.281A21.281,21.281,0,0,1-956.664-550.393Z'
              transform='translate(1076.254 774.928)'
              fill={color}
              stroke='#fff'
              strokeWidth='1'
            />
          </g>
        </g>
        <path
          id='Path_2480'
          data-name='Path 2480'
          d='M17.568,11.086h-3.49V7.6a1.4,1.4,0,0,0-1.5-1.5,1.49,1.49,0,0,0-1.5,1.5v3.49H7.6a1.4,1.4,0,0,0-1.5,1.5,1.64,1.64,0,0,0,1.5,1.662h3.49v3.324a1.4,1.4,0,0,0,1.5,1.5,1.49,1.49,0,0,0,1.5-1.5V14.243h3.49a1.4,1.4,0,0,0,1.5-1.5,1.64,1.64,0,0,0-1.5-1.662'
          transform='translate(1103.656 682.422)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconDepositBorrow
