import React from 'react'

const IconHighlights4 = React.forwardRef<SVGSVGElement>(() => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48.517' height='55.195' viewBox='0 0 52.517 63.195'>
      <g id='Group_5120' data-name='Group 5120' transform='translate(0.25 0.25)'>
        <g id='Group_5121' data-name='Group 5121'>
          <path
            id='Path_2375'
            data-name='Path 2375'
            d='M153.251,155.823a6.871,6.871,0,1,0-6.871-6.871,6.878,6.878,0,0,0,6.871,6.871m0-11.007a4.136,4.136,0,1,1-4.136,4.136,4.139,4.139,0,0,1,4.136-4.136'
            transform='translate(-123.377 -115.108)'
            fill='#00bc64'
            stroke='#00bc64'
            strokeWidth='0.5'
          />
          <path
            id='Path_2376'
            data-name='Path 2376'
            d='M121.7,242.739H112.72a9.093,9.093,0,0,0-9.092,9.068v8.416a2.394,2.394,0,0,0,2.422,2.422h22.3a2.394,2.394,0,0,0,2.422-2.422v-8.408a9.074,9.074,0,0,0-9.068-9.076m6.332,17.178h-2.518v-8.07a1.126,1.126,0,0,0-2.253,0v8.07H111.1v-8.07a1.126,1.126,0,0,0-2.253,0v8.07h-2.478v-8.1a6.354,6.354,0,0,1,6.348-6.34H121.7a6.339,6.339,0,0,1,6.332,6.332v8.11Z'
            transform='translate(-87.343 -199.949)'
            fill='#00bc64'
            stroke='#00bc64'
            strokeWidth='0.5'
          />
          <path
            id='Path_2377'
            data-name='Path 2377'
            d='M207.932,13.743a6.871,6.871,0,1,0-6.871-6.871,6.878,6.878,0,0,0,6.871,6.871m0-11.007A4.136,4.136,0,1,1,203.8,6.871a4.139,4.139,0,0,1,4.136-4.136'
            transform='translate(-169.465)'
            fill='#00bc64'
            stroke='#00bc64'
            strokeWidth='0.5'
          />
          <path
            id='Path_2378'
            data-name='Path 2378'
            d='M190.246,103.271a9.015,9.015,0,0,0-6.413-2.663h-9.124a9.059,9.059,0,0,0-7.282,3.7,1.366,1.366,0,1,0,2.2,1.625,6.315,6.315,0,0,1,5.077-2.583h9.14a6.346,6.346,0,0,1,6.332,6.332v8.11h-2.518v-8.07a1.126,1.126,0,1,0-2.253,0v8.07h-7.056a1.368,1.368,0,1,0,0,2.736h12.133a2.394,2.394,0,0,0,2.422-2.422v-8.424h.008a8.981,8.981,0,0,0-2.663-6.413'
            transform='translate(-140.892 -84.798)'
            fill='#00bc64'
            stroke='#00bc64'
            strokeWidth='0.5'
          />
          <path
            id='Path_2379'
            data-name='Path 2379'
            d='M49.571,72.111A6.871,6.871,0,1,0,42.7,65.239a6.878,6.878,0,0,0,6.871,6.871m0-11.015a4.136,4.136,0,1,1-4.136,4.136A4.139,4.139,0,0,1,49.571,61.1'
            transform='translate(-35.99 -49.196)'
            fill='#00bc64'
            stroke='#00bc64'
            strokeWidth='0.5'
          />
          <path
            id='Path_2380'
            data-name='Path 2380'
            d='M14.45,176.205H7.5v-8.078a1.126,1.126,0,1,0-2.253,0v8.078H2.727v-8.11a6.349,6.349,0,0,1,6.356-6.332l9.036.008a6.075,6.075,0,0,1,2.325.451,1.424,1.424,0,0,0,.515.1,1.365,1.365,0,0,0,.515-2.631,8.855,8.855,0,0,0-3.347-.652l-9.036-.008A9.093,9.093,0,0,0,0,168.095v8.416a2.394,2.394,0,0,0,2.422,2.422H14.45a1.364,1.364,0,1,0,0-2.727'
            transform='translate(0 -134.036)'
            fill='#00bc64'
            stroke='#00bc64'
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconHighlights4
