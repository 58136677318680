import React from 'react'

interface IIconNFTCustodyHeader {
  color?: string
  size?: number
}

const IconNFTCustodyHeader = React.forwardRef<SVGSVGElement, IIconNFTCustodyHeader>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18.157 20.965' fill={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2361' data-name='Rectangle 2361' width='18.157' height='20.965' fill={color} />
        </clipPath>
      </defs>
      <path
        id='Path_2472'
        data-name='Path 2472'
        d='M1.595,14.8V6.161l7.484-4.32,4.51,2.6.01.017.01-.005,2.954,1.706V14.8l-7.483,4.32ZM9.078,0,0,5.241V15.724l9.078,5.241,9.078-5.243V5.241Z'
        fill={color}
      />
      <g id='Group_6003' data-name='Group 6003'>
        <g id='Group_6002' data-name='Group 6002' clipPath='url(#clip-path)'>
          <path
            id='Path_2473'
            data-name='Path 2473'
            d='M84.631,94.3a3.319,3.319,0,0,0-2.007-1.159V90.883a.541.541,0,1,0-1.081,0v2.261A3.319,3.319,0,0,0,79.536,94.3l-1.959-1.131a.541.541,0,0,0-.541.937l1.958,1.13a3.305,3.305,0,0,0,0,2.317l-1.957,1.131a.541.541,0,1,0,.54.937l1.958-1.131a3.323,3.323,0,0,0,2.007,1.159v2.261a.541.541,0,1,0,1.081,0V99.653a3.321,3.321,0,0,0,2.007-1.159l1.958,1.131.035.02h0a.541.541,0,0,0,.5-.956l-1.957-1.131a3.305,3.305,0,0,0,0-2.317l1.958-1.13.073-.043v-.009a.542.542,0,0,0,.125-.688.541.541,0,0,0-.739-.2Zm-2.547,4.312A2.217,2.217,0,1,1,84.3,96.4a2.219,2.219,0,0,1-2.217,2.217'
            transform='translate(-73.005 -85.916)'
            fill={color}
          />
          <path
            id='Path_2474'
            data-name='Path 2474'
            d='M174.81,202.919a.541.541,0,1,0,.541.541.541.541,0,0,0-.541-.541'
            transform='translate(-165.731 -192.977)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconNFTCustodyHeader
