import React from 'react'

interface IIconLooksRare {
  color?: string
  size?: number
}

const IconLooksRare = React.forwardRef<SVGSVGElement, IIconLooksRare>(({ size = 17 }, ref) => {
  return (
    <svg
      ref={ref}
      id='Group_5104'
      data-name='Group 5104'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={size}
      height={size}
      viewBox='0 0 17 17'
    >
      <g id='Group_6390' data-name='Group 6390' transform='translate(0 0)'>
        <g id='Group_6391' data-name='Group 6391' transform='translate(0 0)'>
          <path id='Path_2642' data-name='Path 2642' d='M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0' transform='translate(0 0)' fill='#0ce466' />
          <path
            id='Path_2643'
            data-name='Path 2643'
            d='M11.165,11.635a1.658,1.658,0,1,1,1.658-1.658,1.658,1.658,0,0,1-1.658,1.658m-.72-1.658a.721.721,0,1,0,.72-.72.72.72,0,0,0-.72.72'
            transform='translate(-3.165 -2.769)'
            fillRule='evenodd'
          />
          <path
            id='Path_2644'
            data-name='Path 2644'
            d='M4,9.223,7.168,6.05h4.324l3.171,3.173L9.331,14.554Zm7.784-1.155a3.465,3.465,0,0,0-4.9,0L5.727,9.221,6.88,10.374a3.465,3.465,0,0,0,4.9,0l1.153-1.153Z'
            transform='translate(-1.331 -2.014)'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconLooksRare
