import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconBtoken = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M4.3,12.3h0.3l0,1l-1.7-0.1l-0.4,0
          l0.5-1L4.3,12.3z M13.9,9l2-0.5l0.3,0.4l0.3,0.6l0,0l-2.4,0L13.9,9z M7.9,6L7,5.9L5.9,5.7l-0.6,0l0.2-1l1,0.1l1.4,0.2L8.5,5L7.9,6z
          M7.2,7l-0.7,1L5.2,7.8l-0.3,0l0.2-1.1l1.8,0.2L7.2,7z M10.9,6.8l-0.7-0.2l-1-0.3L9,6.2l0.7-1l1,0.3L12,5.9L12.2,6L10.9,6.8z
          M7.6,8.1l0.7-1L9,7.3l0.8,0.2L8.5,8.4L7.6,8.1z M13.1,8.1l-0.3-0.3l-0.7-0.4l1.3-0.8L14.2,7l0.9,0.6l0,0L13.1,8.1z M9.8,8.8L11,8
          l0.5,0.2l0.3,0.2L9.9,8.9L9.8,8.8z M11.6,9.6l1.2-0.3L13,9.4L13,9.6L11.6,9.6z M13,10.9l-1.1-0.3l1.3,0l-0.1,0.2L13,10.9z M13,12.4
          l0.3-0.3l1.9,0.4l-0.8,0.6l-0.8,0.4l-1.2-0.8L13,12.4z M12,11.8l-0.6,0.3l-1.2-0.7l0,0L12,11.8z M10.7,13.5l0.6-0.2l1.2,0.7
          l-1.5,0.5l-1,0.3l-0.6-0.9L10.7,13.5z M10.1,12.6l-0.7,0.2l-0.7,0.1L8.2,12l0.8-0.2L10.1,12.6z M5.6,13.3l0-1l1.1,0l0.3,0l0.6,0.9
          l-1.4,0.2L5.6,13.3z M5.6,14.3l0.7,0l1.2-0.1l0.7-0.1L8.8,15l-2.2,0.3l-0.9,0.1L5.6,14.3z M16.6,10.5l-0.2,0.7l-0.3,0.4l-2-0.5
          l0.2-0.4l0-0.1L16.6,10.5z M3.5,6.7l0.5,0L3.9,7.8l-1.4,0l-0.4,0L1.9,6.8L3.5,6.7z M1.6,8.9l1.8-0.1l0.9,0l0.8,0l0.8,0.1l1.4,0.2
          l0.6,0.1l0.5,0.2l0.8,0.3l0.3,0.2l0.2,0.1l0.1,0.2l-0.3,0.2l-0.6,0.3l-0.5,0.1l-1.1,0.2l-0.6,0.1l-0.7,0l-1.6,0l-1.7-0.2l-0.8-0.1
          l-0.6-0.1c0-0.3,0-0.5,0-0.8c0-0.3,0-0.7,0.1-1L1.6,8.9z M1.6,12l0.2,0l-0.3,0.6c-0.1-0.2-0.1-0.5-0.2-0.7L1.6,12z M2.8,14.3
          l1.2,0.1l0.6,0l0,1l-1.6,0c-0.3-0.4-0.5-0.7-0.7-1.1L2.8,14.3z M3.6,4.6l0.8,0l-0.2,1l-0.7,0l-1.1,0l-0.1,0C2.5,5.3,2.7,5,3,4.6
          L3.6,4.6z M5.1,16.4l1.6-0.1l1.5-0.2l1.5-0.3l1.4-0.4l1.4-0.4l1.3-0.5L15,14l0.9-0.6l0.8-0.8l0.5-0.7l0.4-0.9l0.1-0.9l-0.2-0.9
          l-0.4-0.9l-0.6-0.8l-0.8-0.8l-1-0.7l-1.1-0.6l-1.3-0.5L11,4.5L9.5,4.1L8,3.9L6.6,3.7L5.1,3.6l-1.1,0c1.6-1.5,3.7-2.4,6-2.4
          c4.9,0,8.9,4,8.9,8.9c0,4.9-4,8.9-8.9,8.9c-2.3,0-4.5-0.9-6.1-2.4L5.1,16.4z'
        />
      </g>
    </svg>
  )
})

export default IconBtoken
