import React from 'react'

interface IIconProfile {
  color?: string
  size?: number
}

const IconProfile = React.forwardRef<SVGSVGElement, IIconProfile>(({ color = 'currentColor', size = 30, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 30.289 30.255'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='solution' transform='translate(-0.012)'>
        <path
          id='Shape'
          d='M1.218,14.48a1.246,1.246,0,0,1-.227-.021,1.217,1.217,0,0,1-.969-1.424,6.543,6.543,0,0,1,4-4.865,4.458,4.458,0,1,1,4.921,0,6.542,6.542,0,0,1,4,4.865,1.224,1.224,0,0,1-.966,1.424,1.651,1.651,0,0,1-.224.02,1.218,1.218,0,0,1-1.193-.989,4.146,4.146,0,0,0-8.144,0A1.217,1.217,0,0,1,1.218,14.48ZM6.482,2.428A2.027,2.027,0,1,0,8.506,4.456,2.029,2.029,0,0,0,6.482,2.428Z'
          transform='translate(17.303 15.76)'
          fill={color}
        />
        <path
          id='Shape-2'
          data-name='Shape'
          d='M27.858,0H2.431A2.434,2.434,0,0,0,0,2.431V27.824a2.434,2.434,0,0,0,2.431,2.431h9.353a1.216,1.216,0,0,0,0-2.431H2.431V2.431H27.858V13.912a1.216,1.216,0,0,0,2.431,0V2.431A2.434,2.434,0,0,0,27.858,0Z'
          transform='translate(0.012)'
          fill={color}
        />
        <path
          id='Shape-3'
          data-name='Shape'
          d='M1.215,15.4a1.216,1.216,0,0,1,0-2.431H7.429a1.216,1.216,0,1,1,0,2.431Zm0-6.483a1.216,1.216,0,0,1,0-2.431H7.429a1.216,1.216,0,1,1,0,2.431Zm0-6.483A1.216,1.216,0,0,1,1.215,0H11.75a1.216,1.216,0,1,1,0,2.431Z'
          transform='translate(4.368 6.483)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconProfile
