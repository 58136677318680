import { useCallback } from 'react'
import { useRouter } from 'next/router'

export const useRedirectLocale = () => {
  const {
    query: { locale }
  } = useRouter()

  const formatLink = useCallback(
    (to: string) => {
      return `/${locale}${to}`
    },
    [locale]
  )

  return { formatLink }
}
