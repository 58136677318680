import { useMemo } from 'react'
import { Interface } from '@ethersproject/abi'
import { useWeb3React } from '@web3-react/core'
import { useBlockNumber } from 'wallet-module'

import { Call, CallState, ListenerOptions, OptionalMethodInputs } from '../interfaces'
import { isValidMethodArgs, toCallState } from '../utils'

import { useCallsData } from './useCallsData'

export function useMultipleContractSingleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: OptionalMethodInputs,
  options?: ListenerOptions
): CallState[] {
  const { account } = useWeb3React()
  const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName])
  const callData: string | undefined = useMemo(() => {
    if (!account || fragment?.inputs?.length !== callInputs?.length) return undefined
    return fragment && isValidMethodArgs(callInputs) ? contractInterface.encodeFunctionData(fragment, callInputs) : undefined
  }, [account, callInputs, contractInterface, fragment])

  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses.map<Call | undefined>(address => {
            return address && callData
              ? {
                  address,
                  callData
                }
              : undefined
          })
        : [],
    [addresses, callData, fragment]
  )

  const results = useCallsData(calls, options)

  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return results.map(result => toCallState(result, contractInterface, fragment, latestBlockNumber))
  }, [fragment, results, contractInterface, latestBlockNumber])
}
