import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconBorrowAndLend = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 27.505, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.505 27.505' fill={color} {...rest}>
      <g id='Group_5483' data-name='Group 5483' transform='translate(-73.482 -199.689)'>
        <path
          id='Path_1142'
          data-name='Path 1142'
          d='M-920.327-494.272l-3.135-3.135h.438a1.06,1.06,0,0,0,1.06-1.059,1.06,1.06,0,0,0-1.06-1.059h-3a1.057,1.057,0,0,0-.749.31,1.057,1.057,0,0,0-.31.749v3a1.06,1.06,0,0,0,1.06,1.06,1.06,1.06,0,0,0,1.059-1.06v-.439l3.135,3.135a1.059,1.059,0,0,0,1.5,0A1.059,1.059,0,0,0-920.327-494.272Z'
          transform='translate(1008.621 712.005)'
          fill={color}
        />
        <path
          id='Path_2416'
          data-name='Path 2416'
          d='M6.752,5.254,3.617,2.119h.438A1.059,1.059,0,1,0,4.055,0h-3A1.059,1.059,0,0,0,0,1.059v3a1.059,1.059,0,1,0,2.119,0V3.617L5.254,6.752a1.059,1.059,0,0,0,1.5-1.5Z'
          transform='translate(93.697 214.65) rotate(180)'
          fill={color}
        />
      </g>
      <path
        id='Path_1143'
        data-name='Path 1143'
        d='M-976.441-562.641a13.752,13.752,0,0,0-13.752-13.752,13.752,13.752,0,0,0-13.752,13.752,13.752,13.752,0,0,0,13.752,13.752A13.752,13.752,0,0,0-976.441-562.641Zm-2.5,0a11.256,11.256,0,0,1-11.256,11.256,11.256,11.256,0,0,1-11.256-11.256A11.256,11.256,0,0,1-990.194-573.9,11.256,11.256,0,0,1-978.937-562.641Z'
        transform='translate(1003.946 576.393)'
        fill={color}
      />
    </svg>
  )
})

export default IconBorrowAndLend
