import React from 'react'

interface IIconCircleX {
  color?: string
  size?: number
}

const IconCircleX = React.forwardRef<SVGSVGElement, IIconCircleX>(({ color = 'currentColor', size = 16.5 }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16.5 16.5' fill={color} stroke={color}>
      <path
        id='Path'
        d='M6.578.164a.563.563,0,0,0-.8,0L3.372,2.583.961.165a.562.562,0,0,0-.8.794L2.578,3.38.164,5.8a.562.562,0,1,0,.8.794L3.372,4.176,5.783,6.6a.562.562,0,1,0,.8-.794L4.166,3.38,6.578.959A.561.561,0,0,0,6.578.164Z'
        transform='translate(4.879 4.87)'
        fill='#ef5350'
        stroke='#ef5350'
        strokeWidth='0.25'
      />
      <path
        id='Subtraction_143'
        data-name='Subtraction 143'
        d='M8,16A8,8,0,0,1,2.343,2.342,8,8,0,0,1,13.657,13.656,7.946,7.946,0,0,1,8,16ZM8,1.124A6.875,6.875,0,1,0,8,14.875a6.85,6.85,0,0,0,4.861-2.015A6.873,6.873,0,0,0,8,1.124Z'
        transform='translate(0.25 0.25)'
        fill='#ef5350'
        stroke='#ef5350'
        strokeMiterlimit='10'
        strokeWidth='0.25'
      />
    </svg>
  )
})

export default IconCircleX
