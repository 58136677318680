'use strict'

var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault')

Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.isEventFromHandle = isEventFromHandle
exports.isValueOutOfRange = isValueOutOfRange
exports.isNotTouchEvent = isNotTouchEvent
exports.getClosestPoint = getClosestPoint
exports.getPrecision = getPrecision
exports.getMousePosition = getMousePosition
exports.getTouchPosition = getTouchPosition
exports.getHandleCenterPosition = getHandleCenterPosition
exports.ensureValueInRange = ensureValueInRange
exports.ensureValuePrecision = ensureValuePrecision
exports.pauseEvent = pauseEvent
exports.calculateNextValue = calculateNextValue
exports.getKeyboardValueMutator = getKeyboardValueMutator

var _toConsumableArray2 = _interopRequireDefault(require('@babel/runtime/helpers/toConsumableArray'))

var _reactDom = require('react-dom')

var _KeyCode = _interopRequireDefault(require('rc-util/lib/KeyCode'))

function isEventFromHandle(e, handles) {
  try {
    return Object.keys(handles).some(function (key) {
      return e.target === (0, _reactDom.findDOMNode)(handles[key])
    })
  } catch (error) {
    return false
  }
}

function isValueOutOfRange(value, _ref) {
  var min = _ref.min,
    max = _ref.max
  return value < min || value > max
}

function isNotTouchEvent(e) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0)
}

function getClosestPoint(val, _ref2) {
  var marks = _ref2.marks,
    step = _ref2.step,
    min = _ref2.min,
    max = _ref2.max
  var points = Object.keys(marks).map(parseFloat)

  if (step !== null) {
    var baseNum = Math.pow(10, getPrecision(step))
    var maxSteps = Math.floor((max * baseNum - min * baseNum) / (step * baseNum))
    var steps = Math.min((val - min) / step, maxSteps)
    var closestStep = Math.round(steps) * step + min
    points.push(closestStep)
  }

  var diffs = points.map(function (point) {
    return Math.abs(val - point)
  })
  return points[diffs.indexOf(Math.min.apply(Math, (0, _toConsumableArray2.default)(diffs)))]
}

function getPrecision(step) {
  var stepString = step.toString()
  var precision = 0

  if (stepString.indexOf('.') >= 0) {
    precision = stepString.length - stepString.indexOf('.') - 1
  }

  return precision
}

function getMousePosition(vertical, e) {
  return vertical ? e.clientY : e.pageX
}

function getTouchPosition(vertical, e) {
  return vertical ? e.touches[0].clientY : e.touches[0].pageX
}

function getHandleCenterPosition(vertical, handle) {
  var coords = handle.getBoundingClientRect()
  return vertical ? coords.top + coords.height * 0.5 : window.pageXOffset + coords.left + coords.width * 0.5
}

function ensureValueInRange(val, _ref3) {
  var max = _ref3.max,
    min = _ref3.min

  if (val <= min) {
    return min
  }

  if (val >= max) {
    return max
  }

  return val
}

function ensureValuePrecision(val, props) {
  var step = props.step
  var closestPoint = isFinite(getClosestPoint(val, props)) ? getClosestPoint(val, props) : 0 // eslint-disable-line

  return step === null ? closestPoint : parseFloat(closestPoint.toFixed(getPrecision(step)))
}

function pauseEvent(e) {
  e.stopPropagation()
  e.preventDefault()
}

function calculateNextValue(func, value, props) {
  //console.log('calculateNextValue')
  var operations = {
    increase: function increase(a, b) {
      return a + b
    },
    decrease: function decrease(a, b) {
      return a - b
    }
  }
  var indexToGet = operations[func](Object.keys(props.marks).indexOf(JSON.stringify(value)), 1)
  var keyToGet = Object.keys(props.marks)[indexToGet]

  if (props.step) {
    return operations[func](value, props.step)
  }

  if (!!Object.keys(props.marks).length && !!props.marks[keyToGet]) {
    return props.marks[keyToGet]
  }

  return value
}

function getKeyboardValueMutator(e, vertical, reverse) {
  var increase = 'increase'
  var decrease = 'decrease'
  var method = increase

  switch (e.keyCode) {
    case _KeyCode.default.UP:
      method = vertical && reverse ? decrease : increase
      break

    case _KeyCode.default.RIGHT:
      method = !vertical && reverse ? decrease : increase
      break

    case _KeyCode.default.DOWN:
      method = vertical && reverse ? increase : decrease
      break

    case _KeyCode.default.LEFT:
      method = !vertical && reverse ? increase : decrease
      break

    case _KeyCode.default.END:
      return function (value, props) {
        return props.max
      }

    case _KeyCode.default.HOME:
      return function (value, props) {
        return props.min
      }

    case _KeyCode.default.PAGE_UP:
      return function (value, props) {
        return value + props.step * 2
      }

    case _KeyCode.default.PAGE_DOWN:
      return function (value, props) {
        return value - props.step * 2
      }

    default:
      return undefined
  }

  return function (value, props) {
    return calculateNextValue(method, value, props)
  }
}
