import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMessage = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 35, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 35 35'
      fill='none'
      stroke={color}
      strokeWidth='0.3'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Shape'
        d='M14.123,1.765A1.766,1.766,0,1,1,15.888,3.53,1.765,1.765,0,0,1,14.123,1.765Zm-7.061,0A1.765,1.765,0,1,1,8.827,3.53,1.764,1.764,0,0,1,7.062,1.765ZM0,1.765A1.765,1.765,0,1,1,1.765,3.53,1.765,1.765,0,0,1,0,1.765Z'
        transform='translate(8.659 14.159)'
        fill='#fff'
        stroke='#fff'
        strokeWidth='1'
      />
      <path
        id='Shape-2'
        data-name='Shape'
        d='M6.178,34a1.2,1.2,0,0,1-1.185-1.056l-.767-6.527A15.346,15.346,0,0,1,0,15.915,15,15,0,0,1,1.349,9.69,15.882,15.882,0,0,1,5.012,4.629a17,17,0,0,1,5.4-3.392,18.184,18.184,0,0,1,13.184,0,17.04,17.04,0,0,1,5.4,3.392A15.829,15.829,0,0,1,32.655,9.69a15.065,15.065,0,0,1,0,12.453A15.919,15.919,0,0,1,28.988,27.2a17.054,17.054,0,0,1-5.4,3.394,18.213,18.213,0,0,1-11.9.445l-4.922,2.8A1.212,1.212,0,0,1,6.178,34ZM17,2.389a15.578,15.578,0,0,0-5.712,1.072A14.641,14.641,0,0,0,6.643,6.379a13.48,13.48,0,0,0-3.115,4.3,12.572,12.572,0,0,0-1.137,5.237,12.957,12.957,0,0,0,3.821,9.122,1.193,1.193,0,0,1,.337.7l.6,5.137,3.814-2.167a1.2,1.2,0,0,1,.6-.159,1.178,1.178,0,0,1,.38.062,15.772,15.772,0,0,0,10.769-.241,14.7,14.7,0,0,0,4.646-2.918,13.447,13.447,0,0,0,3.115-4.3,12.615,12.615,0,0,0,0-10.473,13.5,13.5,0,0,0-3.115-4.3,14.589,14.589,0,0,0-4.646-2.918A15.611,15.611,0,0,0,17,2.389Z'
        transform='translate(0.5 0.5)'
        fill='#fff'
        stroke='#fff'
        strokeWidth='1'
      />
    </svg>
  )
})

export default IconMessage
