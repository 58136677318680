import React from 'react'

interface IIconActiveAuctions {
  color?: string
  size?: number
}

const IconActiveAuctions2 = React.forwardRef<SVGSVGElement, IIconActiveAuctions>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14.469 20.251' fill={color} {...rest}>
      <g id='Group_8537' data-name='Group 8537' transform='translate(0.125 0.125)'>
        <path
          id='Path_3475'
          data-name='Path 3475'
          d='M13.739,3.2h0L11.06.492A1.672,1.672,0,0,0,9.88,0H4.34A1.673,1.673,0,0,0,3.159.492L.481,3.2A1.65,1.65,0,0,0,0,4.364V9.909a1.649,1.649,0,0,0,.487,1.174l2.675,2.673a1.651,1.651,0,0,0,1.175.486h.678v3.672A2.089,2.089,0,0,0,7.1,20h.017a2.089,2.089,0,0,0,2.087-2.087V14.242h.678a1.651,1.651,0,0,0,1.175-.486l2.675-2.673a1.649,1.649,0,0,0,.487-1.174V4.364A1.65,1.65,0,0,0,13.739,3.2M8.033,17.914a.916.916,0,0,1-.915.915H7.1a.916.916,0,0,1-.915-.915V14.242H8.033Zm5.014-8.005a.485.485,0,0,1-.143.345l-2.675,2.673a.487.487,0,0,1-.346.143H4.336a.487.487,0,0,1-.346-.143L1.315,10.254a.485.485,0,0,1-.143-.345V4.364a.485.485,0,0,1,.141-.344l2.678-2.7a.493.493,0,0,1,.348-.145H9.88a.493.493,0,0,1,.348.145l2.678,2.7a.485.485,0,0,1,.141.344Z'
          transform='translate(0)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_3476'
          data-name='Path 3476'
          d='M122.311,75.752a8.915,8.915,0,0,0-1.007-.369c-.362-.118-.594-.2-.751-.266a1.165,1.165,0,0,1-.4-.276.549.549,0,0,1-.138-.4s0,0,0-.006a.458.458,0,0,1,.232-.4,1.373,1.373,0,0,1,.749-.177,2.8,2.8,0,0,1,.783.126,3.314,3.314,0,0,1,.831.381l.09.057a.078.078,0,0,0,.113-.033l.424-.912a.078.078,0,0,0-.028-.1l-.068-.044a3.9,3.9,0,0,0-1.642-.589v-.971a.078.078,0,0,0-.078-.078H120.6a.078.078,0,0,0-.078.078v.982a2.379,2.379,0,0,0-1.234.535l0,0a1.7,1.7,0,0,0-.243,2.2l0,0a1.881,1.881,0,0,0,.707.56,8.169,8.169,0,0,0,1,.361c.353.108.6.19.763.261a1.179,1.179,0,0,1,.415.283.57.57,0,0,1,.139.407s0,0,0,.006a.567.567,0,0,1-.269.494,1.509,1.509,0,0,1-.856.21,2.5,2.5,0,0,1-.98-.215,2.936,2.936,0,0,1-.922-.615l-.091-.09a.078.078,0,0,0-.124.02l-.462.891a.078.078,0,0,0,.016.093l.052.049a3.7,3.7,0,0,0,1.024.676,3.566,3.566,0,0,0,1.047.292v1.072a.078.078,0,0,0,.078.078h.825a.078.078,0,0,0,.078-.078V79.169a2.455,2.455,0,0,0,1.286-.571,1.652,1.652,0,0,0,.556-1.277,1.6,1.6,0,0,0-.3-1.006,1.9,1.9,0,0,0-.712-.563'
          transform='translate(-113.732 -68.892)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconActiveAuctions2
