import React from 'react'

interface IIconPresaleWho {
  color?: string
}

const IconPresaleWho = React.forwardRef<SVGSVGElement, IIconPresaleWho>(({ color = 'currentColor' }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width='28.976' height='32.043' viewBox='0 0 28.976 32.043' color={color}>
      <g id='team' transform='translate(-0.016 -0.012)'>
        <path
          id='Shape'
          d='M13.474,32.043H3.338A3.293,3.293,0,0,1,0,28.8V22.926a8,8,0,0,1,2.469-5.764,8.605,8.605,0,0,1,2.48-1.67,7.42,7.42,0,0,1-2.658-2.322A7,7,0,0,1,1.016,9.145,7.293,7.293,0,0,1,8.4,1.969,7.524,7.524,0,0,1,13.814,4.26,7.25,7.25,0,0,1,16.29,1.32,7.486,7.486,0,0,1,20.563,0a7.291,7.291,0,0,1,7.387,7.174,7,7,0,0,1-1.275,4.03,7.314,7.314,0,0,1-2.658,2.319,8.353,8.353,0,0,1,2.48,1.67,8.035,8.035,0,0,1,2.48,5.761v5.881a3.292,3.292,0,0,1-3.338,3.236h-9.1A3.336,3.336,0,0,1,13.474,32.043ZM8.4,17.3a5.851,5.851,0,0,0-4.091,1.65,5.512,5.512,0,0,0-1.7,3.972V28.8a.709.709,0,0,0,.718.7H13.467a.671.671,0,0,0,.508-.207.637.637,0,0,0,.214-.489V22.926a5.49,5.49,0,0,0-1.643-3.908L12.535,19a.673.673,0,0,1-.049-.052A5.8,5.8,0,0,0,8.4,17.3Zm12.167-1.969a5.823,5.823,0,0,0-4.087,1.657A5.582,5.582,0,0,0,15.4,18.422a7.912,7.912,0,0,1,1.4,4.5v4.613h8.833a.711.711,0,0,0,.718-.7V20.955a5.519,5.519,0,0,0-1.7-3.972A5.851,5.851,0,0,0,20.566,15.333Zm-5.414-3.275h0a7.311,7.311,0,0,1-3.3,3.43,8.236,8.236,0,0,1,1.692.993A8.993,8.993,0,0,1,14.63,15.19a8.637,8.637,0,0,1,2.48-1.675A7.4,7.4,0,0,1,15.152,12.058ZM8.4,4.5A4.847,4.847,0,0,0,5.023,5.863a4.553,4.553,0,0,0,0,6.561A4.828,4.828,0,0,0,8.4,13.782a4.776,4.776,0,0,0,3.376-1.359,4.549,4.549,0,0,0,0-6.561A4.811,4.811,0,0,0,8.4,4.5ZM20.566,2.535A4.812,4.812,0,0,0,17.19,3.894a4.541,4.541,0,0,0,0,6.561,4.87,4.87,0,0,0,6.749,0,4.541,4.541,0,0,0,0-6.561A4.783,4.783,0,0,0,20.566,2.535Z'
          transform='translate(0.016 0.012)'
          fill='#fff'
        />
      </g>
    </svg>
  )
})

export default IconPresaleWho
