import { Box, BoxProps } from 'rebass/styled-components'
import styled from 'styled-components'

export const SkeletonPulse = styled(Box)<BoxProps>`
  display: inline-block;
  background: linear-gradient(-90deg, #f0f0f0 0%, #e1e1e1 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  border-radius: 8px;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const SkeletonPulsePrimary = styled(Box)<BoxProps>`
  display: inline-block;
  background: linear-gradient(-90deg, #aac6ff 0%, #88b0ff 50%, #aac6ff 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  border-radius: 8px;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const SkeletonLine = styled(SkeletonPulse)`
  &::before {
    content: '';
  }
`

export const SkeletonLinePrimary = styled(SkeletonPulsePrimary)`
  &::before {
    content: '';
  }
`
