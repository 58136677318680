import React from 'react'

interface IIconv2Info {
  color?: string
  size?: number
}

const Iconv2Info = React.forwardRef<SVGSVGElement, IIconv2Info>(({ color = 'currentColor', size = 21 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 21.602 21.602' ref={ref}>
      <g id='Group_17234' data-name='Group 17234' transform='translate(0 0)'>
        <path
          id='Path_4476'
          data-name='Path 4476'
          d='M114.078,118.713h-1.44a.459.459,0,0,1-.447-.354l-.169-.717a4.457,4.457,0,0,1-.749-.311l-.627.388a.459.459,0,0,1-.566-.066l-1.018-1.018a.459.459,0,0,1-.066-.566l.388-.627a4.455,4.455,0,0,1-.311-.749l-.717-.169a.459.459,0,0,1-.354-.447v-1.44a.459.459,0,0,1,.354-.447l.717-.169a4.459,4.459,0,0,1,.311-.749l-.388-.627a.459.459,0,0,1,.066-.566l1.018-1.019a.459.459,0,0,1,.566-.066l.627.388a4.458,4.458,0,0,1,.749-.311l.169-.717a.459.459,0,0,1,.447-.354h1.44a.459.459,0,0,1,.447.354l.169.717a4.458,4.458,0,0,1,.749.311l.627-.388a.459.459,0,0,1,.566.066l1.018,1.019a.459.459,0,0,1,.066.566l-.388.627a4.456,4.456,0,0,1,.311.749l.717.169a.459.459,0,0,1,.354.447v1.44a.459.459,0,0,1-.354.447l-.717.169a4.455,4.455,0,0,1-.311.749l.388.627a.459.459,0,0,1-.066.566l-1.018,1.018a.459.459,0,0,1-.566.066l-.627-.388a4.457,4.457,0,0,1-.749.311l-.169.717a.459.459,0,0,1-.447.354M113,117.795h.713l.148-.628a.459.459,0,0,1,.339-.341,3.545,3.545,0,0,0,1.013-.42.459.459,0,0,1,.481,0l.549.34.5-.5-.34-.549a.459.459,0,0,1,0-.481,3.543,3.543,0,0,0,.42-1.013.459.459,0,0,1,.341-.339l.628-.148V113l-.628-.148a.459.459,0,0,1-.341-.339,3.544,3.544,0,0,0-.42-1.013.459.459,0,0,1,0-.481l.34-.549-.5-.5-.549.34a.459.459,0,0,1-.481,0,3.544,3.544,0,0,0-1.013-.42.459.459,0,0,1-.339-.341l-.148-.628H113l-.148.628a.459.459,0,0,1-.339.341,3.544,3.544,0,0,0-1.013.42.459.459,0,0,1-.481,0l-.549-.34-.5.5.34.549a.459.459,0,0,1,0,.481,3.544,3.544,0,0,0-.42,1.013.459.459,0,0,1-.341.339l-.628.148v.713l.628.148a.459.459,0,0,1,.341.339,3.545,3.545,0,0,0,.42,1.013.459.459,0,0,1,0,.481l-.34.549.5.5.549-.34a.459.459,0,0,1,.481,0,3.545,3.545,0,0,0,1.013.42.459.459,0,0,1,.339.341Zm5.254-3.718h0Zm-4.9,1.882a2.6,2.6,0,1,1,2.6-2.6,2.6,2.6,0,0,1-2.6,2.6m0-4.285a1.683,1.683,0,1,0,1.683,1.683,1.685,1.685,0,0,0-1.683-1.683'
          transform='translate(-102.557 -102.556)'
          fill={color}
        />
        <path
          id='Path_4477'
          data-name='Path 4477'
          d='M453.923,4.63a.633.633,0,0,1-.109-1.256l1.737-.306-.306-1.737a.633.633,0,0,1,1.246-.22l.416,2.36a.633.633,0,0,1-.513.733l-2.36.416a.637.637,0,0,1-.111.01'
          transform='translate(-438.947 -0.569)'
          fill={color}
        />
        <path
          id='Path_4478'
          data-name='Path 4478'
          d='M115.829,558.376a.633.633,0,0,1-.623-.523l-.416-2.36a.633.633,0,0,1,.513-.733l2.36-.416a.633.633,0,1,1,.22,1.246l-1.737.306.306,1.737a.633.633,0,0,1-.513.733.639.639,0,0,1-.111.01'
          transform='translate(-111.149 -536.793)'
          fill={color}
        />
        <path
          id='Path_4479'
          data-name='Path 4479'
          d='M.633,11.435A.633.633,0,0,1,0,10.8,10.8,10.8,0,0,1,17.743,2.529a.633.633,0,1,1-.814.969A9.533,9.533,0,0,0,1.266,10.8a.633.633,0,0,1-.633.633'
          transform='translate(0 -0.001)'
          fill={color}
        />
        <path
          id='Path_4480'
          data-name='Path 4480'
          d='M121.981,332.768a10.758,10.758,0,0,1-6.942-2.528.633.633,0,1,1,.814-.969,9.533,9.533,0,0,0,15.663-7.3.633.633,0,1,1,1.266,0,10.792,10.792,0,0,1-10.8,10.8'
          transform='translate(-111.181 -311.166)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default Iconv2Info
