import React from 'react'

interface IIconMarkets {
  color?: string
  size?: number
}

const IconMarkets = React.forwardRef<SVGSVGElement, IIconMarkets>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='markets'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M5.8,10.9c2.8,0,5.1-2.3,5.1-5.1S8.6,0.6,5.8,0.6S0.6,2.9,0.6,5.8S2.9,10.9,5.8,10.9z M2.3,5.8c0-1.9,1.6-3.5,3.5-3.5
          c0.9,0,1.8,0.4,2.4,1c0.7,0.7,1,1.5,1,2.4c0,1.9-1.6,3.5-3.5,3.5c0,0,0,0,0,0C3.9,9.2,2.3,7.7,2.3,5.8z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M14.2,9.1c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1C19.4,11.4,17.1,9.1,14.2,9.1z M17.7,14.2
          c0,1.9-1.5,3.5-3.5,3.5s-3.5-1.5-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C16.1,10.8,17.7,12.3,17.7,14.2z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M14.9,8.6c1.9,0,3.5-1.6,3.5-3.5c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.6-3.5,3.5C11.4,7.1,12.9,8.6,14.9,8.6z M16.7,5.2
          c-0.1,1-0.9,1.8-1.9,1.8c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9c1,0,1.9,0.8,1.9,1.9V5.2z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M5.1,11.4c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5C8.6,13,7.1,11.4,5.1,11.4z M7,14.9V15
          c-0.1,1-0.9,1.8-1.9,1.8c-1,0-1.9-0.8-1.9-1.9S4.1,13,5.1,13C6.2,13,7,13.9,7,14.9z'
        />
      </g>
    </svg>
  )
})

export default IconMarkets
