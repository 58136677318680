import React from 'react'
import {
  BEND_ASSETS_LINK,
  BEND_AUDIT_LINK,
  BEND_BUG_BOUNTY,
  BEND_DEWORK_LINK,
  BEND_DISCORD_LINK,
  BEND_DOCS_LINK,
  BEND_DUNE_ANALYTICS_LINK,
  BEND_EMAIL,
  BEND_GITHUB_LINK,
  BEND_MEDIUM_LINK,
  BEND_SECURITY_LINK,
  BEND_TWITTER_LINK
} from 'constants/index'
import { permalink } from 'constants/routes'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { Flex, FlexProps, Image } from 'rebass/styled-components'
import { Container, Icon, IconSafu } from 'theme/ui'
import Popover from 'theme/ui/common/popover'

import ExternalLink from '../external-link'

import DaysRunning from './details/days'

const FooterApp: React.FC<FlexProps> = ({ ...rest }) => {
  const { t } = useTranslation('common')
  const { colors } = useTheme()
  const { isLaptop } = useResponsive()
  return (
    <Flex
      width='100%'
      height='100%'
      justifyContent='center'
      alignSelf='bottom'
      sx={{
        background: 'linear-gradient(0deg, rgba(9,21,36,1) 0%, rgba(64,74,96,1) 100%)',
        overflow: 'hidden'
      }}
    >
      <Container
        px={20}
        justifyContent={['space-between']}
        pt={[20, 20, 20, 20, 22]}
        pb={[70, 70, 70, 70, 22]}
        flexDirection={['column', 'column', 'row']}
        sx={{ zIndex: 21, maxWidth: '100%', ...rest }}
        alignItems='center'
        width='100%'
      >
        <Flex alignItems='center' minWidth={190}>
          <Flex maxWidth={[65]} maxHeight={[36]} mr={[10]}>
            <Image src='/images/bend-logo-footer-1.svg' />
          </Flex>
          <Image src='/images/bend-logo-text-full-white-1.svg' />
        </Flex>

        {isLaptop && (
          <Flex justifyContent='space-between' width='100%' maxWidth={690} mx={[0, 0, 20, 60, 120]}>
            <FooterElement overlay={t('label.twitter')} icon='IconTwitter' link={BEND_TWITTER_LINK} />
            <FooterElement overlay={t('label.discord')} icon='IconDiscord' link={BEND_DISCORD_LINK} />
            <FooterElement overlay={t('label.medium')} icon='IconMedium' link={BEND_MEDIUM_LINK} />
            <FooterElement overlay={t('label.github')} icon='IconGithub' link={BEND_GITHUB_LINK} />
            <FooterElement overlay={t('label.security')} icon='IconSecurity' link={BEND_SECURITY_LINK} />
            <FooterElement overlay={t('label.docs')} icon='IconDoc2' link={BEND_DOCS_LINK} />
            <FooterElement overlay={t('label.email-us')} icon='IconEmail' link={`mailto:${BEND_EMAIL}`} />
            <FooterElement overlay={t('label.dework')} icon='IconDework' link={BEND_DEWORK_LINK} />
            <FooterElement overlay={t('label.dune-analytics')} icon='IconDuneAnalytics' link={BEND_DUNE_ANALYTICS_LINK} />
            <FooterElement overlay={t('label.bug-bounty')} icon='IconBugBounty' link={BEND_BUG_BOUNTY} />
            <FooterElement overlay={t('label.governance')} icon='IconGovernance' link={permalink.governance} />
            <FooterElement overlay={t('label.forum')} icon='IconForum' link={permalink.forum} />
            <FooterElement overlay={t('label.bend-assets')} icon='IconBendToken' link={BEND_ASSETS_LINK} />
          </Flex>
        )}

        <Flex minWidth={['', '', 280, 290]} mt={[25, 25, 0]} flexDirection='column' lineHeight='lg'>
          <Flex alignItems={['center']} justifyContent={['center', 'center', 'flex-start']} mb={5}>
            <Flex color='header-label' fontSize={['sm', 'sm', 'md']}>
              <Flex mr={[7, 7, 7, 8]}>
                <IconSafu size={16} color={colors.headerLabel} />
              </Flex>
              <Flex mr={3}>
                {t('label.running-safu')}
                <DaysRunning />
                {t('label.days')}.
              </Flex>
              <ExternalLink hideIcon href={BEND_AUDIT_LINK}>
                <Flex color='header-label' sx={{ textDecoration: 'underline', ':hover': { opacity: 0.7, transition: 'all 0.2s' } }}>
                  {t('label.audit-report')}
                </Flex>
              </ExternalLink>
            </Flex>
          </Flex>
          {/*           <Flex alignItems={['center']} justifyContent={['center', 'center', 'flex-start']}>
            <Flex color='header-label' fontSize={['sm', 'sm', 'md']}>
              <Flex mr={[5, 5, 6, 7]}>
                <IconEthRewards size={17} color={colors.headerLabel} />
              </Flex>
              <Flex mr={3}>{t('label.staking-rewards-distributed')}:</Flex>
              <Link href={permalink.yieldStakeBend} passHref>
                <Flex
                  fontWeight='bold'
                  color='header-label'
                  sx={{ textDecoration: 'underline', cursor: 'pointer', ':hover': { opacity: 0.7, transition: 'all 0.2s' } }}
                >
                  <NumberFormat number={ethTotalRewards?.data || 0} roundUp={false} decimals={0} suffix='ETH' suffixMargin={{ ml: 3 }} />
                </Flex>
              </Link>
            </Flex>
          </Flex> */}
        </Flex>
      </Container>
    </Flex>
  )
}

export default FooterApp

interface FooterElementProps {
  overlay: string
  link: string
  icon: string
}

const FooterElement: React.FC<FooterElementProps> = ({ link, overlay, icon }) => {
  return (
    <Popover overlay={overlay} placement='top'>
      <Flex as='a' href={link} target='_blank' rel='noopener noreferrer' variant='footer-link-app'>
        <Icon icon={icon} size={16} />
      </Flex>
    </Popover>
  )
}
