import React from 'react'

interface IAlarm {
  color?: string
  size?: number
}

const Alarm = React.forwardRef<SVGSVGElement, IAlarm>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='alarm'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M17.8,16.1c-0.2-0.2-0.4-0.2-0.6-0.2h-0.4V7.5c-0.1-3.3-2.6-5.9-5.9-6.3V0.9c0-0.5-0.4-0.8-0.9-0.9C9.8,0,9.6,0.1,9.4,0.2
        C9.2,0.4,9.1,0.6,9,0.9v0.4c-3.3,0.3-5.8,3-5.9,6.3v8.3H2.8c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.5-0.8,1c0,0.5,0.5,0.9,1,0.8h5.5v0.7
        c0,0.9,0.8,1.6,1.6,1.6c0,0,0.1,0,0.1,0c0.9,0,1.6-0.7,1.7-1.6v-0.7l5.5,0c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.5,0.8-1
        C18.1,16.5,18,16.3,17.8,16.1z M14.9,7.6v8.3H5.1V7.6C5.2,5,7.3,3,9.8,3C9.9,3,9.9,3,10,3c0.1,0,0.1,0,0.2,0C12.7,3,14.8,5,14.9,7.6 z'
        />
      </g>
    </svg>
  )
})

export default Alarm
