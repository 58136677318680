import React from 'react'

const IconHighlights3 = React.forwardRef<SVGSVGElement>(() => {
  return (
    <svg id='Group_5119' data-name='Group 5119' xmlns='http://www.w3.org/2000/svg' width='64.489' height='49.682' viewBox='0 0 64.489 49.682'>
      <path
        id='Path_2368'
        data-name='Path 2368'
        d='M170.406,81.71v4.871h3.779V83.892l2.329-1.344-1.889-3.272Z'
        transform='translate(-148.942 -69.29)'
        fill='#ffc500'
      />
      <rect id='Rectangle_2035' data-name='Rectangle 2035' width='3.779' height='4.696' transform='translate(21.464 22.324)' fill='#ffc500' />
      <path
        id='Path_2369'
        data-name='Path 2369'
        d='M174.185,257.16h-3.779v4.871l4.218,2.435,1.889-3.272-2.329-1.344Z'
        transform='translate(-148.942 -224.769)'
        fill='#ffc500'
      />
      <path
        id='Path_2370'
        data-name='Path 2370'
        d='M3.779,35.08V14.6L21.513,4.363,32.2,10.533l.023.04.023-.013,7,4.043V35.08L21.513,45.318ZM21.513,0,0,12.42V37.261l21.513,12.42,10.732-6.2,1.863,1.076,1.863-3.227,7.055-4.073V12.42l-7-4.043.266-.153L34.4,4.952,32.245,6.2Z'
        fill='#ffc500'
      />
      <path
        id='Path_2371'
        data-name='Path 2371'
        d='M311.933,350.467l-2.329-1.345-1.889,3.272,4.218,2.435,4.218-2.435-1.889-3.272Z'
        transform='translate(-268.957 -305.149)'
        fill='#ffc500'
      />
      <rect id='Rectangle_2036' data-name='Rectangle 2036' width='4.696' height='3.779' transform='translate(49.663 41.456) rotate(-30.001)' fill='#ffc500' />
      <path
        id='Path_2372'
        data-name='Path 2372'
        d='M465.84,257.16v2.689l-2.329,1.344,1.889,3.272,4.218-2.435v-4.87Z'
        transform='translate(-405.13 -224.769)'
        fill='#ffc500'
      />
      <rect id='Rectangle_2037' data-name='Rectangle 2037' width='3.779' height='4.696' transform='translate(60.711 22.661)' fill='#ffc500' />
      <path
        id='Path_2373'
        data-name='Path 2373'
        d='M463.512,82.548l2.329,1.344v2.689h3.779V81.711L465.4,79.275Z'
        transform='translate(-405.13 -69.29)'
        fill='#ffc500'
      />
      <rect
        id='Rectangle_2038'
        data-name='Rectangle 2038'
        width='3.779'
        height='4.696'
        transform='matrix(0.5, -0.866, 0.866, 0.5, 49.956, 8.394)'
        fill='#ffc500'
      />
      <path
        id='Path_2374'
        data-name='Path 2374'
        d='M311.933,0l-4.218,2.435L309.6,5.708l2.329-1.345,2.329,1.345,1.889-3.272Z'
        transform='translate(-268.957 0)'
        fill='#ffc500'
      />
    </svg>
  )
})

export default IconHighlights3
