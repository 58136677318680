import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Box } from 'rebass/styled-components'

type ArrowProps = {
  onClick: () => void
  left?: boolean
}

const CustomArrow: React.FC<ArrowProps> = ({ left = false, onClick }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: 40,
        height: 40,
        backgroundColor: 'white',
        top: 'calc(50%)',
        transform: 'translateY(-50%)',
        borderRadius: 8,
        color: 'black',
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        left: left ? 20 : 'initial',
        right: !left ? 20 : 'initial',
        opacity: 0.75,
        transition: 'all 0.3s',
        ':hover': {
          backgroundColor: 'white',
          opacity: 1
        }
      }}
      onClick={onClick}
    >
      {left ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
    </Box>
  )
}
export default CustomArrow
