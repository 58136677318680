import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMigrateStaking = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 22, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 22.68 22.12' fill={color} {...rest}>
      <g id='Group_17075' data-name='Group 17075' transform='translate(-471.365 -722)'>
        <text id='V2' transform='translate(480.045 730)' fill={color} fontSize='10' fontFamily='Metropolis-Bold, Metropolis' fontWeight='700'>
          <tspan x='0' y='0'>
            V2
          </tspan>
        </text>
        <path
          id='Path_4463'
          data-name='Path 4463'
          d='M9.215,7.963,3.706,2.219H5.769A1.1,1.1,0,0,0,6.854,1.11,1.1,1.1,0,0,0,5.769,0H1.086A1.072,1.072,0,0,0,.318.325,1.121,1.121,0,0,0,0,1.11V5.9a1.1,1.1,0,0,0,1.086,1.11A1.1,1.1,0,0,0,2.171,5.9V3.789L7.68,9.533a1.069,1.069,0,0,0,1.535,0A1.127,1.127,0,0,0,9.215,7.963Z'
          transform='translate(481.473 734.258) rotate(90)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconMigrateStaking
