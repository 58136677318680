import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'Metropolis';
    src: url('/fonts/metropolis/Metropolis-Medium.eot');
    src: url('Metropolis-Medium.eot?#iefix') format('embedded-opentype'), url('/fonts/metropolis/Metropolis-Medium.woff2') format('woff2'),
      url('/fonts/metropolis/Metropolis-Medium.woff') format('woff'), url('/fonts/metropolis/Metropolis-Medium.svg#Metropolis-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Metropolis';
    src: url('/fonts/metropolis/Metropolis-Regular.eot');
    src: url('/fonts/metropolis/Metropolis-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/metropolis/Metropolis-Regular.woff2') format('woff2'),
      url('/fonts/metropolis/Metropolis-Regular.woff') format('woff'), url('/fonts/metropolis/Metropolis-Regular.svg#Metropolis-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Metropolis';
    src: url('/fonts/metropolis/Metropolis-Bold.eot');
    src: url('/fonts/metropolis/Metropolis-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/metropolis/Metropolis-Bold.woff2') format('woff2'),
      url('/fonts/metropolis/Metropolis-Bold.woff') format('woff'), url('/fonts/metropolis/Metropolis-Bold.svg#Metropolis-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Metropolis';
    src: url('/fonts/metropolis/Metropolis-RegularItalic.eot');
    src: url('/fonts/metropolis/Metropolis-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/metropolis/Metropolis-RegularItalic.woff2') format('woff2'), url('/fonts/metropolis/Metropolis-RegularItalic.woff') format('woff'),
      url('/fonts/metropolis/Metropolis-RegularItalic.svg#Metropolis-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Metropolis';
    src: url('/fonts/metropolis/metropolis-light-webfont.woff2') format('woff2'),
         url('/fonts/metropolis/metropolis-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

  }

  @font-face {
    font-family: 'Metropolis';
    src: url('/fonts/metropolis/metropolis-medium-webfont.woff2') format('woff2'),
        url('/fonts/metropolis/metropolis-medium-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
      font-family: 'Metropolis';
      src: 
        url('/fonts/metropolis/metropolis-thin-webfont.woff2') format('woff2'),
        url('/fonts/metropolis/metropolis-thin-webfont.woff') format('woff');
      font-weight: 100;
      font-style: normal;
  }

`
