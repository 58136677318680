import useTheme from 'hooks/common/useTheme'
import { Box, Flex } from 'rebass/styled-components'
import { Card, SkeletonLine } from 'theme/ui'

import SpacerDash from 'components/common/spacer-dash'

export const ProfileSignatureBannerSkeleton: React.FC = () => {
  return (
    <Card width='100%' maxWidth={1052} flexDirection='row' justifyContent='space-between' alignItems='center'>
      <Box>
        <SkeletonLine width={52} height={52} sx={{ borderRadius: 'sm' }} />
        <SkeletonLine width={400} ml={20} height={52} sx={{ borderRadius: 'sm' }} />
      </Box>
      <Box>
        <SkeletonLine width={236} height={52} sx={{ borderRadius: 'sm' }} mt={25} />
      </Box>
    </Card>
  )
}

export const ProfileSettingsSkeleton: React.FC = () => {
  const { colors } = useTheme()

  return (
    <Card width='100%' maxWidth={1052}>
      <Flex flex={1} flexDirection='column'>
        <Box mb={20}>
          <SkeletonLine width={52} height={52} sx={{ borderRadius: 'sm' }} />
          <SkeletonLine width={250} ml={20} height={52} sx={{ borderRadius: 'sm' }} />
        </Box>

        <Flex mx={[-20, -20, -30]}>
          <SpacerDash bgColor={colors.grey[100]} height={4} width='100%' />
        </Flex>

        <Flex flex={1} justifyContent='flex-start' my={30}>
          <Box flex={0.5}>
            <SkeletonLine width={471} height={81} sx={{ borderRadius: 'sm' }} mb={20} />
            <SkeletonLine width={471} height={81} sx={{ borderRadius: 'sm' }} mb={20} />
            <SkeletonLine width={471} height={81} sx={{ borderRadius: 'sm' }} mb={20} />
            <SkeletonLine width={471} height={81} sx={{ borderRadius: 'sm' }} />
          </Box>

          <Box flex={0.5}>
            <SkeletonLine width={182} height={190} sx={{ borderRadius: 'sm' }} mb={20} />
            <SkeletonLine width={471} height={190} sx={{ borderRadius: 'sm' }} />
          </Box>
        </Flex>
        <>
          <Flex mx={[-20, -20, -30]}>
            <SpacerDash bgColor={colors.grey[100]} height={4} width='100%' />
          </Flex>
          <Flex justifyContent='center'>
            <SkeletonLine maxWidth={236} height={52} sx={{ borderRadius: 'sm' }} mt={25} flex={1} />
          </Flex>
        </>
      </Flex>
    </Card>
  )
}

export const ProfileNotificationsSkeleton: React.FC = () => {
  const { colors } = useTheme()

  return (
    <Card width='100%' maxWidth={1052}>
      <Flex flex={1} flexDirection='column'>
        <Box mb={20}>
          <SkeletonLine width={52} height={52} sx={{ borderRadius: 'sm' }} />
          <SkeletonLine width={400} ml={20} height={52} sx={{ borderRadius: 'sm' }} />
        </Box>
        <Flex mx={[-20, -20, -30]}>
          <SpacerDash bgColor={colors.grey[100]} height={4} width='100%' />
        </Flex>
        <Box flex={1} my={30}>
          <SkeletonLine height={81} sx={{ borderRadius: 'sm' }} width='100%' />
        </Box>
        <>
          <Flex mx={[-20, -20, -30]}>
            <SpacerDash bgColor={colors.grey[100]} height={4} width='100%' />
          </Flex>
          <Flex justifyContent='center'>
            <SkeletonLine maxWidth={236} height={52} sx={{ borderRadius: 'sm' }} mt={25} flex={1} />
          </Flex>
        </>
      </Flex>
    </Card>
  )
}

export const ProfileAnnouncementsSkeleton: React.FC = () => {
  const { colors } = useTheme()

  return (
    <Card width='100%' maxWidth={1052} flexDirection='column'>
      <Box mb={20}>
        <SkeletonLine width={52} height={52} sx={{ borderRadius: 'sm' }} />
        <SkeletonLine width={400} ml={20} height={52} sx={{ borderRadius: 'sm' }} />
      </Box>
      <Flex mx={[-20, -20, -30]}>
        <SpacerDash bgColor={colors.grey[100]} height={4} width='100%' />
      </Flex>
      <Box flex={1} my={30}>
        <SkeletonLine height={81} sx={{ borderRadius: 'sm' }} width='100%' />
      </Box>
    </Card>
  )
}

export const NotificationSkeleton: React.FC = () => {
  return (
    <Box>
      <Flex flex={1} mt={10}>
        <Box mr={15} mt={8}>
          <SkeletonLine width={30} height={[30]} sx={{ borderRadius: 'sm' }} />
        </Box>
        <Box width='100%'>
          <SkeletonLine width='100%' height={[18]} sx={{ borderRadius: 'sm' }} mb={2} />
          <SkeletonLine width='100%' height={[18]} sx={{ borderRadius: 'sm' }} />
          <SkeletonLine width={120} height={[18]} sx={{ borderRadius: 'sm' }} mt={10} />
        </Box>
      </Flex>
    </Box>
  )
}

export const MyNotificationsPageSkeleton: React.FC = () => {
  return (
    <Box sx={{ display: 'grid', gridGap: '10px' }} mt={20}>
      {[0, 1, 2, 3, 4].map(key => (
        <Flex key={key} variant='card'>
          <Box width='100%'>
            <Flex flex={1} mt={10}>
              <Box mr={15} mt={8}>
                <SkeletonLine width={30} height={[30]} sx={{ borderRadius: 'sm' }} />
              </Box>
              <Flex justifyContent='space-between' width='100%'>
                <Box width='75%'>
                  <SkeletonLine width='100%' height={[18]} sx={{ borderRadius: 'sm' }} mb={2} />
                  <SkeletonLine width='100%' height={[18]} sx={{ borderRadius: 'sm' }} />
                  <SkeletonLine width={120} height={[18]} sx={{ borderRadius: 'sm' }} mt={10} />
                </Box>
                <Box alignItems='flex-start'>
                  <SkeletonLine width={80} height={[18]} sx={{ borderRadius: 'sm' }} />
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}
