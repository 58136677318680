import React from 'react'

interface IIconTrade {
  color?: string
  size?: number
}

const IconMarketplace = React.forwardRef<SVGSVGElement, IIconTrade>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24.414 23.612' fill={color} {...rest}>
      <g id='Group_5524' data-name='Group 5524' transform='translate(-1286.384 -120.554)'>
        <path
          id='Path_2399'
          data-name='Path 2399'
          d='M15.4,13.729,4.024,2.357h2.24A1.179,1.179,0,1,0,6.264,0H1.179A1.179,1.179,0,0,0,0,1.179V6.264a1.178,1.178,0,1,0,2.357,0V4.024L13.729,15.4A1.178,1.178,0,0,0,15.4,13.729Z'
          transform='translate(1302.125 120.554) rotate(90)'
          fill={color}
        />
        <path
          id='Path_2400'
          data-name='Path 2400'
          d='M15.4,13.729,4.024,2.357h2.24A1.179,1.179,0,1,0,6.264,0H1.179A1.179,1.179,0,0,0,0,1.179V6.264a1.178,1.178,0,1,0,2.357,0V4.024L13.729,15.4A1.178,1.178,0,0,0,15.4,13.729Z'
          transform='translate(1295.057 144.166) rotate(-90)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconMarketplace
