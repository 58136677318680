import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass'
import { Button, Notification } from 'theme/ui'

export const WrapStep8: React.FC<any> = () => {
  const { t } = useTranslation('common')
  return (
    <Flex flexDirection='column' flex={1} mt={20}>
      <Flex flex={1} mb={18}>
        <Notification loading={true} reducePadding width='100%'>
          <Text fontWeight='normal'>{t('paragraph.confirm')}</Text>
        </Notification>
      </Flex>
      <Button color='black' outlined size='md' fontWeight='bold' disabled loading />
    </Flex>
  )
}
