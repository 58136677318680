import React from 'react'

interface IArrowDown {
  color?: string
  size?: number
}

const ArrowDown = React.forwardRef<SVGSVGElement, IArrowDown>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        d='M.2905,6.1924,11.1351,19.35a1.1452,1.1452,0,0,0,1.7056,0L23.7381,6.1924a1.1432,1.1432,0,0,0-.8528-1.9046H1.1473A1.1432,1.1432,0,0,0,.2905,6.1924Z'
        fill={color}
      />
    </svg>
  )
})

export default ArrowDown
