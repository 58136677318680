import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMyCollaterals = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 33.494 25.999'
      fill={color}
      stroke={color}
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2063' data-name='Rectangle 2063' width={size} height={size} fill={color} />
        </clipPath>
      </defs>
      <g id='Group_5202' data-name='Group 5202' transform='translate(0 0)' clipPath='url(clip-path)'>
        <path
          id='Path_2397'
          data-name='Path 2397'
          d='M11.477,6.929V9.42H13.41V8.045L14.6,7.358l-.966-1.673Zm0,5.065H13.41v2.4H11.477Zm1.933,5.149H11.477v2.491l2.157,1.245.966-1.673-1.191-.687ZM2.433,18.518V8.044L11.5,2.808l5.466,3.156.012.02.012-.007,3.58,2.068V18.518L11.5,23.754ZM11.5.577.5,6.929v12.7l11,6.352,5.489-3.171.953.55.953-1.65,3.608-2.083V6.929l-3.58-2.068.136-.078L18.093,3.11l-1.1.638ZM22.48,23.754l-1.191-.688-.966,1.673,2.157,1.245,2.157-1.245-.966-1.673Zm3.42-1.975,2.08-1.2.966,1.674-2.08,1.2Zm5.65-4.636v1.375l-1.191.687.966,1.673,2.157-1.245V17.143Zm0-4.976h1.933v2.4H31.55ZM30.359,7.358l1.191.687V9.42h1.933V6.93L31.324,5.684ZM26.049,4.87,27.015,3.2,29.1,4.4l-.966,1.674ZM22.48.577,20.322,1.822,21.286,3.5l1.191-.688,1.191.688.966-1.673Z'
          transform='translate(-0.244 -0.282)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconMyCollaterals
