import React from 'react'

interface IIconMyAuctions {
  color?: string
  size?: number
  strokeWidth?: string
}

const IconMyAuctions = React.forwardRef<SVGSVGElement, IIconMyAuctions>(({ color = 'currentColor', size = 20, strokeWidth = '0', ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 29.6 26.7'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g>
        <path
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
          d='M13.3,21.9H1.9c-1.1,0-1.9,0.9-1.9,1.9v1c0,1.1,0.9,1.9,1.9,1.9h11.4l0,0c1.1,0,1.9-0.9,1.9-1.9v-1
          C15.2,22.7,14.3,21.9,13.3,21.9z M13.4,23.8v1c0,0.1-0.1,0.1-0.1,0.1H1.9c-0.1,0-0.1-0.1-0.1-0.1v-1c0-0.1,0.1-0.1,0.1-0.1l11.3,0
          C13.4,23.6,13.4,23.7,13.4,23.8z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
          d='M28.2,19.7c-0.8-0.8-5.5-5.1-9.1-8.2c0.3-0.4,0.6-0.7,0.9-1.1c0.1-0.2,0.3-0.5,0.5-0.9c0.6,0,1.1-0.2,1.5-0.6L22.8,8
          c0.9-0.9,0.9-2.2,0-3.1l-4.3-4.3c-0.9-0.9-2.2-0.8-3.1,0l-0.9,0.9c-0.4,0.4-0.6,1-0.6,1.5c-0.3,0.1-0.6,0.3-0.9,0.5
          c-2,1.3-3.6,3-5,5C7.9,8.7,7.7,9,7.5,9.4C7,9.4,6.5,9.6,6,10l-0.8,0.8l-0.1,0.1c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5
          l4.3,4.3c0.4,0.4,1,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6l0.9-0.9c0.4-0.4,0.6-1,0.6-1.5c0.3-0.1,0.6-0.3,0.9-0.5
          c0.4-0.3,0.8-0.6,1.1-0.8c3.1,3.6,7.4,8.4,8.2,9.1c0.7,0.8,1.6,1.3,2.6,1.4l0.2,0l0,0c1.2-0.2,2.3-1.1,2.5-2.5
          C29.6,21.4,29,20.4,28.2,19.7z M15.6,12.4c-0.1,0-0.1,0.1-0.1,0.2c-0.7,0.6-1.4,1.1-2.2,1.5l-0.9-0.9l-3-3c0.1-0.4,0.8-1.5,2.4-3.1
          c0.9-1,1.9-1.8,3-2.3l3.9,3.9c-0.2,0.4-0.8,1.5-2.4,3.1C16.1,11.9,15.9,12.1,15.6,12.4C15.6,12.4,15.6,12.4,15.6,12.4z M17.8,13
          c3.7,3.2,8.4,7.5,9.1,8.1c0.8,0.8,0.8,1.2,0.8,1.3c0,0.2-0.1,0.3-0.3,0.4c-0.3,0.3-0.6,0.6-1.7-0.5c-0.7-0.7-5-5.4-8.1-9.1
          C17.6,13.1,17.7,13.1,17.8,13z M21.4,6.3c0.1,0.1,0.1,0.2,0,0.3l-0.9,0.9c-0.1,0.1-0.2,0.1-0.3,0c0,0,0,0,0,0l-4.3-4.3c0,0,0,0,0,0
          c-0.1-0.1-0.1-0.2,0-0.3L16.8,2C16.8,2,16.9,2,17,2s0.1,0,0.2,0.1L21.4,6.3z M12,16l-0.9,0.9c-0.1,0.1-0.2,0.1-0.3,0l-4.3-4.3
          c-0.1-0.1-0.1-0.2,0-0.3l0.9-0.9c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1l4.3,4.3c0,0,0,0,0,0C12.1,15.7,12.1,15.9,12,16z'
        />
      </g>
    </svg>
  )
})

export default IconMyAuctions
