import React from 'react'

interface IIconDeposit {
  color?: string
  size?: number
}

const IconNFTWrappers = React.forwardRef<SVGSVGElement, IIconDeposit>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 21.332 23.645' stroke={color} {...rest}>
      <g id='Group_11525' data-name='Group 11525' transform='translate(-1.96 -2.735)'>
        <path
          id='Subtraction_139'
          data-name='Subtraction 139'
          d='M9.185,22.468h0L0,17.166V5.3L9.185,0V2.191L1.9,6.394v9.679l7.284,4.206v2.189Z'
          transform='translate(2.21 3.347)'
          fill={color}
          stroke={color}
          strokeWidth='0.5'
        />
        <path
          id='Subtraction_141'
          data-name='Subtraction 141'
          d='M0,23.255V21.067l.76-.437.948,1.643L0,23.254Zm3.722-2.149h0l-.947-1.639L4.789,18.3l.947,1.643L3.723,21.105Zm4.03-2.322h0L6.8,17.142l1.17-.676V15.119h1.9v2.44L7.753,18.783ZM9.87,12.794h-1.9v-2.33h1.9v2.33ZM9.861,8.139H7.971V6.789L6.8,6.113l.946-1.639,2.114,1.22V8.136l0,0ZM4.785,4.95,2.771,3.787l.947-1.643L5.736,3.308,4.789,4.946v0ZM.76,2.629v0L0,2.187V0L1.708.986.764,2.624Z'
          transform='translate(13.068 2.951)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconNFTWrappers
