import { createContext, useCallback, useEffect, useReducer, useRef } from 'react'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import isArray from 'lodash/isArray'

type WalletProvider = 'unisat' | 'okx' | null
interface Balance {
  confirmed: number
  unconfirmed: number
  total: number
}

interface State {
  walletProvider: WalletProvider
  unisatInstalled: boolean
  okxInstalled: boolean
  connected: boolean
  accounts: string[]
  publicKey: string
  address: string
  balance: Balance
  network: string
  provider?: any
  handleAccountsChanged?: (accounts: string[], walletProvider: WalletProvider) => void
  handleDisconnect?: () => void
  handleConnect?: (walletProvider: WalletProvider) => void
  okxProvider: any
  unisatProvider: any
}

const initialState: State = {
  walletProvider: null,
  unisatInstalled: false,
  okxInstalled: false,
  connected: false,
  accounts: [],
  publicKey: '',
  address: '',
  balance: {
    confirmed: 0,
    unconfirmed: 0,
    total: 0
  },
  network: DEFAULT_CHAIN_ID === '5' ? 'testnet' : 'livenet',
  provider: undefined,
  okxProvider: undefined,
  unisatProvider: undefined
}

export const BtcWalletContext = createContext(initialState)

type Action = {
  type: string
  payload: any
  extraPayload?: any
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_WALLET_PROVIDER':
      return { ...state, walletProvider: action.payload }
    case 'SET_UNISAT_INSTALLED':
      return { ...state, unisatInstalled: action.payload, unisatProvider: action.extraPayload }
    case 'SET_OKX_INSTALLED':
      return { ...state, okxInstalled: action.payload, okxProvider: action.extraPayload }
    case 'SET_CONNECTED':
      return { ...state, connected: action.payload }
    case 'SET_ACCOUNTS':
      return { ...state, accounts: action.payload }
    case 'SET_PUBLIC_KEY':
      return { ...state, publicKey: action.payload }
    case 'SET_ADDRESS':
      return { ...state, address: action.payload }
    case 'SET_BALANCE':
      return { ...state, balance: action.payload }
    case 'SET_NETWORK':
      return { ...state, network: action.payload }
    case 'SET_PROVIDER':
      return { ...state, provider: action.payload }
    case 'DISCONNECT':
      return {
        ...state,
        provider: undefined,
        address: '',
        publicKey: '',
        connected: false,
        walletProvider: null,
        accounts: [],
        balance: { confirmed: 0, unconfirmed: 0, total: 0 }
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default function BtcWalletProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { connected } = state

  // useEffect(() => {
  //   console.group('💳 UniSatProvider data')
  //   console.log({
  //     unisatInstalled,
  //     connected,
  //     accounts,
  //     publicKey,
  //     address,
  //     balance,
  //     network,
  //     unisat
  //   })
  //   console.groupEnd()
  // }, [accounts, address, balance, connected, network, publicKey, unisat, unisatInstalled])

  const getBasicInfo = useCallback(
    async (walletProvider: WalletProvider) => {
      let provider = undefined
      let address = ''
      let publicKey = ''
      let balance = {
        confirmed: 0,
        unconfirmed: 0,
        total: 0
      }
      let network = ''
      if (walletProvider === 'unisat' && state.unisatProvider) {
        provider = state.unisatProvider
        address = (await provider.getAccounts())?.[0]
        publicKey = await provider.getPublicKey()
        balance = await provider.getBalance()
        network = await provider.getNetwork()
      } else if (walletProvider === 'okx' && state.okxProvider) {
        provider = state.okxProvider
        address = (await provider.connect())?.address
        publicKey = (await provider.connect())?.publicKey
        // balance = await provider.getBalance()
        balance = {
          confirmed: 0,
          unconfirmed: 0,
          total: 0
        }
        network = 'livenet'
      }

      //console.log('address, publicKey, balance, network',address, publicKey, balance, network)
      if (!address) return

      dispatch({
        type: 'SET_ADDRESS',
        payload: address
      })
      dispatch({
        type: 'SET_PUBLIC_KEY',
        payload: publicKey
      })
      dispatch({
        type: 'SET_BALANCE',
        payload: balance
      })
      dispatch({
        type: 'SET_NETWORK',
        payload: network
      })
    },
    [state.okxProvider, state.unisatProvider]
  )

  const selfRef = useRef<{ accounts: string[] }>({
    accounts: []
  })
  const self = selfRef.current

  const handleAccountsChanged = useCallback(
    (_accounts: string[], _walletProvider: WalletProvider, buttonTrigger = false) => {
      //console.log('handleAccountsChanged self.accounts', self.accounts)
      //console.log('_accounts', _accounts, _accounts.length, _accounts[0])
      if (self.accounts[0] === _accounts[0]) {
        // prevent from triggering twice
        //console.log('prevent triggering')
        return
      }
      if ((state.walletProvider === null || state.walletProvider !== _walletProvider) && !buttonTrigger) {
        // prevent from triggering wrong provider
        //console.log('prevent triggering wrong provider', _walletProvider)
        return
      }
      self.accounts = _accounts
      if (_accounts.length > 0) {
        dispatch({
          type: 'SET_ACCOUNTS',
          payload: true
        })
        dispatch({
          type: 'SET_CONNECTED',
          payload: true
        })
        dispatch({
          type: 'SET_ADDRESS',
          payload: _accounts[0]
        })
        getBasicInfo(_walletProvider)
      } else {
        dispatch({
          type: 'SET_CONNECTED',
          payload: false
        })
      }
    },
    [getBasicInfo, self, state.walletProvider]
  )

  const handleDisconnect = useCallback(() => {
    self.accounts = []
    dispatch({
      type: 'DISCONNECT',
      payload: null
    })
  }, [self])

  const handleAccountsChangedUnisat = useCallback(
    (_accounts: string[]) => {
      //console.log('handleAccountsChangedUnisat', _accounts)
      if (_accounts?.length === 0 || _accounts === null) {
        handleDisconnect()
        return
      }
      handleAccountsChanged(_accounts, 'unisat')
    },
    [handleAccountsChanged, handleDisconnect]
  )

  const handleAccountsChangedOkx = useCallback(
    (accounts: any) => {
      //console.log('handleAccountsChangedOkx', accounts)
      if (accounts === null || accounts === undefined) {
        handleDisconnect()
        return
      }
      handleAccountsChanged(isArray(accounts) ? accounts.map(account => account.address) : [accounts.address], 'okx')
    },
    [handleAccountsChanged, handleDisconnect]
  )

  const handleNetworkChanged = useCallback(
    (network: string) => {
      dispatch({
        type: 'SET_NETWORK',
        payload: network
      })
      getBasicInfo(state.walletProvider)
    },
    [getBasicInfo, state.walletProvider]
  )

  useEffect(() => {
    async function checkProvider() {
      // detect providers
      let unisatProvider = (window as any).unisat
      // console.log({ unisatProvider })
      for (let i = 1; i < 10 && !unisatProvider; i += 1) {
        await new Promise(resolve => setTimeout(resolve, 100 * i))
        unisatProvider = (window as any).unisat
        //console.log('run set unisat', i)
      }

      // const okxProvider = null
      let okxProvider = (window as any).okxwallet?.bitcoin || (window as any).okexwallet?.bitcoin
      for (let i = 1; i < 10 && !okxProvider; i += 1) {
        await new Promise(resolve => setTimeout(resolve, 100 * i))
        okxProvider = (window as any).okxwallet?.bitcoin || (window as any).okexwallet?.bitcoin
        //console.log('run set okx', i)
      }

      //console.log('unisat Provider', { unisatProvider })
      //console.log('okx Provider', { okxProvider })

      if (unisatProvider) {
        dispatch({
          type: 'SET_UNISAT_INSTALLED',
          payload: true,
          extraPayload: unisatProvider
        })
        unisatProvider.on('accountsChanged', handleAccountsChangedUnisat)
        unisatProvider.on('networkChanged', handleNetworkChanged)
      }

      if (okxProvider) {
        dispatch({
          type: 'SET_OKX_INSTALLED',
          payload: true,
          extraPayload: okxProvider
        })
        okxProvider.on('accountChanged', handleAccountsChangedOkx)
      }

      return () => {
        unisatProvider?.removeListener('accountsChanged', handleAccountsChangedUnisat)
        unisatProvider?.removeListener('networkChanged', handleNetworkChanged)
        okxProvider?.removeListener('accountChanged', handleAccountsChangedOkx)
      }
    }

    checkProvider().then()
  }, [handleAccountsChanged, handleNetworkChanged, connected, state.walletProvider, handleAccountsChangedUnisat, handleAccountsChangedOkx])

  const handleConnect = useCallback(
    async (walletProvider: WalletProvider) => {
      dispatch({
        type: 'SET_WALLET_PROVIDER',
        payload: walletProvider
      })
      if (walletProvider === 'unisat') {
        const unisat = state.unisatProvider
        const accounts = await unisat.requestAccounts()
        dispatch({
          type: 'SET_PROVIDER',
          payload: unisat
        })
        handleAccountsChanged(accounts, walletProvider, true)
      } else if (walletProvider === 'okx') {
        const okx = state.okxProvider
        const accounts = await okx.connect()
        dispatch({
          type: 'SET_PROVIDER',
          payload: okx
        })
        handleAccountsChanged(isArray(accounts) ? accounts.map(account => account.address) : [accounts.address], walletProvider, true)
      }
    },
    [handleAccountsChanged, state.okxProvider, state.unisatProvider]
  )

  return <BtcWalletContext.Provider value={{ ...state, handleAccountsChanged, handleDisconnect, handleConnect }}>{children}</BtcWalletContext.Provider>
}
