import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDiscord = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 18.551, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18.551 16.112'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='icon-social-discord-white'
        d='M18.133,18.7l-.058-.153a.007.007,0,0,0-.007-.007l-.153-.066a13.247,13.247,0,0,0-4.586-.774h-1.76a.019.019,0,0,0-.007.037l3.622,1.738c.022.007.007.037-.015.037A19,19,0,0,0,5.4,19.5a.02.02,0,0,1-.015-.037l3.747-1.731c.015-.007.015-.037-.007-.037H7.208a13.248,13.248,0,0,0-4.586.774l-.153.066a.007.007,0,0,0-.007.007L2.4,18.7C1.117,21.951.752,27.472,1.139,30.926l.022.183.146.11a14.021,14.021,0,0,0,6.368,2.563l.219.029a.145.145,0,0,0,.161-.1l1.1-2.929a.149.149,0,0,0-.117-.2L8.7,30.525a8.175,8.175,0,0,1-4.243-1.95.035.035,0,0,1,.037-.058c.117.044.241.095.372.139a8.607,8.607,0,0,0,.862.307,19.71,19.71,0,0,0,4.55.54v-.029h0V29.5a19.7,19.7,0,0,0,4.543-.54,8.607,8.607,0,0,0,.862-.307c.131-.051.256-.1.372-.139.037-.015.066.037.037.058a8.19,8.19,0,0,1-4.243,1.943l-.336.051a.159.159,0,0,0-.124.2l1.1,2.936a.158.158,0,0,0,.161.1l.219-.029a14.021,14.021,0,0,0,6.368-2.563l.146-.11.022-.183C19.784,27.472,19.419,21.951,18.133,18.7ZM6.689,26.683A2.189,2.189,0,1,1,8.668,24.7,2.192,2.192,0,0,1,6.689,26.683Zm7.559,0A2.189,2.189,0,1,1,16.227,24.7,2.192,2.192,0,0,1,14.248,26.683Z'
        transform='translate(-0.992 -17.7)'
      />
    </svg>
  )
})

export default IconDiscord
