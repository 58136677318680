import usePlatform from 'contexts/platform'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Icon } from 'theme/ui'

interface ISidebarNavButton {
  id: number
  icon: string
  name: string
  active: boolean
  onClick: any
}

export const SidebarNavButton: React.FC<ISidebarNavButton> = ({ id, icon, name, active, onClick, ...restprops }) => {
  const { unreadedNotifications } = usePlatform()
  return (
    <Flex sx={{ position: 'relative', justifyContent: 'center' }} {...restprops}>
      <Flex
        onClick={onClick}
        sx={{
          flexDirection: ['row'],
          justifyContent: 'center',
          alignItems: 'center',
          px: [14, 14, 14, 14, 15],
          height: [78],
          '&:after': {
            content: "''",
            position: 'absolute',
            left: active ? 0 : '50%',
            bottom: 0,
            width: active ? '100%' : 0,
            height: 3,
            bg: active ? ['primary'] : 'transparent',
            transition: 'all 0.3s'
          },
          '& svg': {
            transition: 'all 0.3s',
            color: active ? 'primary' : 'grey.300'
          },
          '&:hover': {
            cursor: 'pointer',
            '&:after': {
              left: 0,
              width: '100%',
              bg: ['primary']
            },
            '& svg': {
              color: 'primary'
            }
          }
        }}
      >
        {icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color='white' />}
        <Text as='span' fontSize='md' lineHeight='md' fontWeight='700' mt={[0]} ml={10} color='black' textAlign='center'>
          {name}
        </Text>
        {id === 1 && (unreadedNotifications > 0 || unreadedNotifications === '99+') && (
          <Box backgroundColor='primary' sx={{ borderRadius: 'sm' }} width={10} height={10} ml={10} />
        )}
      </Flex>
    </Flex>
  )
}
