import React from 'react'

interface IIcon {
  color?: string
  size?: number
  stroke?: boolean
}

const IconDoc = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 18, stroke = true, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 13.655 18.233'
      fill={color}
      stroke={stroke ? color : undefined}
      {...rest}
    >
      <g id='copy' transform='translate(-0.003 -0.001)'>
        <path
          id='Shape'
          d='M1.29,18.233A1.284,1.284,0,0,1,0,16.958V3.569A1.291,1.291,0,0,1,1.29,2.28h.987v-1A1.292,1.292,0,0,1,3.577,0h5A1.307,1.307,0,0,1,9.1.111l.007,0H9.11a.17.17,0,0,1,.037.018.676.676,0,0,1,.077.04,1.419,1.419,0,0,1,.261.206l3.807,3.853a1.271,1.271,0,0,1,.363.9v9.534a1.259,1.259,0,0,1-1.264,1.282H11.377v1.005A1.276,1.276,0,0,1,10.1,18.233Zm-.01-1.284h8.814v-1H3.577a1.292,1.292,0,0,1-1.3-1.282V3.56h-1Zm2.28-2.278h8.814V5.7H9.258a1.292,1.292,0,0,1-1.3-1.282V1.282h-4.4ZM9.24,4.415h2.436L9.24,1.966ZM5.2,12.677a.641.641,0,0,1,0-1.282H9.612a.641.641,0,1,1,0,1.282Zm0-2.28a.641.641,0,0,1,0-1.282h5.555a.641.641,0,1,1,0,1.282Zm0-2.279a.641.641,0,0,1,0-1.282H8.473a.641.641,0,0,1,0,1.282Z'
          transform='translate(0.003 0.001)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconDoc
