import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconVolume = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'white', size = 23, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 23 23' fill={color} stroke={color} {...rest}>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#0057ff' stopOpacity='0.502' />
          <stop offset='1' stopColor='#fff' />
        </linearGradient>
      </defs>
      <rect id='Rectangle_2705' data-name='Rectangle 2705' width={23} height={23} rx='11' fill='url(#linear-gradient)' />
    </svg>
  )
})

export default IconVolume
