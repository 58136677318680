import { calculateGasMargin } from '../index'

// Write

/**
 * @notice approve contract
 * @param tokenContract token Contract
 * @param contractAddress contract address
 */
export const approve = async (tokenContract: any, contractAddress: string) => {
  //const maxUint256 = new BigNumber(2).pow(255).toFixed()
  const maxUint256 = '57896044618658097711785492504343953926634992332820282019728792003956564819968'
  //console.log('maxUint256', maxUint256)
  const estimatedGas = await tokenContract.estimateGas.approve(contractAddress, maxUint256).catch((e: any) => {
    console.log('e', e)
    // general fallback for tokens who restrict approval amounts
    return tokenContract.estimateGas.approve(contractAddress, maxUint256)
  })

  return tokenContract
    .approve(contractAddress, maxUint256, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      //console.log('approve', response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to approve', error)
      throw error
    })
}

/**
 * @notice approve contract
 * @param tokenContract token Contract
 * @param contractAddress contract address
 */
export const resetApprove = async (tokenContract: any, contractAddress: string) => {
  //console.log('maxUint256', maxUint256)
  const estimatedGas = await tokenContract.estimateGas.approve(contractAddress, '0').catch((e: any) => {
    console.log('e', e)
    // general fallback for tokens who restrict approval amounts
    return tokenContract.estimateGas.approve(contractAddress, '0')
  })

  return tokenContract
    .approve(contractAddress, '0', {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      //console.log('approve', response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to approve', error)
      throw error
    })
}
