import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconOkx = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 23.999' fill={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_6477' data-name='Rectangle 6477' width='24' height='23.999' transform='translate(0 0)' fill={color} />
        </clipPath>
      </defs>
      <g id='Group_23998' data-name='Group 23998' transform='translate(0 0)'>
        <g id='Group_23998-2' data-name='Group 23998' transform='translate(0 0)' clipPath='url(#clip-path)'>
          <path
            id='Path_4979'
            data-name='Path 4979'
            d='M35.486,28H28.552a.533.533,0,0,0-.533.533V35.47a.533.533,0,0,0,.533.533h6.933a.533.533,0,0,0,.533-.533V28.536A.533.533,0,0,0,35.486,28'
            transform='translate(-20.015 -20.004)'
            fill={color}
          />
          <path
            id='Path_4980'
            data-name='Path 4980'
            d='M7.467,0H.533A.533.533,0,0,0,0,.533V7.467A.533.533,0,0,0,.533,8H7.467A.533.533,0,0,0,8,7.467V.533A.533.533,0,0,0,7.467,0'
            transform='translate(0 0)'
            fill={color}
          />
          <path
            id='Path_4981'
            data-name='Path 4981'
            d='M63.48,0H56.546a.533.533,0,0,0-.533.533V7.467A.533.533,0,0,0,56.546,8H63.48a.533.533,0,0,0,.533-.533V.533A.533.533,0,0,0,63.48,0'
            transform='translate(-40.013 0)'
            fill={color}
          />
          <path
            id='Path_4982'
            data-name='Path 4982'
            d='M7.467,56.01H.533A.533.533,0,0,0,0,56.543v6.933a.533.533,0,0,0,.533.533H7.467A.533.533,0,0,0,8,63.477V56.543a.533.533,0,0,0-.533-.533'
            transform='translate(0 -40.011)'
            fill={color}
          />
          <path
            id='Path_4983'
            data-name='Path 4983'
            d='M63.48,56.01H56.546a.533.533,0,0,0-.533.533v6.933a.533.533,0,0,0,.533.533H63.48a.533.533,0,0,0,.533-.533V56.543a.533.533,0,0,0-.533-.533'
            transform='translate(-40.013 -40.011)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconOkx
