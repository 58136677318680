import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconCustodySmall = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 15, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13.4 15.473' fill={color} {...rest}>
      <path
        id='Path_2472'
        data-name='Path 2472'
        d='M1.177,10.925V4.547L6.7,1.359,10.028,3.28l.007.012.007,0,2.18,1.259v6.377L6.7,14.114ZM6.7,0,0,3.868V11.6l6.7,3.868L13.4,11.6V3.868Z'
        fill='#0057ff'
      />
      <g id='Group_6003' data-name='Group 6003'>
        <g id='Group_6235' data-name='Group 6235'>
          <path
            id='Path_2473'
            data-name='Path 2473'
            d='M82.571,93.265a2.449,2.449,0,0,0-1.481-.855V90.741a.4.4,0,1,0-.8,0V92.41a2.45,2.45,0,0,0-1.481.855l-1.446-.835a.4.4,0,0,0-.4.691l1.445.834a2.44,2.44,0,0,0,0,1.71l-1.445.835a.4.4,0,1,0,.4.691l1.445-.835a2.453,2.453,0,0,0,1.481.855v1.669a.4.4,0,0,0,.8,0V97.214a2.451,2.451,0,0,0,1.481-.855l1.445.835.026.015h0a.4.4,0,0,0,.371-.706l-1.445-.835a2.44,2.44,0,0,0,0-1.71l1.445-.834.054-.031v-.006a.4.4,0,0,0,.092-.508.4.4,0,0,0-.545-.146Zm-1.88,3.183a1.636,1.636,0,1,1,1.636-1.636,1.638,1.638,0,0,1-1.636,1.636'
            transform='translate(-73.991 -87.075)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2474'
            data-name='Path 2474'
            d='M174.668,202.919a.4.4,0,1,0,.4.4.4.4,0,0,0-.4-.4'
            transform='translate(-167.968 -195.582)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconCustodySmall
