import { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { BEND_ETH_UNISWAP_POOL } from 'modules/bend/constants'
import useUniswapReservePool from 'modules/bend/hooks/useUniswapReservePool'

const useUsdPriceBend = (usdPriceEth: { value: BigNumber; isLoading: boolean }) => {
  const { reserve0, reserve1 } = useUniswapReservePool(BEND_ETH_UNISWAP_POOL)

  return useMemo(() => {
    if (DEFAULT_CHAIN_ID !== '1') return { usdPriceBend: new BigNumber(0.003), isLoading: false }

    const LPAmount = new BigNumber(reserve1).dividedBy(reserve0)
    return {
      usdPriceBend: usdPriceEth.value.multipliedBy(LPAmount),
      isLoading: !reserve0 || !reserve1 || usdPriceEth.isLoading
    }
  }, [reserve0, reserve1, usdPriceEth.isLoading, usdPriceEth.value])
}

export default useUsdPriceBend
