import React from 'react'

interface IArrowUp {
  color?: string
  size?: number
}

const ArrowUp = React.forwardRef<SVGSVGElement, IArrowUp>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        d='M23.7381,17.826,12.8935,4.6687a1.1452,1.1452,0,0,0-1.7056,0L.2905,17.826a1.1431,1.1431,0,0,0,.8528,1.9045H22.8812a1.1431,1.1431,0,0,0,.8569-1.9045Z'
        fill={color}
      />
    </svg>
  )
})

export default ArrowUp
