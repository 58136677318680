import React from 'react'

interface IIconNftBoundNftInCustody {
  size?: number
}

const IconNftBoundNftInCustody = React.forwardRef<SVGSVGElement, IIconNftBoundNftInCustody>(({ size = 16, ...rest }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 16' ref={ref} {...rest}>
      <g id='Group_6938' data-name='Group 6938' transform='translate(-800 -827)'>
        <circle id='Ellipse_327' data-name='Ellipse 327' cx='8' cy='8' r='8' transform='translate(800 827)' />
        <path
          id='Path_2733'
          data-name='Path 2733'
          d='M83.34,93.653a2.774,2.774,0,0,0-1.677-.969v-1.89a.452.452,0,1,0-.9,0v1.89a2.774,2.774,0,0,0-1.677.969l-1.637-.945a.452.452,0,0,0-.452.783l1.636.945a2.763,2.763,0,0,0,0,1.937l-1.636.945a.452.452,0,1,0,.452.783l1.637-.945a2.778,2.778,0,0,0,1.678.969v1.89a.452.452,0,1,0,.9,0v-1.89a2.776,2.776,0,0,0,1.677-.969l1.637.945.029.016h0a.452.452,0,0,0,.421-.8l-1.636-.945a2.763,2.763,0,0,0,0-1.937l1.636-.945.061-.036v-.007a.453.453,0,0,0,.1-.575.452.452,0,0,0-.617-.166Zm-2.129,3.6A1.853,1.853,0,1,1,83.064,95.4a1.855,1.855,0,0,1-1.853,1.853'
          transform='translate(726.789 739.595)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.25'
        />
        <path
          id='Path_2734'
          data-name='Path 2734'
          d='M174.721,202.919a.452.452,0,1,0,.452.452.452.452,0,0,0-.452-.452'
          transform='translate(633.279 631.629)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  )
})

export default IconNftBoundNftInCustody
