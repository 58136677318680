import { useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'

import { Call, CallResult, ListenerOptions } from '../interfaces'
import { parseCallKey, toCallKey } from '../utils'

import { useMulticall } from './useMulticall'

const INVALID_RESULT: CallResult = {
  valid: false,
  blockNumber: undefined,
  data: undefined
}

// the lowest level call for subscribing to contract data
export function useCallsData(calls: (Call | undefined)[], options?: ListenerOptions): CallResult[] {
  const { chainId } = useWeb3React()
  const { MulticallDispatch, callResults, callListeners } = useMulticall()
  //const callResults:any = {}
  //const dispatch:any = {}
  //const callResults = useSelector<AppState, AppState['multicall']['callResults']>(state => state.multicall.callResults)
  //const dispatch = useDispatch<AppDispatch>()

  const serializedCallKeys: string = useMemo(
    () =>
      JSON.stringify(
        calls
          ?.filter((c): c is Call => Boolean(c))
          ?.map(toCallKey)
          ?.sort() ?? []
      ),
    [calls]
  )

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    if (MulticallDispatch === undefined) return undefined
    const callKeys: string[] = JSON.parse(serializedCallKeys)
    if (!chainId || callKeys.length === 0) return undefined
    const calls = callKeys.map(key => parseCallKey(key))

    const listeners = callListeners ?? {}
    listeners[chainId] = listeners[chainId] ?? {}
    const blocksPerFetch = options?.blocksPerFetch ? options.blocksPerFetch : 1
    const callback = options?.callback
    calls.forEach(call => {
      const callKey = toCallKey(call)
      listeners[chainId][callKey] = listeners[chainId][callKey] ?? {}
      listeners[chainId][callKey][blocksPerFetch] = (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1
      listeners[chainId][callKey].callback = callback
    })

    MulticallDispatch({
      type: 'UPDATE',
      payload: {
        callListeners: listeners
      }
    })

    return () => {
      const listeners = callListeners ?? {}

      if (!listeners[chainId]) return
      calls.forEach(call => {
        const callKey = toCallKey(call)
        if (!listeners[chainId][callKey]) return
        if (!listeners[chainId][callKey][blocksPerFetch]) return

        if (listeners[chainId][callKey][blocksPerFetch] === 1) {
          delete listeners[chainId][callKey][blocksPerFetch]
        } else {
          listeners[chainId][callKey][blocksPerFetch]--
        }
      })
      MulticallDispatch({
        type: 'UPDATE',
        payload: {
          callListeners: listeners
        }
      })
    }
  }, [MulticallDispatch, callListeners, chainId, options?.blocksPerFetch, options?.callback, serializedCallKeys])

  return calls.map<CallResult>(call => {
    if (!chainId || !call) return INVALID_RESULT

    const result = callResults[chainId]?.[toCallKey(call)]
    let data
    if (result?.data && result?.data !== '0x') {
      data = result.data
    }
    return { valid: true, data, blockNumber: result?.blockNumber }
  })
}
