import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconBNFT = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        fill={color}
        stroke={color}
        strokeWidth='0'
        d='M13.5,6.1l-2-1.2l-0.7-1.3L10,4.1L6.8,2.2L0,6.1v7.8l6.8,3.9l3.2-1.9l0.7,0.4l0.7-1.1l2.2-1.3V6.1z M11.9,7.1v5.9l-5.1,2.9
        l-5.1-2.9V7.1l5.1-2.9l3,1.8L9.9,6L10,6L11.9,7.1z M8.1,5.2l0.8,1.4L8.1,7.1V8H6.5V6.1L8.1,5.2z M12.4,4.6l-0.8-1.4l1.6-0.9l1.6,0.9
        L14,4.6l-0.8-0.5L12.4,4.6z M8.1,10.9H6.5V9h1.7V10.9z M8.1,12.9l0.8,0.5l-0.8,1.4l-1.6-0.9V12h1.7V12.9z M16.6,13.9l0.8,1.4l-1.7,1
        l-0.8-1.4L16.6,13.9z M18.3,9.1H20V11h-1.7V9.1z M20,6.1V8h-1.7V7.1l-0.8-0.5l0.8-1.4L20,6.1z M15.8,3.7l1.7,1l-0.8,1.4l-1.7-1
        L15.8,3.7z M14,15.4l0.8,1.4l-1.6,0.9l-1.6-0.9l0.8-1.4l0.8,0.5L14,15.4z M18.3,12H20v1.9l-1.6,0.9l-0.8-1.4l0.8-0.5V12z'
      />
    </svg>
  )
})

export default IconBNFT
