import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { okxConnection } from 'modules/wallet-module/connection' // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
import useBtcWallet from 'modules/wallet-module/hooks/useBtcWallet'
import { ConnectionType, getConnectionName } from 'wallet-module'

import Option from './option'

const INJECTED_PROPS = {
  color: '#010101',
  icon: '/images/okx-wallet.png',
  id: 'okx'
}

export function OKXWalletOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = okxConnection.hooks.useIsActive() // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
  const { handleConnect } = useBtcWallet()
  return (
    <Option
      {...INJECTED_PROPS}
      active={isActive}
      header={getConnectionName({ connectionType: ConnectionType.OKX_WALLET, isMetaMask: false })} // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
      onClick={() => {
        tryActivation({
          connector: okxConnection.connector, // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
          type: ConnectionType.OKX_WALLET // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
        })
        handleConnect?.('okx')
      }}
    />
  )
}
