import { ChainId } from 'wallet-module'

import { ListenerOptions } from '../interfaces'

import MULTICALL_ABI from './abis/multicall.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.SEPOLIA]: '0x25Eef291876194AeFAd0D60Dff89e268b90754Bb',
  [ChainId.MERLIN_TESTNET]: '0x758BC6321b7c68F4986d62a4D46E83A2DCb58c80',
  [ChainId.MERLIN]: '0x4398C643e4FA26420B49AcA0d3EeCD96a7C20e5e'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }

// use this options object
export const NEVER_RELOAD: ListenerOptions = {
  blocksPerFetch: Infinity
}
