import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMedium1 = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 18, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 21.129 12' fill={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_6367' data-name='Rectangle 6367' width='21.129' height='12' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_23723' data-name='Group 23723' clipPath='url(#clip-path)'>
        <path
          id='Path_4977'
          data-name='Path 4977'
          d='M5.959,0A6,6,0,1,1,0,6,5.98,5.98,0,0,1,5.959,0m9.516.351c1.646,0,2.98,2.529,2.98,5.649h0c0,3.119-1.334,5.649-2.98,5.649S12.5,9.12,12.5,6,13.83.352,15.476.352m4.606.588c.579,0,1.048,2.266,1.048,5.06s-.469,5.06-1.048,5.06S19.034,8.8,19.034,6,19.5.941,20.081.941'
          transform='translate(0 -0.001)'
        />
      </g>
    </svg>
  )
})

export default IconMedium1
