import React from 'react'

interface IIconLangCn {
  color?: string
  size?: number
}

const IconLangCn = React.forwardRef<SVGSVGElement, IIconLangCn>(({ size = 32, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g>
        <g>
          <ellipse transform='matrix(0.9871 -0.1602 0.1602 0.9871 -1.4716 1.7451)' fill='#EE1C25' cx='10.1' cy='10' rx='9.9' ry='9.9' />
          <path
            fill='#EAEAEA'
            d='M10,20C4.5,20,0,15.5,0,10S4.5,0,10,0s10,4.5,10,10S15.5,20,10,20z M10,0.3c-5.4,0-9.7,4.4-9.7,9.7
                s4.4,9.7,9.7,9.7s9.7-4.4,9.7-9.7S15.4,0.3,10,0.3z'
          />
        </g>
        <g>
          <polygon fill='#FFFF00' points='5.9,5.3 5.3,3.4 4.6,5.3 2.6,5.3 4.2,6.6 3.6,8.5 5.3,7.3 6.9,8.5 6.3,6.6 8,5.3 ' />
          <polygon fill='#FFFF00' points='9.9,3.7 10.2,4.3 10.3,3.6 11,3.5 10.3,3.2 10.4,2.5 9.9,3 9.3,2.7 9.6,3.3 9.2,3.9 ' />
          <polygon fill='#FFFF00' points='11.6,5.5 11.7,6.2 12.1,5.6 12.7,5.7 12.3,5.2 12.6,4.6 12,4.9 11.5,4.4 11.6,5.1 11,5.4 ' />
          <polygon fill='#FFFF00' points='12.3,8.2 12.8,7.8 12.1,7.8 11.9,7.2 11.7,7.8 11,7.9 11.6,8.2 11.4,8.9 11.9,8.5 12.5,8.9 ' />
          <polygon fill='#FFFF00' points='10.3,9.8 10.3,9.1 9.9,9.7 9.3,9.4 9.6,10 9.2,10.5 9.9,10.4 10.3,10.9 10.3,10.2 11,10.1 ' />
        </g>
      </g>
    </svg>
  )
})

export default IconLangCn
