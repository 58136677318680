import { isMobile } from 'react-device-detect'
import useTheme from 'hooks/common/useTheme'
import { getIsOkxWallet, getIsRabby } from 'modules/wallet-module/utils'
import { useTranslation } from 'next-i18next'
import { Box, Flex, Link, Text } from 'rebass'
import { Button, Spacer } from 'theme/ui'
import { getIsCoinbaseWallet, getIsInjected, getIsMetaMask } from 'wallet-module'

import { CoinbaseWalletOption, OpenCoinbaseWalletOption } from './coinbaseWalletOption'
import { GnosisSafeOption } from './gnosisSafeOption'
import { InjectedOption, InstallMetaMaskOption, MetaMaskOption } from './injectedOption'
import { OKXWalletOption } from './okxWalletOption'
import { RabbyWalletOption } from './rabbyWalletOption'
import { TrustWalletOption } from './trustWalletOption'
import { WalletConnectV2Option } from './walletConnectOptionV2'

export const EthWalletStep2: React.FC<any> = ({ handleBack, account, tryActivation }) => {
  const { t } = useTranslation('common')
  const { colors } = useTheme()
  // get wallets user can switch too, depending on device/browser

  function getOptions() {
    const isInjected = getIsInjected()
    const isMetaMask = getIsMetaMask()
    const isCoinbaseWallet = getIsCoinbaseWallet()
    const isOkxWallet = getIsOkxWallet()
    const isRabbyWallet = getIsRabby()
    const isCoinbaseWalletBrowser = isMobile && isCoinbaseWallet
    const isMetaMaskBrowser = isMobile && isMetaMask
    const isInjectedMobileBrowser = isCoinbaseWalletBrowser || isMetaMaskBrowser

    let injectedOption
    if (!isInjected) {
      if (!isMobile) {
        injectedOption = <InstallMetaMaskOption />
      }
    } else if (!isCoinbaseWallet) {
      if (isMetaMask) {
        injectedOption = <MetaMaskOption tryActivation={tryActivation} />
      } else if (isOkxWallet) {
        injectedOption = <OKXWalletOption tryActivation={tryActivation} />
      } else if (isRabbyWallet) {
        injectedOption = <RabbyWalletOption tryActivation={tryActivation} />
      } else {
        injectedOption = <InjectedOption tryActivation={tryActivation} />
      }
    }

    let coinbaseWalletOption
    if (isMobile && !isInjectedMobileBrowser) {
      coinbaseWalletOption = <OpenCoinbaseWalletOption />
    } else if (!isMobile || isCoinbaseWalletBrowser) {
      coinbaseWalletOption = <CoinbaseWalletOption tryActivation={tryActivation} />
    }

    // const walletConnectOption = <WalletConnectOption tryActivation={tryActivation} /> ?? null
    const walletConnectOptionV2 = <WalletConnectV2Option tryActivation={tryActivation} /> ?? null
    const okxWalletOption = <OKXWalletOption tryActivation={tryActivation} /> ?? null
    const rabbyWalletOption = <RabbyWalletOption tryActivation={tryActivation} /> ?? null
    const trustWalletOption = (!isInjectedMobileBrowser && <TrustWalletOption tryActivation={tryActivation} />) ?? null
    const gnosisSafeOption = <GnosisSafeOption tryActivation={tryActivation} /> ?? null

    return (
      <>
        {injectedOption}
        {okxWalletOption}
        {rabbyWalletOption}
        {trustWalletOption}
        {coinbaseWalletOption}
        {gnosisSafeOption}
        {walletConnectOptionV2}
      </>
    )
  }

  return (
    <>
      {account ? (
        <Box>
          <Text fontWeight='bold' fontSize={13}>
            {t('wallet.label.change-provider')}
          </Text>
          <Spacer size='xl' />
        </Box>
      ) : (
        <Box>
          <Text fontWeight='bold' fontSize={13}>
            {t('wallet.label.connect-to-an-eth-wallet')}
          </Text>
          <Spacer size='xl' />
        </Box>
      )}
      <Box>
        <Box
          sx={{
            display: 'grid',
            gridGap: '10px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))'
          }}
        >
          {getOptions()}
        </Box>
        {account && (
          <Box width='100%' mt={20}>
            <Button onClick={handleBack} size='md' outlined fontWeight='bold' width='100%'>
              {t('wallet.button.back')}
            </Button>
          </Box>
        )}
        <Flex mt={20} fontSize={12}>
          <Text sx={{ opacity: 0.5 }}>{t('wallet.label.new-to-ethereum')}? &nbsp;</Text>
          <Link href='https://ethereum.org/wallets/' target='_blank' rel='noopener noreferrer'>
            <Text color={colors.primary} sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8, transition: 'ease-in-out 0.2s' }, zIndex: 200 }}>
              {t('wallet.label.learn-more-about-wallets')}
            </Text>
          </Link>
        </Flex>
      </Box>
    </>
  )
}
