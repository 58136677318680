import React, { forwardRef } from 'react'
// Hooks
import useTheme from 'hooks/common/useTheme'
// Components
import { Flex } from 'rebass/styled-components'

import { ICard, ICardTitle } from './interfaces'

const Card = forwardRef<HTMLElement, ICard>(({ children, title, titleHasPadding = true, type, cardTitleStyles, ...restprops }, ref) => {
  const { darkMode, colors } = useTheme()
  switch (type) {
    default:
    case 'primary':
      return (
        <Flex variant='card' pt={!!title ? (titleHasPadding ? 60 : null) : null} {...restprops} ref={ref}>
          {React.isValidElement(title) && (
            <Flex variant='card-title-wrapper' sx={{ ...cardTitleStyles }}>
              {title}
            </Flex>
          )}
          {children}
        </Flex>
      )
    case 'secondary':
      return (
        <Flex
          variant='card'
          sx={{
            pt: !!title ? (titleHasPadding ? 60 : null) : null,
            bg: darkMode ? colors.black : colors.black,
            color: darkMode ? colors.white : colors.white,
            boxShadow: `0 4px 8px 0 ${colors.shadow[200]}`
          }}
          {...restprops}
          ref={ref}
        >
          {React.isValidElement(title) && (
            <Flex variant='card-title-wrapper' sx={{ ...cardTitleStyles }}>
              {title}
            </Flex>
          )}
          {children}
        </Flex>
      )
    case 'tertiary':
      return (
        <Flex
          variant='card'
          sx={{
            pt: !!title ? (titleHasPadding ? 60 : null) : null,
            bg: darkMode ? colors.blue[300] : colors.blue[300],
            color: darkMode ? colors.white : colors.white,
            boxShadow: `0 4px 8px 0 ${colors.shadow[200]}`
          }}
          {...restprops}
          ref={ref}
        >
          {React.isValidElement(title) && (
            <Flex variant='card-title-wrapper' sx={{ ...cardTitleStyles }}>
              {title}
            </Flex>
          )}
          {children}
        </Flex>
      )
  }
})

export const CardTitle: React.FC<ICardTitle> = ({ children, type, color, backgroundColor, actions, ...restprops }) => (
  <Flex width='100%' alignItems='center' {...restprops}>
    <Flex
      variant={type ? `card-title-${type}` : 'card-title-primary'}
      sx={{
        color: color ? color : null,
        bg: backgroundColor ? backgroundColor : null
      }}
    >
      {children}
    </Flex>
    {actions && (
      <Flex ml='auto' alignItems='center'>
        {actions}
      </Flex>
    )}
  </Flex>
)

export default Card
