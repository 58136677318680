import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'

const useENSAvatar = (account: string | null | undefined = '') => {
  const { provider, chainId } = useWeb3React()

  return useQuery(['get avatar', account, !!provider, chainId], async () => {
    if (DEFAULT_CHAIN_ID !== '1') return null
    if (!account || !provider) return null
    return await provider.getAvatar(account)
  })
}

export default useENSAvatar
