import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { useNotifications } from 'contexts/notifications'

import { useAppNotificationContext } from 'components/common/app-notofications/context'

export default function useHasNotifications() {
  const { notifications } = useNotifications()
  const { notificationsContent } = useAppNotificationContext()
  const { account, chainId } = useWeb3React()

  return useMemo(() => {
    return (
      (notifications.length > 0 && window.localStorage.getItem(`_bend_display_notification__${notifications[0]?.time}`) !== 'true') ||
      notificationsContent.length > 0 ||
      (account && chainId !== Number(DEFAULT_CHAIN_ID)) ||
      !account
    )
  }, [account, chainId, notifications, notificationsContent])
}
