import { createContext, useCallback, useContext, useReducer } from 'react'

export enum AppNotificationColor {
  green = 'green.1000',
  red = 'red.1000',
  yellow = 'yellow.1000',
  primary = 'primary'
}

export type AppNotificationLocation = 'content' | 'right'
export type AppNotificationType = 'default' | 'success' | 'fail' | 'verify' | 'loading'

export type AppNotification = {
  key: string
  message: string | React.ReactNode
  button?: string
  onClick?: () => void
  onClose?: () => void
  close?: boolean
  type?: AppNotificationType
  location?: AppNotificationLocation
}

type InitialState = {
  notifications: Array<AppNotification>
  notificationsContent: Array<AppNotification>
  notificationsRight: Array<AppNotification>
}

type AppNotificationOptions = {
  timeout?: number
  autoHide?: boolean
  location?: AppNotificationLocation
}

type AppNotificationContextProps = {
  addAppNotification: (notification: AppNotification, options?: AppNotificationOptions) => void
  removeAppNotification: (id: string) => void
  clearAppNotifications: () => void
  notificationExists: (key: string) => boolean
}

type AppNotificationsProviderProps = {
  children: React.ReactNode
}

const initialState: InitialState = {
  notifications: [],
  notificationsContent: [],
  notificationsRight: []
}

type ReducerAction = {
  action: 'addAppNotification' | 'removeAppNotification' | 'clearAppNotifications'
  payload?: any
}

const AppNotificationContext = createContext<AppNotificationContextProps & InitialState>({
  ...initialState,
  addAppNotification: () => {},
  removeAppNotification: () => {},
  clearAppNotifications: () => {},
  notificationExists: () => false
})

export const useAppNotificationContext = () => useContext(AppNotificationContext)

const reducer = (state: InitialState, { action, payload }: ReducerAction) => {
  switch (action) {
    default:
    case 'addAppNotification':
      const filteredNotifications = state.notifications.filter(notification => notification.key.toLowerCase() !== payload?.key.toLowerCase())
      return { ...state, notifications: [payload, ...filteredNotifications] }
    case 'removeAppNotification':
      const filteredNotifications2 = state.notifications.filter(notification => notification.key.toLowerCase() !== payload?.toLowerCase())
      return { ...state, notifications: filteredNotifications2 }
    case 'clearAppNotifications':
      return { ...state, notifications: [] }
  }
}

export default function AppNotificationsProvider({ children }: AppNotificationsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const removeAppNotification = useCallback(
    id =>
      dispatch({
        action: 'removeAppNotification',
        payload: id
      }),
    []
  )

  const addAppNotification = useCallback(
    (payload: AppNotification, options: AppNotificationOptions = { timeout: 10000, autoHide: true, location: 'right' }) => {
      dispatch({
        action: 'addAppNotification',
        payload: {
          ...payload,
          location: options?.location ? options.location : 'right'
        }
      })

      if (options?.autoHide) {
        setTimeout(() => {
          removeAppNotification(payload.key)
        }, options?.timeout)
      }
    },
    [removeAppNotification]
  )

  const clearAppNotifications = useCallback(
    () =>
      dispatch({
        action: 'clearAppNotifications'
      }),
    []
  )

  const notificationExists = useCallback(
    (key: string) => state.notifications.some(notification => notification.key.toLowerCase() === key.toLowerCase()),
    [state.notifications]
  )

  return (
    <AppNotificationContext.Provider
      value={{
        notifications: state.notifications,
        notificationsContent: state.notifications.filter(notification => notification.location === 'content'),
        notificationsRight: state.notifications.filter(notification => notification.location === 'right'),
        addAppNotification,
        removeAppNotification,
        clearAppNotifications,
        notificationExists
      }}
    >
      {children}
    </AppNotificationContext.Provider>
  )
}
