import React from 'react'
import { IRoutes } from 'constants/types'
import { Flex, FlexProps } from 'rebass/styled-components'
import NavButtonLanding from 'theme/ui/common/nav-button/nav-button-landing'

import { routes } from '../../../constants'

const NavigationLanding: React.FC<FlexProps> = ({ ...resprops }) => {
  return (
    <Flex alignItems='center' {...resprops}>
      {routes.slice(1).map((route: IRoutes, index) => (
        <NavButtonLanding key={`nav-button-${index}`} {...route} />
      ))}
    </Flex>
  )
}

export default NavigationLanding
