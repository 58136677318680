import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { trustWalletConnection } from 'modules/wallet-module/connection'
import { ConnectionType, getConnectionName } from 'wallet-module'

import Option from './option'

const BASE_PROPS = {
  color: '#315CF5',
  icon: '/images/trust-wallet.png',
  id: 'trust-wallet'
}

export function TrustWalletOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = trustWalletConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      active={isActive}
      onClick={() =>
        tryActivation({
          connector: trustWalletConnection.connector,
          type: ConnectionType.TRUST_WALLET
        })
      }
      header={getConnectionName({ connectionType: ConnectionType.TRUST_WALLET })}
    />
  )
}
