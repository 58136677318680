import React from 'react'

interface IIconRepay2 {
  color?: string
  size?: number
}

const IconRepay2 = React.forwardRef<SVGSVGElement, IIconRepay2>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='repay2'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M12.1,7H9.5C9,7,8.6,7.4,8.6,7.9S9,8.8,9.5,8.8h0.4l-2.7,2.7c-0.4,0.4-0.4,0.9,0,1.3c0,0,0,0,0,0c0.4,0.4,0.9,0.4,1.3,0
        c0,0,0,0,0,0l2.7-2.7v0.4c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V7.9c0-0.2-0.1-0.5-0.3-0.6C12.6,7.1,12.4,7,12.1,7z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10s10-4.5,10-10C20,4.5,15.5,0,10,0z M10,18.2L10,18.2c-4.5,0-8.2-3.7-8.2-8.2
        c0,0,0,0,0,0c0-4.5,3.7-8.2,8.2-8.2c0,0,0,0,0,0c4.5,0,8.2,3.7,8.2,8.2c0,0,0,0,0,0C18.2,14.5,14.5,18.2,10,18.2z'
        />
      </g>
    </svg>
  )
})

export default IconRepay2
