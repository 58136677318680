import { createContext, useContext, useEffect, useReducer } from 'react'
import { ReducerActions } from 'constants/types'
import { useSearch } from 'contexts'
import useTheme from 'hooks/common/useTheme'
import { Flex, FlexProps } from 'rebass/styled-components'

import Filter from '../filter'
import Search from '../search'

interface IToolbar {
  title?: string
  loading?: boolean
  listView?: boolean
  onDashboard?: boolean
  cookieName?: string
  right?: React.ReactNode
  hideSearch?: boolean
  searchLabel?: string
}
interface Filter {
  key: number | string
  name: string
  address: string
  image: string
}
type SelectedFilter = string[]
interface IInitialState {
  isGridList: boolean
  showFilter: boolean
  selectedFilter: SelectedFilter
  selectedSortBy: any
  filter: Filter[]
}

interface IToolbarContext extends IInitialState {
  dispatch?: any
}

const initialState = {
  isGridList: true,
  showFilter: false,
  selectedFilter: [],
  selectedSortBy: {},
  filter: [],
  hasFilter: false
}

const ToolbardContext = createContext<IToolbarContext>(initialState)

export enum ToolbarReducer {
  setIsGridList,
  toggleFilter,
  setSelectedFilter,
  setSelectedSortBy,
  setFilter,
  cleanUp,
  cleanUpFilter
}

const reducer = (state: IInitialState, { action, payload }: ReducerActions<ToolbarReducer>) => {
  switch (action) {
    default:
      return state
    case ToolbarReducer.setIsGridList:
      return { ...state, isGridList: payload }
    case ToolbarReducer.toggleFilter:
      return { ...state, showFilter: payload }
    case ToolbarReducer.setSelectedFilter:
      return { ...state, selectedFilter: payload }
    case ToolbarReducer.setFilter:
      return { ...state, filter: payload, showFilter: true }
    case ToolbarReducer.setSelectedSortBy:
      return { ...state, selectedSortBy: payload }
    case ToolbarReducer.cleanUp:
      return { ...state, ...initialState }
    case ToolbarReducer.cleanUpFilter:
      return { ...state, filter: [], selectedFilter: [], showFilter: false }
  }
}

export const ToolbarContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ToolbardContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {children}
    </ToolbardContext.Provider>
  )
}

export const useToolbar = () => useContext(ToolbardContext)

export const Toolbar: React.FC<IToolbar & FlexProps> = ({ title, listView = false, right, hideSearch = false, searchLabel, ...restprops }) => {
  const { clearSearch, setSearch } = useSearch()
  const { dispatch } = useToolbar()
  const { colors } = useTheme()
  useEffect(() => {
    dispatch({
      action: ToolbarReducer.setIsGridList,
      payload: !listView
    })
  }, [dispatch, listView])

  useEffect(() => {
    return () => {
      clearSearch()
      dispatch({ action: ToolbarReducer.cleanUp })
    }
  }, [clearSearch, dispatch])

  return title ? (
    <Flex
      flexDirection={['column', 'column', 'row', 'row']}
      justifyContent='space-between'
      alignItems='center'
      maxWidth={1400}
      {...restprops}
      mb={[30, 30, 10]}
    >
      <Flex fontWeight='700' fontSize={24} alignItems='center' mb={[20, 20, 0]}>
        {title}
      </Flex>

      <Flex flexDirection={['column', 'column', 'row', 'row']} alignItems={['center']} minWidth={['100%', '100%', 255]}>
        {right}
        {!hideSearch && (
          <Search
            width={['100%', '100%', null]}
            mt={[10, 10, 0]}
            onClick={(searchString: string) => setSearch(searchString)}
            flex={['0 0 100%', '0 0 100%', '0 0 255px', '0 0 255px']}
            label={searchLabel}
          />
        )}
      </Flex>
    </Flex>
  ) : (
    <Flex flexDirection={['column', 'column', 'row', 'row']} justifyContent='space-between' maxWidth={1400} {...restprops}>
      <Flex alignItems='center'>
        {!hideSearch && (
          <Search
            onClick={(searchString: string) => setSearch(searchString)}
            flex={['0 0 100%', '0 0 100%', '0 0 255px', '0 0 255px']}
            borderColor={`${colors.grey[200]}`}
            label={searchLabel}
          />
        )}
      </Flex>

      <Flex flexDirection={['column', 'column', 'row', 'row']} alignItems={['center']} mt={[10, 10, 0]}>
        {right}
      </Flex>
    </Flex>
  )
}

export default Toolbar
