import React from 'react'

interface IIconNftFaucet {
  color?: string
  size?: number
}

const IconNftFaucet = React.forwardRef<SVGSVGElement, IIconNftFaucet>(({ color = 'currentColor', size = 35 }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 35 35' fill={color}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_1899' data-name='Rectangle 1899' width='35' height='35' transform='translate(-0.25)' fill={color} />
        </clipPath>
      </defs>
      <g id='Group_4652' data-name='Group 4652' transform='translate(0.5 0.25)'>
        <g id='Group_4652-2' data-name='Group 4652' transform='translate(-0.25 -0.25)' clipPath='url(clip-path)'>
          <path
            id='Path_1758'
            data-name='Path 1758'
            d='M3.115,60.826a1,1,0,0,0,1.411,0L17.718,47.635l.208,1.742a1,1,0,0,0,1.79.48L23.049,45.4l5.115,2.192a1,1,0,0,0,1.311-1.31l-2.192-5.115,4.456-3.333a1,1,0,0,0-.48-1.79l-5.525-.659-.659-5.525a1,1,0,0,0-1.79-.48L19.95,33.837l-5.115-2.192a1,1,0,0,0-1.311,1.31l2.192,5.115L11.262,41.4a1,1,0,0,0,.48,1.79l1.742.208L.292,56.592A1,1,0,0,0,.292,58ZM17.542,39.2a1,1,0,0,0,.32-1.192l-1.518-3.543,3.543,1.518a1,1,0,0,0,1.192-.32l2.309-3.087.456,3.828a1,1,0,0,0,.873.873l3.827.456-3.087,2.309a1,1,0,0,0-.32,1.192l1.518,3.543-3.543-1.518a1,1,0,0,0-1.192.319l-2.309,3.087-.456-3.828a1,1,0,0,0-.873-.873l-3.828-.456Zm-1.536,4.5,1.261.15.15,1.261-13.6,13.6L2.409,57.3Z'
            transform='translate(0.28 -26.772)'
            fill={color}
          />
          <path
            id='Path_1759'
            data-name='Path 1759'
            d='M415.9,46.883l-1.411,1.411a1,1,0,0,0,1.411,1.411l1.411-1.411a1,1,0,0,0-1.411-1.411'
            transform='translate(-386.356 -43.21)'
            fill={color}
          />
          <path
            id='Path_1760'
            data-name='Path 1760'
            d='M262.279.034a1,1,0,0,0-.706,1.222l.517,1.928a1,1,0,0,0,1.928-.517L263.5.74a1,1,0,0,0-1.222-.706'
            transform='translate(-243.857 0.28)'
            fill={color}
          />
          <path
            id='Path_1761'
            data-name='Path 1761'
            d='M347.689,318.788a1,1,0,0,0-.706,1.222l.517,1.928a1,1,0,0,0,1.928-.517l-.517-1.928a1,1,0,0,0-1.222-.706'
            transform='translate(-323.585 -297.265)'
            fill={color}
          />
          <path
            id='Path_1762'
            data-name='Path 1762'
            d='M135.517,127.312A1,1,0,1,0,135,129.24l1.928.517a1,1,0,1,0,.517-1.928Z'
            transform='translate(-125.047 -118.529)'
            fill={color}
          />
          <path
            id='Path_1763'
            data-name='Path 1763'
            d='M456.2,213.238l-1.928-.517a1,1,0,1,0-.517,1.928l1.928.517a1,1,0,1,0,.517-1.928'
            transform='translate(-422.593 -198.256)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconNftFaucet
