import React from 'react'

interface IIconError {
  color?: string
  size?: number
}

const IconError1 = React.forwardRef<SVGSVGElement, IIconError>(({ color = 'currentColor', size = 52, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 56.08 52.518' fill={color} stroke={color} {...rest}>
      <g id='Group_15663' data-name='Group 15663' transform='translate(0.269 0.268)'>
        <path
          id='Path_4144'
          data-name='Path 4144'
          d='M8.188,52A8.19,8.19,0,0,1,1.049,39.8L20.635,4.194a8.19,8.19,0,0,1,14.288-.012l19.6,35.632A8.191,8.191,0,0,1,47.374,52H8.188ZM27.775,3.019a5.181,5.181,0,0,0-4.5,2.642L3.688,41.27a5.172,5.172,0,0,0,4.5,7.712H47.374a5.172,5.172,0,0,0,4.508-7.7l-19.6-35.63a5.19,5.19,0,0,0-4.51-2.63'
          transform='translate(0 0)'
          fill={color}
          stroke={color}
          strokeWidth='0.5'
        />
        <path
          id='Path_4145'
          data-name='Path 4145'
          d='M27.624,32.234a1.508,1.508,0,0,1-1.509-1.509V17.366a1.509,1.509,0,1,1,3.018,0V30.724a1.508,1.508,0,0,1-1.509,1.509'
          transform='translate(0.158 0.096)'
          fill={color}
          stroke={color}
          strokeWidth='0.5'
        />
        <circle
          id='Ellipse_469'
          data-name='Ellipse 469'
          cx='1.508'
          cy='1.508'
          r='1.508'
          transform='translate(26.275 37.31)'
          fill={color}
          stroke={color}
          strokeWidth='0.5'
        />
      </g>
    </svg>
  )
})

export default IconError1
