import React from 'react'

interface IIconEth {
  color?: string
  size?: number
}

const IconEth = React.forwardRef<SVGSVGElement, IIconEth>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14.764 20.5' stroke={color} {...rest}>
      <g id='Group_8849' data-name='Group 8849' transform='translate(0.276 0.25)'>
        <g id='Group_7281' data-name='Group 7281' transform='translate(0 0)'>
          <path
            id='Path_2749'
            data-name='Path 2749'
            d='M.368,9.739l6.513,2.586a.623.623,0,0,0,.457,0l6.513-2.586a.572.572,0,0,0,.341-.365.539.539,0,0,0-.088-.48L7.591.236a.61.61,0,0,0-.963,0L.115,8.894a.539.539,0,0,0-.088.48A.572.572,0,0,0,.368,9.739ZM7.7,10.962V2.317l5.008,6.657ZM6.517,2.317v8.645L1.509,8.974Z'
            transform='translate(-0.004 0)'
            fill={color}
            strokeWidth='0.2'
          />
          <path
            id='Path_2750'
            data-name='Path 2750'
            d='M14.119,321.356a.566.566,0,0,0-.729-.254l-6.285,2.92L.82,321.1a.563.563,0,0,0-.729.254.712.712,0,0,0,.1.841l6.514,6.536a.549.549,0,0,0,.793,0l6.514-6.536A.712.712,0,0,0,14.119,321.356Zm-7.014,6L3.56,323.8l3.317,1.541a.537.537,0,0,0,.457,0l3.317-1.541Z'
            transform='translate(0 -308.903)'
            fill={color}
            strokeWidth='0.2'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconEth
