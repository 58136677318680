import { useEffect, useState } from 'react'
import { Flex } from 'rebass/styled-components'

// import { MotionFlex } from 'theme/ui'
import { BackdropProps } from '../types'

const Backdrop: React.FC<BackdropProps> = ({ children, onClick }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    return () => setIsMounted(false)
  }, [])

  return (
    <Flex
      onClick={onClick}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        opacity: isMounted ? 1 : 0,
        bg: 'backdrop',
        alignItems: ['flex-end', 'flex-end', 'center'],
        justifyContent: ['center'],
        overflowY: 'hidden',
        zIndex: 2001,
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-in-out'
      }}
    >
      {children}
    </Flex>
  )
}

export default Backdrop
