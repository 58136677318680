import { Circle as FeatherLoader } from 'react-feather'
import styled, { keyframes } from 'styled-components'

interface ILoader {
  size?: number
  color?: string
}

const loaderAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const Loader = styled(FeatherLoader)<ILoader>`
  animation: ${loaderAnimation} 1s linear infinite;
  stroke: ${({ color }) => (color ? color : '#29b500')};
  height: ${({ size }) => size}px;
  stroke-linecap: round;
  stroke-width: 1.4;
  stroke-linejoin: round;
  stroke-dasharray: 45;
`

export default Loader
