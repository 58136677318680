import React from 'react'

interface IIconNotification {
  color?: string
  size?: number
}

const IconNotification = React.forwardRef<SVGSVGElement, IIconNotification>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 13.241 16.239'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Shape'
        d='M6.495,15.989a1.175,1.175,0,0,1-1.217-1.124v-.751H.608a.564.564,0,1,1,0-1.124h.474V6A5.153,5.153,0,0,1,5.887,1.031V.562A.611.611,0,0,1,7.1.562v.469A5.152,5.152,0,0,1,11.908,6v7h.474a.564.564,0,1,1,0,1.124H7.714v.751A1.176,1.176,0,0,1,6.495,15.989Zm0-13.866A4.047,4.047,0,0,0,2.3,6v7h8.39V6A4.048,4.048,0,0,0,6.495,2.124Z'
        transform='translate(0.125 0.125)'
        stroke={color}
        strokeWidth='0.25'
      />
    </svg>
  )
})

export default IconNotification
