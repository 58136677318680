import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, UseMutationOptions } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import usePlatform from 'contexts/platform'
import useUserReserves from 'hooks/common/useUserReserves'
import find from 'lodash/find'
import { resetApprove } from 'modules/wallet-module/utils/erc20'
import { useMulticallContract, useSingleCallResult } from 'multicall-module'
import { useTranslation } from 'next-i18next'
import { isWeth } from 'utils'
import { approve, ERC20_ABI, getContract, useTokenContract, useWallet } from 'wallet-module'

import { BUSDT, BWETH, LEND_POOL, maxBendSupply, USDT_ADDRESS } from '../constants'
import { handleError } from '../utils/handleError'
import { auction, batchBorrow, batchRepay, borrow, deposit, liquidate, redeem, repay, withdraw } from '../utils/ILendPool'

import { useERC20Contract } from './useContract'
import { useApprove } from './useErc'
import { useDepositETH, useWithdrawETH } from './useIWETHGateway'
import { useILendPoolContract } from './'

export enum DepositSteps {
  APPROVE = 0,
  APPROVE_CONFIRM = 1,
  APPROVE_PROCESSING = 2,
  APPROVED = 3,
  PROCESS_CONFIRM = 4,
  PROCESSING = 5,
  PROCESS_SUCCESS = 6,
  PROCESS_FAIL = 7
}

export const useDepositData = (ILendPool_address: string, reserve: any, account: string) => {
  const { addTransaction } = useWallet()
  const [txHash, setTxHash] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [screenState, setScreenState] = useState(DepositSteps.APPROVE)
  const [isLoading, setIsLoading] = useState(true)
  const {
    config: { WETH_GATEWAY }
  } = usePlatform()

  const assetAddress = useMemo(() => reserve?.underlyingAsset ?? '', [reserve])
  const decimals = useMemo(() => reserve?.decimals, [reserve?.decimals])

  const tokenContract = useERC20Contract(assetAddress, false)

  const { result: allowance } = useSingleCallResult(tokenContract, 'allowance', [account, ILendPool_address])
  const allowanceData = allowance?.[0].toString() ?? ''

  const { result: tokenBalance } = useSingleCallResult(tokenContract, 'balanceOf', [account])
  //console.log('tokenBalance', tokenBalance?.[0].toString())
  const tokenBalanceData = tokenBalance?.[0].toString() ?? ''
  const formatedTokenBalance = useMemo(() => new BigNumber(tokenBalanceData).dividedBy(`1e${decimals}`), [tokenBalanceData, decimals])

  const multicallContract = useMulticallContract()
  const { result: ethBalance } = useSingleCallResult(multicallContract, 'getEthBalance', [account])
  const formatedEthBalance = useMemo(() => new BigNumber(ethBalance?.[0].toString()).dividedBy(`1e${decimals}`), [ethBalance, decimals])

  const { t: tc } = useTranslation('common')

  const { onDeposit } = useDeposit(ILendPool_address)
  const handleDeposit = useCallback(
    async ({ amount }) => {
      setTxHash('')
      try {
        setScreenState(DepositSteps.PROCESS_CONFIRM)
        const tx = await onDeposit(assetAddress, new BigNumber(amount).multipliedBy(`1e${decimals}`).toFixed(0), account ?? '', '0')
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setScreenState(DepositSteps.PROCESS_FAIL)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
        } else {
          setTxHash(tx.hash)
          setScreenState(DepositSteps.PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setScreenState(DepositSteps.PROCESS_SUCCESS)
            } else {
              setScreenState(DepositSteps.PROCESS_FAIL)
              setErrorMsg(tc('label.tx-failed'))
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(e.msg)
        setScreenState(DepositSteps.PROCESS_FAIL)
      }
    },
    [onDeposit, assetAddress, decimals, account, addTransaction, tc]
  )

  const { onDepositETH } = useDepositETH(WETH_GATEWAY)
  const handleDepositETH = useCallback(
    async ({ amount }) => {
      setTxHash('')
      try {
        setScreenState(DepositSteps.PROCESS_CONFIRM)
        const tx = await onDepositETH(new BigNumber(amount).multipliedBy(`1e${decimals}`).toFixed(0), account ?? '', '0')
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setScreenState(DepositSteps.PROCESS_FAIL)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
        } else {
          setTxHash(tx.hash)
          setScreenState(DepositSteps.PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setScreenState(DepositSteps.PROCESS_SUCCESS)
            } else {
              setScreenState(DepositSteps.PROCESS_FAIL)
              setErrorMsg(tc('label.tx-failed'))
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(e.msg)
        setScreenState(DepositSteps.PROCESS_FAIL)
      }
    },
    [onDepositETH, decimals, account, addTransaction, tc]
  )

  const { onApprove } = useApprove(assetAddress, ILendPool_address)
  const handleApprove = useCallback(async () => {
    setTxHash('')
    try {
      setScreenState(DepositSteps.APPROVE_CONFIRM)
      const tx = await onApprove()
      // user rejected tx or didn't go thru
      if (!tx || tx.message) {
        setScreenState(DepositSteps.APPROVE)
      } else {
        setTxHash(tx.hash)
        setScreenState(DepositSteps.APPROVE_PROCESSING)
      }
    } catch (e: any) {
      console.log(e)
      setScreenState(DepositSteps.APPROVE)
    }
  }, [onApprove])

  useEffect(() => {
    return () => {
      setScreenState(DepositSteps.APPROVE)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (isWeth(assetAddress)) setScreenState(DepositSteps.APPROVED)
    else setScreenState(DepositSteps.APPROVE)
  }, [assetAddress])

  useEffect(() => {
    if (screenState === DepositSteps.APPROVE || screenState === DepositSteps.APPROVE_PROCESSING) {
      if (new BigNumber(allowanceData).isGreaterThan(0)) setScreenState(DepositSteps.APPROVED)
    }
    setIsLoading(false)
  }, [allowanceData, screenState])

  return {
    assetAddress,
    handleApprove,
    handleDeposit: isWeth(assetAddress) ? handleDepositETH : handleDeposit,
    screenState,
    txHash,
    errorMsg,
    decimals,
    setScreenState,
    balance: isWeth(assetAddress) ? formatedEthBalance : formatedTokenBalance,
    isLoading
  }
}

type UseDepositEthMutationContext = {
  amount: string
}

type UseDepositEthMutationArgs = {
  options: UseMutationOptions<any, any, UseDepositEthMutationContext>
  setTxAwaitText: Dispatch<SetStateAction<string>>
}

// Hook used in new Yield UI
/**
 * It returns a function that will deposit ETH into the WETH contract
 * @param {UseDepositEthMutationArgs}  - UseDepositEthMutationArgs
 * @returns A function that takes an object with an amount property and returns a promise that resolves
 * to a transaction object.
 */
export const useDepositEthMutation = ({ options }: UseDepositEthMutationArgs) => {
  const { account, provider } = useWeb3React()
  const {
    config: { WETH_GATEWAY }
  } = usePlatform()
  const { onDepositETH } = useDepositETH(WETH_GATEWAY)

  return useMutation(
    async ({ amount }) => {
      if (!provider) return
      // const allowance = await tokenContract.allowance(account, LEND_POOL)
      // if (!new BigNumber(allowance?._hex).gt(0)) {
      //   setTxAwaitText(
      //     t('deposit.paragraph.approve-token', {
      //       token: 'WETH'
      //     })
      //   )
      //   const tx = await onApprove()
      //   if (!tx || tx.message) {
      //     throw new Error(tx?.message)
      //   }
      //   setTxAwaitText(t('deposit.paragraph.tx-pending'))
      //   await tx.wait()
      // }
      const tx = await onDepositETH(new BigNumber(amount).multipliedBy(1e18).toFixed(0), account ?? '', '0')
      return tx
    },
    {
      ...options
    }
  )
}

export const useDepositMutation = ({ options, setTxAwaitText }: UseDepositEthMutationArgs) => {
  const { account, provider } = useWeb3React()
  const { onDeposit } = useDeposit(LEND_POOL)
  const { t } = useTranslation()

  return useMutation(
    async ({ reserveAddress, amount, isUsdt = false }: { reserveAddress: string; amount: string; isUsdt?: boolean }) => {
      if (!provider) return
      const amountBN = new BigNumber(amount).multipliedBy(1e6)
      const tokenContract = getContract(reserveAddress, ERC20_ABI, provider, account)
      let allowance = new BigNumber((await tokenContract.allowance(account, LEND_POOL))?.toString())

      if (isUsdt && allowance.gt(1) && allowance.lt(amountBN.toFixed(0))) {
        try {
          setTxAwaitText(
            t('deposit.paragraph.reset-approve-token', {
              token: 'USDT'
            })
          )
          const tx = await resetApprove(tokenContract, LEND_POOL)
          if (!tx || tx.message) {
            throw new Error(tx?.message)
          }
          setTxAwaitText(t('deposit.paragraph.tx-pending'))
          await tx.wait()
          allowance = new BigNumber((await tokenContract.allowance(account, LEND_POOL))?.toString())
        } catch (error: any) {
          throw new Error(error?.message)
        }
      }

      if (new BigNumber(allowance.toString()).lt(amountBN.toFixed(0))) {
        try {
          setTxAwaitText(
            t('deposit.paragraph.approve-token', {
              token: 'USDT'
            })
          )
          const tx = await approve(tokenContract, LEND_POOL)
          if (!tx || tx.message) {
            throw new Error(tx?.message)
          }
          setTxAwaitText(t('deposit.paragraph.tx-pending'))
          await tx.wait()
        } catch (error: any) {
          throw new Error(error?.message)
        }
      }
      const tx = await onDeposit(reserveAddress, amountBN.toFixed(0), account ?? '', '0')
      return tx
    },
    {
      ...options
    }
  )
}

export enum WithdrawSteps {
  PROCESS = 1,
  PROCESS_CONFIRM = 2,
  PROCESSING = 3,
  PROCESS_SUCCESS = 4,
  PROCESS_FAIL = 5,
  APPROVE = 6,
  APPROVE_CONFIRM = 7,
  APPROVE_PROCESSING = 8
}

const MAX_INPUT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const useWithdrawtData = (ILendPool_address: string, reserve: any, account: string, BWETH_address: string) => {
  const { addTransaction } = useWallet()
  const [txHash, setTxHash] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [screenState, setScreenState] = useState(WithdrawSteps.APPROVE)
  const [isLoading, setIsLoading] = useState(true)
  const { data: userReserves } = useUserReserves()
  const {
    config: { WETH_GATEWAY, isLoadingConfig }
  } = usePlatform()

  const assetAddress = useMemo(() => reserve?.underlyingAsset ?? '', [reserve])
  const decimals = useMemo(() => reserve?.decimals, [reserve?.decimals])

  const tokenContract = useERC20Contract(assetAddress, false)
  const bwetTokenContract = useERC20Contract(BWETH_address, false)

  const depositBalance = useMemo(() => {
    if (!userReserves) return new BigNumber(0)
    const userReserve = find(userReserves, userReserve => userReserve.underlyingAsset === reserve.underlyingAsset)
    return userReserve?.currentBTokenBalance
  }, [reserve?.underlyingAsset, userReserves])

  const { result: allowance } = useSingleCallResult(bwetTokenContract, 'allowance', [account, WETH_GATEWAY], {}, isLoadingConfig)
  const allowanceData = allowance?.[0].toString() ?? ''

  const { result: tokenBalance } = useSingleCallResult(tokenContract, 'balanceOf', [account])
  //console.log('tokenBalance', tokenBalance?.[0].toString())
  const tokenBalanceData = tokenBalance?.[0].toString() ?? ''
  const formatedTokenBalance = useMemo(() => new BigNumber(tokenBalanceData).dividedBy(`1e${decimals}`), [tokenBalanceData, decimals])

  const multicallContract = useMulticallContract()
  const { result: ethBalance } = useSingleCallResult(multicallContract, 'getEthBalance', [account])
  const formatedEthBalance = useMemo(() => new BigNumber(ethBalance?.[0].toString()).dividedBy(`1e${decimals}`), [ethBalance, decimals])

  const formatedAvailableLiquidity = useMemo(
    () => new BigNumber(reserve.availableLiquidity).dividedBy(`1e${reserve.decimals}`),
    [reserve?.availableLiquidity, reserve?.decimals]
  )
  const { t: tc } = useTranslation('common')

  const { onApprove } = useApprove(BWETH_address, WETH_GATEWAY)
  const handleApprove = useCallback(async () => {
    setTxHash('')
    try {
      setScreenState(WithdrawSteps.APPROVE_CONFIRM)
      const tx = await onApprove()
      // user rejected tx or didn't go thru
      if (!tx || tx.message) {
        setScreenState(WithdrawSteps.APPROVE)
      } else {
        setTxHash(tx.hash)
        setScreenState(WithdrawSteps.APPROVE_PROCESSING)
        addTransaction(tx, ({ status }: { status: string }) => {
          if (status === 'success') {
            setScreenState(WithdrawSteps.PROCESS)
          } else {
            setScreenState(WithdrawSteps.APPROVE)
          }
        })
      }
    } catch (e: any) {
      console.log(e)
      setScreenState(WithdrawSteps.APPROVE)
    }
  }, [onApprove, addTransaction])

  const { onWithdraw } = useWithdraw(ILendPool_address)
  const handleWithdraw = useCallback(
    async ({ amount }) => {
      setTxHash('')
      try {
        setScreenState(WithdrawSteps.PROCESS_CONFIRM)
        const tx = await onWithdraw(
          reserve?.underlyingAsset ?? '',
          amount ? new BigNumber(amount).multipliedBy(`1e${decimals}`).toFixed(0) : MAX_INPUT,
          account ?? ''
        )
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setScreenState(WithdrawSteps.PROCESS_FAIL)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : handleError({ errorMessage: tx?.message }))
        } else {
          setTxHash(tx.hash)
          setScreenState(WithdrawSteps.PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setScreenState(WithdrawSteps.PROCESS_SUCCESS)
            } else {
              setScreenState(WithdrawSteps.PROCESS_FAIL)
              setErrorMsg(tc('label.tx-failed'))
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(handleError({ errorMessage: e.msg }))
        setScreenState(WithdrawSteps.PROCESS_FAIL)
      }
    },
    [onWithdraw, reserve?.underlyingAsset, decimals, account, addTransaction, tc]
  )

  const { onWithdrawETH } = useWithdrawETH(WETH_GATEWAY)
  const handleWithdrawETH = useCallback(
    async ({ amount }) => {
      setTxHash('')
      try {
        setScreenState(WithdrawSteps.PROCESS_CONFIRM)
        const tx = await onWithdrawETH(amount ? new BigNumber(amount).multipliedBy(`1e${decimals}`).toFixed(0) : MAX_INPUT, account ?? '')
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setScreenState(WithdrawSteps.PROCESS_FAIL)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : handleError({ errorMessage: tx?.message }))
        } else {
          setTxHash(tx.hash)
          setScreenState(WithdrawSteps.PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setScreenState(WithdrawSteps.PROCESS_SUCCESS)
            } else {
              setScreenState(WithdrawSteps.PROCESS_FAIL)
              setErrorMsg(tc('label.tx-failed'))
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(handleError({ errorMessage: e.msg }))
        setScreenState(WithdrawSteps.PROCESS_FAIL)
      }
    },
    [onWithdrawETH, decimals, account, addTransaction, tc]
  )

  useEffect(() => {
    if (
      screenState === WithdrawSteps.APPROVE_CONFIRM ||
      screenState === WithdrawSteps.APPROVE_PROCESSING ||
      screenState === WithdrawSteps.PROCESSING ||
      screenState === WithdrawSteps.PROCESS ||
      screenState === WithdrawSteps.PROCESS_CONFIRM ||
      screenState === WithdrawSteps.PROCESS_SUCCESS ||
      screenState === WithdrawSteps.PROCESS_FAIL
    )
      return

    if (isWeth(assetAddress)) {
      if (new BigNumber(allowanceData).isGreaterThan(maxBendSupply)) {
        setScreenState(WithdrawSteps.PROCESS)
      }
      if (new BigNumber(allowanceData || 0).dividedBy(1e18).lt(depositBalance)) {
        setScreenState(WithdrawSteps.APPROVE)
      } else {
        setScreenState(WithdrawSteps.PROCESS)
      }
    } else {
      setScreenState(WithdrawSteps.PROCESS)
    }
    setIsLoading(false)
  }, [allowanceData, assetAddress, screenState, depositBalance])

  return {
    allowanceData,
    handleApprove,
    handleWithdraw: isWeth(assetAddress) ? handleWithdrawETH : handleWithdraw,
    screenState,
    txHash,
    errorMsg,
    decimals,
    setScreenState,
    balance: isWeth(assetAddress) ? formatedEthBalance : formatedTokenBalance,
    depositBalance,
    isLoading,
    availableLiquidity: formatedAvailableLiquidity
  }
}

type UsewithdrawEthMutationContext = {
  amount: string
  withdrawAll: boolean
}

type UseWithdrawEthMutationArgs = {
  options: UseMutationOptions<any, any, UsewithdrawEthMutationContext>
  setTxAwaitText: Dispatch<SetStateAction<string>>
}

// Hook used in new Yield UI
/**
 * It returns a mutation function that calls the `onWithdrawETH` function from the `useWithdrawETH` hook.
 * @param {UseWithdrawEthMutationArgs}  - `options` - the options object that you would normally pass
 * to `useMutation`
 * @returns A function that takes in an object with an amount and a boolean withdrawAll.
 */
export const useWithdrawEthMutation = ({ options, setTxAwaitText }: UseWithdrawEthMutationArgs) => {
  const { t } = useTranslation('common')
  const { account, provider } = useWeb3React()
  const {
    config: { WETH_GATEWAY }
  } = usePlatform()

  const tokenContract = useTokenContract(BWETH)
  const { onApprove } = useApprove(BWETH, WETH_GATEWAY)

  const { onWithdrawETH } = useWithdrawETH(WETH_GATEWAY)
  return useMutation(
    async ({ amount, withdrawAll = false }) => {
      if (!provider || !tokenContract) return
      const allowance = await tokenContract.allowance(account, WETH_GATEWAY)
      if (new BigNumber(allowance?._hex).dividedBy(1e18).isLessThan(amount)) {
        setTxAwaitText(
          t('tx-modal-await-tx-approve', {
            token: 'bendWETH',
            amount
          })
        )
        const tx = await onApprove()
        if (!tx || tx.message) {
          throw new Error(tx?.message)
        }
        setTxAwaitText(t('deposit.paragraph.tx-pending'))
        await tx.wait()
        setTxAwaitText(t('tx-modal-await-tx'))
      }
      const tx = await onWithdrawETH(!withdrawAll ? new BigNumber(amount).multipliedBy(1e18).toFixed(0) : MAX_INPUT, account as string)
      return tx
    },
    {
      ...options
    }
  )
}

export const useWithdrawMutation = ({ options, setTxAwaitText }: UseWithdrawEthMutationArgs) => {
  const { t } = useTranslation('common')
  const { account, provider } = useWeb3React()

  const tokenContract = useTokenContract(BUSDT)
  const { onApprove } = useApprove(BUSDT, LEND_POOL)

  const { onWithdraw } = useWithdraw(LEND_POOL)
  return useMutation(
    async ({ amount, withdrawAll = false }) => {
      if (!provider || !tokenContract) return
      const allowance = await tokenContract.allowance(account, LEND_POOL)
      if (new BigNumber(allowance?._hex).dividedBy(1e6).isLessThan(amount)) {
        setTxAwaitText(
          t('tx-modal-await-tx-approve', {
            token: 'bendUSDT',
            amount
          })
        )
        const tx = await onApprove()
        if (!tx || tx.message) {
          throw new Error(tx?.message)
        }
        setTxAwaitText(t('deposit.paragraph.tx-pending'))
        await tx.wait()
        setTxAwaitText(t('tx-modal-await-tx'))
      }
      const tx = await onWithdraw(USDT_ADDRESS, !withdrawAll ? new BigNumber(amount).multipliedBy(1e6).toFixed(0) : MAX_INPUT, account as string)
      return tx
    },
    {
      ...options
    }
  )
}

// Hook used in new Yield UI
/**
 * It returns the amount of ETH in the user's wallet
 * @returns The balance of the user's account in ETH.
 */
export const useEthDepositBalance = () => {
  const { account } = useWeb3React()

  const multicallContract = useMulticallContract()
  const { result: ethBalance } = useSingleCallResult(multicallContract, 'getEthBalance', [account])
  return useMemo(() => new BigNumber(ethBalance?.[0].toString()).dividedBy(1e18), [ethBalance])
}

export const useDeposit = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (asset_address: string, amount: string, onBehalfOf_address: string, referralCode: string) => {
      try {
        const tx = await deposit(contract, asset_address, amount, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onDeposit: handleAction }
}

export const useWithdraw = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (asset_address: string, amount: string, to_address: string) => {
      try {
        const tx = await withdraw(contract, asset_address, amount, to_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onWithdraw: handleAction }
}

export const useBorrow = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (asset_address: string, amount: string, nftAsset_address: string, nftTokenId: string, onBehalfOf_address: string, referralCode: string) => {
      try {
        const tx = await borrow(contract, asset_address, amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBorrow: handleAction }
}

export const useRepay = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string) => {
      try {
        const tx = await repay(contract, nftAsset_address, nftTokenId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRepay: handleAction }
}

export const useRedeem = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string, bidFine: string) => {
      if (!contract) return
      try {
        const tx = await redeem(contract, nftAsset_address, nftTokenId, amount, bidFine)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRedeem: handleAction }
}

export const useLiquidate = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string) => {
      try {
        const tx = await liquidate(contract, nftAsset_address, nftTokenId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onLiquidate: handleAction }
}

export const useAuction = (ILendPool_address: string) => {
  const contract = useILendPoolContract(ILendPool_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string, onBehalfOf_address: string) => {
      try {
        const tx = await auction(contract, nftAsset_address, nftTokenId, amount, onBehalfOf_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onAuction: handleAction }
}

/**
 * It's a function that takes in an array of amounts, an array of nftAsset_addresses, an array of
 * nftTokenIds, an onBehalfOf_address, and a referralCode, and returns a transaction
 * @returns The function onBatchBorrowETH is being returned.
 */
export const useBatchBorrow = () => {
  const contract = useILendPoolContract(LEND_POOL)

  const handleAction = useCallback(
    async (assets: string[], amounts: string[], nftAsset_address: string[], nftTokenId: string[], onBehalfOf_address: string, referralCode: string) => {
      if (!contract) return
      try {
        const tx = await batchBorrow(contract, assets, amounts, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBatchBorrow: handleAction }
}

/**
 * It takes in an array of NFT addresses, an array of NFT token IDs, an array of amounts, and a value,
 * and returns a transaction
 * @returns an object with a key of onBatchRepay and a value of handleAction.
 */
export const useBatchRepay = () => {
  const contract = useILendPoolContract(LEND_POOL)

  const handleAction = useCallback(
    async (nftAsset_address: string[], nftTokenId: string[], amount: string[]) => {
      if (!contract) return
      try {
        const tx = await batchRepay(contract, nftAsset_address, nftTokenId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBatchRepay: handleAction }
}
