import { useCallback, useEffect, useMemo, useState } from 'react'
import { X } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { DATE_FORMAT_UTC } from 'constants/index'
import { useNotifications } from 'contexts/notifications'
import Carousel from 'framer-motion-carousel'
import useResponsive from 'hooks/common/useResponsive'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components'
import { Button, IconInfoXl } from 'theme/ui'

import CustomArrow from 'components/common/framer-motion-carousel'
import CustomDots from 'components/common/framer-motion-carousel/dots'

const HomepageNotifications: React.FC<FlexProps> = ({ ...restprops }) => {
  const { notifications } = useNotifications()

  if (notifications.length > 1) {
    return (
      <Box width='100%' sx={{ bg: 'green.1200' }}>
        <Notifications {...restprops} />
      </Box>
    )
  }

  return <NotificationsNoCarousel {...restprops} />
}

export default HomepageNotifications

const Notifications: React.FC<FlexProps> = ({ ...restprops }) => {
  const { t } = useTranslation()
  const { isLaptop } = useResponsive()
  const { notifications, getTextColor } = useNotifications()
  const [hide, setHide] = useState(true)
  const { pathname } = useRouter()
  const isLanding = useMemo(() => pathname === '/' || pathname === '/[locale]', [pathname])

  const handleSetHide = useCallback(() => {
    setHide(true)
    window.localStorage.setItem(`_bend_display_notification__${notifications[0]?.time}`, 'true')
  }, [notifications])

  useEffect(() => {
    const currentUnixTimestamp = moment().unix()
    const period = 14 * 24 * 60 * 60 // 14 days in seconds
    const diff = currentUnixTimestamp - (notifications[notifications.length - 1]?.time || 0) // diff between the lastest notification cookie and current time
    if (window.localStorage.getItem(`_bend_display_notification__${notifications[notifications.length - 1]?.time}`) === 'true') {
      setHide(true)
      return
    } else if (diff > period) {
      setHide(true)
      return
    }
    setHide(false)
  }, [notifications])

  const firstBreakPoint = useMediaQuery({
    query: `(min-width: 1524px)`
  })
  if (!isLaptop) {
    return (
      <Carousel
        autoPlay={true}
        interval={7000}
        loop={true}
        renderDots={({ setActiveIndex: setIndex, activeIndex }) => <CustomDots setIndex={setIndex} length={notifications?.length} activeIndex={activeIndex} />}
        renderArrowLeft={() => undefined}
        renderArrowRight={() => undefined}
      >
        {isEmpty(notifications)
          ? null
          : notifications.map(notification =>
              hide ? null : (
                <Flex
                  key={notification.key}
                  variant='notification-wrapper__mobile'
                  sx={{
                    bg: notification.color,
                    color: getTextColor(notification.color)
                  }}
                >
                  <Flex variant='notification-wrapper__container__mobile' sx={{ ...restprops.sx, minWidth: ['auto', 'auto', 'auto', 1100, 1400] }}>
                    <Flex variant='notification-wrapper__container__title__mobile'>
                      <Text fontWeight='bold' my={5}>
                        {notification.title}
                      </Text>
                      <Text my={5}>
                        {notification?.time && <Text sx={{ opacity: 0.5 }}>({moment.unix(notification.time).utc().format(DATE_FORMAT_UTC)})</Text>}
                      </Text>
                    </Flex>
                    <Flex justifyContent='space-between' sx={{ gap: 10, alignItems: 'center', mt: 10, mb: 20 }}>
                      <Flex variant='notification-wrapper__container__content__mobile' sx={{ flex: [8 / 12, 8 / 12, 10 / 12] }}>
                        {notification.content}
                      </Flex>

                      {notification?.link && (
                        <Box sx={{ flex: [2 / 12, 2 / 12, 1 / 12], alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                          <Button size='sm' href={notification.link} outlined color={getTextColor(notification.color)} newWindow={notification.external}>
                            {notification?.linkLabel || t(['label.read-more', 'Read more'])}
                          </Button>
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                  {notification.close && (
                    <Button
                      size='icon'
                      color='transparent'
                      buttonStyles={{
                        position: ['absolute', null, null, 'relative'],
                        top: [10, null, null, 'auto'],
                        right: [15, 15, null, 'auto'],
                        ml: 10
                      }}
                      onClick={handleSetHide}
                    >
                      <X size={20} />
                    </Button>
                  )}
                </Flex>
              )
            )}
      </Carousel>
    )
  }

  return (
    <Carousel
      autoPlay={true}
      interval={7000}
      loop={true}
      renderDots={() => undefined}
      renderArrowLeft={({ handlePrev }) => <CustomArrow left onClick={handlePrev} />}
      renderArrowRight={({ handleNext }) => <CustomArrow onClick={handleNext} />}
    >
      {isEmpty(notifications)
        ? null
        : notifications.map(notification =>
            hide ? null : (
              <Flex
                key={notification.key}
                variant='notification-wrapper'
                sx={{
                  bg: notification.color,
                  color: getTextColor(notification.color)
                }}
              >
                <Flex
                  variant='notification-wrapper__container'
                  sx={{
                    ...restprops.sx,
                    minWidth: ['auto', 'auto', 'auto', 1000, 1200, 1400],
                    px: ['', '', '', 60, 80],
                    ...(isLanding && { maxWidth: 'content', px: firstBreakPoint ? 0 : [0, 0, 50, 60, 50, 40] })
                  }}
                >
                  <Flex variant='notification-wrapper__container__title'>
                    {isLaptop && <IconInfoXl size={30} />}
                    <Text ml={[0, 0, 0, 15]} fontWeight='bold' mt={[10, 10, 10, 0]} mb={[5, 5, 5, 0]}>
                      {notification.title}
                    </Text>
                    {notification?.time && <Text sx={{ opacity: 0.5, ml: [5] }}>({moment.unix(notification.time).utc().format(DATE_FORMAT_UTC)})</Text>}
                  </Flex>
                  <Flex width='100%' variant='notification-wrapper__container__content'>
                    {notification.content}
                  </Flex>
                  <Flex>
                    {notification?.link && (
                      <Button href={notification.link} outlined color={getTextColor(notification.color)} newWindow={notification.external}>
                        {notification?.linkLabel || t(['label.read-more', 'Read more'])}
                      </Button>
                    )}

                    {notification.close && (
                      <Button
                        size='icon'
                        color='transparent'
                        buttonStyles={{
                          position: ['absolute', null, null, 'relative'],
                          top: [10, null, null, 'auto'],
                          right: [10, null, null, 'auto'],
                          ml: 15
                        }}
                        onClick={handleSetHide}
                      >
                        <X size={20} />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )
          )}
    </Carousel>
  )
}

const NotificationsNoCarousel: React.FC<FlexProps> = ({ ...restprops }) => {
  const { t } = useTranslation()
  const { isLaptop } = useResponsive()
  const { notifications, getTextColor } = useNotifications()
  const [hide, setHide] = useState(true)

  const handleSetHide = useCallback(() => {
    setHide(true)
    window.localStorage.setItem(`_bend_display_notification__${notifications[0]?.time}`, 'true')
  }, [notifications])

  useEffect(() => {
    const currentUnixTimestamp = moment().unix()
    const period = 14 * 24 * 60 * 60 // 14 days in seconds
    const diff = currentUnixTimestamp - (notifications[0]?.time || 0) // diff between notification cookie and current time
    if (window.localStorage.getItem(`_bend_display_notification__${notifications[0]?.time}`) === 'true') {
      setHide(true)
      return
    } else if (diff > period) {
      setHide(true)
      return
    }
    setHide(false)
  }, [notifications])

  const { pathname } = useRouter()
  const isLanding = useMemo(() => pathname === '/' || pathname === '/[locale]', [pathname])

  if (!isLaptop) {
    return (
      <>
        {isEmpty(notifications)
          ? null
          : notifications.map(notification =>
              hide ? null : (
                <Flex
                  key={notification.key}
                  variant='notification-wrapper__mobile'
                  sx={{
                    bg: notification.color,
                    color: getTextColor(notification.color)
                  }}
                >
                  <Flex variant='notification-wrapper__container__mobile' sx={{ ...restprops.sx, minWidth: ['auto', 'auto', 'auto', 1100, 1400] }}>
                    <Flex variant='notification-wrapper__container__title__mobile'>
                      <Text fontWeight='bold' my={5}>
                        {notification.title}
                      </Text>
                      <Text my={5}>
                        {notification?.time && <Text sx={{ opacity: 0.5 }}>({moment.unix(notification.time).utc().format(DATE_FORMAT_UTC)})</Text>}
                      </Text>
                    </Flex>
                    <Flex justifyContent='space-between' sx={{ gap: 10, alignItems: 'center', mt: 10 }}>
                      <Flex variant='notification-wrapper__container__content__mobile' sx={{ flex: 10 / 12 }}>
                        {notification.content}
                      </Flex>

                      {notification?.link && (
                        <Box sx={{ flex: [2 / 12, 2 / 12, 1 / 12], alignItems: 'flex-end', justifyContent: 'flex-end', mr: [15, 15, 0] }}>
                          <Button size='sm' href={notification.link} outlined color={getTextColor(notification.color)} newWindow={notification.external}>
                            {notification?.linkLabel || t(['label.read-more', 'Read more'])}
                          </Button>
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                  {notification.close && (
                    <Button
                      size='icon'
                      color='transparent'
                      buttonStyles={{
                        position: ['absolute', null, null, 'relative'],
                        top: [10, null, null, 'auto'],
                        right: [10, null, null, 'auto'],
                        ml: 15
                      }}
                      onClick={handleSetHide}
                    >
                      <X size={20} />
                    </Button>
                  )}
                </Flex>
              )
            )}
      </>
    )
  }

  return (
    <>
      {isEmpty(notifications)
        ? null
        : notifications.map(notification =>
            hide ? null : (
              <Flex
                key={notification.key}
                variant='notification-wrapper'
                sx={{
                  bg: notification.color,
                  color: getTextColor(notification.color)
                }}
              >
                <Flex
                  variant='notification-wrapper__container'
                  sx={{
                    ...restprops.sx,
                    maxWidth: isLanding ? ['auto', 'auto', 'auto', 1400, 1440] : '100%',
                    minWidth: ['auto', 'auto', 'auto', 'auto', isLanding ? 1300 : 1400]
                  }}
                >
                  <Flex variant='notification-wrapper__container__title'>
                    {isLaptop && <IconInfoXl size={30} />}
                    <Text ml={[0, 0, 0, 15]} fontWeight='bold' mt={[10, 10, 10, 0]} mb={[5, 5, 5, 0]}>
                      {notification.title}
                    </Text>
                    {notification?.time && <Text sx={{ opacity: 0.5, ml: [5] }}>({moment.unix(notification.time).utc().format(DATE_FORMAT_UTC)})</Text>}
                  </Flex>
                  <Flex variant='notification-wrapper__container__content'>{notification.content}</Flex>
                  <Flex>
                    {notification?.link && (
                      <Button href={notification.link} outlined color={getTextColor(notification.color)} newWindow={notification.external}>
                        {notification?.linkLabel || t(['label.read-more', 'Read more'])}
                      </Button>
                    )}

                    {notification.close && (
                      <Button
                        size='icon'
                        color='transparent'
                        buttonStyles={{
                          position: ['absolute', null, null, 'relative'],
                          top: [10, null, null, 'auto'],
                          right: [10, null, null, 'auto'],
                          ml: 15
                        }}
                        onClick={handleSetHide}
                      >
                        <X size={20} />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )
          )}
    </>
  )
}
