import React, { useRef, useState } from 'react'
import { arrow, autoUpdate, flip, FloatingArrow, offset, shift, useFloating, useTransitionStyles } from '@floating-ui/react'
import useTheme from 'hooks/common/useTheme'
import { Box, Flex, FlexProps, SxStyleProp } from 'rebass/styled-components'

const ARROW_WIDTH = 12
const ARROW_HEIGHT = 6

export type PopoverProps = {
  overlay: string | React.ReactNode
  placement?: 'top' | 'right' | 'bottom' | 'left' | 'top-end'
  background?: string
  color?: string
  wrapperStyle?: any
  styles?: SxStyleProp
  disableMobile?: boolean
}

const Popover: React.FC<PopoverProps & FlexProps> = ({ children, placement, overlay, wrapperStyle, styles, background = 'primary', color = 'white' }) => {
  const { colors } = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const arrowRef = useRef(null)

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement: placement || 'top',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(ARROW_HEIGHT + 5), flip({ padding: 5 }), shift({ padding: 5 }), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate
  })

  const arrowX = middlewareData.arrow?.x ?? 0
  const arrowY = middlewareData.arrow?.y ?? 0
  const transformX = arrowX + ARROW_WIDTH / 2
  const transformY = arrowY + ARROW_HEIGHT

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      transform: 'scale(0)'
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`
      }[side]
    })
  })

  return (
    <>
      <Box
        ref={refs.setReference}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        sx={{ display: 'inline-block', ...wrapperStyle, ...styles }}
      >
        {children}
      </Box>
      {isMounted && (
        <div ref={refs.setFloating} style={floatingStyles}>
          <Flex
            sx={{
              bg: background,
              color,
              p: 10,
              py: 8,
              fontSize: 'sm',
              lineHeight: 'md',
              borderRadius: 'xs',
              zIndex: 1,
              ...(background === 'white' && {
                boxShadow: `0 8px 8px 0 ${colors.shadow[100]}`
              }),
              '.arrow': {
                fill: background,
                stroke: background,
                zIndex: 2
              }
            }}
            style={transitionStyles}
          >
            {overlay}
            <FloatingArrow ref={arrowRef} context={context} width={ARROW_WIDTH} height={ARROW_HEIGHT} className='arrow' />
          </Flex>
        </div>
      )}
    </>
  )
}

export default Popover
