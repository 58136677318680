import React from 'react'

interface IIconDocs {
  color?: string
  size?: number | string
}

const IconDocs = React.forwardRef<SVGSVGElement, IIconDocs>(({ color = 'currentColor', size = 15, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 11.984 15.999' fill={color} {...rest}>
      <path
        id='Shape'
        d='M1.141,16A1.134,1.134,0,0,1,0,14.874V1.125A1.134,1.134,0,0,1,1.141,0h5.39a1.09,1.09,0,0,1,.454.1l.006,0h0A.018.018,0,0,1,7,.1a.092.092,0,0,1,.028.014A.323.323,0,0,1,7.1.152a1.19,1.19,0,0,1,.229.179l4.341,4.383a1.106,1.106,0,0,1,.319.793v9.367A1.105,1.105,0,0,1,10.875,16Zm-.016-1.125h9.734V6H7.125a1.134,1.134,0,0,1-1.14-1.125V1.125H1.125Zm5.985-10h3.138L7.11,1.725Zm-4.547,8.25a.562.562,0,1,1,0-1.125h5a.562.562,0,1,1,0,1.125Zm0-2.5a.562.562,0,1,1,0-1.125h7a.562.562,0,1,1,0,1.125Zm0-2.5A.562.562,0,1,1,2.563,7h4a.562.562,0,1,1,0,1.125Z'
        fill={color}
      />
    </svg>
  )
})

export default IconDocs
