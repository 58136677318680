import React from 'react'

interface IIconX {
  color?: string
  size?: number
}

const IconNotFound = React.forwardRef<SVGSVGElement, IIconX>(({ size = 52, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 53 53'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Shape'
        d='M26,52A26,26,0,0,1,7.614,7.617,26,26,0,1,1,44.382,44.385,25.813,25.813,0,0,1,26,52Zm-8.407-5.291A22.34,22.34,0,0,0,43.039,11.546L11.546,43.039A22.116,22.116,0,0,0,17.593,46.709ZM40.453,8.96A22.271,22.271,0,0,0,25.994,3.654a22.616,22.616,0,0,0-8.409,1.637A22.327,22.327,0,0,0,8.96,40.453Z'
        fill='#b1b1b1'
      />
    </svg>
  )
})

export default IconNotFound
