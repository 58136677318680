import React from 'react'

interface IIconRewards {
  color?: string
  size?: number
}

const IconClaimRewards = React.forwardRef<SVGSVGElement, IIconRewards>(({ color = 'currentColor', size = 34.782, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 29.7 29.7' fill={color} {...rest}>
      <g>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M18.9,7.5L18.9,7.5c-0.2-0.5-0.6-0.8-1.1-0.9l-1-0.2l-0.5-1c-0.2-0.3-0.4-0.6-0.8-0.7c-0.3-0.1-0.7-0.1-1,0
        c-0.3,0.1-0.5,0.4-0.7,0.6l-0.5,1l-1,0.2c-0.4,0.1-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.6-0.2,1c0,0.3,0.2,0.5,0.4,0.7l0.8,0.8l-0.2,1.1
        c-0.1,0.7,0.4,1.4,1.1,1.6c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2l0.9-0.5l0.9,0.5c0.6,0.3,1.3,0.2,1.7-0.3
        c0.3-0.3,0.4-0.7,0.3-1.1l-0.2-1.1l0.8-0.8C19,8.6,19.1,8,18.9,7.5z M16.5,10.8L15.1,10l-1.3,0.7l0.3-1.6l-1.1-1.1l1.6-0.2l0.7-1.4
        l0.7,1.4l1.5,0.2l-1.1,1.1L16.5,10.8z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M28.7,2.1H25c0-0.4,0-0.8,0-1.2c0-0.5-0.4-1-1-1H5.6c-0.5,0-1,0.4-1,1c0,0.4,0,0.8,0,1.2H1c-0.5,0-1,0.4-1,1
        c-0.1,3.7,0.9,7.2,2.9,10.3c1.8,2.7,4.3,4.3,6.9,4.5c0.5,0.6,1.1,1.1,1.8,1.5v3.7h-1.3c-1.8,0-3.3,1.5-3.3,3.3v1.3
        c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,1,1h15.7c0.5,0,1-0.4,1-1c0-0.5-0.4-0.9-0.9-1v-1.4c0-1.8-1.5-3.3-3.3-3.3h-1.3v-3.7
        c0.7-0.4,1.3-0.9,1.8-1.5c2.6-0.2,5.1-1.8,6.9-4.5c1.9-3.1,2.9-6.7,2.9-10.3C29.7,2.6,29.3,2.1,28.7,2.1z M10.2,25.1h9.3
        c0.7,0,1.3,0.6,1.4,1.4l0,1.4h-12v-1.4C8.9,25.7,9.5,25.1,10.2,25.1z M8,15.5c-1.5-0.7-2.7-1.8-3.5-3.2l0,0C2.9,9.9,2,7,2,4.1h2.8
        C5,7.7,5.9,11.2,7.4,14.5C7.6,14.8,7.8,15.2,8,15.5z M27.7,4.1c-0.1,2.9-1,5.8-2.5,8.3v0c-0.8,1.4-2.1,2.5-3.5,3.2
        c0.2-0.3,0.4-0.7,0.6-1c1.5-3.3,2.5-6.8,2.7-10.4H27.7z M16.8,18c0,0-0.1,0-0.1,0.1c-1.1,0.6-2.5,0.6-3.6,0c0,0-0.1,0-0.1-0.1
        c-0.7-0.4-1.4-0.9-1.9-1.6c0-0.1-0.1-0.1-0.1-0.1c-0.7-0.8-1.2-1.7-1.7-2.7C7.5,9.9,6.6,6,6.6,1.9h16.6c0,4.1-0.9,8-2.6,11.7
        c-0.5,1-1,1.8-1.7,2.7c0,0-0.1,0.1-0.1,0.1C18.2,17.1,17.5,17.6,16.8,18z M16.2,20.3l0,2.9h-2.7v-2.9
        C14.4,20.5,15.3,20.5,16.2,20.3z'
        />
      </g>
    </svg>
  )
})

export default IconClaimRewards
