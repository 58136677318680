import React from 'react'

interface IIconBendApe {
  color?: string
  size?: number
}

const IconActivityLog = React.forwardRef<SVGSVGElement, IIconBendApe>(({ color = 'currentColor', size = 15 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 19.657 18.699' ref={ref}>
      <g id='Group_16865' data-name='Group 16865' transform='translate(0.25 0.25)'>
        <g id='Group_16866' data-name='Group 16866'>
          <path
            id='Path_4456'
            data-name='Path 4456'
            d='M236.582,192a1.916,1.916,0,1,0,1.916,1.916A1.918,1.918,0,0,0,236.582,192m0,2.873a.958.958,0,1,1,.958-.958.958.958,0,0,1-.958.958'
            transform='translate(-224.13 -183.38)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
          <path
            id='Path_4457'
            data-name='Path 4457'
            d='M110.5,193.915a1.916,1.916,0,1,0-1.916,1.916,1.918,1.918,0,0,0,1.916-1.916m-2.873,0a.958.958,0,1,1,.958.958.958.958,0,0,1-.958-.958'
            transform='translate(-101.878 -183.379)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
          <path
            id='Path_4458'
            data-name='Path 4458'
            d='M154.6,320h-4.789a.479.479,0,0,0,0,.958H154.6a.479.479,0,1,0,0-.958'
            transform='translate(-142.629 -305.633)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
          <path
            id='Path_4459'
            data-name='Path 4459'
            d='M18.678,9.578a.479.479,0,0,0-.479.479v1.437h-.958V8.141a2.4,2.4,0,0,0-2.395-2.395H10.057V3.771a1.916,1.916,0,1,0-.958,0V5.747H4.31A2.4,2.4,0,0,0,1.916,8.141v3.352H.958V10.057a.479.479,0,1,0-.958,0v3.831a.479.479,0,1,0,.958,0V12.452h.958V15.8A2.4,2.4,0,0,0,4.31,18.2H14.846A2.4,2.4,0,0,0,17.241,15.8V12.452H18.2v1.437a.479.479,0,1,0,.958,0V10.057a.479.479,0,0,0-.479-.479M8.62,1.916a.958.958,0,1,1,.958.958.958.958,0,0,1-.958-.958M16.283,15.8a1.438,1.438,0,0,1-1.437,1.437H4.31A1.438,1.438,0,0,1,2.873,15.8V8.141A1.439,1.439,0,0,1,4.31,6.7H14.846a1.438,1.438,0,0,1,1.437,1.437Z'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconActivityLog
