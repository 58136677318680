import React from 'react'

interface IIconStake {
  color?: string
  size?: number
}

const IconIdoHeader = React.forwardRef<SVGSVGElement, IIconStake>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        transform='translate(0.206 0.175)'
        fill={color}
        stroke={color}
        strokeWidth='0'
        d='M19.6,5.4l-3.6-4c-0.1-0.1-0.3-0.2-0.5-0.2L4,1.2
        c-0.2,0-0.4,0.1-0.5,0.2L0,5.4c-0.2,0.2-0.2,0.6,0,0.8l9.3,11.9c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4-0.1,0.5-0.3l9.3-11.9
        c0-0.1,0.1-0.1,0.1-0.2C19.8,5.9,19.8,5.6,19.6,5.4 M14.6,2.5l-0.9,2.2l-2.3-2.2L14.6,2.5z M12.3,5.2H7.3l2.5-2.5L12.3,5.2z
        M3.8,3.1l0.8,2.1H1.9L3.8,3.1z M1.8,6.5h3.3l2.7,7.8L1.8,6.5z M5,2.5h3.1L5.9,4.7L5,2.5z M6.6,6.5H13l-3.2,9.1L6.6,6.5z M11.7,14.3
        l2.7-7.8h3.3L11.7,14.3z M14.9,5.2l0.8-2.1l1.9,2.1L14.9,5.2z'
      />
    </svg>
  )
})

export default IconIdoHeader
