import React from 'react'

interface IIconMyNfts {
  color?: string
  size?: number
}

const IconMyNfts = React.forwardRef<SVGSVGElement, IIconMyNfts>(({ color = 'currentColor', size = 25, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 22.149 25.575' fill={color} {...rest}>
      <path
        id='Path_2475'
        data-name='Path 2475'
        d='M1.945,18.059V7.516l9.129-5.27,5.5,3.176.012.021.012-.007,3.6,2.081V18.059l-9.129,5.27ZM11.075,0,0,6.394V19.182l11.075,6.394,11.075-6.4V6.394Z'
        fill={color}
      />
    </svg>
  )
})

export default IconMyNfts
