import React from 'react'

interface IIconError {
  color?: string
  size?: number
}

const IconError = React.forwardRef<SVGSVGElement, IIconError>(({ color = 'currentColor', size = 52, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 52.5 52.5' fill={color} stroke={color} {...rest}>
      <g id='Group_3552' data-name='Group 3552' transform='translate(-154.5 -95.279)'>
        <path
          id='Subtraction_128'
          data-name='Subtraction 128'
          d='M26,52A26,26,0,0,1,7.616,7.613,26,26,0,0,1,44.387,44.385,25.819,25.819,0,0,1,26,52ZM26,3.652a22.346,22.346,0,1,0,0,44.692A22.275,22.275,0,0,0,41.8,41.8,22.244,22.244,0,0,0,48.348,26a22.323,22.323,0,0,0-6.546-15.8,22.517,22.517,0,0,0-7.108-4.787A22.223,22.223,0,0,0,26,3.652Z'
          transform='translate(155 95.779)'
          fill={color}
          stroke='rgba(0,0,0,0)'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
        <path
          id='Path'
          d='M2.023,22.7A2.022,2.022,0,0,1,0,20.682V2.023a2.023,2.023,0,0,1,4.045,0V20.682A2.022,2.022,0,0,1,2.023,22.7Z'
          transform='translate(178.867 106.644)'
          fill={color}
          stroke='rgba(0,0,0,0)'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
        <path
          id='Path-2'
          data-name='Path'
          d='M0,2.276A2.276,2.276,0,1,0,2.276,0,2.276,2.276,0,0,0,0,2.276Z'
          transform='translate(178.614 132.585)'
          fill={color}
          stroke='rgba(0,0,0,0)'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconError
