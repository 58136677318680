import React, { forwardRef } from 'react'
import { X } from 'react-feather'
import { Box, Flex, Image, Text } from 'rebass/styled-components'

// Components
import Card from '../card'

// Interfaces
import { IModal } from './interfaces'

const Modal = forwardRef(
  ({ title, width = ['100%', '100%', 420], children, onDismiss, image, noLayoutStyle = false, customElement, xColor, closeBtnSx, ...restprops }: IModal, ref: any) => {
    return (
      <Card flexDirection='column' width={width} variant='modal' sx={{ zIndex: 1002, position: 'relative', overflow: 'hidden' }} ref={ref}>
        <Box
          size={30}
          sx={{ borderRadius: 'sm', '&:hover': { opacity: 0.6 }, position: 'absolute', right: 20, top: 20, zIndex: 1001, ...closeBtnSx }}
          alignItems='center'
          pt={3}
          pl={3}
          ml={[10, 20]}
        >
          <X onClick={onDismiss} color={xColor ? xColor : 'black'} cursor='pointer' />
        </Box>
        {image && <Image src={image.src} width={image.width} height={image.height} />}
        {customElement && customElement}
        <Box variant={noLayoutStyle ? 'connect-to-modal' : 'modal-body'} sx={{ pt: 0, zIndex: 1000 }} {...restprops}>
          <Flex justifyContent='space-between' alignItems='center' mb={customElement ? 0 : [20, 20, 10, 20, 30]} mt={customElement ? 0 : [10, 10, 10, 20, 30]}>
            <Text fontSize={[18, 18, 24]} fontWeight='700'>
              {title}
            </Text>
          </Flex>
          {React.isValidElement(children) &&
            React.cloneElement(children, {
              onDismiss
            })}
        </Box>
      </Card>
    )
  }
)

export default Modal

export { Context as ModalContext } from './context'
export { default as useModal } from './useModal'
