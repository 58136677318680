import React from 'react'

interface IIconLoanRepay {
  color?: string
  size?: number
}

const IconStakingCalc2 = React.forwardRef<SVGSVGElement, IIconLoanRepay>(({ color = 'currentColor', size, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13.567 15.968' color={color} fill={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_3791' data-name='Rectangle 3791' width='13.35' height='15.744' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_12133' data-name='Group 12133' clipPath='url(#clip-path)'>
        <path
          id='Path_3934'
          data-name='Path 3934'
          d='M11.835,3.414H3.8a.52.52,0,0,0-.527.527V7.195a.509.509,0,0,0,.527.461h8.037a.52.52,0,0,0,.527-.527V3.941a.52.52,0,0,0-.527-.527m-.527,3.319H4.259V4.468h7.049Z'
          transform='translate(-1.125 -1.174)'
          fill={color}
        />
        <path
          id='Path_3935'
          data-name='Path 3935'
          d='M5.016,17.372H3.8a.527.527,0,0,0,0,1.054H5.016a.527.527,0,0,0,0-1.054'
          transform='translate(-1.126 -5.976)'
          fill={color}
        />
        <path
          id='Path_3936'
          data-name='Path 3936'
          d='M10.205,17.372H8.988a.527.527,0,0,0,0,1.054h1.218a.527.527,0,0,0,0-1.054'
          transform='translate(-2.91 -5.976)'
        />
        <path
          id='Path_3937'
          data-name='Path 3937'
          d='M15.394,17.372H14.177a.527.527,0,0,0,0,1.054h1.218a.527.527,0,0,0,0-1.054'
          transform='translate(-4.695 -5.976)'
        />
        <path
          id='Path_3938'
          data-name='Path 3938'
          d='M5.016,13.356H3.8a.527.527,0,0,0,0,1.054H5.016a.527.527,0,0,0,0-1.054'
          transform='translate(-1.126 -4.594)'
        />
        <path
          id='Path_3939'
          data-name='Path 3939'
          d='M10.205,13.356H8.988a.527.527,0,0,0,0,1.054h1.218a.527.527,0,0,0,0-1.054'
          transform='translate(-2.91 -4.594)'
        />
        <path
          id='Path_3940'
          data-name='Path 3940'
          d='M15.394,13.356H14.177a.527.527,0,0,0,0,1.054h1.218a.527.527,0,0,0,0-1.054'
          transform='translate(-4.695 -4.594)'
        />
        <path
          id='Path_3941'
          data-name='Path 3941'
          d='M12.028,0H1.323A1.262,1.262,0,0,0,.005,1.384V14.362a1.391,1.391,0,0,0,1.384,1.383H11.962a1.391,1.391,0,0,0,1.383-1.383V1.384A1.262,1.262,0,0,0,12.028,0m.461,14.427a.45.45,0,0,1-.461.461H1.323a.45.45,0,0,1-.461-.461V1.45A.45.45,0,0,1,1.323.989h10.7a.45.45,0,0,1,.461.461Z'
          transform='translate(0)'
        />
      </g>
    </svg>
  )
})

export default IconStakingCalc2
