import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDandlingCancel = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 26, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill={color} {...rest}>
      <path
        id='Path_2391_00000005230011328741993890000017740085507721421490_'
        d='M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
        c0,0,0,0,0,0C24,5.4,18.6,0,12,0 M12,21.8c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.8-9.8c5.4,0,9.8,4.4,9.8,9.8c0,0,0,0,0,0
        C21.8,17.4,17.4,21.8,12,21.8'
      />
      <path
        id='Path_2392_00000145770336264667239000000016941955843359198871_'
        d='M15.2,13.7L13.5,12l1.7-1.7c0.4-0.4,0.5-1,0.1-1.4
        c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-1.1-0.4-1.5,0c0,0,0,0,0,0L12,10.5l-1.7-1.7c-0.4-0.4-1-0.5-1.4-0.1c0,0-0.1,0.1-0.1,0.1
        c-0.4,0.4-0.5,1.1-0.1,1.6l1.7,1.7l-1.6,1.6c-0.4,0.4-0.5,1-0.1,1.4c0,0,0.1,0.1,0.1,0.1c0.4,0.4,1.1,0.4,1.5,0c0,0,0,0,0,0l1.6-1.6
        l1.7,1.7c0.4,0.4,1,0.5,1.4,0.1c0,0,0.1-0.1,0.1-0.1C15.6,14.9,15.6,14.2,15.2,13.7'
      />
    </svg>
  )
})

export default IconDandlingCancel
