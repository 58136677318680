import { useState } from 'react'
import { useUserContext } from 'contexts/user'
import { useTranslation } from 'next-i18next'
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components'
import { Avatar, Button, IconSignature } from 'theme/ui'

import SpacerDash from '../spacer-dash'

type VerifyAddressProps = {
  expired?: boolean
  onClick?: () => void
  onDismiss?: () => void
}

export const VerifyAddress: React.FC<VerifyAddressProps & FlexProps> = ({ onClick, onDismiss, expired, ...restprops }) => {
  const { t } = useTranslation('common')
  const { handleLogin } = useUserContext()
  const [isLoading, setLoading] = useState(false)

  return (
    <Flex flex={1} {...restprops}>
      <Flex flexDirection='column' alignItems='center' flex={1}>
        <Avatar
          iconSize={52}
          hasBackground
          hasBorderRadius='md'
          icon={
            <Flex justifyContent='center' alignItems='center'>
              <IconSignature size={28} color='white' />
            </Flex>
          }
        />
        <Flex flexDirection='column' alignItems='center' mt={15} px={20} width='100%'>
          <Text fontSize='xl' fontWeight='bold' textAlign='center' mb={5}>
            {t('title.verify-address')}
          </Text>
          <Text fontSize='md' fontWeight='500' color='grey.500' textAlign='center' mb={15} px={30} lineHeight={1.5}>
            {expired ? t('subtitle.verify-address-expired') : t('subtitle.verify-address')}
          </Text>
        </Flex>
        <SpacerDash bgColor='grey.100' height={1} width='100%' />
        <Box mb={15} mt={10} width='100%' textAlign='center' px={30}>
          <Text as='span' fontSize='md' dangerouslySetInnerHTML={{ __html: t('paragraph-1.verify-address') }} textAlign='center' />
          <Text fontSize='md' lineHeight='22px' textAlign='center' mt={20}>
            {t('paragraph-2.verify-address')}
          </Text>
        </Box>
        <Flex width='100%' px={20}>
          <Button
            size='xl'
            width='100%'
            color='primary'
            onClick={async () => {
              if (onClick) {
                onClick()
              } else {
                try {
                  setLoading(true)
                  const result = await handleLogin()
                  if (result?.status === false) {
                    console.log('Login error', result.message)
                    setLoading(false)
                    return
                  }
                  setLoading(false)
                  if (onDismiss) onDismiss()
                } catch (error) {
                  console.log({ error })
                }
              }
            }}
            loading={isLoading}
            disabled={isLoading}
          >
            {t('button.verify-address')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
