import React from 'react'

interface IWallet {
  color?: string
  size?: number
}

const IconDelegate = React.forwardRef<SVGSVGElement, IWallet>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14.938 15.128' color={color} {...rest}>
      <path
        id='Union_21'
        data-name='Union 21'
        d='M4083-627.22v-2.625h-2.719v-1.75H4083v-2.625h1.75v2.625h2.718v1.75h-2.718v2.625Zm9.444-.178L4090-627.4v-1.661l2.449.006a.827.827,0,0,0,.829-.823v-.716h-1.871a2.644,2.644,0,0,1-2.642-2.642,2.643,2.643,0,0,1,2.642-2.641h1.871v-1.484h-10.787a2.467,2.467,0,0,1-.831-.142v2.288H4080v-4.636a2.5,2.5,0,0,1,2.493-2.492h10.771v1.661h-10.771a.833.833,0,0,0-.833.831.832.832,0,0,0,.831.83h12.447v9.143a2.488,2.488,0,0,1-2.487,2.484Zm-1.921-5.842a.885.885,0,0,0,.883.883h1.871v-1.765h-1.871A.884.884,0,0,0,4090.524-633.24Z'
        transform='translate(-4080 642.348)'
        fill={color}
      />
    </svg>
  )
})

export default IconDelegate
