import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconNativeSwap = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 15, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 114.846 137.589' color={color} {...rest}>
      <g id='spaces_u65sVlfIiCsicqrgC0ba_uploads_kCq5LEAjQ8p4gxNK6Q8w_Black-logo-without-words-with-black-bg-1' transform='translate(-49.9 175.631)'>
        <path
          id='Path_5146'
          data-name='Path 5146'
          fill={color}
          d='M84.6-174.6c-2.6.7-5.2,2-5.7,2.7-1,1.6-1,17.2,0,20.9s5,5.1,16.9,5.9l10.7.7-3.7,1.7c-4.6,2.1-22.4,4.1-29.5,3.4l-5.2-.5-4,5.2c-2.3,2.9-4.1,5.8-4.1,6.3,0,1.9,3.2,2.5,14.3,3.1,12.1.5,12.9,1.2,7,6.1-5.2,4.3-13.4,7.2-22.1,7.8-4,.3-7.4.9-7.6,1.2a26.656,26.656,0,0,0-1,5.3l-.7,4.8h3.8a45.579,45.579,0,0,0,9.3-1.5c9.5-2.6,10.2-1.8,4,4.9-3.3,3.6-7.5,6.3-15.8,10.3-.2,0,.2,1.9.9,4.2,1.4,4.6,1.9,4.8,9.9,2.6,3-.8,5.6-1.3,5.8-1,1,1-1.3,4.7-4.9,7.9-4.5,4.1-4.4,4.9,1.4,10.4l3.4,3.3-4.4,4.4c-4.9,4.9-5.2,7.3-1.3,9.2,3,1.4,9.3.3,15.6-2.9,3.9-1.9,4.5-1.9,8.5-.6,3.3,1.1,4.3,2,4.2,3.4-1,7.7,3.8,10.2,6.4,3.4,1.5-3.7,1.8-4,4.7-3.5,4.2.8,13.7-.8,21.1-3.6,4.7-1.7,7.6-3.8,13.6-9.8,7.9-7.9,10-11.8,13.5-25.1,2.8-10.4,1.4-32.3-2.1-33.7-.7-.2-3.5.7-6.2,2.1-4.1,2-5.1,3.1-6.2,6.6l-1.2,4.1-3.3-2.9a13.989,13.989,0,0,0-8.5-3.5c-6.6-.8-12-3.4-13.4-6.5-2.4-5.3,6.9-8.1,15.1-4.6,5.5,2.4,16.6,2.1,24.7-.6,11.3-3.8,17.6-11.4,16-19.3-2.6-12.6-21.3-27.2-40-31.3C114.5-175.7,90.8-176.4,84.6-174.6ZM135-159.8c6.9,4.8,9.1,11.3,6,18.3a12.066,12.066,0,0,0-1.2,3.7c.3,1.6-7,6.7-10.7,7.3-10.3,1.7-20.8-9.5-17.2-18.5.9-2.3,1-2.2,1,1.6.2,10.9,13.5,18.1,22.9,12.4,1.7-1.1,3.2-2.2,3.2-2.5,0-.2-1.5.4-3.2,1.5a15.846,15.846,0,0,1-14.4.4c-7.4-3.5-9.9-14.3-4.7-20.5C121.8-162.1,129.3-163.6,135-159.8Zm23,23.4a1.453,1.453,0,0,1-1.5,1.4c-1.5,0-2.1-2.1-.9-3.3C156.6-139.2,158-138.1,158-136.4Zm-33.9,33.6a24.054,24.054,0,0,1,7,28.4c-3.9,8.6-14.2,14.3-24.1,13.1C93.7-62.9,85.5-72.7,86.2-86.1c.6-11,5.9-16.7,12.8-13.9,4.5,1.8,12.9,11.6,16.3,18.8,1.6,3.4,3.3,6,3.7,5.7.5-.3,1.6-2.8,2.5-5.6a25.9,25.9,0,0,0-1.9-20.4C116.6-107,118-107.4,124.1-102.8Z'
        />
        <path
          id='Path_5147'
          data-name='Path 5147'
          fill={color}
          d='M123-152.5a4.76,4.76,0,0,0-.6,4c.5,1.9,1.3,2.5,3.5,2.5,3.9,0,5.7-2.3,4.3-5.5A4.3,4.3,0,0,0,123-152.5Z'
        />
      </g>
    </svg>
  )
})

export default IconNativeSwap
