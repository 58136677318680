import React from 'react'

interface IIconLoanRepay {
  color?: string
  size?: number
}

const IconStakingCalc = React.forwardRef<SVGSVGElement, IIconLoanRepay>(({ color = 'currentColor', size, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13.567 15.968' color={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_3779'
            data-name='Rectangle 3779'
            width='13.567'
            height='15.968'
            fill={color}
            stroke={color}
            strokeLinecap='round'
            strokeWidth='0.1'
          />
        </clipPath>
      </defs>
      <g id='Group_12076-2' data-name='Group 12076' clipPath='url(#clip-path)'>
        <path
          id='Path_3926'
          data-name='Path 3926'
          d='M12.163,0H1.4A1.405,1.405,0,0,0,0,1.4V14.564a1.405,1.405,0,0,0,1.4,1.4h10.76a1.405,1.405,0,0,0,1.4-1.4V1.4a1.405,1.405,0,0,0-1.4-1.4m.468,14.564h0a.468.468,0,0,1-.468.468H1.4a.468.468,0,0,1-.468-.468V1.4A.468.468,0,0,1,1.4.936h10.76a.468.468,0,0,1,.468.468V14.564Z'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3927'
          data-name='Path 3927'
          d='M80.513,74.889H72.366a.468.468,0,0,0-.468.468V77.8a.468.468,0,0,0,.468.468h8.147a.468.468,0,0,0,.468-.468V75.357a.468.468,0,0,0-.468-.468m-.468,2.441H72.834V75.825h7.211Z'
          transform='translate(-69.656 -72.553)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3928'
          data-name='Path 3928'
          d='M74.05,242.871H72.366a.468.468,0,0,0-.468.468v2.018a.468.468,0,0,0,.468.468H74.05a.468.468,0,0,0,.468-.468v-2.018a.468.468,0,0,0-.468-.468m-.468,2.018h-.749v-1.082h.749v1.082Z'
          transform='translate(-69.656 -235.297)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3929'
          data-name='Path 3929'
          d='M177.658,242.871h-1.684a.468.468,0,0,0-.468.468v2.018a.468.468,0,0,0,.468.468h1.684a.468.468,0,0,0,.468-.468v-2.018a.468.468,0,0,0-.468-.468m-.468,2.018h-.749v-1.082h.749Z'
          transform='translate(-170.032 -235.297)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3930'
          data-name='Path 3930'
          d='M281.265,242.871h-1.684a.468.468,0,0,0-.468.468v2.018a.468.468,0,0,0,.468.468h1.684a.468.468,0,0,0,.468-.468v-2.018a.468.468,0,0,0-.468-.468m-.468,2.018h-.749v-1.082h.749Z'
          transform='translate(-270.408 -235.297)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3931'
          data-name='Path 3931'
          d='M74.05,351.059H72.366a.468.468,0,0,0-.468.468v2.018a.468.468,0,0,0,.468.468H74.05a.468.468,0,0,0,.468-.468v-2.018a.468.468,0,0,0-.468-.468m-.468,2.018h-.749v-1.082h.749v1.082Z'
          transform='translate(-69.656 -340.11)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3932'
          data-name='Path 3932'
          d='M177.658,351.059h-1.684a.468.468,0,0,0-.468.468v2.018a.468.468,0,0,0,.468.468h1.684a.468.468,0,0,0,.468-.468v-2.018a.468.468,0,0,0-.468-.468m-.468,2.018h-.749v-1.082h.749Z'
          transform='translate(-170.032 -340.11)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
        <path
          id='Path_3933'
          data-name='Path 3933'
          d='M281.265,351.059h-1.684a.468.468,0,0,0-.468.468v2.018a.468.468,0,0,0,.468.468h1.684a.468.468,0,0,0,.468-.468v-2.018a.468.468,0,0,0-.468-.468m-.468,2.018h-.749v-1.082h.749Z'
          transform='translate(-270.408 -340.11)'
          fill={color}
          stroke={color}
          strokeLinecap='round'
          strokeWidth='0.1'
        />
      </g>
    </svg>
  )
})

export default IconStakingCalc
