import { useState } from 'react'
import { Menu } from 'react-feather'
import { accountRoute } from 'constants/routes'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import useWindowPosition from 'hooks/common/useWindowPosition'
import { useCheckAccount } from 'modules/wallet-module/hooks/useCheckAccount'
import { useTranslation } from 'next-i18next'
import { Flex } from 'rebass/styled-components'
import { AnimatePresence, Button, IconWallet, MotionFlex, MotionImage, Spacer, useDrawer } from 'theme/ui'
import NavButtonAccountLanding from 'theme/ui/common/nav-button/nav-button-account-landing'
import Popover from 'theme/ui/common/popover'

import Link from 'components/common/link'

import MobileNavigationSidebar from '../mobile-navigation-sidebar'
import Sidebar from '../sidebar'

import Navigation from './navigation'
import NavigationLanding from './navigation-landing'

export const LANDING_HEADER_HEIGHT = 108
export const LANDING_HEADER_BOTTOM = 390
export const WINDOW_POSITION_TRIGGER = 0

const LandingHeader: React.FC = () => {
  const { colors } = useTheme()
  const { isDesktop, isTablet } = useResponsive()
  const [showMobileMenu, toggleMobileMenu] = useState(false)

  useCheckAccount()

  const windowPosition = useWindowPosition()

  return (
    <>
      <MobileNavigationSidebar showMobileMenu={showMobileMenu} toggleMobileMenu={toggleMobileMenu} />
      <AnimatePresence>
        <MotionFlex
          initial={{
            height: LANDING_HEADER_HEIGHT
          }}
          animate={{
            height: windowPosition > WINDOW_POSITION_TRIGGER ? LANDING_HEADER_HEIGHT - 40 : LANDING_HEADER_HEIGHT,
            boxShadow: windowPosition > 5 ? `0px 0px 12px 0px ${colors.shadow[200]}` : ''
          }}
          transition={{
            type: 'tween',
            // duration: 0,
            delay: 0
            // delay: windowPosition > WINDOW_POSITION_TRIGGER ? 0 : 0.5
          }}
          sx={{
            background: windowPosition > 5 ? (windowPosition > LANDING_HEADER_BOTTOM ? colors['white'] : colors['header1']) : 'transparent',
            transition: 'all 0.3s',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            py: [22, 22, 22, 22, 0],
            // boxShadow: '0 -8px 16px 0 rgba(0,0,0,0.08)',
            zIndex: 302
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: 1400,
              px: [20, 20, 20, 20, 20, 0]
            }}
          >
            {!isDesktop ? (
              <Flex alignItems='center'>
                <Button
                  type='button'
                  size='icon'
                  mr={6}
                  backgroundColor='transparent'
                  fontColor={windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white'}
                  width={24}
                  height={24}
                  style={{ borderColor: 'transparent' }}
                  onClick={() => toggleMobileMenu(state => !state)}
                >
                  <Menu size={24} />
                </Button>
                <Flex mt={[0, 0, 5]} ml={[4]}>
                  <AnimatePresence>
                    {windowPosition > LANDING_HEADER_BOTTOM ? (
                      <Link href='/' passHref>
                        <MotionFlex as='a' alignItems='center'>
                          <MotionImage
                            initial={{
                              maxWidth: isTablet ? 240 : 210
                            }}
                            animate={{
                              maxWidth: windowPosition > WINDOW_POSITION_TRIGGER ? 210 : isTablet ? 240 : 210
                            }}
                            src='/images/bend-logo-full-black-3x.png'
                            sx={{ width: [180, 180, 210] }}
                          />
                        </MotionFlex>
                      </Link>
                    ) : (
                      <Link href='/' passHref>
                        <MotionFlex as='a' alignItems='center'>
                          <MotionImage
                            initial={{
                              maxWidth: isTablet ? 240 : 210
                            }}
                            animate={{
                              maxWidth: windowPosition > WINDOW_POSITION_TRIGGER ? 210 : isTablet ? 240 : 210
                            }}
                            src='/images/bend-logo-3x.png'
                            sx={{ width: [180, 180, 210], maxWidth: windowPosition > WINDOW_POSITION_TRIGGER ? [180, 180, 210] : [210, 210, 240] }}
                          />
                        </MotionFlex>
                      </Link>
                    )}
                  </AnimatePresence>
                </Flex>
              </Flex>
            ) : (
              <Flex alignItems='center' width={[210, 210, 240]}>
                <Flex mt={[0, 0, 5]}>
                  <AnimatePresence>
                    {windowPosition > LANDING_HEADER_BOTTOM ? (
                      <Link href='/' passHref>
                        <MotionFlex as='a' alignItems='center'>
                          <MotionImage
                            initial={{
                              maxWidth: isTablet ? 240 : 210
                            }}
                            animate={{
                              maxWidth: windowPosition > WINDOW_POSITION_TRIGGER ? 210 : isTablet ? 240 : 210
                            }}
                            src='/images/bend-logo-full-black-3x.png'
                            sx={{ width: '100%' }}
                          />
                        </MotionFlex>
                      </Link>
                    ) : (
                      <Link href='/' passHref>
                        <MotionFlex as='a' alignItems='center'>
                          <MotionImage
                            initial={{
                              maxWidth: isTablet ? 240 : 210
                            }}
                            animate={{
                              maxWidth: windowPosition > WINDOW_POSITION_TRIGGER ? 210 : isTablet ? 240 : 210
                            }}
                            src='/images/logo-3x-3.png'
                            sx={{ width: '100%', maxWidth: windowPosition > WINDOW_POSITION_TRIGGER ? [210] : [210, 210, 240] }}
                          />
                        </MotionFlex>
                      </Link>
                    )}
                  </AnimatePresence>
                </Flex>
              </Flex>
            )}

            <Flex>
              {isDesktop && <NavigationLanding />}
              <Flex justifyContent='flex-end' ml={isDesktop ? 10 : 0}>
                <Web3StatusLanding />
              </Flex>
            </Flex>
          </Flex>
        </MotionFlex>
      </AnimatePresence>
    </>
  )
}

export default LandingHeader

export { Navigation }

const Web3StatusLanding: React.FC = () => {
  const { t } = useTranslation('common')
  const { colors } = useTheme()
  const { isTablet } = useResponsive()
  const [toggleSidebar, , setActivePane] = useDrawer(<Sidebar />)
  const windowPosition = useWindowPosition()

  return (
    <Flex alignItems='center' justifyContent='flex-end'>
      <NavButtonAccountLanding {...accountRoute} />
      <Spacer size={isTablet ? 'md' : 'sm'} />
      <Popover placement='bottom' overlay={t('label.wallet')} background={windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'header1'}>
        <Button
          size='icon'
          color={windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'header-label'}
          p={5.5}
          // ml={-6}
          onClick={() => {
            toggleSidebar()
            setActivePane(0)
          }}
          buttonStyles={{
            position: 'relative',
            transition: 'all 0.3s',
            '&:hover': {
              opacity: 1,
              borderColor: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
              svg: {
                transition: 'all 0.3s',
                fill: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                stroke: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                path: {
                  fill: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white',
                  stroke: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
                }
              }
            }
          }}
          outlined
        >
          <IconWallet size={20} color={windowPosition > LANDING_HEADER_BOTTOM ? colors['primary'] : colors['header-label']} />
        </Button>
      </Popover>
    </Flex>
  )
}
