import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientNftApi } from 'clients'
import { REDEEM_TIME } from 'constants/index'
import { useUserContext } from 'contexts/user'

type UseNotificationsProps = {
  skip?: number
  first?: number
  isRead?: boolean | null
}

const body = `
  loan {
    loanID
    state
    borrower
    nftTokenID
    nftAsset
    reserveAsset
    healthFactor
    bidPrice
  }
  nftCollection {
    address
    name
    openseaImageURL
    symbol
    totalSupply
  }
  nftItem {
    collectionAddress
    ownerAddress
    tokenID
    tokenURI
    image
    imageType
  }
  proxy {
    poolID # 1 | 2 | 3
    type # solo | paired
    apeStakedCoinAmount
    bakcStakedCoinAmount
    coinStakedCoinAmount
    ape {
      nftItem {
        tokenID
        collection {
          symbol
        }
      }
    }
    bakc {
      nftItem {
        tokenID
        collection {
          symbol
        }
      }
    }
  }
`

export default function useNotifications({ skip = 0, first = 10, isRead = null }: UseNotificationsProps) {
  const { isLoggedIn, token, user } = useUserContext()
  const notifications = useQuery(
    ['get user notifications', isLoggedIn, token, skip, first, isRead],
    async () => {
      const {
        data: { notifications }
      } = await clientNftApi.query({
        query: gql`
          query Notifications($first: Int, $skip: Int, $isRead: Boolean) {
            notifications(first: $first, skip: $skip, isRead: $isRead) {
              id
              uniqueKey
              address
              topic
              createdAt
              body {
                loan_warn_1a {
                  ${body}
                }
                loan_warn_1b {
                  ${body}
                }
                loan_warn_2 {
                  ${body}
                }
                loan_warn_3 {
                  ${body}
                }
                loan_warn_4a {
                  ${body}
                }
                loan_warn_4b {
                  ${body}
                }
                loan_warn_5a {
                  ${body}
                }
                loan_warn_5b {
                  ${body}
                }
                ape_staking_staked {
                  ${body}
                }
                ape_staking_un_staked {
                  ${body}
                }
              }
              readAt
            }
          }
        `,
        variables: {
          skip,
          first,
          isRead
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return notifications
    },
    {
      enabled: !!isLoggedIn && !!token
    }
  )

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      if (!token) return false
      try {
        const {
          data: {
            readNotificationByID: { ok }
          }
        } = await clientNftApi.mutate({
          mutation: gql`
            mutation MarkAsRead($id: String!) {
              readNotificationByID(request: { id: $id }) {
                ok
              }
            }
          `,
          variables: {
            id
          },
          context: {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        })

        return ok
      } catch (error) {
        return {
          error
        }
      }
    },
    [token]
  )

  const handleMarkAllAsRead = useCallback(async () => {
    if (!token) return false
    if (!user?.address) return false
    try {
      const {
        data: {
          readNotificationByID: { ok }
        }
      } = await clientNftApi.mutate({
        mutation: gql`
          mutation MarkAllAsRead($address: String!) {
            readAllNotification(request: { address: $address }) {
              ok
            }
          }
        `,
        variables: {
          address: user.address
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return ok
    } catch (error) {
      return {
        error
      }
    }
  }, [token, user?.address])

  return {
    notifications,
    handleMarkAsRead,
    handleMarkAllAsRead
  }
}

// type NotificationTypeProps = 'loan_warn_3' | 'loan_warn_2' | 'loan_warn_1a' | 'loan_warn_1b' | 'loan_warn_4a' | 'loan_warn_5a' | 'loan_warn_5b'

type NotificationResult_Token = {
  tokenID: string
  symbol: string
}

export type NotificationResult = {
  healthFactor?: string
  collectionName?: string
  collateral?: string
  highestBid?: string
  reserve?: string
  redeemTime?: number
  ape?: NotificationResult_Token
  bakc?: NotificationResult_Token
  type?: string
  totalStakedAmount?: string
  status?: 'staked' | 'unstaked'
  pool?: string
  name?: string
}

function getTotalStakedAmount(proxy: any): BigNumber {
  const apeStakedCoinAmountBN = new BigNumber(proxy?.apeStakedCoinAmount).dividedBy(1e18)
  const bakcStakedCoinAmountBN = new BigNumber(proxy?.bakcStakedCoinAmount).dividedBy(1e18)
  const coinStakedCoinAmountBN = new BigNumber(proxy?.coinStakedCoinAmount).dividedBy(1e18)

  return apeStakedCoinAmountBN.plus(bakcStakedCoinAmountBN).plus(coinStakedCoinAmountBN)
}

function getStakingPoolName(proxy: any): string {
  if (proxy?.poolID === 1) return 'BAYC'
  if (proxy?.poolID === 2) return 'MAYC'
  if (proxy?.poolID === 3) return 'BAKC'
  return ''
}

function getStakingName(proxy: any): string {
  let name = proxy?.ape?.nftItem?.collection?.symbol + ' #' + proxy?.ape?.nftItem?.tokenID

  if (proxy?.bakc?.nftItem?.collection?.symbol && proxy?.bakc?.nftItem?.tokenID) {
    name = name + ' + ' + proxy?.bakc?.nftItem?.collection?.symbol + ' #' + proxy?.bakc?.nftItem?.tokenID
  }

  return name
}

export const useNotificationProps = (notification: any): NotificationResult => {
  // console.log('notification', notification)
  switch (notification.topic) {
    default:
    case 'loan_warn_1a':
      return {
        healthFactor: new BigNumber(notification.body[notification.topic]?.loan.healthFactor).dp(2).toString(),
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID
      }
    case 'loan_warn_1b':
      return {
        healthFactor: new BigNumber(notification.body[notification.topic]?.loan.healthFactor).dp(2).toString()
      }
    case 'loan_warn_2':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        redeemTime: REDEEM_TIME
      }
    case 'loan_warn_3':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        highestBid: new BigNumber(notification.body[notification.topic]?.loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_4a':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID
      }
    case 'loan_warn_5a':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        highestBid: new BigNumber(notification.body[notification.topic]?.loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_5b':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        highestBid: new BigNumber(notification.body[notification.topic]?.loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'ape_staking_staked':
      return {
        status: 'staked',
        type: notification.body[notification.topic]?.proxy?.type,
        totalStakedAmount: getTotalStakedAmount(notification.body[notification.topic]?.proxy)?.toFixed(0),
        pool: getStakingPoolName(notification.body[notification.topic]?.proxy),
        name: getStakingName(notification.body[notification.topic]?.proxy),
        ape: {
          symbol: notification.body[notification.topic]?.proxy?.ape?.nftItem?.collection?.symbol,
          tokenID: notification.body[notification.topic]?.proxy?.ape?.nftItem?.tokenID
        },
        bakc: {
          symbol: notification.body[notification.topic]?.proxy?.bakc?.nftItem?.collection?.symbol,
          tokenID: notification.body[notification.topic]?.proxy?.bakc?.nftItem?.tokenID
        }
      }
    case 'ape_staking_un_staked':
      return {
        status: 'unstaked',
        type: notification.body[notification.topic]?.proxy?.type,
        totalStakedAmount: getTotalStakedAmount(notification.body[notification.topic]?.proxy)?.toFixed(0),
        pool: getStakingPoolName(notification.body[notification.topic]?.proxy),
        name: getStakingName(notification.body[notification.topic]?.proxy),
        ape: {
          symbol: notification.body[notification.topic]?.proxy?.ape?.nftItem?.collection?.symbol,
          tokenID: notification.body[notification.topic]?.proxy?.ape?.nftItem?.tokenID
        },
        bakc: {
          symbol: notification.body[notification.topic]?.proxy?.bakc?.nftItem?.collection?.symbol,
          tokenID: notification.body[notification.topic]?.proxy?.bakc?.nftItem?.tokenID
        }
      }
  }
}
