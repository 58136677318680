import { useEffect } from 'react'
import { Connector } from '@web3-react/types'
import { getCookie } from 'cookies-next'

import { Connection, getConnection, gnosisSafeConnection, networkConnection, useWallet } from '..'

const selectedWalletType = getCookie('selectedWalletType') as Connection['type']

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate()
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`)
  }
}

export default function useEagerlyConnect() {
  const { WalletDispatch } = useWallet()

  let selectedConnection: Connection | undefined

  if (selectedWalletType) {
    try {
      selectedConnection = getConnection(selectedWalletType)
    } catch {
      WalletDispatch({
        type: 'UPDATE',
        payload: {
          selectedWalletType: undefined
        }
      })
    }
  }

  useEffect(() => {
    connect(gnosisSafeConnection.connector)
    connect(networkConnection.connector)

    if (selectedConnection) {
      connect(selectedConnection.connector)
    }
    // The dependency list is empty so this is only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
