import React from 'react'
import { Box, BoxProps } from 'rebass/styled-components'
// Components

export interface SpacerDashProps extends BoxProps {
  bgColor: string
}

const SpacerDash: React.FC<SpacerDashProps> = ({ bgColor, width = 64, height = 4, ...resprops }) => {
  return <Box bg={bgColor} width={width} height={height} {...resprops} />
}

export default SpacerDash
