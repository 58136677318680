import React from 'react'
import BigNumber from 'bignumber.js'
import { Box, Flex, Text } from 'rebass/styled-components'

import { TextLabel } from '../..'

import { IProgressbar } from './interfaces'

const Progressbar: React.FC<IProgressbar> = ({ label, percent, ...restprops }) => {
  const [progressbarWidth, setProgressbarWidth] = React.useState(0)

  React.useEffect(() => {
    if (!percent) return
    const timeout = setTimeout(() => setProgressbarWidth(percent), 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [percent])
  return (
    <>
      {label && (
        <TextLabel color='grey.300' fontSize='md' lineHeight='md' ml={20} mb={10}>
          {label}
        </TextLabel>
      )}
      <Flex
        sx={{
          width: '100%',
          height: 42,
          bg: 'blue.100',
          color: 'white',
          borderRadius: 'sm',
          overflow: 'hidden',
          position: 'relative'
        }}
        {...restprops}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: 42,
            backgroundImage: 'url(/images/progress-bar-loop-blue.gif)',
            backgroundRepeat: 'repeat-x',
            backgroundPositionX: 0,
            backgroundPositionY: 0,
            width: percent === 0 ? 0 : percent < 0.3 ? '0.3%' : `${progressbarWidth}%`,
            transitionProperty: 'width',
            transitionDuration: '2s',
            transitionTimingFunction: 'ease-in-out',
            transitionDelay: '0.1s',
            zIndex: 1
          }}
        />
        <Flex
          sx={{
            position: 'absolute',
            top: 0,
            left: percent > 9 && percent < 54 ? `calc(${progressbarWidth}% - 52px)` : `calc(${progressbarWidth - Math.abs(50 - progressbarWidth)}% - 26px)`,
            width: 52,
            color: percent > 9 ? 'white' : 'primary',
            pt: 10,
            transitionProperty: 'left',
            transitionDuration: '2s',
            transitionTimingFunction: 'ease-in-out',
            transitionDelay: '0.1s',
            zIndex: 2,
            justifyContent: 'center'
          }}
        >
          <Flex alignItems='center' justifyContent='center' fontSize={16} fontWeight='bold' minWidth={60} ml={-2}>
            <Text as='span' lineHeight='xxl' mr={1}>
              {new BigNumber(new BigNumber(percent).dp() || 0).gt(2) ? percent.toFixed(2) : percent}
            </Text>
            <Text as='span' lineHeight='xl'>
              %
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Progressbar
