import { ReactNode, useCallback, useState } from 'react'

import { UseModal } from '../types'

import { useDisableBodyScroll } from './use-disable-body-scroll'

const useModal = (): UseModal => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode>(null)
  useDisableBodyScroll(modalOpen)

  const close = useCallback(() => setModalOpen(false), [])
  const open = useCallback(() => setModalOpen(true), [])

  return { modalOpen, close, open, modalContent, setModalContent }
}

export default useModal
