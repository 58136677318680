import React from 'react'
import useCopyClipboard from 'hooks/common/useCopyClipboard'
import useTheme from 'hooks/common/useTheme'
import { Flex } from 'rebass/styled-components'
import { Button, IButton, IconCopy } from 'theme/ui'

interface CopyHelperProps extends IButton {
  toCopy: string
}

const CopyHelper: React.FC<CopyHelperProps> = ({ toCopy, children, ...restprops }) => {
  const [isCopied, setCopied] = useCopyClipboard()
  const { colors } = useTheme()

  return (
    <Button size='icon' onClick={() => setCopied(toCopy)} {...restprops}>
      <Flex flexDirection='row' alignItems='center'>
        {isCopied && <IconCopy color={colors.green[1000]} />}
        {isCopied ? '' : children}
      </Flex>
    </Button>
  )
}

export default CopyHelper
