import React from 'react'

interface IIconDefaultPayment {
  color?: string
  size?: number
}

const IconDefaultPayment = React.forwardRef<SVGSVGElement, IIconDefaultPayment>(({ color = 'white', size = 29, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 29 29' {...rest}>
      <g id='Ellipse_409' data-name='Ellipse 409' fill='none' stroke={color} strokeWidth='2.25'>
        <circle cx='14.5' cy='14.5' r='14.5' stroke='none' />
        <circle cx='14.5' cy='14.5' r='13.375' fill='none' />
      </g>
      <g id='Ellipse_410' data-name='Ellipse 410' transform='translate(4 4)' fill='none' stroke={color} strokeWidth='2.25'>
        <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
        <circle cx='10.5' cy='10.5' r='9.375' fill='none' />
      </g>
    </svg>
  )
})

export default IconDefaultPayment
