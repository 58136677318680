import React from 'react'

interface IIconCancelOffer {
  color?: string
  size?: number
}

const IconCancelOffer = React.forwardRef<SVGSVGElement, IIconCancelOffer>(({ color = 'currentColor', size = 26, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 26.382 23.987' stroke={color} {...rest}>
      <path
        id='path828'
        d='M.664,282.428a.7.7,0,0,0-.7.7V293.34a.7.7,0,0,0,.7.7h3.25a.7.7,0,0,0,.7-.7v-8.126H6.7a7.583,7.583,0,0,1,1.99.835,12.6,12.6,0,0,0,2,1.1c1.248.353,3.025.324,4.607.572a1.449,1.449,0,0,1,.255.745H11.176a.7.7,0,1,0,0,1.393h5.139a.7.7,0,0,0,.7-.681c-.019-1.428-.39-2.635-1.511-2.832-1.736-.272-3.779-.324-4.432-.537-.222-.073-1.067-.631-1.693-.97A5.734,5.734,0,0,0,6.7,283.821H3.915a.7.7,0,0,0-.7.7v8.126H1.361v-8.822h.7a.7.7,0,1,0,0-1.393Z'
        transform='translate(0.207 -270.225)'
        fill={color}
        stroke={color}
        strokeMiterlimit='10'
        strokeWidth='0.35'
      />
      <path
        id='path830'
        d='M129.762,367.648a51.665,51.665,0,0,0-6.024,1.169.7.7,0,1,0,.324,1.355,55.043,55.043,0,0,1,5.653-1.121,1.353,1.353,0,0,1,.212.523c-3.013,1.169-7.612,3.375-7.819,3.474l-8.2.478a16.451,16.451,0,0,1-1.958-.776.7.7,0,1,0-.541,1.282,18.382,18.382,0,0,0,1.992.8,1.663,1.663,0,0,0,.562.089l8.358-.491a.7.7,0,0,0,.26-.067s5.162-2.472,8.092-3.585C132.058,370.223,131.03,367.495,129.762,367.648Z'
        transform='translate(-105.159 -351.111)'
        fill={color}
        stroke={color}
        strokeMiterlimit='10'
        strokeWidth='0.35'
      />
      <g id='close' transform='translate(10.065 0.25)'>
        <path
          id='Shape'
          d='M5.569,4.748,9.325.992a.58.58,0,0,0-.82-.82L4.748,3.926.99.17a.58.58,0,0,0-.82.82L3.927,4.747.17,8.5a.58.58,0,0,0,.82.82L4.748,5.568,8.505,9.326a.58.58,0,0,0,.82-.82Z'
          transform='translate(0 0)'
          fill={color}
          stroke={color}
          strokeMiterlimit='10'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  )
})

export default IconCancelOffer
