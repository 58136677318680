import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconWrap = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 15, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 15.25 15.275'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='retweet' transform='translate(0.125 0.111)'>
        <path
          id='Shape'
          d='M5.345,14.848,2.256,11.76a.608.608,0,0,1,0-.857l3-3a.605.605,0,0,1,.856.855l-2,2H9.976A3.812,3.812,0,0,0,13.788,6.95a.606.606,0,0,1,1.212,0A5.022,5.022,0,0,1,9.978,11.97H4.18L6.2,13.993a.606.606,0,0,1-.857.855ZM0,8.076A5.02,5.02,0,0,1,5.02,3.054h5.8L8.8,1.034A.605.605,0,0,1,9.653.178l3.09,3.088a.611.611,0,0,1,.176.429.6.6,0,0,1-.176.429l-3,3a.606.606,0,0,1-.857-.856l2-2H5.02A3.811,3.811,0,0,0,1.21,8.076a.605.605,0,0,1-1.21,0Z'
          transform='translate(0 0.014)'
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconWrap
