import { useTranslation } from 'next-i18next'
import { Flex, FlexProps, Text } from 'rebass/styled-components'
import { Avatar, Button, IconSignature } from 'theme/ui'

interface IVerifyAddressComponent {
  handleLogin: any
}

export const VerifyAddressComponent: React.FC<IVerifyAddressComponent & FlexProps> = ({ handleLogin, ...restprops }) => {
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')

  return (
    <Flex flex={1} mt={20} {...restprops}>
      <Flex flexDirection='column' alignItems='center' flex={1}>
        <Avatar
          iconSize={52}
          hasBackground
          hasBorderRadius={8}
          icon={
            <Flex justifyContent='center' alignItems='center'>
              <IconSignature color='white' />
            </Flex>
          }
        />
        <Flex flexDirection='column' alignItems='center' mt={20}>
          <Text fontSize={18} fontWeight='bold' textAlign='center' mb={10}>
            {t('profile.title.need-signature')}
          </Text>
          <Text fontSize={13} fontWeight='500' color='grey.300' textAlign='center' mb={20} px={30} lineHeight={1.5}>
            {t('sidebar.subtitle.need-signature')}
          </Text>
        </Flex>
        <Button
          size='xl'
          width='100%'
          maxWidth={280}
          color='primary'
          onClick={async () => {
            const result = await handleLogin()
            if (result?.status) {
              console.log('Login error', result.message)
            }
          }}
        >
          {tc('button.verify-address')}
        </Button>
      </Flex>
    </Flex>
  )
}
