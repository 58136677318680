import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconUnisat = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16.885 21' fill={color} {...rest}>
      <path
        id='Union_12'
        data-name='Union 12'
        d='M14206.7-7137.952a4.449,4.449,0,0,1-1.921-1.335l-4.18-4.1a1.462,1.462,0,0,1-.5-1.085,1.742,1.742,0,0,1,.417-1,2.393,2.393,0,0,1,1-.335,1.633,1.633,0,0,1,1.089.5l4.263,4.18a3.69,3.69,0,0,0,1.421,1,2.044,2.044,0,0,0,1.42.086,4.952,4.952,0,0,0,1.589-1,2.854,2.854,0,0,0,1.086-2.174,3.167,3.167,0,0,0-1.168-2.092l-2.342-2.257a1.457,1.457,0,0,1-.5-1.085,1.436,1.436,0,0,1,.5-1,1.906,1.906,0,0,1,1-.5,1.612,1.612,0,0,1,1.088.5l2.172,2.088a7.588,7.588,0,0,1,1.506,1.925,3.212,3.212,0,0,1,.336,2.006,3.813,3.813,0,0,1-.672,1.838,7.228,7.228,0,0,1-1.5,1.839,12.52,12.52,0,0,1-2.173,1.756,4.736,4.736,0,0,1-2.008.668,2.708,2.708,0,0,1-.347.023A3.193,3.193,0,0,1,14206.7-7137.952Zm.335-5.433-4.181-4.095a7.686,7.686,0,0,1-1.5-1.924,3.927,3.927,0,0,1-.249-2.006,3.77,3.77,0,0,1,.667-1.839,7.3,7.3,0,0,1,1.5-1.839,10.01,10.01,0,0,1,2.175-1.756,4.754,4.754,0,0,1,2.006-.668h.691a3.2,3.2,0,0,1,1.232.418,5.689,5.689,0,0,1,1.924,1.421l4.178,4.095a1.473,1.473,0,0,1,.5,1.088,1.758,1.758,0,0,1-.418,1,1.459,1.459,0,0,1-1,.5,1.63,1.63,0,0,1-1.089-.5l-4.262-4.181a3.676,3.676,0,0,0-1.421-1,1.465,1.465,0,0,0-1.421-.253,4.973,4.973,0,0,0-1.589,1,3.151,3.151,0,0,0-1.171,2.174,3.166,3.166,0,0,0,1.171,2.092l4.348,4.18a1.473,1.473,0,0,1,.5,1.086,1.446,1.446,0,0,1-.5,1,1.892,1.892,0,0,1-1,.5A1.62,1.62,0,0,1,14207.037-7143.385Zm-1.17-7.939a1.422,1.422,0,0,1,1.456-1.421h.1a1.5,1.5,0,0,1,1.456,1.5,1.5,1.5,0,0,1-1.5,1.5A1.568,1.568,0,0,1,14205.867-7151.324Z'
        transform='translate(-14199.599 7158.011)'
        fill={color}
        stroke='rgba(0,0,0,0)'
        strokeMiterlimit='10'
        strokeWidth='1'
      />
    </svg>
  )
})

export default IconUnisat
