import React from 'react'

interface IIconRecieverContract {
  color?: string
  size?: number
}

const IconRecieverContract = React.forwardRef<SVGSVGElement, IIconRecieverContract>(({ color = 'currentColor', size = 27, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.696 27.698' fill={color} stroke={color} {...rest}>
      <g id='Group_6489' data-name='Group 6489' transform='translate(0.125 0.125)'>
        <path
          id='Path_2675'
          data-name='Path 2675'
          d='M26.739,8.638a2.415,2.415,0,0,0-3.412,0l-2.419,2.421V4.687A2.4,2.4,0,0,0,20.2,2.981L17.927.707A2.4,2.4,0,0,0,16.221,0H2.412A2.415,2.415,0,0,0,0,2.412V25.036a2.415,2.415,0,0,0,2.412,2.412H18.5a2.415,2.415,0,0,0,2.412-2.412v-7.15l5.831-5.836a2.411,2.411,0,0,0,0-3.412M16.083,1.608a.878.878,0,0,1,.707.236l2.274,2.274a.878.878,0,0,1,.236.707H16.083ZM19.3,25.036a.805.805,0,0,1-.8.8H2.412a.805.805,0,0,1-.8-.8V2.412a.805.805,0,0,1,.8-.8H14.475V5.629a.8.8,0,0,0,.8.8H19.3v6.236l-2.373,2.375L15.79,16.18a.8.8,0,0,0-.194.314l-1.137,3.412a.8.8,0,0,0,1.017,1.017l3.412-1.137a.8.8,0,0,0,.314-.194l.1-.1Zm-1.8-8.287,1.137,1.137-.434.434-1.706.569.569-1.706Zm2.274,0-1.137-1.137L22.5,11.743l1.137,1.137ZM25.6,10.913l-.829.83-1.137-1.137.829-.83A.8.8,0,1,1,25.6,10.913'
          transform='translate(0 0)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2676'
          data-name='Path 2676'
          d='M72.062,150H60.8a.8.8,0,1,0,0,1.608H72.062a.8.8,0,1,0,0-1.608'
          transform='translate(-56.783 -141.959)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.25'
        />
        <path
          id='Path_2677'
          data-name='Path 2677'
          d='M68.846,210H60.8a.8.8,0,1,0,0,1.608h8.041a.8.8,0,1,0,0-1.608'
          transform='translate(-56.783 -198.742)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.25'
        />
        <path
          id='Path_2678'
          data-name='Path 2678'
          d='M68.846,270H60.8a.8.8,0,1,0,0,1.608h8.041a.8.8,0,1,0,0-1.608'
          transform='translate(-56.783 -255.525)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.25'
        />
        <path
          id='Path_2679'
          data-name='Path 2679'
          d='M68.846,330H60.8a.8.8,0,1,0,0,1.608h8.041a.8.8,0,1,0,0-1.608'
          transform='translate(-56.783 -312.309)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.25'
        />
        <path
          id='Path_2680'
          data-name='Path 2680'
          d='M185.629,422H180.8a.8.8,0,1,0,0,1.608h4.825a.8.8,0,0,0,0-1.608'
          transform='translate(-170.35 -399.377)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconRecieverContract
