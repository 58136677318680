import { createContext, useCallback, useContext, useReducer } from 'react'
import { SxStyleProp } from 'rebass'

type BreadcrumbsProviderProps = {
  children: React.ReactNode
}

type Breadcrumb = {
  key: string | number
  label: string
  href?: string
  icon?: string
}

type BreadcrumbsState = {
  breadcrumbs: Array<Breadcrumb>
  style: {}
}

type BreadcrumbsContext = {
  setBreadcrumbs: (breadcrumbs: Array<Breadcrumb>) => void
  clearBreadcrumbs: () => void
  setBreadcrumbsStyle: (style: SxStyleProp) => void
  clearBreadcrumbsStyle: () => void
}

type BreadcrumbsReducerAction = {
  action: 'clearBreadcrumbs' | 'setBreadcrumbs' | 'setBreadcrumbsStyle' | 'clearBreadcrumbsStyle'
  payload: any
}

const initialState: BreadcrumbsState = {
  breadcrumbs: [],
  style: {}
}

const BreadcrumbsContextProps = createContext<BreadcrumbsState & BreadcrumbsContext>({
  ...initialState,
  setBreadcrumbs: () => {},
  clearBreadcrumbs: () => {},
  setBreadcrumbsStyle: () => {},
  clearBreadcrumbsStyle: () => {}
})

export const useBreadcrumbsContext = () => useContext(BreadcrumbsContextProps)

const reducer = (state: BreadcrumbsState, { action, payload }: BreadcrumbsReducerAction) => {
  switch (action) {
    default:
    case 'clearBreadcrumbs':
      return { ...state, breadcrumbs: [], style: {} }
    case 'setBreadcrumbs':
      return { ...state, breadcrumbs: payload }
    case 'setBreadcrumbsStyle':
      return { ...state, style: payload }
    case 'clearBreadcrumbsStyle':
      return { ...state, style: {} }
  }
}

export default function BreadcrumbsProvider({ children }: BreadcrumbsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setBreadcrumbs = useCallback(
    (payload: Array<Breadcrumb>) =>
      dispatch({
        action: 'setBreadcrumbs',
        payload
      }),
    []
  )

  const clearBreadcrumbs = useCallback(
    () =>
      dispatch({
        action: 'clearBreadcrumbs',
        payload: []
      }),
    []
  )

  const setBreadcrumbsStyle = useCallback(
    (payload: SxStyleProp) =>
      dispatch({
        action: 'setBreadcrumbsStyle',
        payload
      }),
    []
  )

  const clearBreadcrumbsStyle = useCallback(
    () =>
      dispatch({
        action: 'clearBreadcrumbsStyle',
        payload: {}
      }),
    []
  )

  return (
    <BreadcrumbsContextProps.Provider value={{ ...state, setBreadcrumbs, clearBreadcrumbs, setBreadcrumbsStyle, clearBreadcrumbsStyle }}>
      {children}
    </BreadcrumbsContextProps.Provider>
  )
}
