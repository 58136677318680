import React from 'react'

interface IIconGovernance {
  color?: string
  size?: number
}

const IconGovernance = React.forwardRef<SVGSVGElement, IIconGovernance>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='governance'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M7.2,9.1c-0.4-0.4-1-0.4-1.4,0C5.7,9.3,5.6,9.5,5.6,9.8s0.1,0.5,0.3,0.7l2.8,2.8l0.1,0.1h0c0.2,0.1,0.4,0.2,0.6,0.2
          c0,0,0,0,0,0c0.2,0,0.5-0.1,0.6-0.3l4.9-4.9l0.1-0.1v0c0.3-0.4,0.3-0.9-0.1-1.2l-0.1-0.1c-0.4-0.4-0.9-0.4-1.3,0l-4.2,4.2L7.2,9.1z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M10,0.1L1.5,2.9v8.5c0,4.7,3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5V2.9L10,0.1z M16.6,11.4c0,3.6-3,6.6-6.6,6.6
          s-6.6-3-6.6-6.6V4.3L10,2l6.6,2.3V11.4z'
        />
      </g>
    </svg>
  )
})

export default IconGovernance
