import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconTxPending = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 27 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 39.087 36.389' ref={ref}>
      <g id='Group_20329' data-name='Group 20329' transform='translate(0 -0.001)'>
        <path
          id='Path_4814'
          data-name='Path 4814'
          d='M20.893,0a1.15,1.15,0,0,0,0,2.3,15.893,15.893,0,1,1-15.582,19L5.2,20.729l1.4,1.1a1.149,1.149,0,1,0,1.427-1.8l-3.46-2.739a1.15,1.15,0,0,0-1.617.192l-2.7,3.432a1.153,1.153,0,0,0,.194,1.615,1.148,1.148,0,0,0,1.615-.192l.9-1.143.089.456A18.189,18.189,0,1,0,20.893,0'
          transform='translate(0 0.001)'
          fill={color}
        />
        <path
          id='Path_4815'
          data-name='Path 4815'
          d='M8.94,2.279a1.151,1.151,0,1,0,2.225-.587L10.98,1A1.162,1.162,0,0,0,9.867.137a1.171,1.171,0,0,0-.29.038,1.151,1.151,0,0,0-.82,1.406Z'
          transform='translate(6.523 0.103)'
          fill={color}
        />
        <path
          id='Path_4816'
          data-name='Path 4816'
          d='M6.819,3.473a1.151,1.151,0,1,0,2-1.14l-.357-.624a1.151,1.151,0,0,0-2,1.14Z'
          transform='translate(4.723 0.846)'
          fill={color}
        />
        <path
          id='Path_4817'
          data-name='Path 4817'
          d='M5.085,5.169A1.15,1.15,0,0,0,6.711,3.543l-.483-.484A1.15,1.15,0,0,0,4.6,4.686Z'
          transform='translate(3.191 2.037)'
          fill={color}
        />
        <path
          id='Path_4818'
          data-name='Path 4818'
          d='M3.272,6.93h0l.612.341a1.151,1.151,0,0,0,1.563-.444A1.148,1.148,0,0,0,5,5.262l-.612-.341a1.162,1.162,0,0,0-.558-.143A1.148,1.148,0,0,0,3.272,6.93'
          transform='translate(2.006 3.576)'
          fill={color}
        />
        <path
          id='Path_4819'
          data-name='Path 4819'
          d='M2.532,9.437h0l.682.184a1.152,1.152,0,0,0,1.411-.811A1.15,1.15,0,0,0,3.812,7.4L3.13,7.215a1.092,1.092,0,0,0-.3-.042,1.154,1.154,0,0,0-.572.154,1.14,1.14,0,0,0-.538.7,1.152,1.152,0,0,0,.811,1.411'
          transform='translate(1.258 5.368)'
          fill={color}
        />
        <path
          id='Path_4820'
          data-name='Path 4820'
          d='M8.884,6.981H9.1v.212A10.769,10.769,0,0,0,9.5,10.7a11.126,11.126,0,0,0,3.447,3.822l.206.168-.206.166A11.139,11.139,0,0,0,9.5,18.683a10.789,10.789,0,0,0-.4,3.51v.212H8.884a1.15,1.15,0,0,0,0,2.3H21.156a1.15,1.15,0,0,0,0-2.3h-.212l0-.212a10.769,10.769,0,0,0-.4-3.512A11.162,11.162,0,0,0,17.1,14.86l-.208-.166.208-.168A11.132,11.132,0,0,0,20.544,10.7a10.811,10.811,0,0,0,.4-3.51l0-.212h.212a1.15,1.15,0,0,0,0-2.3H8.885a1.15,1.15,0,0,0,0,2.3m9.538,12.585,0,0a11.266,11.266,0,0,1,.219,2.621l0,.215H11.4l0-.215a11.454,11.454,0,0,1,.22-2.621c.409-.979,1.815-2.171,3.271-3.315l.131-.1.133.1c1.458,1.145,2.862,2.337,3.267,3.311m.222-12.585,0,.215a11.454,11.454,0,0,1-.22,2.621c-.406.976-1.811,2.17-3.269,3.315l-.131.1-.133-.1c-1.458-1.145-2.862-2.337-3.267-3.311A11.056,11.056,0,0,1,11.4,7.2l0-.215Z'
          transform='translate(5.787 3.503)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconTxPending
