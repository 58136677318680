import React from 'react'
import { useMediaQuery } from 'react-responsive'
import useTheme from 'hooks/common/useTheme'

/**
 * Interfaces & types
 */
export interface IState {
  isDesktop: boolean
  isDesktop2: boolean
  isDesktop3: boolean
  isLaptop: boolean
  isTablet: boolean
  isMobile: boolean
  isPortrait: boolean
  isTouch: boolean
  isMinHeight720: boolean
  isMinHeight795: boolean
  isMinHeight900: boolean
  isMinHeight1200: boolean
}

/** @constant {IState} */
const initialState: IState = {
  isDesktop: false,
  isDesktop2: false,
  isDesktop3: false,
  isLaptop: false,
  isTablet: false,
  isMobile: false,
  isPortrait: false,
  isTouch: false,
  isMinHeight720: false,
  isMinHeight795: false,
  isMinHeight900: false,
  isMinHeight1200: false
}

/**
 * Responsive context init
 * @param initialState
 */
export const ResponsiveContext = React.createContext<IState>(initialState)

/**
 * ResponsiveContextProvider
 * @param {ReactElement} children
 * @return {ReactElement}
 */
const ResponsiveContextProvider: React.FC = ({ children }): React.ReactElement => {
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery({
    query: `(min-width: 0px)`
  })
  const isTablet = useMediaQuery({
    query: `(min-width: ${breakpoints[1]})`
  })
  const isLaptop = useMediaQuery({
    query: `(min-width: ${breakpoints[2]})`
  })
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints[3]})`
  })
  const isDesktop2 = useMediaQuery({
    query: `(min-width: ${breakpoints[4]})`
  })
  const isDesktop3 = useMediaQuery({
    query: `(min-width: ${breakpoints[5]})`
  })
  const isPortrait = useMediaQuery({
    query: '(orientation: portrait)'
  })
  const isTouch = useMediaQuery({
    query: '(hover: none)'
  })
  const isMinHeight720 = useMediaQuery({
    query: '(min-height: 720px)'
  })
  const isMinHeight795 = useMediaQuery({
    query: '(min-height: 795px)'
  })
  const isMinHeight900 = useMediaQuery({
    query: '(min-height: 900px)'
  })
  const isMinHeight1200 = useMediaQuery({
    query: '(min-height: 1200px)'
  })

  return (
    <ResponsiveContext.Provider
      value={{
        isDesktop,
        isDesktop2,
        isDesktop3,
        isLaptop,
        isTablet,
        isMobile,
        isPortrait,
        isTouch,
        isMinHeight720,
        isMinHeight795,
        isMinHeight900,
        isMinHeight1200
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  )
}

export default ResponsiveContextProvider
