import React from 'react'
import { AnimatePresence } from 'framer-motion'
// Hooks
import useTheme from 'hooks/common/useTheme'
import SimpleBar from 'modules/simplebar-react'
import { Box } from 'rebass/styled-components'

import { MotionFlex } from '../motion-box'

// Interfaces
import { IDrawerContext } from './interfaces'

import { CloseIcon } from './index.css'

export const Context = React.createContext<IDrawerContext>({
  onPresent: () => null,
  onDismiss: () => null
})

const sidebarWidth = 360

const DrawerContextProvider: React.FC = ({ children }): React.ReactElement => {
  const { variants, colors } = useTheme()
  const [left, setLeft] = React.useState<string>(variants.drawer.left)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [content, setContent] = React.useState<React.ReactNode>()
  const [activePane, setActivePane] = React.useState(0)

  const handlePresent = React.useCallback(
    (modalContent: React.ReactNode) => {
      setContent(modalContent)
      setLeft('0')
      setIsOpen(true)
    },
    [setContent]
  )

  const handleDismiss = React.useCallback(() => {
    setContent(undefined)
    setLeft(variants.drawer.left)
    setIsOpen(false)
  }, [variants.drawer.left])

  return (
    <Context.Provider
      value={{
        content,
        left,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        activePane,
        setActivePane
      }}
    >
      {children}
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <>
            <MotionFlex
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1
              }}
              exit={{
                opacity: 0,
                transition: { delay: 0.5 }
              }}
              alignItems='center'
              justifyContent='center'
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 2022,
                transition: 'transform 0.3s ease-in-out'
              }}
            >
              <Box
                onClick={handleDismiss}
                bg='backdrop'
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                }}
              />
              <MotionFlex
                initial={{
                  x: sidebarWidth
                }}
                animate={{
                  x: 0,
                  transition: {
                    delay: 0.3,
                    type: 'tween',
                    ease: 'easeIn'
                  }
                }}
                exit={{
                  x: sidebarWidth,
                  transition: {
                    delay: 0,
                    type: 'tween',
                    ease: 'easeOut'
                  }
                }}
                width={sidebarWidth}
                variant='drawer'
                sx={{
                  '.simplebar-track.simplebar-vertical': {
                    mr: 0
                  }
                }}
              >
                <SimpleBar
                  sx={{
                    maxHeight: '100vh',
                    width: '100%',
                    padding: 30
                  }}
                >
                  <CloseIcon cursor='pointer' color={colors.black} onClick={handleDismiss} />
                  {React.isValidElement(content) &&
                    React.cloneElement<any>(content, {
                      onDismiss: handleDismiss
                    })}
                </SimpleBar>
              </MotionFlex>
            </MotionFlex>
          </>
        )}
      </AnimatePresence>
    </Context.Provider>
  )
}

export default DrawerContextProvider
