import React from 'react'

interface IIconSuccess {
  color?: string
  size?: number
  strokeWidth?: number
}

const IconSuccess = React.forwardRef<SVGSVGElement, IIconSuccess>(({ color = 'currentColor', size = 52, strokeWidth = 0.25, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 52 52'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        id='Path_1219'
        data-name='Path 1219'
        d='M27.551-18.084A25.541,25.541,0,0,0,14.5-21.562,25.541,25.541,0,0,0,1.449-18.084,25.676,25.676,0,0,0-8.021-8.613,25.541,25.541,0,0,0-11.5,4.438,25.541,25.541,0,0,0-8.021,17.488a25.676,25.676,0,0,0,9.471,9.471A25.541,25.541,0,0,0,14.5,30.438a25.541,25.541,0,0,0,13.051-3.479,25.676,25.676,0,0,0,9.471-9.471A25.541,25.541,0,0,0,40.5,4.438,25.541,25.541,0,0,0,37.021-8.613,25.676,25.676,0,0,0,27.551-18.084ZM14.5,27.238A22.879,22.879,0,0,1,7.314,26.1,21.091,21.091,0,0,1,1.043,22.82a25.485,25.485,0,0,1-4.9-4.926,21.428,21.428,0,0,1-3.25-6.271A22.879,22.879,0,0,1-8.25,4.438,22.239,22.239,0,0,1-6.447-4.4,22.854,22.854,0,0,1-1.6-11.66a22.854,22.854,0,0,1,7.262-4.85,22.556,22.556,0,0,1,17.672,0A22.854,22.854,0,0,1,30.6-11.66,22.854,22.854,0,0,1,35.447-4.4a22.239,22.239,0,0,1,1.8,8.836,22.239,22.239,0,0,1-1.8,8.836,23.161,23.161,0,0,1-4.85,7.287,22.641,22.641,0,0,1-7.262,4.875A22.239,22.239,0,0,1,14.5,27.238Zm10.359-32.3L9.625,10.277,2.77,3.422a1.6,1.6,0,0,0-2.336,0,1.326,1.326,0,0,0-.2.279,1.86,1.86,0,0,0-.152.33A1.887,1.887,0,0,0,0,4.361a1.251,1.251,0,0,0,0,.355,2.665,2.665,0,0,0,.076.355A1.172,1.172,0,0,0,.23,5.4l.2.3L8.508,13.73a1.539,1.539,0,0,0,2.285,0l.2-.2,16.2-16.3a1.55,1.55,0,0,0,.457-1.143A1.55,1.55,0,0,0,27.2-5.059a1.458,1.458,0,0,0-1.98-.254A1.356,1.356,0,0,0,24.859-5.059Z'
        transform='translate(11.625 21.688)'
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
})

export default IconSuccess
