import React from 'react'

interface IIconFlashClaim {
  color?: string
  size?: number
}

const IconFlashClaim = React.forwardRef<SVGSVGElement, IIconFlashClaim>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 23.585 40.093' fill={color} stroke={color} {...rest}>
      <path
        id='Path_2483'
        data-name='Path 2483'
        d='M7.7,39.683a1.165,1.165,0,0,0,1.445-.451L23.127,17.014a1.165,1.165,0,0,0-.986-1.786H12.806L16.279,1.451A1.165,1.165,0,0,0,14.163.545L.179,22.764a1.165,1.165,0,0,0,.986,1.786H10.5L7.027,38.327A1.165,1.165,0,0,0,7.7,39.683ZM3.276,22.22,12.224,8l-2.044,8.107a1.165,1.165,0,0,0,1.13,1.45h8.72L11.082,31.777l2.044-8.107A1.165,1.165,0,0,0,12,22.22Z'
        transform='translate(0.139 0.158)'
        fill={color}
        stroke={color}
        strokeWidth='0.25'
      />
    </svg>
  )
})

export default IconFlashClaim
