import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { coinbaseWalletConnection, ConnectionType, getConnectionName } from 'wallet-module'

import Option from './option'

const BASE_PROPS = {
  color: '#315CF5',
  icon: '/images/walletCoinbaseIcon.svg',
  id: 'coinbase-wallet'
}

export function OpenCoinbaseWalletOption() {
  const isActive = coinbaseWalletConnection.hooks.useIsActive()
  return <Option {...BASE_PROPS} active={isActive} link='https://go.cb-w.com/mtUDhEZPy1' header='Open in Coinbase Wallet' />
}

export function CoinbaseWalletOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = coinbaseWalletConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      active={isActive}
      onClick={() =>
        tryActivation({
          connector: coinbaseWalletConnection.connector,
          type: ConnectionType.COINBASE_WALLET
        })
      }
      header={getConnectionName({ connectionType: ConnectionType.COINBASE_WALLET })}
    />
  )
}
