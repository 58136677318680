import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMore = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20.965 20.965' fill={color} {...rest}>
      <g id='ellipsis' transform='translate(2.483 8.983)'>
        <path id='Shape' d='M0,1.5A1.5,1.5,0,1,0,1.5,0,1.5,1.5,0,0,0,0,1.5Z' transform='translate(2)' fill={color} />
        <path id='Shape-2' data-name='Shape' d='M0,1.5A1.5,1.5,0,1,0,1.5,0,1.5,1.5,0,0,0,0,1.5Z' transform='translate(6.5)' fill={color} />
        <path id='Shape-3' data-name='Shape' d='M0,1.5A1.5,1.5,0,1,0,1.5,0,1.5,1.5,0,0,0,0,1.5Z' transform='translate(11)' fill={color} />
      </g>
      <path
        id='Path_2485'
        data-name='Path 2485'
        d='M-982.981-565.911a10.482,10.482,0,0,0-10.482-10.482,10.482,10.482,0,0,0-10.482,10.482,10.482,10.482,0,0,0,10.482,10.482A10.482,10.482,0,0,0-982.981-565.911Zm-1.9,0a8.58,8.58,0,0,1-8.58,8.58,8.58,8.58,0,0,1-8.58-8.58,8.58,8.58,0,0,1,8.58-8.58A8.58,8.58,0,0,1-984.883-565.911Z'
        transform='translate(576.393 -982.981) rotate(-90)'
        fill={color}
      />
    </svg>
  )
})

export default IconMore
