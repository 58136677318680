import React from 'react'

interface IIconLogo {
  color?: string
  size?: number
}

const IconLogoBig = React.forwardRef<SVGSVGElement, IIconLogo>(({ color = 'currentColor', size = 145.9, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 145.877 77.536'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='Group_3925' data-name='Group 3925' transform='translate(0.624 0.625)'>
        <g id='Group_3256' data-name='Group 3256' transform='translate(0)'>
          <line
            id='Line_28'
            data-name='Line 28'
            x1='11.054'
            y2='28.76'
            transform='translate(0.147 24.26)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_29'
            data-name='Line 29'
            x1='13.108'
            y2='26.119'
            transform='translate(0.147 26.901)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_30'
            data-name='Line 30'
            x1='16.336'
            y2='26.999'
            transform='translate(0 29.396)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_31'
            data-name='Line 31'
            x1='19.027'
            y2='27.586'
            transform='translate(0.978 31.744)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_32'
            data-name='Line 32'
            x1='21.032'
            y2='28.32'
            transform='translate(3.179 33.847)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_33'
            data-name='Line 33'
            x1='22.402'
            y2='29.298'
            transform='translate(6.31 35.706)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_34'
            data-name='Line 34'
            x1='22.776'
            y2='30.276'
            transform='translate(10.834 37.32)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_35'
            data-name='Line 35'
            x1='22.206'
            y2='31.157'
            transform='translate(16.288 38.64)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_36'
            data-name='Line 36'
            x1='20.739'
            y2='31.89'
            transform='translate(22.695 39.961)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_37'
            data-name='Line 37'
            x1='18.244'
            y2='32.673'
            transform='translate(30.13 40.939)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_38'
            data-name='Line 38'
            x1='15.016'
            y2='33.358'
            transform='translate(38.347 41.722)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_39'
            data-name='Line 39'
            x1='10.663'
            y2='33.651'
            transform='translate(47.298 42.26)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_40'
            data-name='Line 40'
            x1='5.772'
            y2='33.553'
            transform='translate(56.786 42.602)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_41'
            data-name='Line 41'
            x1='0.263'
            y2='33.39'
            transform='translate(66.469 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_42'
            data-name='Line 42'
            x2='5.674'
            y2='32.754'
            transform='translate(70.645 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_43'
            data-name='Line 43'
            x2='12.065'
            y2='31.45'
            transform='translate(74.101 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_44'
            data-name='Line 44'
            x2='18.652'
            y2='29.82'
            transform='translate(77.166 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_45'
            data-name='Line 45'
            x2='24.88'
            y2='27.782'
            transform='translate(80.134 42.586)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_46'
            data-name='Line 46'
            x2='31.499'
            y2='25.304'
            transform='translate(82.318 42.325)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_47'
            data-name='Line 47'
            x2='37.099'
            y2='22.565'
            transform='translate(84.478 41.933)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_48'
            data-name='Line 48'
            x2='42.484'
            y2='19.169'
            transform='translate(86.072 41.611)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_49'
            data-name='Line 49'
            x2='46.662'
            y2='15.603'
            transform='translate(87.65 41.232)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_50'
            data-name='Line 50'
            x2='50.721'
            y2='11.543'
            transform='translate(88.285 40.792)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_51'
            data-name='Line 51'
            x2='53.404'
            y2='7.488'
            transform='translate(88.733 40.396)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_52'
            data-name='Line 52'
            x2='55.122'
            y2='3.11'
            transform='translate(88.972 39.933)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_53'
            data-name='Line 53'
            y1='1.174'
            x2='55.515'
            transform='translate(89.117 38.151)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_54'
            data-name='Line 54'
            y1='5.453'
            x2='54.989'
            transform='translate(88.665 33.358)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_55'
            data-name='Line 55'
            y1='9.521'
            x2='53.216'
            transform='translate(88.057 28.63)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_56'
            data-name='Line 56'
            y1='13.63'
            x2='50.477'
            transform='translate(87.209 24.064)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_57'
            data-name='Line 57'
            y1='17.608'
            x2='47.216'
            transform='translate(85.71 19.63)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_58'
            data-name='Line 58'
            y1='21.032'
            x2='42.993'
            transform='translate(83.834 15.652)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_59'
            data-name='Line 59'
            y1='24.198'
            x2='38.004'
            transform='translate(81.78 11.886)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_60'
            data-name='Line 60'
            y1='26.755'
            x2='32.917'
            transform='translate(79.041 8.755)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_61'
            data-name='Line 61'
            y1='29.005'
            x2='27.195'
            transform='translate(76.155 6.016)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_62'
            data-name='Line 62'
            y1='31.01'
            x2='21.863'
            transform='translate(72.732 3.619)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_63'
            data-name='Line 63'
            y1='32.184'
            x2='16.19'
            transform='translate(68.868 2.054)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_64'
            data-name='Line 64'
            y1='33.211'
            x2='11.005'
            transform='translate(64.759 0.783)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_65'
            data-name='Line 65'
            y1='33.7'
            x2='6.114'
            transform='translate(60.161 0.147)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_66'
            data-name='Line 66'
            y1='33.945'
            x2='1.908'
            transform='translate(55.27)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_67'
            data-name='Line 67'
            x1='1.321'
            y1='33.651'
            transform='translate(48.765 0.342)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_68'
            data-name='Line 68'
            x1='4.206'
            y1='33.309'
            transform='translate(40.548 1.125)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_69'
            data-name='Line 69'
            x1='5.894'
            y1='32.477'
            transform='translate(33.358 2.348)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_70'
            data-name='Line 70'
            x1='7.043'
            y1='31.695'
            transform='translate(26.804 4.06)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_71'
            data-name='Line 71'
            x1='6.61'
            y1='29.3'
            transform='translate(21.276 6.065)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_72'
            data-name='Line 72'
            x1='5.001'
            y1='24.026'
            transform='translate(16.777 8.364)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_73'
            data-name='Line 73'
            x2='3.081'
            y2='18.537'
            transform='translate(13.255 10.858)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_74'
            data-name='Line 74'
            x2='0.929'
            y2='11.69'
            transform='translate(10.907 13.451)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_75'
            data-name='Line 75'
            x1='4.497'
            y2='18.576'
            transform='translate(5.051 21.684)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_76'
            data-name='Line 76'
            x1='1.277'
            y2='9.866'
            transform='translate(8.017 18.146)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_77'
            data-name='Line 77'
            y1='4.696'
            x2='1.614'
            transform='translate(9.293 13.451)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_78'
            data-name='Line 78'
            x1='2.348'
            y2='2.592'
            transform='translate(10.907 10.858)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_79'
            data-name='Line 79'
            x1='3.522'
            y2='2.494'
            transform='translate(13.255 8.364)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_80'
            data-name='Line 80'
            x1='4.5'
            y2='2.299'
            transform='translate(16.777 6.065)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_81'
            data-name='Line 81'
            x1='5.527'
            y2='2.005'
            transform='translate(21.276 4.06)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_82'
            data-name='Line 82'
            x1='6.554'
            y2='1.712'
            transform='translate(26.804 2.348)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_83'
            data-name='Line 83'
            x1='7.19'
            y2='1.223'
            transform='translate(33.358 1.125)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_84'
            data-name='Line 84'
            x1='8.217'
            y2='0.783'
            transform='translate(40.548 0.342)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_85'
            data-name='Line 85'
            x1='8.413'
            y2='0.342'
            transform='translate(48.765)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_86'
            data-name='Line 86'
            x1='9.098'
            y1='0.147'
            transform='translate(57.178)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_87'
            data-name='Line 87'
            x1='9.489'
            y1='0.636'
            transform='translate(66.275 0.147)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_88'
            data-name='Line 88'
            x1='9.293'
            y1='1.272'
            transform='translate(75.764 0.783)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_89'
            data-name='Line 89'
            x1='9.538'
            y1='1.565'
            transform='translate(85.057 2.054)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_90'
            data-name='Line 90'
            x1='8.755'
            y1='2.397'
            transform='translate(94.595 3.619)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_91'
            data-name='Line 91'
            x1='8.608'
            y1='2.739'
            transform='translate(103.35 6.016)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_92'
            data-name='Line 92'
            x1='7.826'
            y1='3.13'
            transform='translate(111.959 8.755)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_93'
            data-name='Line 93'
            x1='7.107'
            y1='3.791'
            transform='translate(119.721 11.86)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_94'
            data-name='Line 94'
            x1='6.098'
            y1='3.978'
            transform='translate(126.828 15.652)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_95'
            data-name='Line 95'
            x1='4.761'
            y1='4.435'
            transform='translate(132.925 19.63)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_96'
            data-name='Line 96'
            x1='3.587'
            y1='4.565'
            transform='translate(137.686 24.064)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_97'
            data-name='Line 97'
            x1='2.38'
            y1='4.728'
            transform='translate(141.273 28.63)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_98'
            data-name='Line 98'
            x1='0.978'
            y1='4.793'
            transform='translate(143.653 33.358)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_99'
            data-name='Line 99'
            y1='4.891'
            x2='0.538'
            transform='translate(144.094 38.151)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_100'
            data-name='Line 100'
            y1='4.842'
            x2='1.956'
            transform='translate(142.137 43.042)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_101'
            data-name='Line 101'
            y1='4.451'
            x2='3.13'
            transform='translate(139.007 47.885)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_102'
            data-name='Line 102'
            y1='4.5'
            x2='4.696'
            transform='translate(134.311 52.335)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_103'
            data-name='Line 103'
            y1='3.946'
            x2='5.755'
            transform='translate(128.556 56.835)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_104'
            data-name='Line 104'
            y1='3.717'
            x2='6.978'
            transform='translate(121.578 60.781)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_105'
            data-name='Line 105'
            y1='3.13'
            x2='7.761'
            transform='translate(113.817 64.498)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_106'
            data-name='Line 106'
            y1='2.739'
            x2='8.902'
            transform='translate(104.915 67.628)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_107'
            data-name='Line 107'
            y1='2.348'
            x2='9.098'
            transform='translate(95.818 70.367)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_108'
            data-name='Line 108'
            y1='1.63'
            x2='9.652'
            transform='translate(86.166 72.715)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_109'
            data-name='Line 109'
            y1='1.304'
            x2='9.848'
            transform='translate(76.319 74.346)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_110'
            data-name='Line 110'
            y1='0.636'
            x2='9.85'
            transform='translate(66.469 75.65)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_111'
            data-name='Line 111'
            x2='9.682'
            y2='0.131'
            transform='translate(56.786 76.155)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_112'
            data-name='Line 112'
            x2='9.653'
            y2='0.247'
            transform='translate(47.298 75.911)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_113'
            data-name='Line 113'
            x2='8.951'
            y2='0.831'
            transform='translate(38.347 75.079)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_114'
            data-name='Line 114'
            x2='8.217'
            y2='1.467'
            transform='translate(30.13 73.612)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_115'
            data-name='Line 115'
            x2='7.435'
            y2='1.761'
            transform='translate(22.695 71.851)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_116'
            data-name='Line 116'
            x2='6.407'
            y2='2.054'
            transform='translate(16.288 69.797)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_117'
            data-name='Line 117'
            x2='5.454'
            y2='2.201'
            transform='translate(10.834 67.596)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_118'
            data-name='Line 118'
            x2='4.524'
            y2='2.592'
            transform='translate(6.31 65.004)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_119'
            data-name='Line 119'
            x1='3.13'
            y1='2.837'
            transform='translate(3.179 62.167)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_120'
            data-name='Line 120'
            x1='2.201'
            y1='2.837'
            transform='translate(0.978 59.33)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_121'
            data-name='Line 121'
            x2='0.978'
            y2='2.935'
            transform='translate(0 56.395)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_122'
            data-name='Line 122'
            x1='0.147'
            y2='3.375'
            transform='translate(0 53.02)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_123'
            data-name='Line 123'
            x2='0.255'
            y2='3.538'
            transform='translate(9.293 18.146)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_124'
            data-name='Line 124'
            x1='1.652'
            y1='2.576'
            transform='translate(9.549 21.684)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_125'
            data-name='Line 125'
            x1='2.054'
            y1='2.641'
            transform='translate(11.201 24.26)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_126'
            data-name='Line 126'
            x1='3.081'
            y1='2.494'
            transform='translate(13.255 26.901)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_127'
            data-name='Line 127'
            x1='3.668'
            y1='2.348'
            transform='translate(16.337 29.396)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_128'
            data-name='Line 128'
            x1='4.206'
            y1='2.103'
            transform='translate(20.005 31.744)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_129'
            data-name='Line 129'
            x1='4.5'
            y1='1.859'
            transform='translate(24.211 33.847)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_130'
            data-name='Line 130'
            x1='4.899'
            y1='1.614'
            transform='translate(28.711 35.706)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_131'
            data-name='Line 131'
            x1='4.883'
            y1='1.321'
            transform='translate(33.61 37.32)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_132'
            data-name='Line 132'
            x1='4.94'
            y1='1.321'
            transform='translate(38.494 38.64)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_133'
            data-name='Line 133'
            x1='4.94'
            y1='0.978'
            transform='translate(43.434 39.961)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_134'
            data-name='Line 134'
            x1='4.989'
            y1='0.783'
            transform='translate(48.374 40.939)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_135'
            data-name='Line 135'
            x1='4.598'
            y1='0.538'
            transform='translate(53.363 41.722)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_136'
            data-name='Line 136'
            x1='4.598'
            y1='0.342'
            transform='translate(57.96 42.26)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_137'
            data-name='Line 137'
            x1='4.174'
            y1='0.293'
            transform='translate(62.558 42.602)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_138'
            data-name='Line 138'
            x1='3.913'
            transform='translate(66.732 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_139'
            data-name='Line 139'
            x1='3.456'
            transform='translate(70.645 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_140'
            data-name='Line 140'
            x1='3.065'
            transform='translate(74.101 42.895)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_141'
            data-name='Line 141'
            x1='2.967'
            y2='0.31'
            transform='translate(77.166 42.586)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_142'
            data-name='Line 142'
            x1='2.185'
            y2='0.261'
            transform='translate(80.134 42.325)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_143'
            data-name='Line 143'
            x1='2.16'
            y2='0.391'
            transform='translate(82.318 41.933)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_144'
            data-name='Line 144'
            x1='1.594'
            y2='0.322'
            transform='translate(84.478 41.611)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_145'
            data-name='Line 145'
            x1='1.577'
            y2='0.379'
            transform='translate(86.072 41.232)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_146'
            data-name='Line 146'
            x1='0.636'
            y2='0.44'
            transform='translate(87.65 40.792)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_147'
            data-name='Line 147'
            x1='0.448'
            y2='0.396'
            transform='translate(88.285 40.396)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_148'
            data-name='Line 148'
            x1='0.239'
            y2='0.464'
            transform='translate(88.733 39.933)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_149'
            data-name='Line 149'
            x1='0.145'
            y2='0.608'
            transform='translate(88.972 39.325)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_150'
            data-name='Line 150'
            x2='0.452'
            y2='0.515'
            transform='translate(88.665 38.81)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_151'
            data-name='Line 151'
            x2='0.607'
            y2='0.659'
            transform='translate(88.057 38.151)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_152'
            data-name='Line 152'
            x2='0.848'
            y2='0.457'
            transform='translate(87.209 37.695)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_153'
            data-name='Line 153'
            x2='1.5'
            y2='0.457'
            transform='translate(85.71 37.238)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_154'
            data-name='Line 154'
            x2='1.875'
            y2='0.554'
            transform='translate(83.834 36.684)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_155'
            data-name='Line 155'
            x2='2.054'
            y2='0.6'
            transform='translate(81.78 36.084)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_156'
            data-name='Line 156'
            x2='2.739'
            y2='0.574'
            transform='translate(79.041 35.51)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_157'
            data-name='Line 157'
            x2='2.886'
            y2='0.489'
            transform='translate(76.155 35.021)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_158'
            data-name='Line 158'
            x2='3.424'
            y2='0.391'
            transform='translate(72.732 34.629)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_159'
            data-name='Line 159'
            x2='3.864'
            y2='0.391'
            transform='translate(68.868 34.238)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_160'
            data-name='Line 160'
            x2='4.109'
            y2='0.245'
            transform='translate(64.759 33.994)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_161'
            data-name='Line 161'
            x2='4.598'
            y2='0.147'
            transform='translate(60.161 33.847)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_162'
            data-name='Line 162'
            y1='0.098'
            x2='4.891'
            transform='translate(55.27 33.847)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_163'
            data-name='Line 163'
            y1='0.049'
            x2='5.185'
            transform='translate(50.086 33.945)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_164'
            data-name='Line 164'
            y1='0.44'
            x2='5.331'
            transform='translate(44.754 33.994)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_165'
            data-name='Line 165'
            y1='0.391'
            x2='5.503'
            transform='translate(39.252 34.434)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_166'
            data-name='Line 166'
            y1='0.929'
            x2='5.405'
            transform='translate(33.847 34.825)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_167'
            data-name='Line 167'
            y1='0.646'
            x2='3.028'
            transform='translate(30.819 35.754)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <path
            id='Path_1170'
            data-name='Path 1170'
            d='M629.8,720.021l.919,2.92,1.466,2.524'
            transform='translate(-621.631 -693.181)'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_168'
            data-name='Line 168'
            x2='2.527'
            y2='2.492'
            transform='translate(10.554 32.284)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_169'
            data-name='Line 169'
            x2='3.075'
            y2='2.548'
            transform='translate(13.081 34.776)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_170'
            data-name='Line 170'
            x1='3.821'
            y1='2.224'
            transform='translate(16.156 37.325)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_171'
            data-name='Line 171'
            x1='4.297'
            y1='1.96'
            transform='translate(19.977 39.548)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_172'
            data-name='Line 172'
            x1='4.746'
            y1='1.913'
            transform='translate(24.274 41.508)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_173'
            data-name='Line 173'
            x1='4.972'
            y1='1.535'
            transform='translate(29.021 43.421)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_174'
            data-name='Line 174'
            x1='5.259'
            y1='1.436'
            transform='translate(33.992 44.956)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_175'
            data-name='Line 175'
            x1='5.471'
            y1='1.087'
            transform='translate(39.252 46.391)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_176'
            data-name='Line 176'
            x1='5.653'
            y1='0.88'
            transform='translate(44.722 47.478)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_177'
            data-name='Line 177'
            x1='5.464'
            y1='0.596'
            transform='translate(50.375 48.358)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_178'
            data-name='Line 178'
            x1='5.55'
            y1='0.447'
            transform='translate(55.839 48.955)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_179'
            data-name='Line 179'
            x1='5.212'
            y2='0.001'
            transform='translate(61.388 49.401)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_180'
            data-name='Line 180'
            x1='5.171'
            transform='translate(66.6 49.401)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_181'
            data-name='Line 181'
            x1='4.74'
            y2='0.223'
            transform='translate(71.772 49.178)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_182'
            data-name='Line 182'
            x1='4.311'
            y2='0.438'
            transform='translate(76.511 48.74)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_183'
            data-name='Line 183'
            x1='4.217'
            y2='0.677'
            transform='translate(80.822 48.064)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_184'
            data-name='Line 184'
            x1='3.47'
            y2='0.765'
            transform='translate(85.039 47.298)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_185'
            data-name='Line 185'
            x1='3.412'
            y2='0.838'
            transform='translate(88.509 46.46)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_186'
            data-name='Line 186'
            x1='2.501'
            y2='1.081'
            transform='translate(91.921 45.379)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_187'
            data-name='Line 187'
            x1='2.244'
            y2='1.132'
            transform='translate(94.422 44.247)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_188'
            data-name='Line 188'
            x1='1.569'
            y2='1.191'
            transform='translate(96.666 43.057)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_189'
            data-name='Line 189'
            x1='1.118'
            y2='1.171'
            transform='translate(98.235 41.885)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_190'
            data-name='Line 190'
            x1='0.687'
            y2='1.291'
            transform='translate(99.353 40.594)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_191'
            data-name='Line 191'
            x1='0.082'
            y2='1.527'
            transform='translate(100.041 39.068)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_192'
            data-name='Line 192'
            x2='0.567'
            y2='1.337'
            transform='translate(99.555 37.731)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_193'
            data-name='Line 193'
            x2='1.009'
            y2='1.456'
            transform='translate(98.546 36.274)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_194'
            data-name='Line 194'
            x2='1.451'
            y2='1.249'
            transform='translate(97.095 35.025)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_195'
            data-name='Line 195'
            x2='2.008'
            y2='1.284'
            transform='translate(95.087 33.741)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_196'
            data-name='Line 196'
            x2='2.751'
            y2='1.216'
            transform='translate(92.336 32.525)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_197'
            data-name='Line 197'
            x2='3.122'
            y2='1.174'
            transform='translate(89.214 31.351)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_198'
            data-name='Line 198'
            x2='3.681'
            y2='1.117'
            transform='translate(85.532 30.234)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_199'
            data-name='Line 199'
            x2='4.039'
            y2='0.907'
            transform='translate(81.493 29.327)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_200'
            data-name='Line 200'
            x2='4.493'
            y2='0.753'
            transform='translate(77.001 28.574)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_201'
            data-name='Line 201'
            x2='4.948'
            y2='0.667'
            transform='translate(72.053 27.907)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_202'
            data-name='Line 202'
            x2='5.101'
            y2='0.53'
            transform='translate(66.952 27.377)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_203'
            data-name='Line 203'
            x2='5.571'
            y2='0.251'
            transform='translate(61.381 27.125)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_204'
            data-name='Line 204'
            x2='5.727'
            y2='0.004'
            transform='translate(55.653 27.122)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_205'
            data-name='Line 205'
            y1='0.269'
            x2='5.827'
            transform='translate(49.826 27.122)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_206'
            data-name='Line 206'
            y1='0.486'
            x2='5.9'
            transform='translate(43.926 27.391)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_207'
            data-name='Line 207'
            y1='0.617'
            x2='5.824'
            transform='translate(38.103 27.876)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_208'
            data-name='Line 208'
            y1='1.009'
            x2='5.645'
            transform='translate(32.458 28.493)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_209'
            data-name='Line 209'
            y1='1.302'
            x2='5.6'
            transform='translate(26.857 29.502)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_210'
            data-name='Line 210'
            y1='1.678'
            x2='5.375'
            transform='translate(21.482 30.804)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_211'
            data-name='Line 211'
            x2='0.132'
            y2='2.46'
            transform='translate(6.886 32.682)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_212'
            data-name='Line 212'
            x1='0.904'
            y1='2.384'
            transform='translate(7.018 35.143)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_213'
            data-name='Line 213'
            x1='1.887'
            y1='2.656'
            transform='translate(7.922 37.527)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_214'
            data-name='Line 214'
            x1='2.603'
            y1='2.568'
            transform='translate(9.81 40.183)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_215'
            data-name='Line 215'
            x1='3.388'
            y1='2.421'
            transform='translate(12.413 42.751)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_216'
            data-name='Line 216'
            x1='3.988'
            y1='2.203'
            transform='translate(15.801 45.172)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_217'
            data-name='Line 217'
            x1='4.715'
            y1='2.05'
            transform='translate(19.789 47.374)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_218'
            data-name='Line 218'
            x1='5.065'
            y1='1.738'
            transform='translate(24.504 49.425)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_219'
            data-name='Line 219'
            x1='5.559'
            y1='1.57'
            transform='translate(29.569 51.163)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_220'
            data-name='Line 220'
            x1='5.96'
            y1='1.253'
            transform='translate(35.128 52.733)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_221'
            data-name='Line 221'
            x1='6.29'
            y1='1.03'
            transform='translate(41.088 53.986)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_222'
            data-name='Line 222'
            x1='6.308'
            y1='0.731'
            transform='translate(47.378 55.016)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_223'
            data-name='Line 223'
            x1='6.535'
            y1='0.438'
            transform='translate(53.687 55.747)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_224'
            data-name='Line 224'
            x1='6.379'
            y1='0.047'
            transform='translate(60.222 56.185)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_225'
            data-name='Line 225'
            x1='6.309'
            y2='0.266'
            transform='translate(66.6 55.966)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_226'
            data-name='Line 226'
            x1='6.026'
            y2='0.471'
            transform='translate(72.909 55.496)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_227'
            data-name='Line 227'
            x1='5.544'
            y2='0.812'
            transform='translate(78.935 54.683)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_228'
            data-name='Line 228'
            x1='5.653'
            y2='0.933'
            transform='translate(84.478 53.75)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_229'
            data-name='Line 229'
            x1='4.655'
            y2='1.41'
            transform='translate(90.132 52.341)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_230'
            data-name='Line 230'
            x1='4.45'
            y2='1.431'
            transform='translate(94.786 50.91)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_231'
            data-name='Line 231'
            x1='3.726'
            y2='1.677'
            transform='translate(99.236 49.233)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_232'
            data-name='Line 232'
            x1='3.14'
            y2='1.83'
            transform='translate(102.963 47.403)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_233'
            data-name='Line 233'
            x1='2.368'
            y2='2.017'
            transform='translate(106.103 45.386)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_234'
            data-name='Line 234'
            x1='1.644'
            y2='1.992'
            transform='translate(108.47 43.394)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_235'
            data-name='Line 235'
            x1='1.094'
            y2='2.207'
            transform='translate(110.115 41.187)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_236'
            data-name='Line 236'
            x1='0.034'
            y2='2.33'
            transform='translate(111.208 38.857)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_237'
            data-name='Line 237'
            x2='0.58'
            y2='2.228'
            transform='translate(110.662 36.629)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_238'
            data-name='Line 238'
            x2='1.344'
            y2='2.282'
            transform='translate(109.317 34.347)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_239'
            data-name='Line 239'
            x2='2.003'
            y2='2.081'
            transform='translate(107.314 32.266)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_240'
            data-name='Line 240'
            x2='2.781'
            y2='2.047'
            transform='translate(104.533 30.218)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_241'
            data-name='Line 241'
            x2='3.44'
            y2='1.977'
            transform='translate(101.093 28.241)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_242'
            data-name='Line 242'
            x2='4.177'
            y2='1.794'
            transform='translate(96.916 26.447)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_243'
            data-name='Line 243'
            x2='4.718'
            y2='1.631'
            transform='translate(92.198 24.816)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_244'
            data-name='Line 244'
            x2='5.184'
            y2='1.376'
            transform='translate(87.014 23.44)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_245'
            data-name='Line 245'
            x2='5.582'
            y2='1.151'
            transform='translate(81.432 22.289)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_246'
            data-name='Line 246'
            x2='6.077'
            y2='0.948'
            transform='translate(75.355 21.341)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_247'
            data-name='Line 247'
            x2='6.207'
            y2='0.595'
            transform='translate(69.149 20.747)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_248'
            data-name='Line 248'
            x2='6.552'
            y2='0.321'
            transform='translate(62.596 20.425)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_249'
            data-name='Line 249'
            x2='6.562'
            y2='0.078'
            transform='translate(56.034 20.348)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_250'
            data-name='Line 250'
            y1='0.238'
            x2='6.475'
            transform='translate(49.559 20.348)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_251'
            data-name='Line 251'
            y1='0.558'
            x2='6.483'
            transform='translate(43.076 20.586)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_252'
            data-name='Line 252'
            y1='0.829'
            x2='6.157'
            transform='translate(36.919 21.144)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_253'
            data-name='Line 253'
            y1='1.112'
            x2='5.888'
            transform='translate(31.032 21.973)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_254'
            data-name='Line 254'
            y1='1.423'
            x2='5.594'
            transform='translate(25.437 23.085)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_255'
            data-name='Line 255'
            y1='1.628'
            x2='4.961'
            transform='translate(20.476 24.508)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_256'
            data-name='Line 256'
            y1='2.013'
            x2='4.347'
            transform='translate(16.129 26.135)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_257'
            data-name='Line 257'
            y1='0.429'
            x2='0.804'
            transform='translate(15.325 28.149)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_258'
            data-name='Line 258'
            x2='0.269'
            y2='2.522'
            transform='translate(5.084 40.123)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_259'
            data-name='Line 259'
            x1='1.111'
            y1='3.066'
            transform='translate(5.353 42.646)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_260'
            data-name='Line 260'
            x1='2.147'
            y1='2.551'
            transform='translate(6.465 45.711)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_261'
            data-name='Line 261'
            x1='2.989'
            y1='2.564'
            transform='translate(8.612 48.262)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_262'
            data-name='Line 262'
            x1='3.702'
            y1='2.414'
            transform='translate(11.601 50.826)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_263'
            data-name='Line 263'
            x1='4.611'
            y1='2.284'
            transform='translate(15.304 53.24)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_264'
            data-name='Line 264'
            x1='5.227'
            y1='1.849'
            transform='translate(19.915 55.525)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_265'
            data-name='Line 265'
            x1='5.847'
            y1='1.724'
            transform='translate(25.142 57.374)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_266'
            data-name='Line 266'
            x1='6.408'
            y1='1.5'
            transform='translate(30.988 59.098)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_267'
            data-name='Line 267'
            x1='7.016'
            y1='1.006'
            transform='translate(37.396 60.598)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_268'
            data-name='Line 268'
            x1='7.175'
            y1='0.769'
            transform='translate(44.412 61.604)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_269'
            data-name='Line 269'
            x1='7.507'
            y1='0.368'
            transform='translate(51.587 62.373)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_270'
            data-name='Line 270'
            x1='7.506'
            y1='0.134'
            transform='translate(59.094 62.742)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_271'
            data-name='Line 271'
            x1='7.45'
            y2='0.319'
            transform='translate(66.6 62.557)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_272'
            data-name='Line 272'
            x1='7.298'
            y2='0.769'
            transform='translate(74.051 61.788)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_273'
            data-name='Line 273'
            x1='6.946'
            y2='1.101'
            transform='translate(81.349 60.687)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_274'
            data-name='Line 274'
            x1='6.783'
            y2='1.415'
            transform='translate(88.295 59.273)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_275'
            data-name='Line 275'
            x1='6.119'
            y2='1.783'
            transform='translate(95.077 57.49)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_276'
            data-name='Line 276'
            x1='5.486'
            y2='2.051'
            transform='translate(101.196 55.439)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_277'
            data-name='Line 277'
            x1='4.827'
            y2='2.349'
            transform='translate(106.683 53.089)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_278'
            data-name='Line 278'
            x1='4.055'
            y2='2.522'
            transform='translate(111.509 50.567)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_279'
            data-name='Line 279'
            x1='3.143'
            y2='2.851'
            transform='translate(115.564 47.716)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_280'
            data-name='Line 280'
            x1='2.223'
            y2='2.805'
            transform='translate(118.708 44.911)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_281'
            data-name='Line 281'
            x1='1.248'
            y2='3.105'
            transform='translate(120.931 41.806)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_282'
            data-name='Line 282'
            x1='0.199'
            y2='3.184'
            transform='translate(122.179 38.622)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_283'
            data-name='Line 283'
            x2='0.563'
            y2='3.134'
            transform='translate(121.814 35.488)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_284'
            data-name='Line 284'
            x2='1.783'
            y2='3.058'
            transform='translate(120.032 32.43)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_285'
            data-name='Line 285'
            x2='2.517'
            y2='2.919'
            transform='translate(117.515 29.511)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_286'
            data-name='Line 286'
            x2='3.479'
            y2='2.837'
            transform='translate(114.036 26.674)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_287'
            data-name='Line 287'
            x2='4.343'
            y2='2.64'
            transform='translate(109.693 24.034)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_288'
            data-name='Line 288'
            x2='5.102'
            y2='2.474'
            transform='translate(104.591 21.56)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_289'
            data-name='Line 289'
            x2='5.774'
            y2='2.123'
            transform='translate(98.817 19.437)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_290'
            data-name='Line 290'
            x2='6.283'
            y2='1.885'
            transform='translate(92.534 17.552)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_291'
            data-name='Line 291'
            x2='6.705'
            y2='1.499'
            transform='translate(85.828 16.053)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_292'
            data-name='Line 292'
            x2='7.19'
            y2='1.239'
            transform='translate(78.638 14.815)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_293'
            data-name='Line 293'
            x2='7.249'
            y2='0.829'
            transform='translate(71.389 13.985)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_294'
            data-name='Line 294'
            x2='7.535'
            y2='0.491'
            transform='translate(63.854 13.494)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_295'
            data-name='Line 295'
            x2='7.434'
            y2='0.005'
            transform='translate(56.42 13.489)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_296'
            data-name='Line 296'
            y1='0.25'
            x2='7.129'
            transform='translate(49.291 13.489)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_297'
            data-name='Line 297'
            y1='0.555'
            x2='7.08'
            transform='translate(42.211 13.74)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_298'
            data-name='Line 298'
            y1='1.062'
            x2='6.492'
            transform='translate(35.719 14.294)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_299'
            data-name='Line 299'
            y1='1.395'
            x2='6.095'
            transform='translate(29.624 15.356)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_300'
            data-name='Line 300'
            y1='1.665'
            x2='5.584'
            transform='translate(24.04 16.751)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_301'
            data-name='Line 301'
            y1='1.962'
            x2='4.763'
            transform='translate(19.277 18.415)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_302'
            data-name='Line 302'
            y1='1.92'
            x2='4.121'
            transform='translate(15.157 20.377)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_303'
            data-name='Line 303'
            y1='2.601'
            x2='3.339'
            transform='translate(11.817 22.297)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_304'
            data-name='Line 304'
            x1='0.228'
            y2='2.176'
            transform='translate(2.742 45.674)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_305'
            data-name='Line 305'
            x1='0.387'
            y1='3.375'
            transform='translate(2.742 47.849)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_306'
            data-name='Line 306'
            x1='1.616'
            y1='2.645'
            transform='translate(3.129 51.224)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_307'
            data-name='Line 307'
            x1='2.556'
            y1='2.749'
            transform='translate(4.745 53.869)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_308'
            data-name='Line 308'
            x1='3.475'
            y1='2.546'
            transform='translate(7.3 56.618)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_309'
            data-name='Line 309'
            x1='4.584'
            y1='2.417'
            transform='translate(10.775 59.164)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_310'
            data-name='Line 310'
            x1='5.256'
            y1='2.144'
            transform='translate(15.359 61.58)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_311'
            data-name='Line 311'
            x1='6.152'
            y1='1.864'
            transform='translate(20.616 63.724)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_312'
            data-name='Line 312'
            x1='6.966'
            y1='1.568'
            transform='translate(26.768 65.588)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_313'
            data-name='Line 313'
            x1='7.611'
            y1='1.261'
            transform='translate(33.734 67.156)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_314'
            data-name='Line 314'
            x1='8.063'
            y1='0.832'
            transform='translate(41.345 68.418)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_315'
            data-name='Line 315'
            x1='8.511'
            y1='0.32'
            transform='translate(49.408 69.25)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_316'
            data-name='Line 316'
            x1='8.602'
            y2='0.005'
            transform='translate(57.919 69.565)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_317'
            data-name='Line 317'
            x1='8.651'
            y2='0.53'
            transform='translate(66.521 69.034)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_318'
            data-name='Line 318'
            x1='8.6'
            y2='0.928'
            transform='translate(75.173 68.106)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_319'
            data-name='Line 319'
            x1='8.341'
            y2='1.313'
            transform='translate(83.772 66.793)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_320'
            data-name='Line 320'
            x1='7.987'
            y2='1.911'
            transform='translate(92.113 64.881)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_321'
            data-name='Line 321'
            x1='7.359'
            y2='2.361'
            transform='translate(100.1 62.521)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_322'
            data-name='Line 322'
            x1='6.69'
            y2='2.541'
            transform='translate(107.459 59.98)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_323'
            data-name='Line 323'
            x1='5.94'
            y2='3.02'
            transform='translate(114.15 56.96)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_324'
            data-name='Line 324'
            x1='4.761'
            y2='3.289'
            transform='translate(120.089 53.672)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_325'
            data-name='Line 325'
            x1='3.964'
            y2='3.656'
            transform='translate(124.85 50.016)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_326'
            data-name='Line 326'
            x1='2.697'
            y2='3.621'
            transform='translate(128.814 46.395)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_327'
            data-name='Line 327'
            x1='1.724'
            y2='3.965'
            transform='translate(131.511 42.43)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_328'
            data-name='Line 328'
            x1='0.294'
            y2='4.044'
            transform='translate(133.235 38.386)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_329'
            data-name='Line 329'
            x2='0.807'
            y2='3.944'
            transform='translate(132.722 34.442)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_330'
            data-name='Line 330'
            x2='1.974'
            y2='3.929'
            transform='translate(130.748 30.513)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_331'
            data-name='Line 331'
            x2='3.01'
            y2='3.762'
            transform='translate(127.738 26.751)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_332'
            data-name='Line 332'
            x2='4.132'
            y2='3.646'
            transform='translate(123.607 23.105)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_333'
            data-name='Line 333'
            x2='5.179'
            y2='3.344'
            transform='translate(118.428 19.761)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_334'
            data-name='Line 334'
            x2='6.146'
            y2='3.098'
            transform='translate(112.282 16.663)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_335'
            data-name='Line 335'
            x2='6.848'
            y2='2.605'
            transform='translate(105.434 14.058)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_336'
            data-name='Line 336'
            x2='7.457'
            y2='2.312'
            transform='translate(97.978 11.746)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_337'
            data-name='Line 337'
            x2='7.824'
            y2='1.827'
            transform='translate(90.153 9.919)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_338'
            data-name='Line 338'
            x2='8.266'
            y2='1.563'
            transform='translate(81.887 8.356)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_339'
            data-name='Line 339'
            x2='8.311'
            y2='0.971'
            transform='translate(73.576 7.386)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_340'
            data-name='Line 340'
            x2='8.49'
            y2='0.685'
            transform='translate(65.086 6.701)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_341'
            data-name='Line 341'
            x2='8.282'
            y2='0.049'
            transform='translate(56.804 6.652)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_342'
            data-name='Line 342'
            y1='0.391'
            x2='7.776'
            transform='translate(49.028 6.652)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_343'
            data-name='Line 343'
            y1='0.666'
            x2='7.649'
            transform='translate(41.379 7.043)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_344'
            data-name='Line 344'
            y1='1.026'
            x2='6.862'
            transform='translate(34.517 7.71)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_345'
            data-name='Line 345'
            y1='1.596'
            x2='6.32'
            transform='translate(28.197 8.736)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_346'
            data-name='Line 346'
            y1='1.823'
            x2='5.547'
            transform='translate(22.65 10.331)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_347'
            data-name='Line 347'
            y1='2.076'
            x2='4.652'
            transform='translate(17.998 12.154)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_348'
            data-name='Line 348'
            y1='2.302'
            x2='3.8'
            transform='translate(14.198 14.23)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_349'
            data-name='Line 349'
            y1='2.509'
            x2='2.847'
            transform='translate(11.352 16.533)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
          <line
            id='Line_350'
            data-name='Line 350'
            y1='2.517'
            x2='1.812'
            transform='translate(9.54 19.041)'
            fill='none'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconLogoBig
