import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconList = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 13, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12.817 12.817' fill='none' stroke={color} {...rest}>
      <g id='Group_7480' data-name='Group 7480' transform='translate(0.125 0.125)'>
        <g id='Group_5151' data-name='Group 5151'>
          <path
            id='Path_2391'
            data-name='Path 2391'
            d='M6.284,0a6.284,6.284,0,1,0,6.284,6.284A6.3,6.3,0,0,0,6.284,0m0,11.436a5.153,5.153,0,1,1,5.153-5.153,5.176,5.176,0,0,1-5.153,5.153'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2392'
            data-name='Path 2392'
            d='M10.293,7.923H9.017V6.647A.51.51,0,0,0,8.47,6.1a.545.545,0,0,0-.547.547V7.923H6.647A.51.51,0,0,0,6.1,8.47a.6.6,0,0,0,.547.608H7.923v1.215a.51.51,0,0,0,.547.547.545.545,0,0,0,.547-.547V9.078h1.276a.51.51,0,0,0,.547-.547.6.6,0,0,0-.547-.608'
            transform='translate(-2.186 -2.186)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconList
