import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconGithub = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 19, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 19.985 19.53'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='icon-social-github-white' transform='translate(0)'>
        <path
          id='Path_572'
          data-name='Path 572'
          d='M25.646,14.765A10.2,10.2,0,0,0,22,11.122,9.867,9.867,0,0,0,16.988,9.8a9.669,9.669,0,0,0-5.015,1.347A10.152,10.152,0,0,0,8.347,14.79,9.648,9.648,0,0,0,7,19.8a10.039,10.039,0,0,0,6.849,9.5.527.527,0,0,0,.52-.1.462.462,0,0,0,.173-.4V26.968l-.3.05a5.466,5.466,0,0,1-.727.05,3.715,3.715,0,0,1-.9-.1,1.791,1.791,0,0,1-.876-.4,1.667,1.667,0,0,1-.57-.8l-.173-.3a3.345,3.345,0,0,0-.4-.669,1.7,1.7,0,0,0-.57-.5l-.1-.074c-.05-.05-.124-.1-.173-.149a.753.753,0,0,1-.124-.173q-.037-.074.074-.149a.741.741,0,0,1,.372-.05l.248.05a1.721,1.721,0,0,1,.644.322,2.005,2.005,0,0,1,.62.669,2.634,2.634,0,0,0,.727.826,1.616,1.616,0,0,0,.851.273,3.568,3.568,0,0,0,.752-.074,2.8,2.8,0,0,0,.595-.2,2.166,2.166,0,0,1,.644-1.347,9.529,9.529,0,0,1-1.347-.223,5.509,5.509,0,0,1-1.223-.5,3.2,3.2,0,0,1-1.049-.876,4.164,4.164,0,0,1-.669-1.371,6.678,6.678,0,0,1-.273-1.942,3.884,3.884,0,0,1,1.024-2.693,3.546,3.546,0,0,1,.107-2.685,1.846,1.846,0,0,1,1.124.173c.5.2.851.372,1.1.5.223.149.421.248.545.347a9.144,9.144,0,0,1,4.99,0l.5-.322a9.022,9.022,0,0,1,1.2-.57,1.734,1.734,0,0,1,1.049-.149,3.463,3.463,0,0,1,.1,2.669,3.769,3.769,0,0,1,1.024,2.693,6.688,6.688,0,0,1-.273,1.966,3.749,3.749,0,0,1-.694,1.371,3.683,3.683,0,0,1-1.049.876,5.51,5.51,0,0,1-1.223.5,9.529,9.529,0,0,1-1.347.223,2.326,2.326,0,0,1,.669,1.842V28.8a.528.528,0,0,0,.173.4.636.636,0,0,0,.52.1,10.039,10.039,0,0,0,6.849-9.5,9.705,9.705,0,0,0-1.338-5.031Z'
          transform='translate(-7 -9.8)'
        />
      </g>
    </svg>
  )
})

export default IconGithub
