import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDework = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 16 }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 16.351' fill={color}>
      <g id='Group_6406' data-name='Group 6406' transform='translate(0)'>
        <path
          id='Path_2645'
          data-name='Path 2645'
          d='M15.378,5.047A8.175,8.175,0,0,0,7.833,0H5.544C5.5,0,5.448,0,5.4,0c-.664.006-1.181,0-1.6,0A7.944,7.944,0,0,0,2.12.081a2.772,2.772,0,0,0-1.4,4.563l0,0L4.014,8.166.7,11.718l.007.006A2.772,2.772,0,0,0,2.12,16.27a7.943,7.943,0,0,0,1.682.079c.415,0,.931-.007,1.594,0q.076,0,.147,0H7.833a8.175,8.175,0,0,0,7.545-11.3m-1.783,5.519a6.246,6.246,0,0,1-5.77,3.856H2.649a.814.814,0,0,1-.656-.38q-.058-.075-.116-.164h0v0a2.1,2.1,0,0,1,.273-.866L2.143,13l.41-.44h4.99a4.386,4.386,0,0,0,0-8.772H2.571l-.45-.482a2.017,2.017,0,0,1-.245-.83v0h0l0,0L1.9,2.446,1.9,2.434l0-.006a2.019,2.019,0,0,1,.138-.183A.975.975,0,0,1,2.339,2a.672.672,0,0,1,.31-.073H7.825a6.246,6.246,0,0,1,5.77,8.636M6.421,7.917l-2.05-2.2H7.544a2.456,2.456,0,1,1,0,4.912H4.353L6.421,8.415a.376.376,0,0,0,0-.5'
          transform='translate(0)'
          fill={color}
          fillRule='evenodd'
        />
      </g>
    </svg>
  )
})

export default IconDework
