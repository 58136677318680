const NotificationLoader = ({ size = 16, color = 'currentColor' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray={45}
    >
      <circle cx='12' cy='12' r='10'>
        <animateTransform
          attributeName='transform'
          begin='0s'
          dur='1s'
          type='rotate'
          from={`0 ${size} ${size}`}
          to={`360 ${size} ${size}`}
          repeatCount='indefinite'
        />
      </circle>
    </svg>
  )
}

export default NotificationLoader
