import React from 'react'

interface IIconYielding {
  color?: string
  size?: number
}

const IconYieldHub = React.forwardRef<SVGSVGElement, IIconYielding>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 41.26 41.266' stroke={color} fill={color} {...rest}>
      <path
        id='Path_2806'
        data-name='Path 2806'
        d='M38.427,15.7c-.312,0-.546-.156-.546-.468A18.241,18.241,0,0,0,25.469,1.569,5.883,5.883,0,0,1,26.8,4.691a.585.585,0,0,1-1.171,0c0-1.327-1.561-3.122-2.186-3.669-.546-.468.078-1.093.546-1.015A19.107,19.107,0,0,1,38.973,14.995a.575.575,0,0,1-.546.7M19.459,18.82a7.784,7.784,0,0,1,.234-12.255.585.585,0,0,1,.7,0,7.913,7.913,0,0,1,3.122,6.245A7.732,7.732,0,0,1,20.629,18.9v3.591a8.426,8.426,0,0,1,10.46-3.357.7.7,0,0,1,.39.624,8.4,8.4,0,0,1-10.85,7.025v6.167a.585.585,0,1,1-1.171,0V26.782a8.4,8.4,0,0,1-10.85-7.025c-.078-.312.078-.468.312-.624a8.614,8.614,0,0,1,7.572.468,8.1,8.1,0,0,1,2.966,2.888Zm.546-11.084a6.637,6.637,0,0,0,0,10.07,6.531,6.531,0,0,0,0-10.07m.624,17.8a7.168,7.168,0,0,0,9.6-5.464,7.246,7.246,0,0,0-9.6,5.464M.022,16.009A19.408,19.408,0,0,1,15.087,1.022a.6.6,0,1,1,.234,1.171A18.091,18.091,0,0,0,1.583,14.6a5.441,5.441,0,0,1,3.2-1.405.585.585,0,1,1,0,1.171c-1.405,0-3.2,1.639-3.747,2.264a.612.612,0,0,1-1.015-.624M15.946,39.974A19.236,19.236,0,0,1,1.115,24.986a.6.6,0,1,1,1.171-.234,17.647,17.647,0,0,0,12.333,13.66A5.778,5.778,0,0,1,13.37,35.29a.585.585,0,0,1,1.171,0c0,1.327,1.561,3.122,2.186,3.669.546.624-.156,1.171-.781,1.015m8.9-1.015a.594.594,0,0,1-.078-1.171A18.091,18.091,0,0,0,38.5,25.377,5.774,5.774,0,0,1,35.3,26.7a.585.585,0,0,1,0-1.171c1.405,0,3.2-1.561,3.747-2.186.468-.468,1.015,0,.937.624a19.491,19.491,0,0,1-6.371,10.752A18.6,18.6,0,0,1,25,38.881c-.078.078-.078.078-.156.078M9.779,20.068a7.246,7.246,0,0,0,9.6,5.464,7.174,7.174,0,0,0-9.6-5.464'
        transform='translate(0.636 0.63)'
        fill={color}
        stroke={color}
        strokeWidth='1.25'
        fillRule='evenodd'
      />
    </svg>
  )
})

export default IconYieldHub
