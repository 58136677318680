import React from 'react'
import { BLOCK_START_TIME } from 'constants/index'
import { useTimeCountUp } from 'hooks/common/useTimeCountdown'
import { Flex, FlexProps } from 'rebass/styled-components'
import { SkeletonLine } from 'theme/ui'

const DaysRunning: React.FC<FlexProps> = () => {
  const { d: days } = useTimeCountUp(BLOCK_START_TIME, 3600000)

  return <>{days ? <Flex sx={{ mx: 5 }}>{days}</Flex> : <SkeletonLine width={28} height={16} sx={{ opacity: 0.3, alignSelf: 'center', mx: 5 }} />}</>
}

export default DaysRunning
