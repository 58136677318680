import React from 'react'

interface IIconSearch {
  color?: string
  size?: number
}

const IconSearch = React.forwardRef<SVGSVGElement, IIconSearch>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='search'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M16.8,15.8l-3.1-3.1c1.8-2.2,1.7-5.4-0.1-7.6c-1-1.2-2.5-2-4.1-2.2C8,2.9,6.4,3.4,5.2,4.4c-1.2,1-2,2.5-2.2,4.1
            c-0.1,1.6,0.3,3.2,1.4,4.4c2.1,2.5,5.8,2.9,8.4,0.9l3.1,3.1c0.1,0.1,0.3,0.2,0.5,0.2l0.1,0v0c0.1,0,0.3-0.1,0.4-0.2
            c0.1-0.1,0.2-0.3,0.2-0.5C17,16.1,16.9,15.9,16.8,15.8z M9,13.7C9,13.7,9,13.7,9,13.7c-1.2,0-2.4-0.5-3.3-1.4
            c-0.9-0.9-1.4-2-1.4-3.3c0-1.2,0.5-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4c0,0,0,0,0,0c0.6,0,1.2,0.1,1.8,0.4c0.5,0.2,1,0.6,1.5,1
            c0.9,0.9,1.4,2,1.4,3.3c0,1.2-0.5,2.4-1.4,3.3C11.4,13.2,10.2,13.7,9,13.7z'
        />
      </g>
    </svg>
  )
})

export default IconSearch
