export enum ChainId {
  MAINNET = 1,
  SEPOLIA = 11155111,
  MERLIN = 4200,
  MERLIN_TESTNET = 686868
}

export const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.SEPOLIA]: 'Sepolia',
  [ChainId.MERLIN]: 'Merlin',
  [ChainId.MERLIN_TESTNET]: 'Merlin Testnet'
}
