import React from 'react'

interface IIconNftVault {
  color?: string
  size?: number
}

const IconNftVault = React.forwardRef<SVGSVGElement, IIconNftVault>(({ color = 'currentColor', size = 45, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 32.839 37.918' fill={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2361' data-name='Rectangle 2361' width='32.839' height='37.918' fill='none' />
        </clipPath>
      </defs>
      <path
        id='Path_2472'
        data-name='Path 2472'
        d='M2.884,26.774V11.143L16.419,3.33l8.157,4.709.018.03.018-.01,5.343,3.086V26.774L16.419,34.588ZM16.419,0,0,9.479V28.439l16.419,9.479,16.419-9.482V9.479Z'
        fill={color}
      />
      <g id='Group_6003' data-name='Group 6003'>
        <g id='Group_6002' data-name='Group 6002' clipPath='url(clip-path)'>
          <path
            id='Path_2473'
            data-name='Path 2473'
            d='M90.991,97.506a6,6,0,0,0-3.63-2.1V91.32a.978.978,0,1,0-1.955,0v4.09a6,6,0,0,0-3.63,2.1l-3.543-2.045a.978.978,0,0,0-.978,1.695L80.8,99.2a5.978,5.978,0,0,0,0,4.192l-3.54,2.045a.978.978,0,1,0,.977,1.694l3.542-2.046a6.011,6.011,0,0,0,3.63,2.1v4.09a.978.978,0,1,0,1.955,0v-4.09a6.007,6.007,0,0,0,3.63-2.1l3.542,2.046.063.036H94.6a.978.978,0,0,0,.91-1.73l-3.54-2.045a5.979,5.979,0,0,0,0-4.192l3.54-2.044.133-.077v-.015a.981.981,0,0,0,.225-1.244.978.978,0,0,0-1.336-.359Zm-4.607,7.8a4.009,4.009,0,1,1,4.009-4.009,4.014,4.014,0,0,1-4.009,4.009'
            transform='translate(-69.964 -82.337)'
            fill={color}
          />
          <path
            id='Path_2474'
            data-name='Path 2474'
            d='M175.247,202.919a.978.978,0,1,0,.978.978.979.979,0,0,0-.978-.978'
            transform='translate(-158.827 -184.938)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconNftVault
