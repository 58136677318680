import { calculateGasMargin } from 'wallet-module'

/**
 * @dev change eth into weth
 * @param contract The contract
 * @param amount The amount to be exchanged
 **/
export const deposit = async (contract: any, amount: string) => {
  console.debug('util deposit data', amount)
  const estimatedGas = await contract.estimateGas
    .deposit({
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.deposit({
        value: amount
      })
    })

  return contract
    .deposit({
      gasLimit: calculateGasMargin(estimatedGas),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to deposit', error)
      throw error
    })
}

/**
 * @dev change weth into eth
 * @param contract The contract
 * @param amount The amount to be exchanged
 * @return
 **/
export const withdraw = async (contract: any, amount: string) => {
  console.debug('util withdraw data', amount)
  const estimatedGas = await contract.estimateGas.withdraw(amount).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.withdraw(amount)
  })

  return contract
    .withdraw(amount, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to withdraw', error)
      throw error
    })
}
