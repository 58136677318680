import React from 'react'

interface IIconElementDark {
  color?: string
  size?: number
}

const IconElementDark = React.forwardRef<SVGSVGElement, IIconElementDark>(({ size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 32 32' strokeLinecap='round' strokeLinejoin='round' {...rest}>
      <g id='Group_3089' data-name='Group 3089' transform='translate(-749 -128)'>
        <g id='Rectangle_10' data-name='Rectangle 10' transform='translate(749 128)' fill='none' stroke='#000' strokeWidth='1'>
          <rect width='32' height='32' rx='16' stroke='none' />
          <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' fill='none' />
        </g>
        <g id='Group_3092' data-name='Group 3092' transform='translate(1)'>
          <path id='Path' d='M0,1.25A1.25,1.25,0,1,0,1.25,0,1.25,1.25,0,0,0,0,1.25Z' transform='translate(758 138)' fill='#040404' />
          <path
            id='Path-2'
            data-name='Path'
            d='M7.68,1.433H.716A.717.717,0,0,1,0,.716H0A.717.717,0,0,1,.716,0H7.682A.717.717,0,0,1,8.4.716h0A.718.718,0,0,1,7.68,1.433Z'
            transform='translate(762.602 138.393)'
            fill='#040404'
          />
          <path id='Path-3' data-name='Path' d='M0,1.25A1.25,1.25,0,1,0,1.25,0,1.25,1.25,0,0,0,0,1.25Z' transform='translate(758 143.25)' fill='#040404' />
          <path
            id='Path-4'
            data-name='Path'
            d='M7.68,1.433H.716A.717.717,0,0,1,0,.716H0A.717.717,0,0,1,.716,0H7.682A.717.717,0,0,1,8.4.716h0A.719.719,0,0,1,7.68,1.433Z'
            transform='translate(762.602 143.64)'
            fill='#040404'
          />
          <path id='Path-5' data-name='Path' d='M0,1.25A1.25,1.25,0,1,0,1.25,0,1.25,1.25,0,0,0,0,1.25Z' transform='translate(758 148.5)' fill='#040404' />
          <path
            id='Path-6'
            data-name='Path'
            d='M7.68,1.433H.716A.717.717,0,0,1,0,.716H0A.717.717,0,0,1,.716,0H7.682A.717.717,0,0,1,8.4.716h0A.718.718,0,0,1,7.68,1.433Z'
            transform='translate(762.602 148.896)'
            fill='#040404'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconElementDark
