import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconTwitterX = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'white', size = 16, ...rest }, ref) => {
  return (
    <svg ref={ref} id='icon-social-twitter-white' xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 15.5' {...rest}>
      <path
        id='Path_5177'
        data-name='Path 5177'
        d='M2.779,1.447h1.46L13.277,14.1H11.818ZM13.626,0,8.9,5.382,5.116,0H.068L6.089,8.578,0,15.5H1.951l5-5.692,4,5.692H16L9.762,6.615,15.578,0Z'
        fill={color}
      />
    </svg>
  )
})

export default IconTwitterX
