import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { ConnectionType, getConnectionName, walletConnectConnectionV2 } from 'wallet-module'

import Option from './option'

const BASE_PROPS = {
  color: '#4196FC',
  icon: '/images/walletConnectIcon.svg',
  id: 'wallet-connect'
}

export function WalletConnectV2Option({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = walletConnectConnectionV2.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      active={isActive}
      onClick={() =>
        tryActivation({
          connector: walletConnectConnectionV2.connector,
          type: ConnectionType.WALLET_CONNECT_V2
        })
      }
      header={getConnectionName({ connectionType: ConnectionType.WALLET_CONNECT_V2 })}
    />
  )
}
