import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMail = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'white', size = 37, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      id='earth'
      height={size}
      viewBox='0 0 37.152 37.152'
      fill={color}
      stroke={color}
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M18.5,37C8.3,37,0,28.7,0,18.5c0-4.9,1.9-9.6,5.4-13.1c7.2-7.2,18.9-7.2,26.2,0s7.2,18.9,0,26.2
		C28.1,35.1,23.4,37,18.5,37z M9.1,31.4L9.1,31.4c1.5,1.1,3.3,2,5.1,2.5c-1.2-1.1-2.2-2.3-2.9-3.8C10.6,30.4,9.9,30.9,9.1,31.4
		C9.2,31.4,9.1,31.4,9.1,31.4z M25.7,30.1L25.7,30.1c-0.7,1.4-1.7,2.7-2.9,3.8c1.8-0.5,3.6-1.3,5.1-2.5h0
		C27.1,30.9,26.4,30.4,25.7,30.1z M17.4,28.4c-1.3,0.1-2.5,0.3-3.7,0.7c0.7,1.6,2.1,2.9,3.7,3.6V28.4z M20,28.4v4.2
		c1.5-0.8,2.7-2,3.4-3.5C22.2,28.8,21.1,28.5,20,28.4L20,28.4z M2.6,19.3c0.2,3.9,1.8,7.6,4.5,10.4c1-0.8,2-1.4,3.1-2
		c-1-2.7-1.5-5.5-1.5-8.4L2.6,19.3z M28.2,19.3L28.2,19.3c-0.1,2.9-0.6,5.7-1.5,8.4c1.1,0.5,2.2,1.2,3.1,2c2.7-2.8,4.3-6.5,4.5-10.4
		L28.2,19.3z M20,19.3v6.5c1.5,0.1,3,0.4,4.4,0.9c0.8-2.4,1.2-4.9,1.3-7.4L20,19.3z M11.3,19.3c0,2.5,0.5,5,1.3,7.4
		c1.5-0.5,3.1-0.8,4.8-0.9v-6.5H11.3z M7.2,7.4c-2.5,2.5-4.1,5.8-4.5,9.3h6.1c0.1-2.5,0.6-5,1.5-7.4C9.2,8.8,8.1,8.1,7.2,7.4
		L7.2,7.4z M12.6,10.3L12.6,10.3c-0.7,2.1-1.1,4.2-1.2,6.4h6v-5.5C15.7,11.1,14.1,10.8,12.6,10.3L12.6,10.3z M24.4,10.3
		c-1.4,0.5-2.9,0.8-4.4,0.9v5.5h5.6C25.5,14.5,25.1,12.4,24.4,10.3L24.4,10.3L24.4,10.3z M29.8,7.4L29.8,7.4c-1,0.8-2,1.4-3.1,2
		c0.8,2.4,1.3,4.9,1.5,7.4h6.1C33.9,13.2,32.3,9.9,29.8,7.4z M17.4,4.3L17.4,4.3c-1.7,0.7-3,2-3.7,3.6c1.2,0.4,2.5,0.6,3.7,0.7V4.3z
		 M20,4.4L20,4.4v4.2c1.1-0.1,2.3-0.3,3.4-0.7C22.6,6.4,21.5,5.2,20,4.4L20,4.4z M14.2,3.2c-1.8,0.5-3.6,1.3-5.1,2.5h0.1
		c0.7,0.5,1.4,0.9,2.1,1.3C12.1,5.5,13,4.2,14.2,3.2L14.2,3.2z M22.7,3.2L22.7,3.2c1.2,1.1,2.2,2.3,2.9,3.8c0.7-0.4,1.5-0.8,2.1-1.3
		h0C26.3,4.5,24.6,3.7,22.7,3.2z'
        />
      </g>
    </svg>
  )
})

export default IconMail
