import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconNftNew = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 23.975 20.632' fill={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_4267' data-name='Rectangle 4267' width='23.975' height='20.632' transform='translate(0 0)' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_14672' data-name='Group 14672' transform='translate(0 0)' clipPath='url(#clip-path)'>
        <path id='Path_4116' data-name='Path 4116' d='M21.676,12.361a.563.563,0,1,0-.275,1.092l.013,0a.584.584,0,0,0,.125.014.563.563,0,0,0,.137-1.109' />
        <path
          id='Path_4117'
          data-name='Path 4117'
          d='M13.979,12.361a.563.563,0,0,0,.563.562H14.9v3.153a.564.564,0,0,0,.563.562.562.562,0,0,0,.563-.562V12.923h.37a.563.563,0,0,0,0-1.126H14.542a.565.565,0,0,0-.563.564'
        />
        <path
          id='Path_4118'
          data-name='Path 4118'
          d='M8.5,8.946,11.7,10.8l.023.013h0a.561.561,0,0,0,.54-.013l3.2-1.858a.565.565,0,0,0,.281-.487V4.744a.565.565,0,0,0-.281-.487L12.27,2.4a.565.565,0,0,0-.565,0L8.5,4.257a.563.563,0,0,0-.281.487V8.459a.563.563,0,0,0,.281.487m.845-.811V5.068l2.642-1.531L14.63,5.068V8.135L11.988,9.666Z'
        />
        <path
          id='Path_4119'
          data-name='Path 4119'
          d='M12.684,12.923a.563.563,0,0,0,0-1.126H11.29a.563.563,0,0,0-.562.563v3.716a.562.562,0,0,0,.562.562h0a.563.563,0,0,0,.563-.563v-1.33h.72a.563.563,0,0,0,0-1.125h-.72v-.7Z'
        />
        <path
          id='Path_4120'
          data-name='Path 4120'
          d='M23.628,4.361h0a1.55,1.55,0,0,0-.758-.444L18.82,2.909V1.492A1.5,1.5,0,0,0,17.329,0H6.646A1.5,1.5,0,0,0,5.155,1.492V2.909L1.1,3.917A1.376,1.376,0,0,0,0,5.279a2.717,2.717,0,0,0,.035.39c0,.015.006.031.009.045l3.468,13.77.006.022a1.479,1.479,0,0,0,1.794,1.078l6.676-1.609,6.671,1.607a1.228,1.228,0,0,0,.352.05h.007a1.515,1.515,0,0,0,1.444-1.147l1.113-4.418a.563.563,0,0,0-1.092-.272l-1.117,4.434a.36.36,0,0,1-.439.26l-2.2-.531h.6a1.5,1.5,0,0,0,1.491-1.491V10.888l.434-.092a.562.562,0,0,0,.416-.365l1.069-3.065a.564.564,0,0,0-.112-.56L18.82,4.783V4.07l3.762.935c.236.065.3.187.253.445L21.5,10.742a.563.563,0,0,0,.41.683.574.574,0,0,0,.682-.408l1.337-5.31.005-.025a2.2,2.2,0,0,0,.038-.4,1.331,1.331,0,0,0-.347-.918M19.567,7.309,18.82,9.452V6.473ZM6.281,17.467V1.493a.367.367,0,0,1,.366-.366H17.329a.367.367,0,0,1,.365.366V17.467a.367.367,0,0,1-.365.366H6.647a.367.367,0,0,1-.366-.366m-1.21,2.118-.032-.094a.362.362,0,0,1-.433-.276L1.145,5.479c0-.039-.007-.077-.011-.114-.021-.173-.031-.259.258-.359L5.156,4.07v.713L3.348,6.806a.561.561,0,0,0-.111.56L4.3,10.431a.563.563,0,0,0,.416.366l.434.091v6.579a1.5,1.5,0,0,0,1.492,1.492h.6l-2.2.53ZM4.408,7.309l.747-.836V9.452Z'
        />
        <path
          id='Path_4121'
          data-name='Path 4121'
          d='M7.576,16.638h0a.563.563,0,0,0,.563-.563V14.783l.72,1.46a.6.6,0,0,0,.676.338A.614.614,0,0,0,10,15.95V12.36a.563.563,0,0,0-1.126,0v1.361l-.79-1.6a.563.563,0,0,0-1.068.249v3.708a.564.564,0,0,0,.563.563'
        />
      </g>
    </svg>
  )
})

export default IconNftNew
