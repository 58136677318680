import styled from 'styled-components'
/* const animParticle = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2560px);
  }
` */

const ParticlesWrapper = styled.div`
  width: 100%;
  background: #0f1826 url('/images/space.jpg') no-repeat top center;
  background-size: cover;
  color: white;
  overflow: hidden;
`

/* const particle = `
  background: transparent;
  border-radius: 100px;
  z-index: 10;

  &:after {
    background: transparent;
    border-radius: 100px;
    position: absolute;
    content: "";
    top: 2560px;
  }
` */

/* const Particle1 = styled.div`
  ${particle}

  animation: ${animParticle} 40s linear infinite;
  box-shadow: 0px 0px #ffffff, 503px 1453px #00bc64, 371px 132px #ef5350, 953px 1161px #ffffff, 1239px 2237px #ef5350, 1778px 1579px #ef5350,
    392px 2488px #ef5350, 2072px 619px #ffc400, 531px 1190px #0057ff, 412px 1046px #00bc64, 794px 621px #ffffff, 2357px 227px #00bc64, 654px 2327px #ffc400,
    90px 2192px #00bc64, 1607px 1717px #ef5350, 666px 1460px #00bc64, 825px 723px #ffc400, 1273px 1775px #ffc400, 2390px 2344px #ffc400, 1210px 1570px #00bc64,
    1928px 716px #0057ff, 1112px 680px #00bc64, 1567px 1692px #00bc64, 1197px 1190px #0057ff, 717px 1116px #ffffff, 1735px 1490px #ffffff, 118px 1787px #00bc64,
    972px 590px #ffffff, 1965px 1625px #00bc64, 1336px 2360px #00bc64, 231px 88px #ffc400, 1993px 955px #ffffff, 613px 2189px #ffffff, 1264px 223px #0057ff,
    2157px 737px #ffc400, 928px 1518px #ef5350, 34px 1214px #ef5350, 1579px 819px #ef5350, 2110px 384px #00bc64, 1670px 1106px #ffffff, 2521px 1534px #0057ff,
    1567px 1199px #ffffff, 2242px 519px #ffc400, 1685px 2086px #00bc64, 731px 1882px #00bc64, 430px 1321px #ffffff, 1242px 165px #0057ff, 1044px 988px #00bc64,
    112px 1857px #ffc400, 2388px 1861px #ffc400, 1805px 109px #ef5350, 2497px 1335px #ef5350, 220px 1080px #ffffff, 2407px 974px #ffffff, 2555px 880px #ffc400,
    1555px 332px #ffc400, 744px 157px #ffc400, 1264px 489px #ffffff, 2437px 630px #0057ff, 2343px 1250px #ffc400, 1651px 2217px #ffc400, 1697px 986px #ffc400,
    2187px 1175px #0057ff, 199px 282px #0057ff, 1231px 647px #ffc400, 2224px 1980px #00bc64, 24px 27px #ffc400, 141px 855px #00bc64, 2084px 679px #ef5350,
    1179px 1455px #ffc400, 2130px 2131px #00bc64, 1274px 51px #ffffff, 2383px 1131px #00bc64, 2156px 1742px #0057ff, 1924px 2124px #ffc400, 917px 1253px #ef5350,
    2131px 1290px #ef5350, 1292px 1532px #ffc400, 1067px 1682px #ef5350, 933px 1523px #ef5350, 1958px 1856px #ffffff, 1583px 1819px #ef5350,
    242px 2252px #0057ff, 2046px 2023px #ffffff, 1591px 1090px #00bc64, 2282px 886px #ffc400, 1188px 925px #ef5350, 1080px 2492px #ef5350, 1449px 2043px #ffc400,
    1164px 568px #ef5350, 1017px 371px #0057ff, 1192px 1207px #ffffff, 989px 305px #ffc400, 1201px 805px #ffffff, 968px 167px #00bc64, 1512px 565px #ffc400,
    1551px 2406px #ffffff, 2160px 1670px #ef5350, 265px 2524px #ffffff, 1878px 1124px #ffc400, 837px 1880px #0057ff, 1700px 1472px #ffc400, 237px 2221px #00bc64,
    607px 1346px #ffffff, 847px 196px #ffc400, 2021px 1488px #00bc64, 1672px 1532px #00bc64, 1878px 1098px #ef5350, 1775px 606px #ffc400, 2231px 816px #ffc400,
    1319px 1737px #ffc400, 1178px 816px #0057ff, 1864px 1551px #ffc400, 442px 2549px #ef5350, 1608px 2471px #00bc64, 2381px 1957px #ffc400, 1840px 240px #ffffff,
    1246px 1550px #00bc64, 1065px 1313px #ffc400, 1738px 1876px #00bc64, 360px 788px #ffffff, 740px 1776px #00bc64, 2065px 2488px #ffc400, 1512px 2378px #00bc64,
    1391px 1992px #ffffff, 1055px 622px #ffffff, 653px 1905px #ef5350, 1138px 1172px #ef5350, 1395px 86px #0057ff, 393px 914px #ffffff, 1284px 2493px #ffffff,
    2269px 1889px #00bc64, 1837px 2478px #0057ff, 804px 1980px #00bc64, 619px 1000px #ffc400, 1119px 1644px #ef5350, 967px 905px #ef5350, 1827px 1518px #ffc400,
    722px 2260px #ffc400, 703px 2425px #0057ff, 1328px 526px #0057ff, 2133px 2526px #00bc64, 1285px 415px #0057ff, 2514px 1303px #ffffff, 1888px 20px #00bc64,
    668px 876px #00bc64, 285px 1403px #0057ff, 1929px 1150px #00bc64, 841px 1536px #ef5350, 2068px 245px #ffc400, 531px 143px #0057ff, 348px 1227px #ef5350,
    1458px 891px #00bc64, 2071px 1629px #ef5350, 1076px 780px #0057ff, 871px 1389px #ffc400, 1685px 1140px #ffc400, 2547px 431px #ffc400, 1769px 1142px #ef5350,
    738px 246px #00bc64, 1213px 1980px #ffffff, 498px 1608px #ffffff, 703px 526px #0057ff, 1411px 1005px #ffffff, 934px 2082px #ffffff, 709px 2342px #00bc64,
    2162px 850px #ef5350, 1144px 1625px #ffffff, 1549px 290px #ef5350, 2068px 273px #ffffff, 2238px 2510px #ffffff, 2140px 1801px #ef5350, 152px 942px #ef5350,
    2206px 252px #00bc64, 679px 1858px #ffffff, 1297px 1111px #ffffff, 965px 2305px #ef5350, 1631px 840px #ffc400, 706px 270px #ffffff, 1888px 453px #ffc400,
    818px 2210px #ffffff, 1901px 840px #ef5350, 2079px 2278px #ffc400, 2073px 1336px #ef5350, 2271px 185px #ffc400, 673px 1589px #00bc64, 244px 1996px #0057ff,
    1189px 1845px #ffffff, 2403px 886px #ef5350, 1207px 1470px #0057ff, 692px 470px #ffffff, 1895px 1965px #ffc400, 1179px 571px #ef5350, 714px 2031px #00bc64,
    705px 2132px #00bc64, 2385px 1713px #0057ff, 1416px 2173px #00bc64, 1245px 1462px #ffffff, 2470px 1382px #ef5350, 456px 691px #ef5350, 943px 355px #00bc64,
    501px 696px #0057ff, 2471px 211px #ef5350, 210px 495px #ef5350, 933px 2267px #0057ff, 1589px 411px #0057ff, 1478px 2469px #0057ff, 2192px 1582px #00bc64,
    1369px 2520px #0057ff, 2549px 482px #ef5350, 1820px 2477px #ffc400, 444px 310px #ffffff, 80px 1974px #ffffff, 285px 743px #ffc400, 1513px 883px #ef5350,
    1877px 505px #ffc400, 97px 133px #ef5350, 24px 1093px #0057ff, 1051px 27px #0057ff, 1734px 786px #00bc64, 1011px 2462px #00bc64, 1865px 306px #ffc400,
    1205px 1084px #ef5350, 2192px 2121px #ef5350, 1875px 897px #00bc64, 739px 281px #ffffff, 2223px 557px #ef5350, 674px 387px #ffc400, 875px 1929px #00bc64,
    292px 2137px #00bc64, 2171px 2218px #0057ff, 1684px 2520px #ffc400, 1812px 228px #00bc64, 1416px 1497px #ffffff, 464px 1642px #00bc64, 1346px 280px #ef5350,
    817px 2473px #ffc400, 970px 556px #ffffff, 313px 1340px #ef5350, 1416px 2460px #0057ff, 1340px 1305px #ffc400, 2182px 1919px #ef5350, 91px 127px #ffffff,
    1355px 1265px #0057ff, 1028px 1461px #00bc64, 1240px 1900px #0057ff, 1765px 178px #00bc64, 535px 1841px #ef5350, 1876px 862px #ffffff, 1418px 7px #ef5350,
    5px 1043px #00bc64, 66px 1917px #ffc400, 1815px 2381px #0057ff, 1789px 1961px #ef5350, 1656px 131px #ef5350, 443px 650px #00bc64, 1668px 2134px #0057ff,
    365px 2185px #ffc400, 27px 1163px #ffc400, 513px 1586px #ffc400, 602px 1057px #00bc64, 1175px 502px #ffc400, 392px 340px #00bc64, 846px 2368px #00bc64,
    861px 183px #ef5350, 2250px 123px #00bc64, 949px 1796px #ef5350, 1042px 2487px #ef5350, 1021px 685px #ffc400, 1834px 749px #0057ff, 1931px 2495px #00bc64,
    1526px 385px #ef5350, 1621px 2083px #ffffff, 1232px 1012px #ef5350, 590px 866px #00bc64, 79px 230px #0057ff, 1262px 1227px #0057ff, 1272px 2133px #ef5350,
    11px 2375px #ffc400, 1607px 1176px #0057ff, 900px 1090px #ffc400, 1904px 2497px #ffffff, 2236px 1159px #ffffff, 1509px 1906px #ef5350, 791px 1039px #ef5350,
    16px 2227px #ef5350, 1294px 906px #ffffff, 2183px 2376px #ef5350, 716px 1393px #ffffff, 1225px 1907px #ffffff, 1756px 735px #ffffff, 1572px 2359px #ffffff,
    809px 35px #ffc400, 2287px 1873px #0057ff, 1704px 1849px #0057ff, 1120px 768px #ffc400, 1082px 677px #00bc64, 1575px 2293px #0057ff, 187px 1097px #ffffff,
    1323px 616px #0057ff, 616px 2465px #0057ff, 582px 291px #ffffff, 365px 671px #00bc64, 1499px 1800px #00bc64, 2292px 20px #ffc400, 2458px 1569px #00bc64,
    979px 1196px #0057ff, 1992px 2435px #0057ff, 1173px 1129px #ef5350, 859px 2539px #ffffff, 1245px 186px #ffc400, 705px 484px #ffc400, 73px 2031px #0057ff,
    2382px 32px #0057ff, 1508px 1153px #ef5350, 1545px 441px #0057ff, 1245px 1808px #ffffff, 1806px 1285px #00bc64, 214px 1406px #ffffff, 1001px 1204px #ffffff,
    2497px 405px #ef5350, 2058px 2454px #ffc400, 1442px 1750px #ffc400, 1403px 2353px #ffc400, 295px 885px #0057ff, 2297px 891px #0057ff, 1752px 1275px #ef5350,
    473px 619px #0057ff, 1765px 2075px #ffc400, 2117px 1182px #0057ff, 1427px 164px #0057ff, 218px 1365px #ffc400, 267px 1562px #ffffff, 1974px 1086px #0057ff,
    2272px 68px #ef5350, 2112px 117px #ffc400, 1338px 1915px #ef5350, 270px 2311px #00bc64, 1472px 645px #0057ff, 2195px 2378px #ffffff, 2372px 780px #ef5350,
    2533px 809px #ffc400, 2488px 310px #ffffff, 1887px 105px #00bc64, 378px 935px #ef5350, 238px 1710px #ffc400, 1641px 160px #0057ff, 1383px 1929px #ef5350,
    1457px 836px #ef5350, 1989px 2058px #ffffff, 2103px 61px #00bc64, 1380px 16px #ffffff, 399px 1729px #00bc64, 619px 1854px #ffffff, 1321px 1382px #ffffff,
    459px 2011px #ffffff, 1538px 773px #00bc64, 1595px 1805px #0057ff, 1469px 2397px #0057ff, 1794px 2084px #0057ff, 977px 1066px #ef5350, 433px 2385px #ef5350,
    2219px 947px #ffffff, 931px 1639px #ef5350, 1203px 515px #ffc400, 2002px 1114px #ffc400, 2159px 153px #0057ff, 2452px 350px #ef5350, 1819px 2341px #ef5350,
    258px 551px #00bc64, 975px 353px #ffffff, 1371px 430px #0057ff, 700px 798px #ef5350, 403px 1365px #ef5350, 187px 663px #ef5350, 1221px 512px #0057ff,
    2499px 2064px #ffc400, 431px 2246px #0057ff, 791px 2068px #ffc400, 2257px 1376px #ffffff, 1885px 857px #ffffff, 2332px 2395px #ffc400, 1703px 2288px #ffffff,
    1833px 1374px #00bc64, 1033px 1326px #0057ff, 2371px 2556px #ffc400, 2478px 2370px #ef5350, 1770px 1689px #ffffff, 1359px 1505px #0057ff,
    1577px 1901px #ffc400, 905px 2095px #00bc64, 1522px 1155px #00bc64, 1211px 1552px #00bc64, 1472px 720px #00bc64, 1599px 610px #ffffff, 1678px 1922px #ffffff,
    1306px 2473px #ef5350, 702px 1287px #ffffff, 1528px 1014px #ffffff, 2030px 1528px #ffffff, 44px 375px #00bc64, 2234px 205px #ef5350, 1344px 1789px #ffc400,
    1508px 1987px #ffffff, 835px 1685px #ef5350, 496px 1469px #ffffff, 1441px 46px #ef5350, 1962px 1179px #ef5350, 892px 2035px #ef5350, 261px 2153px #ef5350,
    2009px 2510px #00bc64, 2530px 181px #00bc64, 2026px 929px #ffc400, 16px 2075px #00bc64, 405px 1865px #0057ff, 1383px 1092px #0057ff, 2017px 1705px #ffffff,
    514px 1335px #ffc400, 1128px 1181px #ffc400, 1659px 950px #0057ff, 1208px 235px #ffc400, 2360px 774px #ef5350, 94px 381px #00bc64, 668px 778px #ffffff,
    2338px 2182px #ef5350, 1410px 2020px #ffc400, 436px 450px #0057ff, 1261px 348px #ef5350, 1394px 93px #ffffff, 219px 1848px #0057ff, 2041px 1024px #0057ff,
    1208px 2542px #ef5350, 1565px 139px #ffc400, 781px 1050px #00bc64, 2186px 1986px #ffc400, 1146px 1185px #ffc400, 1901px 2383px #ffc400, 1879px 29px #ffffff,
    510px 2470px #0057ff, 1714px 369px #ffffff, 230px 853px #ef5350, 1194px 156px #00bc64, 1568px 2133px #0057ff, 1039px 1640px #00bc64, 1145px 777px #ef5350,
    630px 636px #ffc400, 1369px 2127px #ffc400, 2024px 743px #00bc64, 2227px 783px #ffc400, 428px 50px #ef5350, 387px 1898px #0057ff, 1351px 125px #00bc64,
    1879px 43px #ef5350, 1410px 832px #00bc64, 989px 1328px #0057ff, 2016px 275px #0057ff, 2352px 1546px #00bc64, 501px 2287px #ef5350, 1943px 633px #00bc64,
    1320px 1563px #ffffff, 607px 49px #ffc400, 1483px 2509px #ef5350, 107px 728px #0057ff, 692px 497px #00bc64, 2005px 1303px #ef5350, 1799px 59px #ef5350,
    854px 1938px #ef5350, 1496px 1082px #ef5350, 663px 258px #0057ff, 2274px 1205px #ffc400, 1773px 1110px #ffc400, 1413px 363px #ef5350, 1316px 1365px #0057ff,
    443px 1089px #ffffff, 1189px 1741px #ffffff, 901px 2024px #0057ff, 2191px 182px #ffffff, 580px 1682px #00bc64, 1601px 682px #00bc64, 2190px 587px #0057ff,
    983px 861px #00bc64, 1554px 1103px #ef5350, 1393px 970px #00bc64, 1832px 994px #ffc400, 986px 1391px #ffffff, 1009px 93px #ef5350, 2485px 482px #00bc64,
    2151px 1579px #00bc64, 1543px 1876px #00bc64, 622px 802px #0057ff, 1721px 1306px #ef5350, 2162px 547px #00bc64, 2285px 1954px #0057ff, 1615px 1708px #ffffff,
    2110px 786px #ffc400, 1330px 2004px #00bc64, 52px 750px #0057ff, 1859px 1883px #00bc64, 2317px 453px #00bc64, 2409px 984px #00bc64, 2413px 1181px #00bc64,
    2497px 158px #ffffff, 1049px 1198px #0057ff, 1487px 228px #ffc400, 1728px 2236px #ef5350, 242px 1425px #0057ff, 1904px 1417px #ffffff, 205px 1858px #ffc400,
    1127px 19px #ef5350, 1149px 526px #ffc400, 377px 2126px #ffffff, 1260px 918px #0057ff, 786px 2px #0057ff, 370px 194px #ffffff, 757px 1536px #ffffff,
    1073px 154px #ef5350, 2530px 2063px #ffffff, 1070px 421px #0057ff, 1659px 1112px #0057ff, 133px 1277px #ffffff, 745px 1098px #0057ff, 872px 1102px #0057ff,
    1019px 1384px #ef5350, 1460px 1183px #00bc64, 1678px 1380px #ffc400, 2535px 651px #ffc400, 2138px 998px #0057ff, 858px 2377px #0057ff, 1795px 2390px #ffc400,
    1045px 226px #00bc64, 1584px 1445px #0057ff, 2224px 279px #0057ff, 208px 448px #0057ff, 662px 1637px #ffc400, 1032px 1876px #ffffff, 1966px 1214px #00bc64,
    1664px 2019px #0057ff, 2125px 643px #ffffff, 1038px 742px #ffc400, 1321px 1294px #0057ff, 1826px 1338px #ef5350, 201px 2291px #ffffff, 2437px 55px #ef5350,
    2404px 795px #0057ff, 2418px 1138px #0057ff, 2288px 1273px #ffffff, 1261px 2451px #ef5350, 156px 1585px #0057ff, 682px 1423px #ef5350, 2455px 392px #ffffff,
    1578px 443px #ef5350, 126px 2326px #ef5350, 744px 2518px #ef5350, 600px 2355px #ef5350, 573px 1450px #ffc400, 1775px 1978px #ffffff, 1236px 1793px #00bc64,
    1184px 754px #ef5350, 1939px 369px #00bc64, 1491px 886px #ef5350, 2486px 366px #ffffff, 1818px 462px #ef5350, 151px 2092px #ef5350, 2168px 1077px #ffc400,
    230px 320px #0057ff, 948px 1906px #ffc400, 723px 2558px #ffc400, 1682px 2472px #ffc400, 1140px 83px #0057ff, 1233px 1807px #0057ff, 1991px 1604px #00bc64,
    2301px 301px #ef5350, 1966px 1903px #ef5350, 757px 2208px #00bc64, 1541px 2319px #ffc400, 1736px 2437px #ef5350, 1009px 693px #ffc400, 2463px 84px #0057ff,
    2117px 1533px #ef5350, 2152px 1760px #00bc64, 70px 2353px #00bc64, 1105px 2296px #ef5350, 267px 661px #ffffff, 19px 1146px #0057ff, 2293px 1113px #0057ff,
    292px 428px #ffffff, 1200px 113px #ffc400, 254px 347px #0057ff, 2067px 611px #0057ff, 587px 1795px #ffc400, 1934px 1633px #ffc400, 1116px 756px #ef5350,
    857px 1103px #ffffff, 209px 662px #ffc400, 2202px 1064px #ffc400, 1532px 2393px #0057ff, 357px 278px #0057ff, 1955px 572px #ef5350, 841px 1088px #ffc400,
    398px 1352px #00bc64, 794px 151px #ef5350;
  height: 2px;
  width: 2px;

  &:after {
    box-shadow: 0px 0px #ffffff, 448px 1324px #ffffff, 2437px 2122px #ffffff, 1528px 222px #ef5350, 1434px 487px #ef5350, 38px 186px #00bc64,
      201px 802px #0057ff, 139px 943px #0057ff, 1187px 1390px #ffffff, 1493px 1003px #0057ff, 285px 1932px #ef5350, 2107px 1958px #ffffff, 407px 353px #ef5350,
      623px 1528px #0057ff, 2214px 1748px #00bc64, 1384px 1617px #ffffff, 2116px 1635px #ffffff, 1498px 379px #ffffff, 746px 527px #0057ff, 436px 1834px #00bc64,
      1706px 2352px #ffffff, 882px 888px #ffc400, 966px 1328px #ffc400, 833px 527px #ffffff, 2251px 982px #ef5350, 2538px 1208px #ffc400, 2086px 1409px #0057ff,
      1874px 2429px #0057ff, 173px 1275px #0057ff, 71px 1888px #ef5350, 2308px 722px #ffc400, 749px 2075px #ffc400, 1233px 95px #0057ff, 1448px 931px #0057ff,
      1986px 1161px #0057ff, 39px 1811px #ef5350, 821px 2044px #ef5350, 346px 1156px #ffc400, 74px 1810px #00bc64, 2031px 1305px #ffffff, 1342px 2292px #ffffff,
      2344px 1159px #ffc400, 2046px 186px #ef5350, 1802px 1424px #ef5350, 401px 979px #00bc64, 1430px 790px #00bc64, 1335px 952px #ef5350, 1531px 175px #0057ff,
      1947px 1060px #00bc64, 554px 2247px #00bc64, 2365px 1646px #00bc64, 1569px 860px #00bc64, 1217px 2180px #0057ff, 1244px 2214px #0057ff,
      1273px 1163px #ef5350, 1628px 785px #ef5350, 146px 402px #ffc400, 949px 1438px #ef5350, 1815px 1936px #ffc400, 2030px 913px #00bc64, 84px 225px #ef5350,
      289px 2187px #00bc64, 1606px 2516px #ffc400, 1526px 1298px #ffffff, 1671px 142px #ffc400, 1895px 2032px #ffc400, 214px 173px #00bc64,
      2317px 1089px #ffc400, 494px 2280px #0057ff, 1133px 145px #ef5350, 1507px 753px #ffc400, 1466px 1162px #0057ff, 951px 1589px #ef5350, 902px 649px #ef5350,
      1680px 856px #ffc400, 2358px 1523px #ef5350, 92px 956px #00bc64, 149px 246px #ffc400, 413px 1309px #00bc64, 217px 1655px #00bc64, 1784px 1148px #ffc400,
      1391px 389px #ffc400, 2116px 1335px #00bc64, 780px 1631px #0057ff, 298px 2307px #ef5350, 1564px 1112px #ffffff, 879px 1116px #ffffff, 875px 999px #ffffff,
      636px 2338px #ffc400, 111px 2119px #00bc64, 169px 707px #ffffff, 2311px 2536px #ffffff, 1314px 877px #ffffff, 757px 1604px #0057ff, 1015px 2024px #00bc64,
      2378px 1942px #00bc64, 1645px 1385px #ef5350, 1805px 2460px #ffffff, 1957px 873px #ffffff, 536px 2517px #00bc64, 988px 1051px #00bc64,
      1168px 2485px #ffc400, 901px 1311px #ef5350, 1554px 774px #ef5350, 475px 80px #ffc400, 299px 242px #00bc64, 2061px 652px #ef5350, 913px 1239px #0057ff,
      1375px 567px #00bc64, 1477px 665px #ffffff, 1112px 1445px #ffffff, 1969px 282px #00bc64, 635px 1108px #0057ff, 2366px 751px #0057ff, 2423px 1943px #00bc64,
      1976px 2387px #ffc400, 1306px 1411px #0057ff, 790px 2206px #ffffff, 391px 921px #ef5350, 120px 135px #0057ff, 321px 2197px #0057ff, 1690px 1084px #0057ff,
      739px 31px #ffc400, 2554px 943px #0057ff, 113px 387px #00bc64, 1927px 1052px #00bc64, 726px 176px #ffffff, 1577px 931px #0057ff, 2323px 1543px #ffffff,
      1052px 1591px #ffffff, 2377px 2251px #ef5350, 1888px 2335px #ffffff, 938px 321px #ffffff, 834px 1617px #ffffff, 2515px 110px #0057ff, 1074px 473px #0057ff,
      1134px 75px #00bc64, 1497px 1390px #ffffff, 2322px 1046px #ef5350, 502px 819px #ffffff, 1621px 1468px #0057ff, 58px 2306px #00bc64, 2388px 2287px #ef5350,
      1518px 1276px #ffc400, 1987px 2021px #ffc400, 117px 790px #ffc400, 1656px 240px #ef5350, 1479px 2513px #00bc64, 488px 586px #ffffff, 2307px 781px #00bc64,
      496px 2154px #ef5350, 1276px 293px #ffffff, 1570px 2219px #ef5350, 311px 1771px #00bc64, 113px 2140px #0057ff, 424px 1998px #ffffff, 1339px 754px #ffc400,
      875px 26px #0057ff, 595px 1767px #ffc400, 1476px 1968px #ffffff, 214px 2020px #0057ff, 1248px 1754px #00bc64, 2549px 452px #0057ff, 401px 1758px #00bc64,
      433px 1988px #ffffff, 1308px 2355px #ffc400, 1884px 1412px #ffffff, 1391px 624px #ffffff, 1596px 211px #0057ff, 2440px 276px #00bc64, 1025px 544px #ffc400,
      2204px 498px #0057ff, 2203px 2097px #ffc400, 1750px 2026px #ffffff, 2150px 802px #00bc64, 657px 1565px #ffffff, 223px 690px #ffffff, 1419px 1084px #00bc64,
      1131px 1782px #0057ff, 840px 1400px #ef5350, 1904px 1610px #ffffff, 2447px 420px #00bc64, 236px 1034px #00bc64, 2069px 1275px #ffc400,
      2524px 2299px #00bc64, 1749px 553px #0057ff, 2357px 869px #0057ff, 325px 1482px #00bc64, 1654px 2373px #ef5350, 1212px 2472px #ffffff,
      566px 1237px #ef5350, 2303px 837px #00bc64, 1457px 833px #0057ff, 1104px 1255px #ef5350, 1601px 1713px #ef5350, 1867px 849px #ffffff, 586px 1381px #ef5350,
      1309px 435px #0057ff, 364px 1183px #ffffff, 2505px 26px #ffc400, 1333px 1600px #00bc64, 1505px 1829px #ffc400, 2475px 982px #ef5350, 285px 1692px #ffc400,
      2519px 2539px #ffffff, 181px 1126px #ffffff, 132px 395px #ffffff, 1518px 308px #ffc400, 2499px 2334px #ffc400, 940px 1271px #00bc64, 2103px 2160px #00bc64,
      813px 1625px #00bc64, 1093px 73px #ffc400, 2261px 629px #ffc400, 1090px 789px #00bc64, 1072px 1919px #ffc400, 1530px 1440px #ef5350, 647px 1531px #00bc64,
      1545px 1972px #0057ff, 651px 2338px #ffffff, 1329px 1222px #ffc400, 2165px 1816px #0057ff, 407px 105px #00bc64, 424px 30px #00bc64, 1697px 1831px #ffffff,
      1649px 203px #ffc400, 1996px 1883px #ffc400, 170px 1460px #ffc400, 291px 1046px #ffffff, 1906px 2259px #0057ff, 515px 2558px #0057ff,
      2198px 2189px #ffffff, 25px 1253px #0057ff, 1771px 1363px #ffffff, 1408px 2525px #0057ff, 617px 1247px #ef5350, 1610px 29px #0057ff, 1055px 1491px #ffc400,
      897px 961px #ffc400, 1078px 1620px #ef5350, 1916px 655px #0057ff, 806px 1638px #ffc400, 2043px 1456px #00bc64, 1635px 1850px #ffffff, 222px 800px #ffc400,
      1288px 1032px #00bc64, 482px 1269px #ef5350, 118px 975px #ffc400, 793px 67px #ffc400, 830px 697px #00bc64, 323px 442px #0057ff, 124px 1384px #ef5350,
      2217px 2299px #ffffff, 320px 1258px #0057ff, 2417px 844px #ffc400, 934px 388px #0057ff, 120px 806px #ffffff, 2534px 1386px #ef5350, 244px 582px #ffc400,
      2003px 1591px #ef5350, 1043px 2548px #ef5350, 2274px 214px #ffffff, 2324px 1984px #ef5350, 158px 2063px #00bc64, 784px 101px #0057ff,
      2147px 1072px #ef5350, 1910px 397px #0057ff, 551px 197px #ffffff, 2415px 2480px #0057ff, 1948px 252px #00bc64, 1440px 54px #ffc400, 1740px 178px #ffc400,
      2170px 450px #ffffff, 277px 425px #ef5350, 1046px 118px #ffc400, 778px 711px #00bc64, 1851px 1258px #0057ff, 2485px 1273px #ffffff, 2359px 1303px #ffc400,
      1017px 357px #ef5350, 375px 2179px #ef5350, 68px 2251px #ef5350, 558px 2394px #ffffff, 1513px 2328px #00bc64, 173px 1842px #ffc400, 1642px 209px #ef5350,
      32px 822px #0057ff, 2073px 916px #ef5350, 1861px 234px #ffc400, 1609px 1703px #ffc400, 771px 1309px #ffffff, 1516px 2046px #ffffff, 1743px 640px #ef5350,
      2160px 905px #ef5350, 494px 512px #0057ff, 873px 773px #ef5350, 767px 1244px #ffffff, 963px 1459px #ffffff, 1914px 207px #ffffff, 1185px 864px #ef5350,
      1951px 1331px #0057ff, 1150px 2523px #ffffff, 542px 918px #ffffff, 1382px 313px #ef5350, 1668px 468px #ffc400, 1920px 1822px #ffffff, 181px 1076px #ef5350,
      276px 1066px #ef5350, 2537px 1432px #00bc64, 1111px 2378px #ffffff, 2523px 1918px #ffc400, 2256px 1097px #ef5350, 1011px 718px #00bc64,
      204px 1633px #ffc400, 588px 233px #00bc64, 735px 1222px #ffffff, 1901px 1889px #0057ff, 971px 199px #ffc400, 2556px 2192px #ffc400, 510px 1779px #0057ff,
      1754px 398px #00bc64, 1783px 1144px #ffffff, 940px 2447px #0057ff, 1661px 1878px #ef5350, 1483px 526px #ffc400, 841px 646px #ffffff, 1789px 455px #0057ff,
      2443px 1200px #0057ff, 2251px 1173px #00bc64, 1997px 2413px #0057ff, 1428px 1340px #ffc400, 7px 497px #0057ff, 792px 915px #ffc400, 2069px 1498px #0057ff,
      1103px 1932px #ef5350, 1995px 1765px #ffffff, 2311px 1346px #ffc400, 2303px 211px #0057ff, 2258px 942px #0057ff, 663px 268px #00bc64, 2143px 101px #00bc64,
      2093px 1119px #00bc64, 759px 304px #ef5350, 1102px 200px #ffffff, 2329px 2144px #0057ff, 260px 1805px #00bc64, 1905px 185px #00bc64, 2366px 2471px #00bc64,
      1987px 512px #00bc64, 307px 1044px #0057ff, 987px 1793px #00bc64, 2473px 1093px #0057ff, 2377px 859px #00bc64, 603px 628px #0057ff, 1803px 1250px #ffc400,
      548px 1787px #0057ff, 2071px 1142px #ef5350, 1733px 1963px #ffffff, 1580px 1250px #ffffff, 1136px 1125px #00bc64, 1461px 1614px #00bc64,
      1729px 2044px #ffc400, 1594px 1751px #ffffff, 880px 434px #ffc400, 38px 725px #00bc64, 1976px 1375px #ffc400, 1361px 2059px #0057ff, 775px 1495px #ffc400,
      324px 286px #ffffff, 1784px 2168px #0057ff, 733px 1724px #ffc400, 1837px 1994px #ef5350, 1462px 2540px #00bc64, 2052px 1536px #ffffff,
      1914px 958px #ef5350, 1462px 268px #ef5350, 691px 963px #ffc400, 1888px 889px #ef5350, 2229px 281px #00bc64, 1755px 1903px #ffffff, 759px 2216px #0057ff,
      1474px 2154px #ffffff, 2240px 1818px #ef5350, 709px 1493px #ffffff, 1120px 1326px #ffffff, 1130px 189px #ffffff, 1029px 129px #0057ff,
      1069px 2031px #ffffff, 1190px 2247px #ffffff, 1839px 1817px #ffc400, 2421px 481px #0057ff, 932px 305px #ffc400, 1041px 580px #ef5350, 1927px 339px #00bc64,
      598px 2186px #00bc64, 2471px 453px #ef5350, 2548px 1238px #00bc64, 1378px 423px #0057ff, 358px 2054px #ffc400, 1039px 782px #ef5350, 613px 1056px #ffffff,
      1686px 1715px #0057ff, 1465px 1605px #ffffff, 2124px 704px #ef5350, 1008px 1188px #00bc64, 2539px 1734px #ffffff, 2149px 393px #ffc400,
      977px 1346px #ef5350, 1225px 2500px #ef5350, 772px 2077px #ffc400, 1768px 2370px #ffffff, 1082px 1329px #ef5350, 1219px 2271px #00bc64,
      329px 1330px #00bc64, 683px 1716px #0057ff, 2179px 1001px #ffffff, 2474px 1020px #ffc400, 1609px 1500px #ef5350, 29px 1564px #ffc400,
      2503px 2044px #ffc400, 237px 869px #ffffff, 2376px 1447px #ffffff, 757px 779px #ffc400, 1813px 2469px #0057ff, 814px 2240px #ffc400, 2524px 72px #ffc400,
      800px 1234px #ffc400, 1483px 2067px #ffc400, 2194px 2048px #0057ff, 851px 730px #ffffff, 1067px 2379px #00bc64, 1588px 559px #ffffff,
      1790px 1935px #ef5350, 970px 876px #0057ff, 43px 2488px #00bc64, 787px 1309px #ef5350, 659px 1856px #00bc64, 1030px 1410px #0057ff, 1729px 64px #00bc64,
      2510px 492px #0057ff, 1194px 811px #ffc400, 324px 1691px #00bc64, 1731px 947px #0057ff, 1219px 1448px #00bc64, 1032px 1258px #ffffff, 1671px 156px #0057ff,
      1840px 1820px #ffc400, 2125px 392px #0057ff, 604px 1169px #ef5350, 1285px 2433px #ffffff, 465px 1197px #00bc64, 1005px 688px #ffc400, 2099px 948px #0057ff,
      1873px 903px #ffc400, 771px 1583px #ef5350, 1296px 1357px #ffc400, 1704px 1214px #ffffff, 2000px 1617px #0057ff, 145px 732px #00bc64, 342px 1140px #0057ff,
      1330px 1969px #00bc64, 1648px 2445px #0057ff, 161px 1416px #00bc64, 787px 1531px #ffffff, 16px 385px #0057ff, 1836px 773px #ffffff, 1207px 325px #00bc64,
      980px 973px #0057ff, 537px 2330px #ffffff, 549px 1352px #ffc400, 473px 1080px #0057ff, 1004px 328px #ef5350, 2224px 196px #ffc400, 273px 1497px #00bc64,
      1448px 2141px #0057ff, 1965px 1532px #00bc64, 306px 1752px #ef5350, 810px 239px #00bc64, 2282px 879px #00bc64, 2058px 865px #0057ff, 2379px 748px #ef5350,
      2041px 2063px #ef5350, 302px 495px #ffc400, 1908px 1656px #ef5350, 195px 2143px #0057ff, 1367px 412px #00bc64, 2160px 935px #ffffff, 1072px 1838px #ffc400,
      1382px 2485px #00bc64, 548px 1289px #0057ff, 1871px 2100px #ffc400, 625px 1758px #ef5350, 2147px 1710px #ffffff, 1221px 1169px #ffc400,
      282px 1591px #0057ff, 1028px 439px #ef5350, 1781px 1259px #ffc400, 1509px 46px #ffffff, 2063px 816px #ef5350, 1003px 2173px #00bc64, 1101px 1930px #0057ff,
      1278px 1598px #0057ff, 807px 621px #00bc64, 729px 2558px #ffc400, 2472px 1893px #ef5350, 2037px 1831px #ef5350, 77px 2421px #00bc64, 456px 2263px #ef5350,
      870px 2213px #ffffff, 2030px 2175px #ffffff, 1702px 544px #ef5350, 2433px 238px #0057ff, 107px 1386px #0057ff, 1090px 934px #ef5350, 1825px 1547px #ef5350,
      32px 1005px #ef5350, 2187px 1337px #ffffff, 225px 1927px #ef5350, 377px 39px #00bc64, 661px 258px #0057ff, 2107px 977px #00bc64, 2288px 1743px #00bc64,
      2192px 1423px #ffc400, 937px 1039px #00bc64, 1853px 771px #ffffff, 923px 1349px #0057ff, 132px 2130px #00bc64, 1239px 2409px #ffc400, 1863px 711px #00bc64,
      708px 577px #0057ff, 2076px 1413px #00bc64, 763px 1003px #0057ff, 1888px 2088px #ffffff, 1055px 592px #ffffff, 722px 172px #0057ff, 2070px 1550px #ffc400,
      680px 1974px #00bc64, 1923px 533px #ffffff, 1814px 322px #00bc64, 1650px 1389px #ffffff, 957px 2505px #ffffff, 1470px 792px #0057ff, 1441px 2055px #00bc64,
      785px 1253px #ffffff, 455px 2405px #00bc64, 994px 738px #ef5350, 2269px 69px #ef5350, 413px 880px #ef5350, 478px 1540px #ffffff, 1467px 270px #0057ff,
      1364px 1801px #00bc64, 980px 313px #ef5350, 2233px 1233px #ffffff, 2279px 1409px #0057ff, 1255px 77px #0057ff, 1573px 2182px #ffc400,
      1904px 2251px #ef5350, 894px 215px #00bc64, 2215px 495px #ffffff, 1685px 1597px #ef5350, 1374px 2388px #00bc64, 792px 1265px #ef5350,
      1811px 1477px #ffffff, 1946px 2494px #00bc64, 1563px 2462px #0057ff, 2439px 450px #ffc400, 443px 2500px #ef5350, 327px 1550px #0057ff,
      2483px 1213px #ef5350, 61px 235px #ffffff, 998px 1437px #ffc400, 974px 266px #0057ff, 2547px 416px #ffc400, 311px 1420px #00bc64, 654px 1053px #00bc64,
      1663px 530px #ffffff, 496px 849px #ffc400, 665px 408px #ffc400, 343px 1448px #0057ff, 1922px 845px #00bc64, 1224px 1726px #00bc64, 606px 1262px #00bc64,
      1324px 703px #ffffff, 2519px 847px #ffc400, 2380px 1709px #ffc400, 195px 1573px #ffffff, 922px 1845px #ffc400, 62px 1325px #0057ff, 577px 808px #0057ff,
      2262px 630px #ffc400, 452px 928px #00bc64, 315px 1957px #ef5350, 1409px 2166px #00bc64, 2469px 2078px #ef5350, 2389px 2441px #ef5350, 538px 2450px #0057ff,
      425px 494px #0057ff, 1537px 767px #ffc400, 2336px 2273px #00bc64, 1792px 2310px #00bc64, 2549px 1569px #00bc64;
    height: 2px;
    width: 2px;
  }
`

const Particle2 = styled.div`
  ${particle}

  animation: ${animParticle} 80s linear infinite;
  box-shadow: 0px 0px #ffffff, 1955px 258px #0057ff, 416px 1936px #ffffff, 321px 1300px #ffc400, 391px 639px #ef5350, 511px 276px #ffffff, 256px 125px #00bc64,
    195px 2396px #ef5350, 1919px 115px #ef5350, 1173px 714px #ffc400, 1000px 652px #00bc64, 1006px 276px #00bc64, 1709px 1632px #ffc400, 2275px 1184px #ffc400,
    480px 2386px #ffffff, 777px 1665px #ffffff, 1006px 2262px #00bc64, 2170px 724px #ffc400, 1821px 187px #ffc400, 2519px 418px #00bc64, 1557px 446px #00bc64,
    96px 1220px #ef5350, 2293px 2400px #00bc64, 1053px 36px #0057ff, 1092px 1489px #ffc400, 51px 1156px #00bc64, 1349px 2475px #ffffff, 356px 1008px #00bc64,
    871px 390px #ffffff, 1114px 7px #ffffff, 268px 1387px #00bc64, 389px 852px #00bc64, 682px 1769px #0057ff, 627px 1825px #00bc64, 110px 1828px #ffffff,
    1562px 1415px #ffc400, 1111px 57px #0057ff, 837px 1648px #0057ff, 1073px 392px #00bc64, 1076px 855px #ffc400, 1568px 900px #0057ff, 751px 920px #00bc64,
    1628px 1766px #ffc400, 1994px 1794px #00bc64, 724px 1990px #ffffff, 1855px 127px #ef5350, 380px 1851px #ffffff, 110px 1793px #0057ff, 2404px 1016px #0057ff,
    1567px 1596px #00bc64, 387px 80px #0057ff, 323px 1586px #ffc400, 2477px 1324px #ef5350, 526px 2476px #ffffff, 1570px 2344px #00bc64, 2433px 577px #00bc64,
    2504px 677px #00bc64, 1038px 2474px #00bc64, 409px 379px #00bc64, 72px 1203px #ffc400, 92px 739px #ef5350, 564px 1489px #ffffff, 475px 2486px #ef5350,
    2103px 1788px #00bc64, 696px 175px #0057ff, 246px 2257px #ef5350, 359px 2032px #ef5350, 675px 2198px #00bc64, 2247px 1255px #00bc64, 519px 490px #ffc400,
    1116px 1798px #ffffff, 444px 1711px #ffc400, 2066px 1732px #ffffff, 1747px 1682px #0057ff, 357px 2157px #ffc400, 1367px 2055px #ffc400, 979px 2074px #0057ff,
    1267px 2064px #ffffff, 1532px 290px #ffc400, 1831px 1709px #ef5350, 227px 2553px #ffffff, 1638px 1569px #00bc64, 879px 1628px #ffffff, 1873px 2172px #ffc400,
    835px 550px #ffffff, 1268px 1048px #0057ff, 465px 2375px #0057ff, 176px 2244px #ffc400, 467px 1502px #0057ff, 301px 1627px #ffffff, 362px 2163px #ef5350,
    725px 1766px #ffc400, 181px 2146px #0057ff, 2501px 539px #ffffff, 196px 283px #0057ff, 1018px 1267px #00bc64, 911px 2207px #00bc64, 624px 518px #ffffff,
    1630px 1037px #ffc400, 1216px 2079px #ef5350, 189px 1590px #ffc400, 923px 769px #ef5350, 697px 2327px #ffffff, 1956px 1277px #ffffff, 1694px 881px #ef5350,
    1034px 1700px #ffffff, 344px 2329px #00bc64, 1063px 1053px #ffffff, 624px 1064px #ffffff, 2258px 1530px #ef5350, 235px 2512px #ffc400, 1134px 1762px #00bc64,
    778px 1293px #0057ff, 1455px 2087px #0057ff, 62px 577px #00bc64, 1367px 2429px #0057ff, 342px 391px #ffc400, 2557px 1466px #ffffff, 1699px 198px #ef5350,
    1669px 2484px #ef5350, 766px 189px #ffffff, 872px 313px #00bc64, 2365px 1023px #00bc64, 649px 1490px #ffffff, 2513px 434px #ffc400, 462px 1388px #0057ff,
    1761px 153px #ffffff, 25px 2401px #ffc400, 576px 1765px #0057ff, 2523px 583px #ffc400, 937px 367px #00bc64, 1731px 1062px #0057ff, 2208px 1123px #ffffff,
    982px 971px #ffffff, 1960px 2110px #0057ff, 1405px 74px #00bc64, 1344px 919px #0057ff, 1741px 996px #ffc400, 1755px 1364px #ffc400, 1136px 2547px #ffffff,
    1579px 330px #ffc400, 162px 2512px #00bc64, 2035px 1718px #ffffff, 239px 707px #ffffff, 120px 1249px #0057ff, 1675px 1200px #ffc400, 187px 2462px #ffc400,
    908px 2296px #00bc64, 720px 881px #0057ff, 1840px 2532px #0057ff, 118px 1860px #ef5350, 748px 1795px #ffffff, 142px 2384px #0057ff, 1012px 2070px #0057ff,
    2405px 322px #ffc400, 847px 1979px #00bc64, 452px 595px #ffffff, 2532px 1214px #0057ff, 2197px 1474px #00bc64, 1772px 1657px #00bc64, 195px 2305px #00bc64,
    244px 1676px #ef5350, 628px 1076px #0057ff, 1186px 2231px #ef5350, 2154px 427px #ffffff, 204px 2410px #0057ff, 299px 2513px #ffc400, 2440px 553px #ffffff,
    2366px 2011px #00bc64, 1532px 685px #0057ff, 328px 1525px #ffc400, 1608px 731px #ffc400, 1758px 777px #ffc400, 2257px 1343px #ffc400, 1767px 2247px #00bc64,
    2106px 1345px #00bc64, 1934px 1615px #00bc64, 2484px 1671px #00bc64, 1226px 1264px #ffc400, 1689px 182px #00bc64, 1739px 2013px #ffc400,
    1442px 1965px #ef5350, 1201px 231px #0057ff, 2053px 1521px #ffffff, 1637px 2437px #0057ff, 302px 2098px #00bc64, 1920px 1560px #ffffff,
    1259px 2310px #ffffff, 170px 1875px #ffc400, 892px 363px #0057ff, 1941px 1374px #ffc400, 1925px 722px #ef5350, 1928px 237px #0057ff, 521px 420px #ffffff,
    432px 2366px #00bc64, 881px 1108px #ef5350, 1887px 815px #ffffff, 1683px 2560px #ffc400, 2102px 573px #0057ff, 2157px 706px #0057ff, 310px 1175px #0057ff;
  height: 3px;
  width: 3px;

  &:after {
    box-shadow: 0px 0px #ffffff, 311px 1056px #ffffff, 797px 475px #0057ff, 1189px 2050px #ffc400, 2293px 2475px #00bc64, 1816px 445px #00bc64,
      480px 1825px #0057ff, 810px 1101px #ffc400, 311px 1171px #ffc400, 1094px 382px #0057ff, 927px 2419px #ef5350, 1258px 1822px #0057ff, 2285px 560px #ef5350,
      1004px 997px #0057ff, 1636px 1012px #ffc400, 2485px 1459px #ffc400, 701px 1740px #ffc400, 1312px 275px #ffc400, 1215px 1651px #0057ff,
      1043px 2260px #ffffff, 325px 1863px #0057ff, 583px 1582px #00bc64, 1404px 346px #ffc400, 1748px 1918px #ffffff, 1248px 2114px #ef5350,
      997px 1556px #0057ff, 2172px 1395px #00bc64, 1767px 51px #0057ff, 2081px 1398px #00bc64, 2199px 800px #ffffff, 1626px 1450px #0057ff, 941px 1672px #ffc400,
      1846px 1108px #ffffff, 1172px 1840px #ef5350, 604px 372px #ffc400, 2175px 1753px #0057ff, 959px 1883px #00bc64, 552px 2114px #ef5350,
      1735px 2004px #ffc400, 90px 1735px #ffffff, 1994px 1649px #ffffff, 2457px 792px #ffc400, 595px 1318px #ffffff, 960px 1520px #ffc400, 1887px 2237px #00bc64,
      372px 1743px #ffffff, 1856px 687px #ffffff, 1609px 934px #0057ff, 205px 1169px #ffc400, 2339px 429px #0057ff, 1225px 117px #ffffff, 1238px 37px #ffffff,
      1106px 467px #ffffff, 537px 2531px #ef5350, 1070px 2151px #ffc400, 152px 1891px #ef5350, 894px 934px #00bc64, 448px 1625px #0057ff, 1496px 2492px #00bc64,
      2501px 1675px #ffffff, 2205px 2179px #0057ff, 1314px 360px #ffc400, 1797px 156px #00bc64, 462px 170px #ef5350, 1746px 2419px #0057ff,
      1024px 1468px #ffc400, 1989px 277px #0057ff, 2038px 995px #ffffff, 905px 1870px #ffc400, 467px 1127px #0057ff, 2136px 2357px #ef5350,
      1541px 1053px #ef5350, 2151px 1596px #ef5350, 636px 2216px #00bc64, 1342px 2144px #00bc64, 413px 1389px #ffffff, 628px 1767px #ffc400,
      1528px 1816px #ffc400, 1447px 1818px #ffffff, 1268px 2482px #00bc64, 749px 1125px #0057ff, 2245px 2115px #0057ff, 1540px 2143px #00bc64,
      843px 17px #ef5350, 632px 75px #ffc400, 456px 463px #ffc400, 1970px 2040px #0057ff, 1588px 848px #ffffff, 1117px 2195px #ffc400, 2538px 1551px #00bc64,
      1955px 1557px #0057ff, 645px 1691px #ef5350, 1585px 1017px #ffffff, 309px 2003px #00bc64, 1662px 2005px #ffffff, 1607px 1812px #ef5350,
      54px 1798px #ffc400, 589px 1132px #0057ff, 1707px 2460px #ffffff, 2226px 282px #ffc400, 174px 106px #ffffff, 595px 1853px #ef5350, 1437px 1896px #ffc400,
      578px 897px #00bc64, 1916px 1583px #ffc400, 1543px 922px #ffc400, 1603px 1349px #ffffff, 1521px 710px #0057ff, 1705px 1105px #ffffff, 1544px 991px #ffffff,
      181px 1606px #ffc400, 302px 152px #ffffff, 1553px 2456px #ffc400, 2430px 184px #ffffff, 2526px 3px #ffc400, 536px 214px #00bc64, 297px 1141px #00bc64,
      349px 2122px #ef5350, 2560px 1454px #ef5350, 2127px 1879px #ef5350, 1884px 1039px #00bc64, 1356px 1427px #ffc400, 1232px 578px #ef5350,
      2269px 2516px #ef5350, 2227px 501px #ffc400, 1059px 34px #00bc64, 2013px 2104px #ffc400, 2128px 1700px #ef5350, 2282px 1910px #0057ff,
      2473px 1090px #ef5350, 2302px 661px #00bc64, 161px 1367px #ef5350, 905px 705px #ffc400, 2481px 2420px #ffffff, 1852px 369px #ffc400, 1131px 290px #ffffff,
      2358px 1774px #ffffff, 899px 161px #ef5350, 644px 1276px #ffffff, 1069px 2112px #ef5350, 656px 755px #ffc400, 2521px 1630px #00bc64, 1456px 1669px #0057ff,
      1288px 1801px #0057ff, 100px 1218px #ffc400, 1079px 45px #0057ff, 2105px 1761px #ffc400, 1625px 1051px #ef5350, 2170px 2414px #0057ff,
      419px 1309px #0057ff, 939px 1901px #ef5350, 641px 1008px #00bc64, 229px 1437px #ffc400, 1980px 1908px #0057ff, 2255px 2341px #0057ff,
      1342px 1608px #ffffff, 1807px 1456px #ffc400, 645px 1947px #ef5350, 1951px 89px #ef5350, 278px 2427px #0057ff, 43px 1840px #00bc64, 2551px 2300px #0057ff,
      312px 1653px #0057ff, 1095px 2324px #00bc64, 208px 1616px #0057ff, 264px 246px #ffc400, 2516px 1838px #00bc64, 1826px 907px #ffc400, 1553px 1803px #00bc64,
      2438px 839px #ef5350, 1655px 1363px #00bc64, 518px 1674px #0057ff, 74px 1225px #ffc400, 1846px 1595px #ef5350, 1963px 2476px #00bc64, 1607px 39px #00bc64,
      922px 193px #ffffff, 2494px 904px #ef5350, 2118px 910px #ffffff, 1719px 1051px #ffc400, 736px 105px #ef5350, 1078px 2236px #ef5350, 2160px 1909px #00bc64,
      1629px 1426px #00bc64, 615px 2403px #00bc64, 287px 699px #0057ff, 2270px 2412px #ffc400, 338px 2333px #ffc400, 1759px 1957px #ffc400, 1914px 52px #ffc400,
      99px 590px #ef5350, 470px 1353px #0057ff, 1125px 720px #00bc64, 1833px 1283px #ffc400, 1156px 371px #ef5350, 668px 1418px #0057ff, 1766px 825px #ffffff,
      2406px 349px #ffc400, 2317px 2039px #ffffff, 1246px 1189px #ef5350, 1545px 553px #0057ff;
    height: 3px;
    width: 3px;
  }
`

const Particle3 = styled.div`
  ${particle}

  animation: ${animParticle} 120s linear infinite;
  box-shadow: 0px 0px #ffffff, 2014px 451px #0057ff, 1551px 1226px #0057ff, 1829px 1665px #00bc64, 1154px 185px #0057ff, 1737px 1224px #0057ff,
    146px 31px #0057ff, 1746px 1391px #ef5350, 581px 769px #ef5350, 577px 701px #0057ff, 1210px 1552px #ffc400, 1583px 2555px #ef5350, 2422px 876px #0057ff,
    367px 2362px #ef5350, 2019px 1791px #ffc400, 1937px 351px #ffffff, 2252px 1859px #ef5350, 720px 1994px #0057ff, 651px 504px #00bc64, 2039px 1836px #ffc400,
    1118px 165px #00bc64, 996px 2000px #00bc64, 2108px 883px #00bc64, 1448px 1473px #0057ff, 341px 1393px #0057ff, 801px 89px #ffffff, 1303px 2455px #ef5350,
    13px 2448px #0057ff, 591px 822px #00bc64, 847px 2107px #0057ff, 1617px 587px #00bc64, 2253px 2513px #ffffff, 317px 1002px #00bc64, 1385px 904px #ffffff,
    1523px 254px #ffffff, 724px 1688px #00bc64, 2420px 27px #0057ff, 520px 560px #00bc64, 2226px 1300px #00bc64, 197px 1003px #0057ff, 2482px 703px #00bc64,
    591px 526px #ef5350, 1211px 642px #ffffff, 564px 1562px #0057ff, 1190px 1331px #ffffff, 2334px 186px #0057ff, 501px 722px #ffc400, 1476px 2389px #00bc64,
    832px 1957px #ffffff, 2251px 1651px #ffffff, 1079px 1721px #ffffff, 247px 1727px #ffffff, 1598px 2280px #ef5350, 925px 1001px #ffc400, 1535px 58px #0057ff,
    1361px 1527px #00bc64, 2400px 1123px #ffffff, 2372px 2074px #ef5350, 2251px 1858px #00bc64, 2317px 196px #0057ff, 794px 1869px #ef5350,
    1410px 2322px #00bc64, 198px 2159px #ef5350, 2225px 2028px #0057ff, 1613px 2352px #ffffff, 746px 1655px #0057ff, 1622px 51px #0057ff, 1475px 1839px #ffc400,
    319px 2229px #ffc400, 1555px 1776px #0057ff, 1454px 2384px #ffffff, 147px 1292px #ffc400, 1888px 527px #ffffff, 1177px 688px #00bc64, 2241px 764px #ef5350,
    1787px 567px #ffffff, 608px 108px #00bc64, 823px 1680px #00bc64, 1789px 1526px #00bc64, 1232px 741px #ef5350, 1345px 2263px #ef5350, 1890px 338px #00bc64,
    2431px 2490px #00bc64, 782px 1635px #0057ff, 783px 223px #ffffff, 173px 2215px #ffc400, 2500px 226px #ef5350, 2271px 1139px #ffc400, 1981px 2108px #ffc400,
    489px 513px #ffc400, 2004px 2054px #00bc64, 200px 180px #ffffff, 651px 528px #0057ff, 9px 357px #00bc64, 979px 816px #0057ff, 2512px 2187px #00bc64,
    689px 2133px #ef5350, 2342px 1222px #0057ff, 189px 2417px #0057ff, 171px 1280px #ffc400, 423px 1935px #00bc64;
  height: 4px;
  width: 4px;

  &:after {
    box-shadow: 0px 0px #ffffff, 2494px 767px #ffc400, 79px 1470px #00bc64, 656px 86px #ffc400, 699px 671px #00bc64, 1652px 2015px #ffffff,
      1930px 1689px #ffffff, 2076px 231px #00bc64, 1190px 2046px #ef5350, 489px 1123px #ffc400, 1185px 729px #ef5350, 492px 289px #ef5350, 1893px 1652px #ffffff,
      1410px 202px #0057ff, 433px 942px #ffc400, 31px 1401px #0057ff, 2204px 1754px #ffc400, 372px 1105px #ffc400, 665px 2531px #ef5350, 1058px 2428px #ffffff,
      742px 1947px #ffffff, 423px 2293px #ffc400, 77px 351px #0057ff, 1979px 1031px #ffc400, 2541px 1678px #0057ff, 319px 626px #ffc400, 1531px 634px #ffc400,
      1654px 2558px #ffc400, 1358px 1358px #ffc400, 1867px 1968px #ffffff, 1363px 2208px #ef5350, 1878px 74px #00bc64, 562px 886px #0057ff, 700px 884px #ffc400,
      1786px 477px #00bc64, 604px 1533px #ef5350, 980px 80px #0057ff, 202px 1864px #ffc400, 993px 2247px #ffc400, 496px 1198px #0057ff, 2497px 448px #ffffff,
      1248px 1057px #ffffff, 1586px 2471px #00bc64, 1378px 1474px #ffc400, 1926px 1422px #0057ff, 1008px 2054px #0057ff, 2326px 2512px #ffc400,
      387px 1307px #ef5350, 2172px 702px #ffffff, 1564px 648px #ffffff, 193px 2010px #ffffff, 2408px 476px #0057ff, 2391px 1005px #0057ff, 488px 1969px #ef5350,
      2047px 440px #0057ff, 1287px 1614px #0057ff, 1587px 1357px #ffc400, 851px 1002px #ffc400, 2369px 1706px #0057ff, 1041px 1617px #0057ff,
      2282px 997px #ffffff, 1835px 35px #0057ff, 178px 1032px #00bc64, 2px 514px #00bc64, 2447px 332px #00bc64, 1326px 864px #0057ff, 1465px 2395px #00bc64,
      1631px 1747px #0057ff, 1285px 1545px #ffffff, 1463px 1956px #ffffff, 1421px 2155px #ef5350, 1935px 2282px #ef5350, 1178px 1851px #0057ff,
      14px 352px #0057ff, 662px 569px #ffc400, 1126px 623px #00bc64, 2498px 747px #00bc64, 751px 684px #ffc400, 999px 862px #0057ff, 1472px 1267px #ef5350,
      1068px 363px #ffc400, 2499px 897px #ffffff, 1612px 436px #ef5350, 2075px 954px #ffc400, 1165px 1454px #ffc400, 421px 1720px #00bc64, 1679px 1033px #ef5350,
      1254px 1555px #ffc400, 71px 790px #ef5350, 2468px 1812px #0057ff, 1198px 2197px #ffffff, 2068px 101px #ffffff, 2148px 1297px #ffffff,
      1375px 2092px #ffffff, 1590px 1516px #ffc400, 819px 2104px #00bc64, 1644px 1742px #ef5350, 1411px 2323px #00bc64, 2455px 1833px #ef5350,
      1915px 124px #ffc400, 2163px 1710px #ef5350;
    height: 4px;
    width: 4px;
  }
`

const Particle4 = styled.div`
  ${particle}

  animation: ${animParticle} 400s linear infinite;
  box-shadow: 0px 0px #ffffff, 590px 761px #ef5350, 1247px 1760px #0057ff, 2480px 1840px #0057ff, 2327px 2002px #ef5350, 1408px 1037px #0057ff,
    196px 574px #ffffff, 181px 674px #0057ff, 635px 266px #0057ff, 1280px 1865px #ffc400, 1036px 265px #0057ff, 2412px 2454px #0057ff, 935px 2450px #ef5350,
    1559px 2142px #00bc64, 2432px 819px #00bc64, 2078px 1370px #00bc64, 74px 610px #0057ff, 23px 963px #ef5350, 1492px 1036px #ef5350, 726px 920px #00bc64,
    965px 1909px #00bc64, 1262px 1164px #00bc64, 452px 901px #ef5350, 75px 1129px #ffffff, 168px 2473px #00bc64, 1205px 98px #ffc400, 1857px 2019px #0057ff,
    2064px 879px #0057ff, 2430px 1194px #00bc64, 825px 1698px #00bc64, 2114px 1616px #0057ff, 683px 834px #ef5350, 2321px 143px #ef5350, 2305px 2131px #00bc64,
    16px 854px #ffffff, 2125px 122px #00bc64, 1063px 463px #ffc400, 1441px 1757px #ffc400, 1760px 1934px #ef5350, 752px 300px #ef5350, 1066px 2410px #00bc64,
    2307px 2225px #0057ff, 811px 958px #00bc64, 269px 1466px #00bc64, 1699px 1597px #00bc64, 408px 1374px #ffc400, 141px 1432px #ffc400, 1400px 1895px #ef5350,
    1240px 1133px #00bc64, 645px 100px #ef5350, 831px 2008px #00bc64, 1729px 164px #ef5350, 347px 315px #ffffff, 1116px 987px #00bc64, 1460px 285px #ffffff,
    816px 2073px #ffffff, 1322px 1088px #ef5350, 895px 1632px #00bc64, 1443px 1059px #ef5350, 2222px 846px #00bc64, 2138px 1940px #ffc400, 207px 2508px #ffffff,
    25px 1885px #ef5350, 1200px 2453px #00bc64, 698px 2019px #00bc64, 2076px 784px #ef5350, 2437px 235px #0057ff, 124px 1507px #ef5350, 1469px 968px #0057ff,
    238px 719px #ef5350, 459px 1027px #00bc64, 731px 90px #ffc400, 1990px 711px #ffffff, 2012px 595px #ef5350, 1117px 2418px #ef5350, 2421px 1019px #ffffff,
    781px 212px #00bc64, 129px 825px #00bc64, 1614px 2306px #ffc400, 1023px 2421px #ffc400, 498px 418px #ffffff, 162px 1831px #ffffff, 1991px 178px #ef5350,
    926px 332px #ffc400, 1567px 1421px #ffc400, 258px 213px #ffc400, 1725px 1891px #0057ff, 1267px 1255px #ef5350, 2090px 1496px #00bc64, 626px 917px #00bc64,
    1437px 74px #ffffff, 2412px 2265px #ef5350, 1279px 1526px #00bc64, 754px 1092px #ffc400, 470px 997px #00bc64, 1605px 362px #00bc64, 2136px 1572px #0057ff,
    1210px 2332px #00bc64, 1559px 2546px #ffffff, 167px 775px #ef5350, 1484px 49px #ffc400, 720px 25px #ffffff, 1246px 116px #ffffff, 1393px 1156px #ffc400,
    215px 83px #ffffff, 2014px 1558px #ffffff, 951px 1484px #00bc64, 387px 1767px #0057ff, 2272px 78px #0057ff, 2366px 2435px #ffffff, 1996px 621px #0057ff,
    1064px 394px #0057ff, 1481px 279px #0057ff, 1711px 743px #ffffff, 987px 2372px #00bc64, 288px 1954px #ffffff, 2192px 2067px #0057ff, 1045px 1742px #ffc400,
    939px 217px #ffffff, 447px 883px #0057ff, 1973px 2189px #ffffff, 889px 1938px #ffffff, 356px 1026px #00bc64, 1714px 1610px #0057ff, 1559px 1825px #ef5350,
    551px 2076px #ffc400, 309px 1007px #00bc64, 2268px 2555px #ef5350, 1834px 25px #ffffff, 1479px 724px #0057ff, 1681px 2003px #0057ff, 2553px 2162px #ffffff,
    789px 863px #0057ff, 36px 796px #ffc400, 397px 977px #00bc64, 470px 154px #0057ff, 2318px 886px #00bc64, 326px 1698px #00bc64, 1681px 431px #ffc400,
    6px 2253px #ffffff, 2534px 1200px #ef5350, 802px 492px #00bc64, 578px 1535px #ffc400, 900px 855px #ffc400, 1375px 2325px #ffffff, 2246px 1118px #00bc64,
    378px 719px #00bc64, 2172px 774px #ef5350, 1975px 352px #ffffff, 1230px 1613px #ffffff, 1482px 158px #ffffff, 863px 1762px #00bc64, 1755px 1782px #ffffff,
    333px 2161px #00bc64, 1030px 220px #ffc400, 1106px 605px #0057ff, 2437px 1623px #00bc64, 2278px 1746px #ffc400, 71px 2154px #0057ff, 918px 291px #0057ff,
    2398px 2155px #00bc64, 996px 2358px #0057ff, 518px 1809px #ffffff, 183px 1232px #0057ff, 1121px 46px #ffffff, 83px 2184px #0057ff, 955px 1860px #0057ff,
    1518px 1426px #ef5350, 1226px 1316px #ffc400, 2287px 879px #ffffff, 845px 1030px #ffc400, 1302px 1294px #ffc400, 918px 2234px #0057ff, 2195px 1545px #ffc400,
    1641px 1221px #ef5350, 706px 840px #ef5350, 2294px 1778px #00bc64, 839px 2517px #ffffff, 429px 51px #ffffff, 586px 451px #00bc64, 1587px 1228px #ffffff,
    1232px 1549px #00bc64, 1178px 1025px #ffc400, 2250px 865px #0057ff, 1327px 2074px #ffffff, 1367px 1611px #ffffff, 1848px 2162px #ffc400,
    2280px 1598px #ffc400, 1213px 2491px #ef5350, 2183px 926px #0057ff, 77px 2463px #ef5350, 556px 907px #00bc64, 721px 822px #00bc64, 1917px 1475px #ef5350,
    2244px 2281px #00bc64, 648px 1953px #ef5350, 45px 354px #ffc400, 204px 1388px #ffc400, 1773px 1813px #ef5350, 1912px 68px #ffffff, 2335px 2366px #00bc64,
    1730px 632px #00bc64, 461px 1792px #00bc64, 828px 2409px #ffffff, 783px 1293px #0057ff, 1298px 1210px #ef5350, 306px 897px #ef5350, 118px 1024px #00bc64,
    2558px 2322px #00bc64, 2076px 2034px #ffc400, 1581px 265px #0057ff, 292px 263px #ffc400, 926px 603px #00bc64, 2335px 2190px #ef5350, 1840px 1692px #ffffff,
    2317px 329px #00bc64, 1465px 578px #ef5350, 28px 368px #0057ff, 2084px 489px #ffc400, 1790px 2150px #ef5350, 245px 2150px #0057ff, 340px 1404px #0057ff,
    1705px 2512px #00bc64, 840px 2035px #ffc400, 302px 1903px #ffffff, 397px 2284px #ef5350, 1421px 2290px #ffc400, 671px 1193px #ef5350, 2557px 1557px #ffc400,
    1305px 1522px #ffc400, 1182px 1303px #ef5350, 739px 700px #00bc64, 1651px 1964px #0057ff, 780px 2274px #0057ff, 777px 2495px #00bc64, 541px 2444px #ef5350,
    459px 1963px #ffffff, 1948px 1027px #0057ff, 1452px 41px #00bc64, 1809px 2017px #ffc400, 2178px 836px #ffc400, 1747px 3px #ffc400, 300px 2295px #0057ff,
    1395px 138px #00bc64, 523px 156px #00bc64, 2226px 483px #0057ff, 85px 595px #ffffff, 1501px 2552px #ffffff, 2252px 1552px #ffc400, 147px 1144px #0057ff,
    974px 1531px #ffc400, 861px 1980px #0057ff, 1831px 1305px #ffffff, 1687px 80px #00bc64, 1556px 347px #00bc64, 787px 1171px #00bc64, 655px 836px #ffc400,
    1346px 1910px #ffffff, 387px 1620px #ffc400, 681px 2162px #ffffff, 371px 2261px #0057ff, 1065px 1982px #0057ff, 523px 1072px #ffc400, 1630px 1778px #0057ff,
    2380px 487px #ef5350, 293px 1528px #ffffff, 1908px 1054px #ffc400, 1693px 1250px #ef5350, 2399px 1387px #ef5350, 1647px 1239px #ffffff, 1136px 656px #ef5350,
    377px 491px #ef5350, 737px 2248px #0057ff, 416px 1402px #0057ff, 559px 2417px #ef5350, 869px 2209px #ffc400, 30px 838px #0057ff, 534px 1632px #00bc64,
    1357px 1366px #0057ff, 615px 1488px #ffc400, 1580px 2042px #ffc400, 1500px 2535px #0057ff, 297px 1044px #ffc400, 1833px 679px #00bc64, 478px 1503px #ffffff,
    845px 460px #ffc400, 1359px 961px #ef5350, 1710px 839px #ef5350, 2258px 2151px #ffc400, 71px 2214px #00bc64, 895px 2332px #ffc400, 48px 2040px #00bc64,
    580px 374px #ef5350, 1905px 2050px #00bc64, 1685px 2280px #ffffff, 99px 639px #ffffff, 2126px 2272px #ef5350, 1672px 853px #00bc64, 1046px 406px #00bc64,
    1880px 1801px #0057ff, 1197px 2005px #0057ff, 1398px 2111px #ef5350, 588px 1660px #ef5350, 1140px 1755px #ffc400, 129px 198px #ffffff, 1031px 871px #ef5350,
    1787px 2348px #ffc400, 2194px 20px #ffffff, 2261px 2392px #ffc400, 1338px 322px #0057ff, 1974px 2238px #0057ff, 2426px 660px #ffffff, 374px 245px #0057ff,
    1757px 1869px #ef5350, 815px 1875px #0057ff, 679px 523px #ffc400, 1369px 1120px #00bc64, 951px 1920px #ffc400, 1862px 1074px #ef5350, 2493px 572px #ffc400,
    807px 373px #0057ff, 1235px 1196px #00bc64, 120px 2495px #ffc400, 1619px 112px #ffffff, 2245px 617px #00bc64, 1160px 1053px #0057ff, 1528px 720px #ef5350,
    2262px 349px #ef5350, 1407px 1279px #0057ff, 2312px 234px #ffffff, 1831px 268px #ef5350, 210px 699px #0057ff, 1593px 2343px #ffffff, 2554px 1127px #ef5350,
    291px 2555px #ef5350, 1831px 198px #0057ff, 802px 225px #00bc64, 755px 1457px #ffffff, 1971px 1461px #ffc400, 123px 2161px #0057ff, 1886px 2191px #ffffff,
    545px 1982px #ef5350, 406px 505px #0057ff, 1763px 770px #ffc400, 199px 1557px #ffffff, 114px 763px #0057ff, 268px 2142px #ef5350, 2158px 1564px #00bc64,
    890px 1813px #ffc400, 474px 2481px #00bc64, 2286px 13px #0057ff, 1714px 1177px #0057ff, 2154px 367px #0057ff, 1192px 2295px #ef5350, 590px 832px #00bc64,
    1804px 196px #ffc400, 1913px 1095px #ffc400, 890px 353px #ffffff, 308px 2067px #ffc400, 1019px 1919px #ef5350, 1697px 1125px #ef5350, 2286px 2373px #00bc64,
    1388px 1792px #ef5350, 2318px 2124px #00bc64, 2153px 1576px #ef5350, 1184px 2445px #ef5350, 745px 367px #ef5350, 492px 1675px #ef5350, 2521px 339px #00bc64,
    390px 1164px #0057ff, 1131px 1342px #ffc400, 1069px 776px #00bc64, 2262px 1390px #ef5350, 1855px 721px #0057ff, 1237px 1761px #ffc400, 2272px 1954px #0057ff,
    2412px 2145px #ffffff, 964px 619px #ffffff, 1533px 2132px #ef5350, 1720px 729px #ffc400, 1175px 931px #ffc400, 1230px 189px #ffc400, 1649px 971px #ffc400,
    262px 378px #ffc400, 1828px 1919px #0057ff, 1272px 584px #0057ff, 1018px 1639px #00bc64, 1957px 603px #ffffff, 289px 372px #0057ff, 767px 2320px #00bc64,
    832px 1869px #ef5350, 2222px 1739px #ffc400, 1481px 1264px #ffffff, 1409px 2559px #0057ff, 2255px 1546px #ef5350, 1761px 486px #ef5350, 2030px 662px #ffc400,
    939px 27px #ef5350, 482px 927px #ef5350, 1811px 2505px #ffffff, 841px 2441px #ffffff;
  height: 5px;
  width: 5px;

  &:after {
    box-shadow: 0px 0px #ffffff, 798px 1517px #ef5350, 2421px 1105px #ffc400, 2322px 41px #00bc64, 426px 620px #00bc64, 1313px 1764px #0057ff,
      233px 680px #ef5350, 981px 261px #ffffff, 588px 413px #0057ff, 940px 225px #00bc64, 2268px 2482px #ef5350, 1531px 1119px #ef5350, 1781px 70px #00bc64,
      1614px 1216px #ef5350, 1084px 2218px #ef5350, 1382px 1319px #ffffff, 294px 1735px #00bc64, 40px 436px #ef5350, 123px 2121px #ffc400, 1283px 959px #ffc400,
      1335px 295px #ffc400, 256px 1693px #ef5350, 1498px 1875px #00bc64, 811px 1262px #ffc400, 579px 705px #ffffff, 933px 2004px #ffc400, 1617px 239px #00bc64,
      1686px 54px #0057ff, 1363px 1569px #00bc64, 351px 2477px #00bc64, 515px 1711px #ffc400, 2117px 1801px #ffc400, 282px 2143px #00bc64, 369px 385px #ffffff,
      302px 1506px #ffffff, 639px 2337px #ffffff, 2302px 2345px #0057ff, 1451px 342px #ef5350, 1834px 1812px #0057ff, 2306px 942px #ffffff,
      2560px 1045px #ffffff, 1257px 2553px #ffc400, 1159px 182px #ffffff, 1788px 2009px #0057ff, 1012px 402px #ffffff, 1039px 230px #ffc400, 424px 65px #ef5350,
      1082px 256px #ffffff, 1025px 511px #ef5350, 2545px 207px #0057ff, 809px 1044px #00bc64, 1928px 437px #ef5350, 1139px 1403px #ffc400, 1640px 895px #ef5350,
      2195px 2442px #0057ff, 1795px 88px #ffc400, 910px 552px #ffffff, 1450px 739px #ffc400, 1786px 911px #00bc64, 1340px 982px #0057ff, 2263px 627px #0057ff,
      7px 1754px #ef5350, 594px 1252px #ef5350, 1674px 450px #ef5350, 836px 1408px #ef5350, 376px 125px #0057ff, 1546px 621px #0057ff, 82px 2286px #0057ff,
      2018px 629px #ffc400, 1365px 633px #ef5350, 1482px 2105px #ffc400, 1698px 940px #0057ff, 1251px 1002px #ef5350, 1833px 2066px #00bc64, 580px 660px #0057ff,
      1501px 736px #00bc64, 886px 677px #00bc64, 1787px 995px #00bc64, 2320px 2180px #ffc400, 1385px 1299px #ffc400, 1380px 925px #0057ff, 264px 2167px #ef5350,
      694px 316px #ffffff, 2189px 808px #ef5350, 1433px 1253px #ef5350, 1599px 2230px #00bc64, 2095px 525px #0057ff, 1933px 2442px #ffc400,
      1815px 1644px #ffc400, 879px 634px #0057ff, 148px 2410px #ffc400, 2406px 73px #00bc64, 1582px 660px #00bc64, 183px 1116px #ef5350, 2372px 1585px #ef5350,
      2559px 813px #0057ff, 467px 8px #ffc400, 1403px 183px #ffffff, 786px 1892px #0057ff, 342px 1488px #ffc400, 1171px 2296px #ffc400, 582px 753px #0057ff,
      415px 26px #ffc400, 1935px 185px #00bc64, 1545px 2327px #0057ff, 2417px 991px #ffffff, 2272px 2225px #ef5350, 2048px 874px #ffc400, 159px 1092px #00bc64,
      1439px 1881px #ffc400, 2252px 1414px #ffc400, 1006px 2142px #ffffff, 481px 1597px #00bc64, 809px 1143px #00bc64, 1865px 874px #0057ff, 996px 747px #00bc64,
      1384px 2339px #ffc400, 1219px 216px #0057ff, 803px 1202px #0057ff, 1625px 1828px #0057ff, 2073px 1788px #ffc400, 1898px 2367px #00bc64,
      32px 1830px #00bc64, 2310px 2169px #ffffff, 2132px 2478px #ef5350, 87px 149px #ef5350, 1969px 2005px #ef5350, 2439px 821px #ef5350, 1795px 1449px #0057ff,
      1344px 911px #ffffff, 757px 620px #ffc400, 1067px 1741px #ffffff, 302px 2167px #ef5350, 2457px 148px #0057ff, 828px 602px #00bc64, 2153px 1231px #00bc64,
      275px 2553px #00bc64, 65px 1311px #ffffff, 2000px 2534px #0057ff, 1159px 531px #ffffff, 2401px 1948px #ef5350, 2130px 27px #ffc400, 729px 2395px #00bc64,
      2487px 1121px #0057ff, 1263px 2454px #0057ff, 1615px 1597px #ffffff, 1576px 1111px #00bc64, 1055px 1376px #ef5350, 589px 2003px #ef5350,
      2412px 2135px #ffffff, 2010px 1404px #0057ff, 1063px 1997px #ffffff, 2478px 1449px #ef5350, 1182px 163px #00bc64, 364px 1238px #00bc64,
      2179px 2429px #00bc64, 191px 1817px #0057ff, 2003px 1178px #ef5350, 926px 1720px #ffc400, 403px 1996px #0057ff, 739px 409px #00bc64, 369px 68px #ef5350,
      2251px 1311px #ffffff, 657px 2041px #ef5350, 1446px 1988px #00bc64, 969px 797px #00bc64, 1424px 2437px #ffc400, 1523px 665px #ffc400, 598px 688px #ffffff,
      1412px 525px #00bc64, 842px 1528px #ffc400, 1059px 1565px #ffffff, 1887px 1441px #ffc400, 1405px 2254px #ef5350, 723px 1059px #0057ff,
      324px 1145px #ffffff, 424px 464px #ffffff, 2411px 267px #0057ff, 2208px 617px #00bc64, 2502px 2246px #ffc400, 1481px 1960px #ffc400, 794px 1258px #ffc400,
      864px 2461px #ffc400, 49px 970px #ffc400, 1245px 64px #ffffff, 466px 1403px #00bc64, 1443px 1390px #ffc400, 464px 1404px #ef5350, 576px 161px #ffffff,
      1769px 646px #ffc400, 1154px 1644px #00bc64, 1619px 179px #ef5350, 2194px 1851px #ef5350, 864px 2388px #ef5350, 1187px 1483px #ffc400,
      1224px 1985px #ffc400, 1208px 358px #ffc400, 1937px 2087px #ef5350, 1855px 1363px #ef5350, 2215px 2311px #ffffff, 1590px 1012px #ffc400,
      1660px 228px #0057ff, 2311px 1587px #00bc64, 2221px 956px #00bc64, 1715px 170px #ffc400, 2032px 1997px #ef5350, 366px 708px #ef5350, 2513px 1392px #ffc400,
      1433px 1879px #ffffff, 617px 1268px #ffc400, 1223px 953px #0057ff, 1344px 299px #ef5350, 1405px 1523px #0057ff, 2142px 1639px #ef5350,
      2161px 570px #ffc400, 1674px 1262px #0057ff, 2000px 1797px #ffc400, 188px 1564px #ef5350, 1838px 227px #00bc64, 2055px 1890px #ffffff,
      365px 1212px #00bc64, 1855px 2467px #ef5350, 122px 284px #00bc64, 1604px 522px #ffffff, 2039px 1939px #ffffff, 1918px 104px #ffffff, 1147px 20px #ef5350,
      2046px 820px #ffc400, 1467px 1022px #0057ff, 2226px 137px #ffffff, 2329px 1388px #ffc400, 1783px 732px #ef5350, 1624px 2181px #ffc400,
      2354px 573px #ef5350, 2322px 933px #ffc400, 1017px 687px #0057ff, 1960px 740px #ffffff, 1472px 863px #ffffff, 2172px 1905px #ffffff, 1477px 1605px #ef5350,
      1436px 1948px #00bc64, 1964px 341px #00bc64, 313px 1763px #ef5350, 826px 165px #0057ff, 1130px 2302px #00bc64, 77px 1743px #ffffff, 1884px 1534px #ffc400,
      2345px 856px #00bc64, 883px 2186px #00bc64, 984px 2329px #0057ff, 1910px 2222px #ffffff, 1140px 1096px #ef5350, 1344px 1385px #0057ff,
      1341px 2231px #ffffff, 1707px 1059px #00bc64, 573px 2337px #ffffff, 166px 1451px #ef5350, 226px 151px #0057ff, 183px 50px #0057ff, 1897px 1253px #ef5350,
      252px 2240px #ffffff, 1957px 1709px #ef5350, 1455px 615px #ffffff, 35px 1976px #ef5350, 1697px 387px #00bc64, 1438px 818px #ffffff, 1576px 1590px #ef5350,
      291px 1760px #ffc400, 562px 2474px #0057ff, 188px 1283px #ffffff, 2259px 2178px #ffffff, 1597px 480px #ef5350, 963px 2135px #ffc400, 953px 2471px #0057ff,
      2454px 868px #ffffff, 2081px 2439px #ef5350, 1752px 1373px #00bc64, 1386px 1906px #ffffff, 771px 1741px #0057ff, 2542px 1477px #ffc400,
      2530px 1035px #ffc400, 43px 347px #ef5350, 2293px 1937px #ef5350, 2004px 2261px #ffffff, 964px 1637px #ffc400, 892px 1804px #ffc400, 1247px 1166px #ef5350,
      726px 427px #ffffff, 2393px 1316px #0057ff, 1816px 542px #ef5350, 1818px 579px #0057ff, 214px 1253px #ffffff, 1758px 1852px #ffc400, 571px 163px #ffffff,
      1774px 810px #0057ff, 1432px 2451px #00bc64, 1858px 348px #0057ff, 150px 50px #0057ff, 228px 1587px #ef5350, 749px 208px #ffc400, 2176px 2487px #00bc64,
      1820px 1788px #ffc400, 1869px 1766px #ef5350, 715px 794px #00bc64, 2524px 1161px #0057ff, 1714px 2238px #00bc64, 30px 88px #ef5350, 2477px 1875px #ffc400,
      162px 168px #ffffff, 349px 276px #00bc64, 319px 1970px #ef5350, 316px 1173px #00bc64, 647px 269px #ef5350, 2355px 996px #ef5350, 445px 1987px #ffc400,
      2553px 1384px #ffffff, 1816px 1505px #00bc64, 1918px 1630px #ef5350, 2238px 213px #00bc64, 25px 1164px #0057ff, 2505px 644px #0057ff, 963px 674px #ffffff,
      1514px 2128px #0057ff, 152px 2149px #0057ff, 541px 1988px #ef5350, 816px 1456px #ffffff, 2085px 1260px #0057ff, 2547px 2049px #00bc64, 2036px 30px #0057ff,
      1169px 1248px #0057ff, 1895px 688px #ffc400, 347px 355px #0057ff, 720px 1718px #ffffff, 2119px 1316px #0057ff, 641px 2188px #ffffff, 1454px 2129px #ffffff,
      170px 784px #ef5350, 592px 2540px #ffffff, 251px 2382px #ffffff, 2088px 1100px #0057ff, 1980px 2358px #ffc400, 350px 2435px #ef5350, 964px 1632px #ffc400,
      1032px 2330px #0057ff, 2398px 492px #00bc64, 381px 1381px #ef5350, 192px 260px #ffffff, 1027px 289px #ffffff, 2162px 225px #0057ff, 265px 487px #ffffff,
      107px 1218px #ffffff, 1727px 884px #00bc64, 102px 1927px #ffc400, 807px 634px #ffc400, 295px 1884px #ffc400, 2084px 2542px #ef5350, 612px 329px #ffc400,
      1038px 2523px #0057ff, 427px 2147px #00bc64, 33px 1574px #00bc64, 1070px 1275px #0057ff, 1945px 1659px #0057ff, 1701px 2414px #ffc400,
      2489px 1560px #0057ff, 2497px 449px #0057ff, 887px 948px #0057ff, 523px 1446px #ffc400, 628px 1953px #ef5350, 859px 1034px #0057ff, 2528px 2466px #0057ff,
      47px 1443px #ef5350, 1457px 1318px #ef5350, 1012px 2359px #00bc64, 107px 476px #ffffff, 91px 2525px #ef5350, 392px 976px #0057ff, 1341px 110px #00bc64,
      1717px 722px #0057ff, 1036px 2444px #ffc400, 2446px 713px #0057ff, 1172px 13px #00bc64, 1035px 1888px #ffc400, 2107px 898px #00bc64, 127px 2070px #ffffff,
      1448px 2150px #00bc64, 708px 539px #ffffff, 2220px 1031px #ef5350, 67px 2334px #0057ff, 268px 480px #ef5350, 675px 2401px #ffffff, 1719px 2528px #0057ff,
      1316px 399px #00bc64, 813px 495px #ffc400, 662px 2513px #00bc64, 1593px 126px #0057ff, 2077px 1964px #0057ff, 2355px 450px #0057ff, 1809px 2388px #ffffff,
      743px 664px #ffffff, 128px 639px #ffffff, 407px 216px #ef5350;
    height: 5px;
    width: 5px;
  }
`
 */
const Particles: React.FC = ({ children }) => (
  <ParticlesWrapper>
    {/* <Particle1 />
    <Particle2 />
    <Particle3 />
    <Particle4 /> */}
    {children}
  </ParticlesWrapper>
)

export default Particles
