import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconGateio = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20 20.004' fill={color} {...rest}>
      <g id='Group_24046' data-name='Group 24046'>
        <path
          id='Path_4984'
          data-name='Path 4984'
          d='M10,15.5a5.5,5.5,0,1,1,0-11V0A10,10,0,1,0,20,10H15.5A5.5,5.5,0,0,1,10,15.5'
          fill={color}
          fillRule='evenodd'
        />
        <rect id='Rectangle_6487' data-name='Rectangle 6487' width='5.525' height='5.525' transform='translate(9.982 4.496)' fill={color} />
      </g>
    </svg>
  )
})

export default IconGateio
