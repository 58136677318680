import React from 'react'

interface IIconLoanRepay {
  color?: string
  size?: number
}

const IconLoanRepay = React.forwardRef<SVGSVGElement, IIconLoanRepay>(({ color = 'currentColor', size = 29.791, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 29.791 29.791' fill={color} stroke={color} {...rest}>
      <g id='Group_5507' data-name='Group 5507' transform='translate(-277.256 -135.756)'>
        <g id='Group_3525' data-name='Group 3525' transform='translate(287.811 144.901)'>
          <g id='Group_3526' data-name='Group 3526' transform='translate(0)'>
            <path
              id='Path_1213'
              data-name='Path 1213'
              d='M106.74,209.622c-2.474,0-4.34-1.037-4.34-2.411s1.866-2.411,4.34-2.411,4.34,1.037,4.34,2.411-1.866,2.411-4.34,2.411m0-3.858c-1.932,0-3.376.764-3.376,1.447s1.444,1.447,3.376,1.447,3.376-.764,3.376-1.447-1.444-1.447-3.376-1.447'
              transform='translate(-102.4 -204.799)'
              fill={color}
              stroke={color}
              strokeWidth='1'
            />
            <path
              id='Path_1214'
              data-name='Path 1214'
              d='M106.74,244.72c-2.474,0-4.34-1.037-4.34-2.411v-2.894a.482.482,0,0,1,.964,0v2.894c0,.683,1.444,1.447,3.376,1.447s3.376-.764,3.376-1.447v-2.894a.482.482,0,0,1,.964,0v2.894c0,1.374-1.866,2.411-4.34,2.411'
              transform='translate(-102.4 -236.521)'
              fill={color}
              stroke={color}
              strokeWidth='1'
            />
            <path
              id='Path_1217'
              data-name='Path 1217'
              d='M106.74,244.72c-2.474,0-4.34-1.037-4.34-2.411v-2.894a.482.482,0,0,1,.964,0v2.894c0,.683,1.444,1.447,3.376,1.447s3.376-.764,3.376-1.447v-2.894a.482.482,0,0,1,.964,0v2.894c0,1.374-1.866,2.411-4.34,2.411'
              transform='translate(-102.4 -233.22)'
              fill={color}
              stroke={color}
              strokeWidth='1'
            />
          </g>
        </g>
        <path
          id='Path_1215'
          data-name='Path 1215'
          d='M14.4,28.791A14.412,14.412,0,0,1,0,14.4a.554.554,0,0,1,1.107,0A13.3,13.3,0,1,0,4.3,5.764a.554.554,0,0,1-.842-.72A14.394,14.394,0,1,1,14.4,28.791'
          transform='translate(277.756 136.256)'
          fill={color}
          stroke={color}
          strokeWidth='1'
        />
        <path
          id='Path_1216'
          data-name='Path 1216'
          d='M56.183,6.644a.566.566,0,0,1-.085-.007l-4.429-.687A.554.554,0,0,1,51.2,5.4V.554a.554.554,0,0,1,1.107,0V4.93l3.961.613a.554.554,0,0,1-.085,1.1'
          transform='translate(229.877 136.256)'
          fill={color}
          stroke={color}
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconLoanRepay
