import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMail = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'white', size = 30, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 30.058 25.936'
      fill={color}
      stroke={color}
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='mail' transform='translate(0.483 0.477)'>
        <path
          id='Shape'
          d='M26.727,24.936H2.332A2.334,2.334,0,0,1,0,22.6V2.331A2.334,2.334,0,0,1,2.332,0H26.727a2.334,2.334,0,0,1,2.332,2.331V22.6A2.334,2.334,0,0,1,26.727,24.936ZM2.332,3.278h0V22.6H26.727l0-19.319-9.643,8.06a3.469,3.469,0,0,1-2.468,1.032H14.6a3.482,3.482,0,0,1-2.462-1.014Zm2.52-.95h0l8.867,7.315.046.046a1.166,1.166,0,0,0,1.679-.009l.046-.049.053-.045,8.684-7.258H4.852Z'
          transform='translate(0.017 0.023)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconMail
