import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useReserves, { Reserve } from 'hooks/common/useReserves'
import isEmpty from 'lodash/isEmpty'
import { useMulticallContract, useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from 'multicall-module'
import { lendPoolIcon } from 'utils'
import { ERC20_INTERFACE, isAddress, useTokenContract } from 'wallet-module'

import { APECOIN_ADDRESS, BEND_ADDRESS, VE_BEND_ADDRESS } from '../constants'

const showDebug = false

export function useBalanceOf(token?: any): {
  token: string
  amount: string
} {
  const { account } = useWeb3React()
  const contract = useTokenContract(token?.address, false)

  const { result } = useSingleCallResult(contract, 'balanceOf', [account ?? undefined])
  const data = result?.[0]

  return useMemo(
    () =>
      data
        ? {
            token: token?.address,
            amount: data.toString()
          }
        : {
            token: token?.address,
            amount: ''
          },
    [token, data]
  )
}
/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(uncheckedAddresses?: (string | null | undefined)[]): { [address: string]: any | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: any }>(
        (memo, address, i) => {
          const value = results?.[i]?.result?.[0]
          if (value)
            memo[address] = {
              token: 'ETH',
              amount: value.toString()
            }
          return memo
        },
        {
          token: 'ETH',
          amount: ''
        }
      ),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (string | undefined)[]
): [{ [tokenAddress: string]: any | undefined }, boolean] {
  const validatedTokens: string[] = useMemo(() => tokens?.filter((t?: string): t is string => isAddress(t) !== false) ?? [], [tokens])
  if (showDebug) console.log('useAllowancesWithLoadingIndicator got validatedTokens', validatedTokens)
  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt), [validatedTokens])

  if (showDebug) console.log('useTokenBalancesWithLoadingIndicator got validatedTokenAddresses', validatedTokenAddresses)
  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  if (showDebug) console.log('useTokenBalancesWithLoadingIndicator got balances', balances)
  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{
              [tokenAddress: string]: any | undefined
            }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? value.toString() : undefined
              if (amount) {
                memo[token] = {
                  token: token,
                  amount: amount
                }
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(address?: string, tokens?: (string | undefined)[]): { [tokenAddress: string]: any | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

export function useReserveBalancesWithLoadingIndicator(
  address?: string,
  tokens?: { [x: string]: Reserve }
): [{ [tokenAddress: string]: any | undefined }, boolean] {
  const validatedTokenAddresses = useMemo(() => {
    if (tokens)
      return Object.keys(tokens).map((key: string) => {
        return key
      })

    return []
  }, [tokens])

  if (showDebug) console.log('useReserveBalancesWithLoadingIndicator got validatedTokenAddresses', JSON.stringify(validatedTokenAddresses))
  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  if (showDebug) console.log('useReserveBalancesWithLoadingIndicator got balances', JSON.stringify(balances))
  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(() => {
      if (address && validatedTokenAddresses.length > 0 && tokens) {
        const bals: any = []
        Object.keys(tokens).map((key: string, index: number) => {
          bals[key] = {
            walletBalance: new BigNumber(balances?.[index]?.result?.[0]?._hex)
          }
        })
        return bals
      } else return {}
    }, [address, validatedTokenAddresses, tokens, balances]),
    anyLoading
  ]
}

export function useReserveBalances(address?: string, tokens?: { [x: string]: Reserve } | undefined): { [tokenAddress: string]: any | undefined } {
  return useReserveBalancesWithLoadingIndicator(address, tokens)[0]
}

export function useBalances(): {
  eth: BigNumber
  reserve: any
} {
  const { account } = useWeb3React()
  //const { dashboardMyDeposits } = useDataContext()
  const multicallContract = useMulticallContract()
  const { data } = useReserves(false)

  const reserves = useMemo(() => {
    if (!data || isEmpty(data)) return []
    return data.map((reserve: Reserve) => ({
      key: `${reserve.id}`,
      id: reserve.id,
      assetName: reserve.symbol,
      assetIcon: lendPoolIcon(reserve.underlyingAsset),
      assetAddress: reserve.underlyingAsset,
      walletBalance: '-',
      decimals: reserve.decimals,
      isFrozen: reserve.isFrozen
    }))
  }, [data])

  const address = useMemo(() => account ?? '', [account])

  const { result: ethBalance } = useSingleCallResult(account ? multicallContract : undefined, 'getEthBalance', [address])

  //console.log('ethBalance', ethBalance?.[0].toString())

  const formatedEthBalance = useMemo(() => new BigNumber(ethBalance?.[0].toString()).dividedBy(1e18), [ethBalance])
  //console.log('formatedEthBalance', formatedEthBalance.toFixed(5))

  const validatedTokenAddresses = useMemo(() => {
    let addresses = []
    if (reserves)
      addresses = Object.keys(reserves).map((key: string) => {
        return reserves[key].assetAddress
      })
    addresses.push(BEND_ADDRESS)
    addresses.push(VE_BEND_ADDRESS)
    addresses.push(APECOIN_ADDRESS)

    return addresses
  }, [reserves])

  const balances = useMultipleContractSingleData(account ? validatedTokenAddresses : [undefined], ERC20_INTERFACE, 'balanceOf', account ? [address] : undefined)

  const formatedBalances = useMemo(
    () =>
      address && validatedTokenAddresses.length > 0
        ? validatedTokenAddresses.reduce<{
            [tokenAddress: string]: any | undefined
          }>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            const amount = value ? new BigNumber(value.toString()) : new BigNumber(0)
            if (amount) {
              memo[token] = {
                token: token,
                amount: amount
              }
            }
            return memo
          }, {})
        : {},
    [address, validatedTokenAddresses, balances]
  )

  return {
    eth: formatedEthBalance,
    reserve: formatedBalances
  }
}
