import React from 'react'

interface IIcon {
  color?: string
}

const IconBugBounty = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor' }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width='16.655' height='14.334' viewBox='0 0 16.655 14.334' fill={color}>
      <path
        id='Path_2660'
        data-name='Path 2660'
        d='M4.815,7.152l3.5-3.56L11.84,7.183l-3.5,3.56ZM4.351,0,0,4.429l2.656,2.7L0,9.836l4.4,4.48,1.12-1.109L2.209,9.836,3.726,8.291l3.9,3.967v1.966H9.131V12.189L12.91,8.342l1.536,1.564-3.262,3.32L12.3,14.334l4.351-4.429L14,7.2l2.655-2.7L12.255.019l-1.12,1.109L14.447,4.5,12.929,6.043l-3.8-3.866V.111H7.623V2.045L3.745,5.993,2.209,4.429l3.262-3.32Z'
        fill={color}
      />
    </svg>
  )
})

export default IconBugBounty
