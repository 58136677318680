import React from 'react'

interface IIconUnstake {
  color?: string
  size?: number
}

const IconUnstake = React.forwardRef<SVGSVGElement, IIconUnstake>(({ color = 'currentColor', size = 32.38, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 32.38 30.63'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Subtraction_136'
        data-name='Subtraction 136'
        d='M9450.389-7703.371a2.965,2.965,0,0,1-2.962-2.962h-11.853a2.967,2.967,0,0,1-2.964,2.962,2.965,2.965,0,0,1-2.962-2.962v-.273a4.453,4.453,0,0,1-2.964-4.172v-.408h2.964v.408a1.483,1.483,0,0,0,1.481,1.481h20.739a1.484,1.484,0,0,0,1.483-1.481v-17.778a1.483,1.483,0,0,0-1.483-1.48H9431.13a1.483,1.483,0,0,0-1.481,1.48v.371h-2.964v-.371a4.45,4.45,0,0,1,4.445-4.445h20.739a4.45,4.45,0,0,1,4.445,4.445v17.778a4.454,4.454,0,0,1-2.962,4.172v.273A2.966,2.966,0,0,1,9450.389-7703.371Z'
        transform='translate(-9424.435 7733.501)'
        fill='#fff'
        stroke='rgba(0,0,0,0)'
        strokeMiterlimit='10'
        strokeWidth='1'
      />
      <path
        id='Path_1142'
        data-name='Path 1142'
        d='M8.63,6.4,5.38,3.152h.652A1.576,1.576,0,1,0,6.032,0H1.576A1.576,1.576,0,0,0,0,1.576V6.033a1.576,1.576,0,1,0,3.151,0V5.38L6.4,8.629A1.576,1.576,0,0,0,8.63,6.4Z'
        transform='translate(0.141 13.834) rotate(-45)'
        fill='#fff'
        stroke='#0057ff'
        strokeWidth='0.2'
      />
      <path
        id='Path_1172'
        data-name='Path 1172'
        d='M143.851,85.333a5.171,5.171,0,0,0-1.481,10.129v3.2a1.481,1.481,0,1,0,2.963,0v-3.2a5.171,5.171,0,0,0-1.481-10.129m0,7.407a2.222,2.222,0,1,1,2.222-2.222,2.225,2.225,0,0,1-2.222,2.222'
        transform='translate(107.805 -130.017) rotate(90)'
        fill='#fff'
      />
    </svg>
  )
})

export default IconUnstake
