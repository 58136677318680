import { createContext, useCallback, useContext, useReducer } from 'react'
import { SxStyleProp } from 'rebass/styled-components'

type WalletCheckProviderProps = {
  children: React.ReactNode
}

type WalletCheckState = {
  style: SxStyleProp
}

type WalletCheckContext = {
  setWalletCheckStyle: (style: SxStyleProp) => void
  clearWalletCheckStyle: () => void
}

type WalletCheckReducerAction = {
  action: 'setStyle' | 'clearStyle'
  payload: SxStyleProp
}

const initialState: WalletCheckState = {
  style: {}
}

const WalletCheckContextProps = createContext<WalletCheckState & WalletCheckContext>({
  ...initialState,
  setWalletCheckStyle: () => {},
  clearWalletCheckStyle: () => {}
})

export const useWalletCheckContext = () => useContext(WalletCheckContextProps)

const reducer = (state: WalletCheckState, { action, payload }: WalletCheckReducerAction) => {
  switch (action) {
    default:
    case 'clearStyle':
      return { ...state, style: {} }
    case 'setStyle':
      return { ...state, style: payload }
  }
}

export default function WalletCheckProvider({ children }: WalletCheckProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setWalletCheckStyle = useCallback(
    (payload: SxStyleProp) =>
      dispatch({
        action: 'setStyle',
        payload
      }),
    []
  )

  const clearWalletCheckStyle = useCallback(
    () =>
      dispatch({
        action: 'clearStyle',
        payload: {}
      }),
    []
  )

  return <WalletCheckContextProps.Provider value={{ ...state, setWalletCheckStyle, clearWalletCheckStyle }}>{children}</WalletCheckContextProps.Provider>
}
