import React from 'react'

interface IIconProfileReferralCode {
  color?: string
  size?: number
}

const IconProfileReferralCode = React.forwardRef<SVGSVGElement, IIconProfileReferralCode>(({ color = 'currentColor', size = 25, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 28.519 25.83'
      fill={color}
      stroke={color}
      {...rest}
    >
      <path id='Path_2488' data-name='Path 2488' d='M4,25.324A4.006,4.006,0,0,0,7.9,22.2H20.1a4,4,0,1,0,3.025-4.778q-.16.036-.316.085l-6.1-10.562a4,4,0,1,0-5.652-.236q.113.123.236.236l-6.1,10.562A4,4,0,1,0,4,25.324Zm22.25-4A2.25,2.25,0,1,1,24,19.074a2.25,2.25,0,0,1,2.25,2.25M14,1.753A2.25,2.25,0,1,1,11.751,4,2.25,2.25,0,0,1,14,1.753M12.807,7.821a4.006,4.006,0,0,0,2.388,0l6.1,10.562A4,4,0,0,0,20.1,20.449H7.9a4,4,0,0,0-1.194-2.066ZM4,19.074a2.25,2.25,0,1,1-2.25,2.25A2.25,2.25,0,0,1,4,19.074' transform='translate(0.261 0.251)' fill={color} stroke={color} strokeWidth='0.5'/>
    </svg>

  )
})

export default IconProfileReferralCode
