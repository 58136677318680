import React from 'react'

interface IIconInfo {
  color?: string
  size?: number
}

const IconInfo = React.forwardRef<SVGSVGElement, IIconInfo>(({ color = 'currentColor', size = 17, ...rest }, ref) => {
  return (
    <svg x='0px' y='0px' width={size} height={size} viewBox='0 0 17 17' fill={color} ref={ref} {...rest}>
      <g>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M14.5,2.5C12.9,0.9,10.8,0,8.5,0c0,0,0,0,0,0c-2.2,0-4.4,0.9-6,2.5c-3.3,3.3-3.3,8.7,0,12c1.7,1.7,3.8,2.5,6,2.5
          c2.2,0,4.4-0.8,6-2.5c1.6-1.6,2.5-3.7,2.5-6C17,6.2,16.1,4.1,14.5,2.5z M15.5,8.7c-0.1,3.7-3.1,6.7-6.8,6.7c-0.1,0-0.1,0-0.2,0h0
          c0,0,0,0,0,0c-0.9,0-1.8-0.2-2.7-0.5C5,14.5,4.3,14,3.6,13.4c-1.3-1.3-2-3-2-4.8c0-1.8,0.7-3.6,2-4.8c1.3-1.3,3-2,4.8-2
          c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0C12.6,1.7,15.6,4.9,15.5,8.7z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M8.5,7.6c-0.5,0-0.9,0.3-0.9,0.8l0,4.1c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0.1,0c0.5,0,0.9-0.3,0.9-0.8l0-4.1
          C9.4,7.9,9,7.5,8.5,7.6z'
        />
        <path fill={color} stroke={color} strokeWidth='0.0' d='M8.5,3.8c-0.5,0-1,0.4-1,1s0.4,1,1,1c0.5,0,1-0.4,1-1S9.1,3.8,8.5,3.8z' />
      </g>
    </svg>
  )
})

export default IconInfo
