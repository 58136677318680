import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconQuestion = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 35, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 35 35'
      fill='none'
      stroke={color}
      strokeWidth='0.3'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='question-circle-o' transform='translate(0.25 0.25)'>
        <path
          id='Shape'
          d='M5.127,29.875A17.5,17.5,0,0,1,29.875,5.124,17.5,17.5,0,1,1,5.127,29.875ZM11.644,3.642A15.045,15.045,0,1,0,28.136,6.861a15.183,15.183,0,0,0-4.784-3.222,15.086,15.086,0,0,0-11.707,0Zm4.894,23.93a1.484,1.484,0,1,1,.74.2A1.482,1.482,0,0,1,16.539,27.572Zm.661-4.888a1.233,1.233,0,0,1-1.1-1.347c.168-1.706,1.419-3.083,2.8-4.461,2.2-2.2,2.243-2.9,2.28-3.646a3.167,3.167,0,0,0-.9-2.363,3.841,3.841,0,0,0-2.776-1.183h-.007A3.681,3.681,0,0,0,13.82,13.36a1.23,1.23,0,0,1-2.461,0,6.133,6.133,0,0,1,6.121-6.136,6.314,6.314,0,0,1,4.573,1.949A5.611,5.611,0,0,1,23.63,13.36c-.087,1.582-.571,2.83-3,5.257-1.231,1.23-2.007,2.154-2.086,2.964a1.231,1.231,0,0,1-1.223,1.11C17.283,22.691,17.241,22.687,17.2,22.685Z'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  )
})

export default IconQuestion
