import React from 'react'

interface IIconHFWarning {
  color?: string
  width?: number
  height?: number
}

const IconHFWarning = React.forwardRef<SVGSVGElement, IIconHFWarning>(({ color = 'currentColor', width = 33.519, height = 25.975, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 33.519 25.975' {...rest}>
      <g id='Group_5505' data-name='Group 5505'>
        <path
          id='Path_2420'
          data-name='Path 2420'
          d='M32.542,11.4H30.367c1.4-3.929-.223-8.728-3.884-10.518A8.106,8.106,0,0,0,16.76,2.708,8.106,8.106,0,0,0,7.037.885C3.376,2.675,1.748,7.475,3.153,11.4H.978a.982.982,0,0,0,0,1.965H4.014a9.393,9.393,0,0,0,1.645,2.161c.3.3,9.544,9.733,9.624,9.816l.017.017h0a2.085,2.085,0,0,0,1.442.613h.037a2.084,2.084,0,0,0,1.442-.613h0l.02-.02c.077-.079,9.325-9.516,9.621-9.813a9.392,9.392,0,0,0,1.645-2.161h3.036a.982.982,0,0,0,0-1.965Zm-27.92-3.3A6.328,6.328,0,0,1,7.936,2.632a6.124,6.124,0,0,1,7.987,2.236.986.986,0,0,0,1.673,0,6.125,6.125,0,0,1,7.987-2.236A6.328,6.328,0,0,1,28.9,8.106a8.02,8.02,0,0,1-.633,3.3H21.353a.983.983,0,0,0-.743.34l-3.02,3.495L14.463,7.52a.982.982,0,0,0-1.741-.156L10.167,11.4H5.255A8.023,8.023,0,0,1,4.622,8.106Zm21.873,6.013c-.12.116-9.573,9.762-9.657,9.848l-.006.006a.123.123,0,0,1-.071.036.128.128,0,0,1-.074-.038c-.088-.09-9.54-9.736-9.661-9.852a6.512,6.512,0,0,1-.664-.751h4.346a.982.982,0,0,0,.83-.457L13.36,10.03l3.012,7.435a.982.982,0,0,0,1.654.273l3.776-4.37h5.356A6.514,6.514,0,0,1,26.494,14.118Z'
          transform='translate(-0.001 0)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconHFWarning
