import React from 'react'

interface IIconAnnouncement {
  color?: string
  size?: number
}

const IconAnnouncement = React.forwardRef<SVGSVGElement, IIconAnnouncement>(({ color = 'currentColor', size = 27, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.713 21.277' fill={color} stroke={color} {...rest}>
      <g id='Group_5300' data-name='Group 5300' transform='translate(0.375 -63.609)'>
        <g id='Group_2029' data-name='Group 2029' transform='translate(5.4 63.99)'>
          <g id='Group_2028' data-name='Group 2028' transform='translate(0 0)'>
            <path
              id='Path_1069'
              data-name='Path 1069'
              d='M121.247,82.35a.553.553,0,0,1-.194-.036l-14.04-5.4a.54.54,0,0,1-.346-.5V69.93a.54.54,0,0,1,.346-.5l14.04-5.4a.541.541,0,0,1,.734.5V81.81a.541.541,0,0,1-.54.54Zm-13.5-6.312,12.96,4.985V65.316L107.747,70.3Z'
              transform='translate(-106.667 -63.99)'
              fill='#fff'
              stroke='#fff'
              strokeWidth='0.75'
            />
          </g>
        </g>
        <g id='Group_2031' data-name='Group 2031' transform='translate(0 69.39)'>
          <g id='Group_2030' data-name='Group 2030' transform='translate(0 0)'>
            <path
              id='Path_1070'
              data-name='Path 1070'
              d='M5.94,178.227H2.16A2.162,2.162,0,0,1,0,176.067v-3.24a2.162,2.162,0,0,1,2.16-2.16H5.94a.54.54,0,0,1,.54.54v6.48A.54.54,0,0,1,5.94,178.227Zm-3.78-6.48a1.08,1.08,0,0,0-1.08,1.08v3.24a1.08,1.08,0,0,0,1.08,1.08H5.4v-5.4Z'
              transform='translate(0 -170.667)'
              fill='#fff'
              stroke='#fff'
              strokeWidth='0.75'
            />
          </g>
        </g>
        <g id='Group_2033' data-name='Group 2033' transform='translate(3.24 75.871)'>
          <g id='Group_2032' data-name='Group 2032' transform='translate(0 0)'>
            <path
              id='Path_1071'
              data-name='Path 1071'
              d='M68.859,307.326h-3.24a.54.54,0,0,1-.535-.464L64,299.3a.54.54,0,0,1,.459-.61.547.547,0,0,1,.61.459l1.014,7.1h2.166l-.721-6.43a.542.542,0,0,1,.476-.6.549.549,0,0,1,.6.476l.788,7.031a.54.54,0,0,1-.536.6Z'
              transform='translate(-63.999 -298.687)'
              fill='#fff'
              stroke='#fff'
              strokeWidth='0.75'
            />
          </g>
        </g>
        <g id='Group_2035' data-name='Group 2035' transform='translate(22.594 67.23)'>
          <g id='Group_2034' data-name='Group 2034' transform='translate(0 0)'>
            <path
              id='Path_1072'
              data-name='Path 1072'
              d='M427.209,131.245a.54.54,0,0,1-.3-.989l3.24-2.16a.54.54,0,0,1,.6.9l-3.24,2.16A.535.535,0,0,1,427.209,131.245Z'
              transform='translate(-426.669 -128.006)'
              fill='#fff'
              stroke='#fff'
              strokeWidth='0.75'
            />
          </g>
        </g>
        <g id='Group_2037' data-name='Group 2037' transform='translate(22.595 76.951)'>
          <g id='Group_2036' data-name='Group 2036' transform='translate(0 0)'>
            <path
              id='Path_1073'
              data-name='Path 1073'
              d='M430.454,323.251a.533.533,0,0,1-.3-.091l-3.24-2.16a.54.54,0,0,1,.6-.9l3.24,2.16a.54.54,0,0,1-.3.989Z'
              transform='translate(-426.674 -320.011)'
              fill='#fff'
              stroke='#fff'
              strokeWidth='0.75'
            />
          </g>
        </g>
        <g id='Group_2039' data-name='Group 2039' transform='translate(22.594 73.17)'>
          <g id='Group_2038' data-name='Group 2038' transform='translate(0 0)'>
            <path
              id='Path_1074'
              data-name='Path 1074'
              d='M430.447,246.413h-3.24a.54.54,0,0,1,0-1.08h3.24a.54.54,0,1,1,0,1.08Z'
              transform='translate(-426.667 -245.333)'
              fill='#fff'
              stroke='#fff'
              strokeWidth='0.75'
            />
          </g>
        </g>
      </g>
    </svg>
  )
})

export default IconAnnouncement
