import { Icon as IconProps, X } from 'react-feather'
import styled from 'styled-components'

export const CloseIcon = styled<IconProps>(X)`
  position: absolute;
  width: 19px;
  height: 19px;
  right: 30px;
  top: 27px;
`
