import { useWeb3React } from '@web3-react/core'
import { useUserContext } from 'contexts/user'
import useENS from 'modules/bend/hooks/useENS'
import Popover from 'theme/ui/common/popover'
import { shortenAddress } from 'wallet-module'

interface IEns {
  address: string | undefined | null
  chars?: number
  maxLength?: number
  showUsername?: boolean
  firstNumbers?: boolean
  cursor?: string
}

export const Ens: React.FC<IEns> = ({ address, chars = 8, maxLength, showUsername = true, firstNumbers = false, cursor = 'default' }) => {
  const ensResolve = useENS(address)
  const { user } = useUserContext()
  const { chainId } = useWeb3React()

  if (!address) return <></>
  if (ensResolve.name && chainId === 1) {
    if (!maxLength) return <>{ensResolve.name}</>
    if (ensResolve.name.length > maxLength)
      return (
        <Popover overlay={ensResolve.name} placement='top' sx={{ cursor }}>
          {ensResolve.name.slice(0, maxLength)}..
        </Popover>
      )
    else return <>{ensResolve.name}</>
  }
  if (address?.toLowerCase() === user?.address?.toLowerCase() && user?.username && showUsername)
    return <>{firstNumbers ? user?.username.slice(0, maxLength) : user?.username}</>
  if (firstNumbers) return <>{address?.slice(0, chars)}</>
  return <>{shortenAddress(address, chars)}</>
}
