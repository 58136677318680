import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconTwitter = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'white', size = 18, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      id='icon-social-twitter-white'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18.297 15.004'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Path_582'
        data-name='Path 582'
        d='M19.4,26.675a7.4,7.4,0,0,1-2.155.6,3.784,3.784,0,0,0,1.65-2.095,7.343,7.343,0,0,1-2.393.916A3.719,3.719,0,0,0,13.762,24.9a3.774,3.774,0,0,0-3.755,3.789,4.244,4.244,0,0,0,.094.865,10.67,10.67,0,0,1-7.733-3.964,3.8,3.8,0,0,0,1.16,5.055,3.681,3.681,0,0,1-1.7-.473v.044A3.8,3.8,0,0,0,4.84,33.94a3.678,3.678,0,0,1-.987.131,3.81,3.81,0,0,1-.706-.065,3.779,3.779,0,0,0,3.51,2.633,7.511,7.511,0,0,1-4.663,1.615A7.28,7.28,0,0,1,1.1,38.2a10.66,10.66,0,0,0,16.438-9.076l-.014-.487A7.794,7.794,0,0,0,19.4,26.675Z'
        transform='translate(-1.1 -24.9)'
      />
    </svg>
  )
})

export default IconTwitter
