import React from 'react'

interface IIconFail {
  color?: string
  size?: number
}

const IconFail = React.forwardRef<SVGSVGElement, IIconFail>(({ color = 'currentColor', size = 52, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 52 52'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Shape'
        d='M7.616,44.386A26,26,0,0,1,44.386,7.614,26,26,0,0,1,7.616,44.386ZM17.3,5.412A22.416,22.416,0,1,0,26,3.653,22.339,22.339,0,0,0,17.3,5.412ZM33.838,36.451,26,28.589l-7.835,7.857a1.828,1.828,0,1,1-2.59-2.58L23.421,26l-7.845-7.866a1.827,1.827,0,1,1,2.59-2.578L26,23.409l7.837-7.855a1.825,1.825,0,1,1,2.584,2.578L28.581,26l7.847,7.872a1.828,1.828,0,1,1-2.59,2.58Z'
        transform='translate(0.001 -0.001)'
        fill={color}
      />
    </svg>
  )
})

export default IconFail
