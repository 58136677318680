import React from 'react'

interface IIconSelector {
  color?: string
  size?: number
}

const IconSelector = React.forwardRef<SVGSVGElement, IIconSelector>(({ color = 'currentColor', size = 12.775, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12.775 12.775' fill={color} {...rest}>
      <g id='select' transform='translate(-5)'>
        <path
          id='Shape'
          d='M11.428,0H1.347A1.347,1.347,0,0,0,0,1.347V11.428a1.347,1.347,0,0,0,1.347,1.347H4.392a.674.674,0,1,0,0-1.347H1.347V1.347H11.428V4.392a.674.674,0,1,0,1.347,0V1.347A1.347,1.347,0,0,0,11.428,0Z'
          transform='translate(5 12.775) rotate(-90)'
          fill={color}
        />
        <path
          id='Shape-2'
          data-name='Shape'
          d='M3.471,2.517,5.414.575A.337.337,0,0,0,5.176,0H.674A.673.673,0,0,0,0,.674v4.5a.336.336,0,0,0,.575.238L2.517,3.47,6.035,6.988a.674.674,0,0,0,.954-.953L3.471,2.517Z'
          transform='translate(17.775) rotate(90)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconSelector
