import { JsonRpcProvider } from '@ethersproject/providers'
import { rpc } from 'constants/index'
import { ethers } from 'ethers'
import { LEND_POOL_ADDRESSES_PROVIDER, UI_POOL_DATA_PROVIDER, UI_POOL_DATA_PROVIDER_ABI } from 'modules/bend/constants'

import { ReserveData } from './types'

export class SimpleReservesData {
  private contractAddress = UI_POOL_DATA_PROVIDER
  private contractAbi = UI_POOL_DATA_PROVIDER_ABI
  private lendPoolAddress = LEND_POOL_ADDRESSES_PROVIDER
  private data: ReserveData[][] | null = null
  // private zero = new BigNumber(0)
  private contract

  constructor(provider?: JsonRpcProvider) {
    this.contract = new ethers.Contract(this.contractAddress, this.contractAbi, provider || rpc)
  }

  public async getSimpleReservesData() {
    return await this.contract.functions.getSimpleReservesData(this.lendPoolAddress)
  }

  public async init() {
    this.data = await this.getSimpleReservesData()
  }

  public async getReservesData() {
    return this.data ? this.data[0] : []
  }

  public async getReserveData(underlyingAsset: string) {
    return this.data ? this.data[0].find(data => data.underlyingAsset?.toLowerCase() === underlyingAsset?.toLowerCase()) : null
  }
}
