import React from 'react'

interface IIconAnalytics {
  color?: string
  size?: number
}

const IconAnalytics = React.forwardRef<SVGSVGElement, IIconAnalytics>(({ color = 'currentColor', size = 31, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 31.936 36.877' fill={color} stroke={color} {...rest}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2688' data-name='Rectangle 2688' width='31.936' height='36.877' fill='none' />
        </clipPath>
      </defs>
      <path
        id='Path_2742'
        data-name='Path 2742'
        d='M2.8,26.039v-15.2l13.163-7.6L23.9,7.817l.016.031.017-.011,5.2,3v15.2l-13.162,7.6ZM15.968,0,0,9.219V27.658l15.968,9.219,15.968-9.223V9.219Z'
        fill={color}
      />
      <g id='Group_7162' data-name='Group 7162'>
        <g id='Group_7161-2' data-name='Group 7161' clipPath='url(#clip-path)'>
          <path
            id='Path_2743'
            data-name='Path 2743'
            d='M16,16.109h-.057a1.673,1.673,0,0,0-1.6,1.713v7.031a1.628,1.628,0,1,0,3.25.025V17.849a1.678,1.678,0,0,0-1.6-1.74'
            fill={color}
          />
          <path
            id='Path_2744'
            data-name='Path 2744'
            d='M15.969,13.64h0a1.628,1.628,0,1,0,0-3.256h0a1.629,1.629,0,0,0-1.628,1.626,1.63,1.63,0,0,0,1.628,1.63'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconAnalytics
