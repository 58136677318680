import { useCallback, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'

import { WalletContext } from '../contexts/wallet'
import { sendTransaction } from '../utils/contract'

export function useWallet(): any {
  const wallet = useContext(WalletContext)
  return wallet
}

export const useSendTransaction = () => {
  const { provider, account } = useWeb3React()

  const handleAction = useCallback(
    async (amount: any, to: any) => {
      if (!provider || !account)
        return {
          message: 'error'
        }
      try {
        const tx = await sendTransaction(provider, account, amount, to)
        return tx
      } catch (e) {
        return e
      }
    },
    [provider, account]
  )

  return { onSendTransaction: handleAction }
}
