import React from 'react'
import useTheme from 'hooks/common/useTheme'
import { Box, Flex, Image, Text } from 'rebass/styled-components'
import { Button } from 'theme/ui'

import Link from 'components/common/link'

interface OptionProps {
  link?: string | null
  onClick?: any
  color: string
  header: React.ReactNode
  icon: any
  active?: boolean
  id: string
  disabled?: boolean
}

export const Option: React.FC<OptionProps> = ({ link = null, onClick = null, header, icon, id, disabled }) => {
  const { colors } = useTheme()

  const content = (
    <Button id={id} color='black' onClick={onClick} width='100%' outlined buttonStyles={{ p: 10 }} disabled={disabled}>
      <Flex flexDirection='column' alignItems='center' justifyContent='space-between' width='100%'>
        <Box
          sx={{
            '.wallet-icon': {
              bg: colors.white,
              padding: '3px !important'
            }
          }}
        >
          <Image src={icon} alt='Icon' width={26} height={26} className='wallet-icon' />
        </Box>
        <Flex flexDirection='column' alignItems='flex-start'>
          <Text fontSize='md' lineHeight='lg' fontWeight='bold' color='black'>
            {header}
          </Text>
        </Flex>
      </Flex>
    </Button>
  )
  if (link) {
    return <Link href={link}>{content}</Link>
  }

  return content
}

export default Option
