import BigNumber from 'bignumber.js'
import {
  DATE_FORMAT,
  DEFAULT_CHAIN_ID,
  ENDPOINTS,
  ETH,
  ETH_ADDRESS,
  ETH_DECIMAL_PLACES,
  ethImagePath,
  IPFS_GATEWAY,
  nftImageUrlType,
  REDEEM_TIME,
  SHOW_DECIMAL_PLACES,
  USDT_DECIMAL_PLACES,
  WBTC_DECIMAL_PLACES
} from 'constants/index'
import { permalink } from 'constants/routes'
import { CollectionPayload, EndpointName, Reserve as ReserveMarkets, ReservePayload } from 'constants/types'
import { Reserve } from 'hooks/common/useUserReserves'
import { find, isArray, isEmpty } from 'lodash'
import {
  BOUND_STAKED_BAYC_ADDRESS,
  BOUND_STAKED_MAYC_ADDRESS,
  STAKED_BAKC_ADDRESS,
  STAKED_BAYC_ADDRESS,
  STAKED_MAYC_ADDRESS
} from 'modules/apecoin-staking-v2/constants'
import {
  CRYPTOPUNKS_ADDRESS,
  OTHERDEED_ADDRESS,
  USDT_ADDRESS,
  WBTC_ADDRESS,
  WETH_ADDRESS,
  WKODA_ADDRESS,
  WPUNKS_ADDRESS,
  WSTETH_ADDRESS
} from 'modules/bend/constants'
import moment from 'moment'
import { ChainId } from 'wallet-module'

import { Collection } from './api/get-homepage-collections'

export const getPlaceholderUrl = (imageUrl: any): string => {
  return `/api/placeholder?src=${imageUrl}`
}

export const getNftImageUrl = (url: string, type: string = nftImageUrlType.ipfsUrl): string => {
  const ipfsLink = `${IPFS_GATEWAY}${url?.replace('ipfs://', '')}`
  if (type === nftImageUrlType.ipfsUrl) return ipfsLink
  if (type === nftImageUrlType.httpUrl) return url

  return ipfsLink
}

export const parseIpfsLink = (link: string) => link.replace('ipfs://', IPFS_GATEWAY)

export const getEndpoint = (key: EndpointName, chainId: string = DEFAULT_CHAIN_ID): string => ENDPOINTS[chainId || '4'][key]

export const lendPoolIcon = (address: string | null | undefined): string => (address ? `/images/elements/${address?.toLowerCase()}.svg` : ethImagePath)
export const arrayIncludes = (array: string[], search: string) => array?.some(address => address.toLowerCase() === search.toLowerCase())

export const parseCollectionAddress = (address: string): string =>
  address?.toLowerCase() === CRYPTOPUNKS_ADDRESS.toLowerCase() ? WPUNKS_ADDRESS.toLowerCase() : address?.toLowerCase()

export const getOpenseaLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://testnets.opensea.io/assets?search[query]=${address}`
    default:
      return `https://opensea.io/assets?search[query]=${address}`
  }
}

export const getLooksRareCollectionLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://sepolia.looksrare.org/collections/${address}`
    default:
      return `https://looksrare.org/collections/${address}`
  }
}

export const getX2Y2CollectionLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://sepolia.looksrare.org/accounts/${address}`
    default:
      return `https://x2y2.io/collection/${address}/items`
  }
}

export const getBendCollectionLink = (address: string): string => {
  return `${permalink.collection}/${address}`
}

export const getX2Y2BoundNFTCollectionLink = (bnftToken: string, address: string) => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://sepolia.looksrare.org/accounts/${bnftToken}`
    default:
      return `https://x2y2.io/user/${bnftToken}/items?contract=1_${address}`
  }
}

export const getUserCollectionLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://sepolia.etherscan.io/address/${address.toLocaleLowerCase()}`
    default:
      return `https://etherscan.io/address/${address.toLocaleLowerCase()}`
  }
}

export const getPunksNftLink = (tokenID: string): string => `https://cryptopunks.app/cryptopunks/details/${tokenID}`

/* export const getX2Y2NFTLink = (address: string, tokenId: string): string =>
  DEFAULT_CHAIN_ID === '5' ? `https://sepolia.looksrare.org/collections/${address}/${tokenId}` : `https://x2y2.io/eth/${address}/${tokenId}` */

export const getBendNFTLink = (address: string, tokenId: string): string => `${permalink.asset}/${address}/${tokenId}`

export const getOpenseaLinkBoundNft = (address: string, tokenId: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://testnets.opensea.io/assets/${address}/${tokenId}`
    default:
      return `https://opensea.io/assets/${address}/${tokenId}`
  }
}

export const getEtherscanUrl = (address: string, type = 'token') => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.SEPOLIA):
      return `https://sepolia.etherscan.io/${type}/${address}`
    default:
      return `https://etherscan.io/${type}/${address}`
  }
}

export const isWeth = (address: string | undefined): boolean => WETH_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isEth = (address: string | undefined): boolean => ETH_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isPunk = (address: string | undefined): boolean => CRYPTOPUNKS_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isWPunk = (address: string | undefined): boolean => WPUNKS_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isOtherdeed = (address: string | undefined): boolean => OTHERDEED_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isWKoda = (address: string | undefined): boolean => WKODA_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isWrappedCollection = (address: string | undefined): boolean => !!address
const hours = (numDays: number) => numDays * 3600
export const getBidEndTimestamp = (bidStartTime: number, auctionDuration: number): number => Number(bidStartTime) + hours(auctionDuration)
export const getTokenIdFromCollateralKey = (key: string): string => key?.split('-')[0]
export const getCollectionAddressFromCollateralKey = (key: string): string => key?.split('-')[1]
export const getTokenIdAndCollectionAddressFromCollateralKey = (key: string): string[] => key?.split('-')

export interface CustomTokenProps {
  address: string | undefined
  decimals: number
  symbol: string
  image?: string
}
export const addCustomToken = async ({ address, decimals, symbol, image = '' }: CustomTokenProps) => {
  const provider: any = window.ethereum
  if (provider) {
    try {
      const customToken = await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals,
            image
          }
        }
      })

      if (customToken) {
        console.log(`${symbol} has been added to MetaMask.`)
      } else {
        console.log('Your loss!')
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getUserReserve = (userReserves: Array<Reserve>, id: string): Reserve | undefined => find(userReserves, userReserve => userReserve.id === id)

type NotificationTypeProps = 'loan_warn_3' | 'loan_warn_2' | 'loan_warn_1a' | 'loan_warn_1b' | 'loan_warn_4a' | 'loan_warn_5a' | 'loan_warn_5b'
type NotificationResult = {
  healthFactor?: string
  collectionName?: string
  collateral?: string
  highestBid?: string
  reserve?: string
  redeemTime?: number
}
export const getNotificationProps = (type: NotificationTypeProps, notification: any): NotificationResult => {
  // console.log(notification)
  switch (type) {
    default:
    case 'loan_warn_1a':
      return {
        healthFactor: new BigNumber(notification.body[type].loan.healthFactor).dp(2).toString(),
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        redeemTime: REDEEM_TIME
      }
    case 'loan_warn_1b':
      return {
        healthFactor: new BigNumber(notification.body[type].loan.healthFactor).dp(2).toString()
      }
    case 'loan_warn_2':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID
      }
    case 'loan_warn_3':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        highestBid: new BigNumber(notification.body[type].loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_4a':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID
      }
    case 'loan_warn_5a':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        highestBid: new BigNumber(notification.body[type].loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_5b':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        highestBid: new BigNumber(notification.body[type].loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
  }
}

/**
 * Generates reserve payload.
 * @param {Reserve} reserve
 * @return {ReservePayload}
 */
export const reservePayload = (reserve: ReserveMarkets, distributionManager: string | undefined): ReservePayload => {
  return {
    key: reserve.id,
    id: reserve.id,
    address: reserve.underlyingAsset,
    assetName: isWeth(reserve.underlyingAsset) ? ETH.name : reserve.name,
    assetTicker: isWeth(reserve.underlyingAsset) ? ETH.symbol : reserve.symbol,
    assetLogo: lendPoolIcon(reserve.underlyingAsset),
    totalLiquidity: new BigNumber(reserve.totalBTokenSupply).dividedBy(`1e${reserve.decimals}`),
    depositAPY: new BigNumber(100).multipliedBy(new BigNumber(reserve.liquidityRate).dividedBy(`1e27`)),
    totalBorrows: new BigNumber(reserve.totalCurrentVariableDebt).dividedBy(`1e${reserve.decimals}`),
    borrowAPY: new BigNumber(100).multipliedBy(new BigNumber(reserve.variableBorrowRate).dividedBy(`1e27`)),
    active: reserve.isActive && !reserve.isFrozen,
    priceInEth: new BigNumber(reserve.price.priceInEth).dividedBy(1e18),
    bToken: reserve.bToken.id,
    debtToken: reserve.debtToken.id,
    totalBTokenSupply: new BigNumber(reserve.totalBTokenSupply).dividedBy(`1e${reserve.decimals}`),
    totalBTokenSupplyUSD: new BigNumber(
      new BigNumber(reserve.totalBTokenSupply)
        .dividedBy(`1e${reserve.decimals}`)
        .multipliedBy(new BigNumber(reserve.price.priceInEth).dividedBy(1e18))
        .toFixed(4)
    ).toNumber(),
    availableLiquidity: new BigNumber(reserve.availableLiquidity).dividedBy(`1e${reserve.decimals}`),
    distributionManager,
    lifetimeBorrows: new BigNumber(reserve.lifetimeBorrows).dividedBy(`1e${reserve.decimals}`),
    utilizationRate: reserve.debtUtilizationRate,
    variableBorrowRate: reserve.variableBorrowRate,
    decimals: reserve.decimals,
    totalDebtTokenSupply: new BigNumber(reserve.totalCurrentVariableDebt).dividedBy(`1e${reserve.decimals}`)
  }
}

/**
 * Generates collection payload.
 * @param {Collection} collection
 * @return {CollectionPayload}
 */
export const collectionPayload = (collection: Collection, eth: any): CollectionPayload => {
  return {
    key: collection.address,
    id: collection.id,
    // collectionName: isWPunk(collection.address) ? PUNKS_NAME : collection.name,
    collectionName: collection.wrappedAssetNFTCollection?.name ? collection.wrappedAssetNFTCollection?.name : collection.name,
    collectionSymbol: collection.wrappedAssetNFTCollection?.symbol ? collection.wrappedAssetNFTCollection?.symbol : collection.symbol,
    address: collection.address,
    totalCollaterals: collection.realTotalSupply || collection.totalSupply,
    collectionImages: collection.openseaImageURL,
    floorPrice: collection.floorPrice,
    /**
     * availableToBorrow = baseLTVasCollateral*priceInEth
     */
    availableToBorrow: collection.availableToBorrow,
    priceInEth: collection.floorPrice,
    activeCollaterals: collection.activeCollaterals,
    priceHint: collection.priceHint,
    eth,
    bnftToken: collection.bnftToken,
    orderBy: {
      totalSupply: collection.orderBy.totalSupply
    }
  }
}

const ethAddressPattern = /^0x[a-fA-F0-9]{40}$/
export const isEthAddress = (address: string): boolean => ethAddressPattern.test(address)

type CalculateBidFineProps = {
  minBidFine: string
  amount: BigNumber
  redeemFine: string
  ethPriceInReserve: BigNumber
}
export const calculateBidFine = ({ minBidFine, amount, redeemFine, ethPriceInReserve }: CalculateBidFineProps): BigNumber => {
  const minBidFineBig = new BigNumber(minBidFine).dividedBy(1e4).multipliedBy(ethPriceInReserve)
  const bidFine = new BigNumber(amount).multipliedBy(new BigNumber(redeemFine).dividedBy(1e4)).multipliedBy(1.01)
  return minBidFineBig.isGreaterThan(bidFine) ? minBidFineBig : bidFine
}

export const calculateRoyaltyFee = (input: string | number) => new BigNumber(input).dividedBy(1e2).dp(2).toFixed()
export const calculateTradingFee = (input: string | number) => new BigNumber(input).dividedBy(1e2).dp(2).toFixed()

export const expirationHelper = (endDate: number) => {
  if (!endDate) return '--'
  const currentTime = moment()
  const endTime = moment.unix(endDate)
  const difference = endTime.diff(currentTime, 'minutes', false)
  const differenceSec = endTime.diff(currentTime, 'seconds', false)

  if (Number(difference) > 43200) return endTime.format(DATE_FORMAT)
  if (Number(difference) < 43200 && Number(difference) > 1440)
    return `${Math.floor(Number(difference) / 1440)} ${Math.floor(Number(difference) / 1440) === 1 ? 'day' : 'days'}`
  if (Number(difference) < 1440 && Number(difference) > 60) return `${Math.floor(Number(difference) / 60)} hours`
  if (difference < 0) return 'Expired'
  return `${Math.floor(Number(differenceSec) / 60)} min ${((Number(differenceSec) / 60 - Math.floor(Number(differenceSec) / 60)) * 60).toFixed()} sec`
}

export const formatUnixTimestamp = (endTime: number) => {
  const currentTime = moment()
  const futureTime = moment.unix(endTime)
  const difference = futureTime.diff(currentTime, 'minutes', false)
  if (difference < 0) return 'Expired'
  return moment.unix(endTime).format('DD/MM/YYYY')
}

export const gtCurrentTime = (time: number | undefined): Boolean => (!time ? false : moment.unix(time).isAfter(moment()))

export const checkOldSignature = (signature: any) => {
  if (!signature) return
  if (signature?.slice(-2) === '00') {
    return signature?.slice(0, -2) + '1b'
  }
  if (signature?.slice(-2) === '01') {
    return signature?.slice(0, -2) + '1c'
  }

  return signature
}

export const generateFilterUrlAppend = (data: any, urlAppend: string) => {
  Object?.keys(data).map(key => {
    if (data[key]) urlAppend = `${urlAppend}${isArray(data[key]) ? (!isEmpty(data[key]) ? `&${key}=${data[key]}` : '') : `&${key}=${data[key]}`}`
  })

  return urlAppend
}

export const cutZeroDecimals = (input: string) => {
  if (input.slice(-3) === '.00') return input.substring(0, input.length - 3)
  if (input.slice(-5) === '.0000') return input.substring(0, input.length - 5)
  return input
}

export const uuid = (): string => {
  const hashTable = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const uuid = []
  for (let i = 0; i < 35; i++) {
    if (i === 7 || i === 12 || i === 17 || i === 22) {
      uuid[i] = '-'
    } else {
      uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
    }
  }
  return uuid.join('')
}

export const parseLiquidateData = (
  data: {
    repayAmount: string
    remainAmount: string
    reserve: {
      decimals: number
    }
    user: {
      id: string
    }
  }[]
): {
  totalAmount: BigNumber
  repaidAmount: BigNumber
  remainingAmount: BigNumber
  liquidator: string
} | null => {
  if (isEmpty(data)) return null
  const {
    repayAmount,
    remainAmount,
    user,
    reserve: { decimals }
  } = data?.[0]

  return {
    totalAmount: new BigNumber(repayAmount).dividedBy(`1e${decimals}`).plus(new BigNumber(remainAmount).dividedBy(`1e${decimals}`)),
    repaidAmount: new BigNumber(repayAmount).dividedBy(`1e${decimals}`),
    remainingAmount: new BigNumber(remainAmount).dividedBy(`1e${decimals}`),
    liquidator: user.id
  }
}

export const parseNumberToPx = (fontSize: string | number | string[] | number[]) => {
  if (typeof fontSize === 'number') {
    return `${fontSize}px`
  }
  if (typeof fontSize === 'string') {
    return fontSize
  }
  if (Array.isArray(fontSize)) {
    return fontSize.map(size => (typeof size === 'number' ? `${size}px` : size))
  }
  return fontSize
}

export const gt = (time1: number, time2: number): boolean => moment.unix(time1).isAfter(moment.unix(time2))
export const filterUnchecked = (data: any) => {
  return data?.filter((item: string | boolean) => item !== false)
}

export const lendPoolSymbol = (payload: ReservePayload) => (isWeth(payload?.address) ? 'ETH' : payload?.assetTicker)

export const powDecimals = (decimals: number) => `1e${decimals}`
export const isStNFT = (collection: string) =>
  collection?.toLowerCase()?.toLowerCase() === STAKED_BAYC_ADDRESS?.toLowerCase() ||
  collection?.toLowerCase() === STAKED_MAYC_ADDRESS?.toLowerCase() ||
  collection?.toLowerCase() === STAKED_BAKC_ADDRESS?.toLowerCase()

export const isBoundStNFT = (collection: string) =>
  collection?.toLowerCase()?.toLowerCase() === BOUND_STAKED_BAYC_ADDRESS?.toLowerCase() ||
  collection?.toLowerCase() === BOUND_STAKED_MAYC_ADDRESS?.toLowerCase()

export const renderDecimals = (decimals: number | undefined) => {
  if (!decimals || decimals > 7) return 4
  return 2
}

export const decimalsByTicker = (address: string | undefined) => {
  if (!address) return SHOW_DECIMAL_PLACES
  const checkAddress = address.toLowerCase()
  switch (checkAddress) {
    case USDT_ADDRESS.toLowerCase():
      return USDT_DECIMAL_PLACES
    case WETH_ADDRESS.toLowerCase():
      return ETH_DECIMAL_PLACES
    case WBTC_ADDRESS.toLowerCase():
      return WBTC_DECIMAL_PLACES
    default:
      return SHOW_DECIMAL_PLACES
  }
}

export const getAssetTicker = (address: string | undefined) => {
  if (!address) return ''
  const checkAddress = address.toLowerCase()
  switch (checkAddress) {
    case USDT_ADDRESS.toLowerCase():
      return 'USDT'
    case WETH_ADDRESS.toLowerCase():
      return 'ETH'
    case WBTC_ADDRESS.toLowerCase():
      return 'WBTC'
    case WSTETH_ADDRESS.toLowerCase():
      return 'wstETH'
    default:
      return ''
  }
}

export const symbolByAddress = (address: string | undefined) => {
  if (!address) return ''
  const checkAddress = address.toLowerCase()
  switch (checkAddress) {
    case USDT_ADDRESS.toLowerCase():
      return 'USDT'
    case WETH_ADDRESS.toLowerCase():
      return 'ETH'
    case ETH_ADDRESS.toLowerCase():
      return 'ETH'
    case WBTC_ADDRESS.toLowerCase():
      return 'WBTC'
    case WSTETH_ADDRESS.toLowerCase():
      return 'wstETH'
    default:
      return 'WETH'
  }
}
