import React from 'react'

const IconHighlights1 = React.forwardRef<SVGSVGElement>(() => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='53.544' height='50.71' viewBox='0 0 53.544 50.71'>
      <g id='Group_5129' data-name='Group 5129' transform='translate(0.5 0.5)'>
        <path
          id='Path_1868'
          data-name='Path 1868'
          d='M33.462,110.812l3.755,2.168a1.034,1.034,0,0,0,1.034-1.791L34.5,109.02a1.034,1.034,0,1,0-1.034,1.791'
          transform='translate(-29.564 -97.707)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
        <path
          id='Path_1869'
          data-name='Path 1869'
          d='M6.4,232.876a1.034,1.034,0,0,0-1.034-1.034H1.034a1.034,1.034,0,0,0,0,2.069H5.37A1.034,1.034,0,0,0,6.4,232.876'
          transform='translate(0 -208.049)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
        <path
          id='Path_1870'
          data-name='Path 1870'
          d='M423.855,112.98l3.755-2.168a1.034,1.034,0,1,0-1.034-1.791l-3.755,2.168a1.034,1.034,0,1,0,1.034,1.791'
          transform='translate(-378.964 -97.707)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
        <path
          id='Path_1871'
          data-name='Path 1871'
          d='M135.472,16.239H122.255L126.86,4.972c.006-.013.013-.026.018-.04l1.434-3.507A1.034,1.034,0,0,0,127.355,0H114.077a1.035,1.035,0,0,0-.989.736s-5.473,18.522-7.4,25.039a1.033,1.033,0,0,0,.991,1.325h13.911l-4.373,21.333a1.034,1.034,0,0,0,1.907.728l18.24-31.367a1.034,1.034,0,0,0-.894-1.554M119.513,42.657l3.359-16.384a1.034,1.034,0,0,0-1.013-1.242H108.065L114.85,2.069h10.962l-6.055,14.813a1.034,1.034,0,0,0,.957,1.426h12.958Z'
          transform='translate(-94.804)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
        <path
          id='Path_1872'
          data-name='Path 1872'
          d='M37.218,333.815l-3.755,2.168a1.034,1.034,0,1,0,1.034,1.791l3.755-2.168a1.034,1.034,0,1,0-1.034-1.791'
          transform='translate(-29.564 -299.433)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
        <path
          id='Path_1873'
          data-name='Path 1873'
          d='M454.961,231.842h-4.336a1.034,1.034,0,1,0,0,2.069h4.336a1.034,1.034,0,1,0,0-2.069'
          transform='translate(-403.452 -208.049)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
        <path
          id='Path_1874'
          data-name='Path 1874'
          d='M427.61,335.983l-3.755-2.168a1.034,1.034,0,0,0-1.034,1.791l3.755,2.168a1.034,1.034,0,1,0,1.034-1.791'
          transform='translate(-378.964 -299.433)'
          fill='#0057ff'
          stroke='#0057ff'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconHighlights1
