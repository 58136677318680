import useTheme from 'hooks/common/useTheme'
import { Box, BoxProps } from 'rebass/styled-components'

type CustomDotsProps = {
  setIndex: (index: number) => void
  length: number
  activeIndex: number
  dotStyle?: BoxProps['sx']
}

const CustomDots: React.FC<CustomDotsProps & BoxProps> = ({ setIndex, length, activeIndex, sx, dotStyle }) => {
  const { colors } = useTheme()
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '5px',
        left: '50%',
        transform: 'translateX(-50%)',
        ...sx
      }}
    >
      {new Array(length).fill('').map((_, i) => (
        <Box
          key={i}
          sx={{
            width: '5px',
            height: '5px',
            borderRadius: '50%',
            margin: '0 5px',
            display: 'inline-block',
            cursor: 'pointer',
            background: colors.grey[100],
            opacity: i === activeIndex ? 0.8 : 0.5,
            ...dotStyle
          }}
          onClick={() => setIndex(i)}
        />
      ))}
    </Box>
  )
}

export default CustomDots
