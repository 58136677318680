import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react'

type ModalContextProps = {
  isPan: boolean
  disablePan: () => void
  enablePan: () => void
  handleClose: () => void
  enableKiosk: () => void | Dispatch<SetStateAction<boolean>>
  disableKiosk: () => void | Dispatch<SetStateAction<boolean>>
  updateComponent: (component: ReactNode | undefined | null) => void | Dispatch<React.SetStateAction<ReactNode>>
}

type Props = {
  children: React.ReactNode
  setKioskMode: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
  setCustomComponent: Dispatch<SetStateAction<ReactNode>>
}

const ModalContext = createContext<ModalContextProps>({
  isPan: true,
  disablePan: () => {},
  enablePan: () => {},
  handleClose: () => {},
  enableKiosk: () => {},
  disableKiosk: () => {},
  updateComponent: () => {}
})

export const useModalContext = () => useContext(ModalContext)

export default function ModalProvider({ children, handleClose, setKioskMode, setCustomComponent }: Props) {
  const [isPan, setIsPan] = useState(true)

  const disablePan = useCallback(() => setIsPan(false), [])
  const enablePan = useCallback(() => setIsPan(true), [])
  const enableKiosk = useCallback(() => setKioskMode(true), [setKioskMode])
  const disableKiosk = useCallback(() => setKioskMode(false), [setKioskMode])
  const updateComponent = useCallback((component: ReactNode) => setCustomComponent(component), [setCustomComponent])

  return (
    <ModalContext.Provider value={{ isPan, disablePan, enablePan, handleClose, enableKiosk, disableKiosk, updateComponent }}>{children}</ModalContext.Provider>
  )
}

const ModalAppContext = createContext<{ isModalOpen: boolean; setIsModalOpen: Dispatch<SetStateAction<boolean>> }>({
  isModalOpen: false,
  setIsModalOpen: () => {}
})

export const useModalAppContext = () => useContext(ModalAppContext)

export const ModalAppProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return <ModalAppContext.Provider value={{ isModalOpen, setIsModalOpen }}>{children}</ModalAppContext.Provider>
}

const ModalContentContext = createContext<{ setModalContent?: undefined | Dispatch<SetStateAction<ReactNode>>; actions?: any }>({
  setModalContent: undefined,
  actions: {}
})

export const useModalContentContext = () => useContext(ModalContentContext)

type ModalContentProviderProps = {
  children: ReactNode
  setModalContent: Dispatch<SetStateAction<ReactNode>> | undefined
  actions?: any
}

export const ModalContentProvider = ({ setModalContent, actions, children }: ModalContentProviderProps) => {
  return <ModalContentContext.Provider value={{ setModalContent, actions }}>{children}</ModalContentContext.Provider>
}
