import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconBtc = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13.007 18' fill='none' {...rest}>
      <path
        id='Path_4789'
        data-name='Path 4789'
        d='M12.494,9.443A3.248,3.248,0,0,0,10.6,8.329a2.565,2.565,0,0,0,1.417-2.791A2.758,2.758,0,0,0,11.623,4.3a2.478,2.478,0,0,0-.893-.844,4.913,4.913,0,0,0-1.222-.487,9.644,9.644,0,0,0-1.514-.243V0H6.328V2.65q-.433,0-1.32.022V0H3.343V2.726q-.357.011-1.049.011L0,2.726V4.5H1.2a.859.859,0,0,1,.963.736v3.1a1.1,1.1,0,0,1,.173.011H2.163V12.7a.578.578,0,0,1-.627.552H.335L0,15.231H2.164q.206,0,.595.005t.584.005V18H5.009V15.274q.454.011,1.32.011V18H7.994V15.242a12.951,12.951,0,0,0,1.574-.162,6.405,6.405,0,0,0,1.314-.373,3.547,3.547,0,0,0,1.049-.633,2.986,2.986,0,0,0,.7-.963,4.093,4.093,0,0,0,.351-1.352,3.2,3.2,0,0,0-.486-2.315M5.041,4.619l.427-.005L6.052,4.6q.233-.005.628.027a5.969,5.969,0,0,1,.665.087,3.706,3.706,0,0,1,.595.178,1.612,1.612,0,0,1,.519.3,1.5,1.5,0,0,1,.33.454,1.418,1.418,0,0,1,.135.633,1.5,1.5,0,0,1-.1.552,1.04,1.04,0,0,1-.292.416,2.959,2.959,0,0,1-.4.292,1.833,1.833,0,0,1-.525.2q-.319.076-.557.119a4.56,4.56,0,0,1-.595.06q-.357.016-.541.022t-.5,0L5.041,7.94V4.619h0ZM9.59,11.985a1.538,1.538,0,0,1-.265.433,1.5,1.5,0,0,1-.427.324,3.665,3.665,0,0,1-.514.227,4.136,4.136,0,0,1-.6.152q-.352.065-.617.092t-.633.043q-.368.016-.573.016t-.519-.005l-.4-.005V9.606q.086,0,.514-.011t.7-.011q.27,0,.747.032a6.379,6.379,0,0,1,.8.1,5.263,5.263,0,0,1,.708.2,2.054,2.054,0,0,1,.622.33,1.623,1.623,0,0,1,.4.5,1.545,1.545,0,0,1,.06,1.244'
        fill={color}
      />
    </svg>
  )
})

export default IconBtc
