import { useMemo } from 'react'
import { isAddress } from 'wallet-module'

import useENSName from './useENSName'

/**
 * Given a name or address, does a lookup to resolve to an address and name
 * @param nameOrAddress ENS name or address
 */
export default function useENS(nameOrAddress?: string | null): {
  loading: boolean
  name: string | null
} {
  const validated = isAddress(nameOrAddress)
  const reverseLookup = useENSName(validated ? validated : undefined)

  return useMemo(
    () => ({
      loading: reverseLookup.loading,
      name: reverseLookup.ENSName || null
    }),
    [reverseLookup.ENSName, reverseLookup.loading]
  )
}
