import React from 'react'

interface IIconPresaleHowMuch {
  color?: string
}

const IconPresaleHowMuch = React.forwardRef<SVGSVGElement, IIconPresaleHowMuch>(({ color = 'currentColor' }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width='32.434' height='32.434' viewBox='0 0 32.434 32.434' color={color}>
      <g id='Group_5107' data-name='Group 5107' transform='translate(0.25 0.25)'>
        <g id='Group_5108' data-name='Group 5108'>
          <path
            id='Path_2364'
            data-name='Path 2364'
            d='M18.87,6.065V3.579C18.87-1.193,0-1.193,0,3.579V18.12C0,20,2.937,21.165,6.47,21.581c-1.292.58-2.115,1.362-2.115,2.355V28.3c0,2.386,4.746,3.635,9.435,3.635s9.435-1.249,9.435-3.635V26.105c4.44-.122,8.709-1.36,8.709-3.623V9.4c0-3.013-7.518-4.122-13.064-3.33M9.435,1.451c5.165,0,7.983,1.438,7.983,2.177S14.6,5.806,9.435,5.806,1.452,4.368,1.452,3.629,4.27,1.451,9.435,1.451m-7.983,4.2c1.761,1.06,4.881,1.609,7.983,1.609s6.222-.549,7.983-1.609v.721c-2.522.559-4.355,1.581-4.355,3.065v.429a23.02,23.02,0,0,1-3.629.3c-5.165,0-7.983-1.438-7.983-2.177V5.648ZM14.515,20.163c.138.083.282.164.436.241-.145-.006-.29-.012-.436-.016ZM1.452,10c1.761,1.06,4.881,1.609,7.983,1.609a24.6,24.6,0,0,0,3.629-.285v3.617a23.021,23.021,0,0,1-3.629.3c-5.165,0-7.983-1.438-7.983-2.177Zm0,8.141V15.083c1.761,1.06,4.881,1.609,7.983,1.609a24.6,24.6,0,0,0,3.629-.285v3.617a23.021,23.021,0,0,1-3.629.3c-5.165,0-7.983-1.438-7.983-2.177M21.773,28.3c0,.739-2.818,2.177-7.983,2.177S5.806,29.044,5.806,28.3V25.97c1.761,1.06,4.881,1.609,7.983,1.609A17.754,17.754,0,0,0,21.544,26.1c.075,0,.153,0,.229.006V28.3ZM13.79,26.127c-5.165,0-7.983-1.438-7.983-2.177s2.818-2.177,7.983-2.177,7.983,1.438,7.983,2.177-2.818,2.177-7.983,2.177M30.482,22.5c0,.7-2.572,2.021-7.258,2.152v-.7c0-.907-.686-1.64-1.793-2.2.355.014.711.024,1.067.024,3.1,0,6.222-.549,7.983-1.609V22.5Zm0-4.355c0,.739-2.818,2.177-7.983,2.177s-7.983-1.438-7.983-2.177V15.809c1.761,1.06,4.881,1.609,7.983,1.609s6.222-.549,7.983-1.609Zm0-4.355c0,.739-2.818,2.177-7.983,2.177s-7.983-1.438-7.983-2.177V11.454c1.761,1.06,4.881,1.609,7.983,1.609s6.222-.549,7.983-1.609ZM22.5,11.612c-5.165,0-7.983-1.438-7.983-2.177S17.333,7.257,22.5,7.257,30.482,8.7,30.482,9.435s-2.818,2.177-7.983,2.177'
            transform='translate(0 0)'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconPresaleHowMuch
