import React from 'react'

interface IIconNftOriginal {
  size?: number
}

const IconNftOriginal = React.forwardRef<SVGSVGElement, IIconNftOriginal>(({ size = 16, ...rest }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 16' ref={ref} {...rest}>
      <g id='Group_6957' data-name='Group 6957' transform='translate(-800 -827)'>
        <circle id='Ellipse_327' data-name='Ellipse 327' cx='8' cy='8' r='8' transform='translate(800 827)' fill='#0057ff' />
        <path
          id='Path_2472'
          data-name='Path 2472'
          d='M.705,6.547V2.725L4.015.814,7.324,2.725V6.547L4.015,8.457ZM4.015,0,0,2.318V6.954L4.015,9.272,8.03,6.953V2.318Z'
          transform='translate(803.985 830.365)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.75'
        />
      </g>
    </svg>
  )
})

export default IconNftOriginal
