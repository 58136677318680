import React from 'react'

interface IIconHome {
  color?: string
  size?: number
}

const IconHome = React.forwardRef<SVGSVGElement, IIconHome>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='home' transform='translate(0.109 0.194)'>
        <path
          id='Shape'
          d='M3.451,20.965a1.689,1.689,0,0,1-1.688-1.687V12.785a.844.844,0,1,1,1.688,0v6.492H6.8V13.651a1.689,1.689,0,0,1,1.688-1.687h3.282a1.689,1.689,0,0,1,1.688,1.688v5.626h3.351V12.785a.844.844,0,1,1,1.688,0v6.492a1.689,1.689,0,0,1-1.687,1.688Zm5.04-1.687h3.282V13.651H8.491Zm10.364-9.2L10.131,1.688,1.409,10.081A.843.843,0,0,1,.278,8.829L9,.436a1.686,1.686,0,0,1,2.262,0l8.721,8.393a.843.843,0,1,1-1.13,1.252Z'
          transform='translate(0.017 0.012)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconHome
