import React from 'react'
import { Flex, FlexProps } from 'rebass/styled-components'

const ScrollableArea: React.FC<FlexProps> = ({ children, overflowY }) => {
  return (
    <Flex
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        alignItems: ['flex-end', 'flex-end', 'center'],
        justifyContent: ['center'],
        overflowY: overflowY || 'auto',
        zIndex: 2000
      }}
    >
      {children}
    </Flex>
  )
}

export default ScrollableArea
