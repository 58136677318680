import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass/styled-components'
import { Button, Container, Notification, useDrawer } from 'theme/ui'

import Sidebar from '../sidebar'

import { useWalletCheckContext } from './context'

const WalletCheck = () => {
  const { t } = useTranslation('common')
  const { account, chainId } = useWeb3React()

  if (account && chainId === Number(DEFAULT_CHAIN_ID)) return null

  if (account && chainId !== Number(DEFAULT_CHAIN_ID))
    return (
      <WalletCheckNotification
        message={t('error-no-wallet-notification-wrong-network', { network: DEFAULT_CHAIN_ID === '1' ? 'Ethereum Mainnet' : 'Görli Testnet' })}
      />
    )

  return <WalletCheckNotification />
}

export default WalletCheck

type WalletCheckNotificationProps = {
  message?: string
}

const WalletCheckNotification: React.FC<WalletCheckNotificationProps> = ({ message }) => {
  const { t } = useTranslation('common')
  const [toggleSidebar, , setActivePane] = useDrawer(<Sidebar />)
  const { style } = useWalletCheckContext()
  return (
    <Container variant='card-body' justifyContent='center' flexDirection='column' sx={{ mt: 20, maxWidth: 'content', ...style }}>
      <Notification reducePadding type='default' hasBorder noicon sx={{ py: [15], px: [15] }}>
        <Flex flexDirection={['column', 'column', 'column', 'row']} alignItems={['center']} width='100%' justifyContent={['space-between']}>
          <Text
            as='span'
            dangerouslySetInnerHTML={{
              __html: message || t('error-no-wallet-notification')
            }}
            sx={{ lineHeight: ['lg', 'lg', 'lg', 'md'] }}
          />
          <Button
            onClick={() => {
              toggleSidebar()
              setActivePane(0)
            }}
            color='primary'
            size='sm'
            buttonStyles={{ mt: [10, 10, 10, 0] }}
            fontWeight='bold'
          >
            {t('error-no-wallet-button')}
          </Button>
        </Flex>
      </Notification>
    </Container>
  )
}
