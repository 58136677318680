import React from 'react'

interface IIcon {
  size?: number
  lineColor?: string
}

const IconFloorPrice = React.forwardRef<SVGSVGElement, IIcon>(({ size = 23, lineColor = '#ef5350', ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000' width={size} height={size} viewBox='0 0 23 23' {...rest}>
      <g id='Group_7259' transform='translate(-597 -664)'>
        <g transform='translate(597 664)'>
          <path fill='#fff' d='M11,0.2h1C18,0.2,22.8,5,22.8,11v1c0,6-4.9,10.8-10.8,10.8h-1C5,22.8,0.2,18,0.2,12v-1C0.2,5,5,0.2,11,0.2z' />
          <path
            fill='#DCDCDD'
            d='M12,22.8h-1C5,22.8,0.2,18,0.2,12v-1C0.2,5,5,0.2,11,0.2h1C18,0.2,22.8,5,22.8,11v1C22.8,18,18,22.8,12,22.8z
            M11,1.1c-5.4,0-9.9,4.4-9.9,9.9v1c0,5.4,4.4,9.9,9.9,9.9h1c5.4,0,9.9-4.4,9.9-9.9v-1c0-5.4-4.4-9.9-9.9-9.9H11z'
          />
        </g>
      </g>
      <path
        fill={lineColor}
        d='M4.6,19.9c-0.4,0-0.8-0.1-1-0.4c-0.6-0.6-0.6-1.5,0-2.1L17.4,3.6c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1L5.6,19.4
        C5.4,19.7,5,19.9,4.6,19.9z'
      />
    </svg>
  )
})

export default IconFloorPrice
