import { useCallback, useMemo } from 'react'
// import { OKXWallet } from '@okwallet/web3-react-okxwallet'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { ConnectorType, TryActivationArgs, WalletSteps } from 'modules/bend/hooks/useWallet'
import { ConnectionType } from 'modules/wallet-module'
import { TrustWallet } from 'modules/wallet-module/connection/trustWallet'
import { useTranslation } from 'next-i18next'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Button, Notification } from 'theme/ui'

import ExternalLink from 'components/common/external-link'
interface PendingViewProps {
  connector?: ConnectorType
  error: boolean
  pendingErrorWrongNetwork: any
  setPendingError: (error: boolean) => void
  setPendingWrongNetwork: any
  tryActivation: (args: TryActivationArgs) => void
  handleBack: any
  errorMsg: any
  setScreenState: any
}

export const EthWalletStep3: React.FC<PendingViewProps> = ({
  connector,
  error,
  setPendingError,
  pendingErrorWrongNetwork,
  setPendingWrongNetwork,
  tryActivation,
  handleBack,
  errorMsg,
  setScreenState
}) => {
  const { t } = useTranslation('common')

  const type = useMemo(() => {
    if (connector instanceof MetaMask) return ConnectionType.INJECTED
    if (connector instanceof WalletConnectV2) return ConnectionType.WALLET_CONNECT_V2
    if (connector instanceof CoinbaseWallet) return ConnectionType.COINBASE_WALLET
    if (connector instanceof GnosisSafe) return ConnectionType.GNOSIS_SAFE
    // if (connector instanceof OKXWallet) return ConnectionType.OKX_WALLET
    if (connector instanceof TrustWallet) return ConnectionType.TRUST_WALLET
  }, [connector])

  const handleSwitch = useCallback(async () => {
    if (!connector) return
    try {
      connector
        .activate(Number(DEFAULT_CHAIN_ID))
        ?.then(() => {
          setPendingError(false)
          setPendingWrongNetwork(false)
          setScreenState(WalletSteps.CONNECTED)
        })
        .catch(e => {
          console.log('Network switch error', e)
        })
    } catch (error) {
      console.log('Error changing network')
    }
  }, [connector, setPendingError, setPendingWrongNetwork, setScreenState])

  const formatedErrorMsg = useMemo(() => {
    if (errorMsg === 'NoSafeContext: The app is loaded outside safe context')
      return (
        <>
          The app is loaded outside safe context.
          <ExternalLink href='https://app.safe.global' styles={{ justifyContent: 'center', mt: 5 }}>
            <Text fontWeight='bold' fontSize='lg' color='primary'>
              Visit app.safe.global
            </Text>
          </ExternalLink>
        </>
      )
    return errorMsg
  }, [errorMsg])

  return (
    <Flex flexDirection='column' width='100%'>
      <Flex
        my={20}
        flex={1}
        flexDirection='column'
        sx={{
          borderRadius: 'sm'
        }}
      >
        {error && pendingErrorWrongNetwork ? (
          <Box width='100%'>
            <Flex width='100%' flexDirection='column' alignItems='center' mb={20}>
              <Flex color='primary' flex={1} width='100%'>
                <Notification noicon reducePadding width='100%'>
                  {t('wallet.label.wrong-network')}
                </Notification>
              </Flex>
            </Flex>
            <Flex flex={1} justifyContent='space-between'>
              <Button flex={0.48} onClick={handleBack} buttonStyles={{ cursor: 'pointer' }} fontWeight='bold' width='100%' outlined>
                {t('wallet.button.back')}
              </Button>
              <Button flex={0.48} size='md' buttonStyles={{ cursor: 'pointer' }} onClick={handleSwitch} color='primary' fontWeight='bold' maxWidth={160}>
                <Text mx={-10}>{t('wallet.label.change-network')}</Text>
              </Button>
            </Flex>
          </Box>
        ) : error ? (
          <>
            <Flex width='100%' flexDirection='column' justifyContent='center' alignItems='center' mb={20}>
              <Flex flexDirection='column' color='primary' width='100%'>
                <Notification noicon reducePadding sx={{ px: 10 }}>
                  <Box fontWeight={800}>{t('wallet.label.error-connecting')}</Box>
                  <Box mt={10}>{formatedErrorMsg}</Box>
                </Notification>
              </Flex>
            </Flex>
            <Flex flex={1} justifyContent='space-between'>
              <Button
                onClick={() => {
                  setPendingError(false)
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  connector &&
                    tryActivation({
                      connector,
                      type
                    })
                }}
                flex={0.48}
                size='md'
                color='primary'
                fontWeight='bold'
              >
                {t('wallet.label.try-again')}
              </Button>
              <Button flex={0.48} onClick={handleBack} buttonStyles={{ cursor: 'pointer' }} fontWeight='bold' width='100%' outlined>
                {t('wallet.button.back')}
              </Button>
            </Flex>
          </>
        ) : (
          <Flex flexDirection='column' color='primary'>
            <Notification reducePadding mb={20}>
              {t('wallet.label.initializing')}
            </Notification>
            <Button flex={0.48} onClick={handleBack} buttonStyles={{ cursor: 'pointer' }} fontWeight='bold' width='100%' outlined>
              {t('wallet.button.back')}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
