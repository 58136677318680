import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { JsonRpcProvider, WebSocketProvider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import {
  bendXlImagePath,
  CRYPTOPUNKS_LINK,
  DEFAULT_CHAIN_ID,
  OPENSEA_LINK,
  openSeaImagePathXl,
  punksXlImagePath,
  X2Y2_LINK,
  x2y2ImagePath
} from 'constants/index'
import { permalink } from 'constants/routes'
import { get, isEmpty } from 'lodash'
import { LEND_POOL_ADDRESSES_PROVIDER, PUNK_GATEWAY_ID, WETH_GATEWAY_ID } from 'modules/bend/constants'
import { useAddressProviderContract } from 'modules/bend/hooks'
import { getAddress } from 'modules/bend/utils/LendPoolAddressesProvider'
import { useTranslation } from 'next-i18next'

import useReserves from '../hooks/common/useReserves'

import { BreadcrumbsContext, IBreadcrumbsState } from '.'

type ActiveReserve = {
  id: string
  underlyingAsset: string
}

type MarketIcon = {
  key: string
  title: string
  link: string
  image: string
}

type PlatformContextProviderProps = {
  breadcrumbs: IBreadcrumbsState
  isSupportedNetwork?: boolean
  activeReserve: ActiveReserve
  setActiveReserve: React.Dispatch<React.SetStateAction<ActiveReserve>>
  handleSelectReserve: any
  config?: any
  marketIcons: Array<MarketIcon>
  httpProvider?: JsonRpcProvider
  wsProvider?: WebSocketProvider
  unreadedNotifications: number | string
  setUnreadedNotifications: React.Dispatch<React.SetStateAction<string | number>>
}

export interface Config {
  PUNK_GATEWAY: string
  WETH_GATEWAY: string
  isLoadingConfig: boolean
}

const ConfigInit: Config = {
  PUNK_GATEWAY: '',
  WETH_GATEWAY: '',
  isLoadingConfig: true
}

const PlatformContext = createContext<PlatformContextProviderProps>({
  breadcrumbs: {
    breadcrumbTitle: ''
  },
  activeReserve: {
    underlyingAsset: '',
    id: ''
  },
  setActiveReserve: () => {},
  handleSelectReserve: () => {},
  marketIcons: [],
  unreadedNotifications: 0,
  setUnreadedNotifications: () => {}
})

const usePlatform = () => useContext(PlatformContext)

export default usePlatform

export const PlatformContextProvider: React.FC = ({ children }) => {
  const breadcrumbs = useContext(BreadcrumbsContext)
  const { chainId } = useWeb3React()
  const isSupportedNetwork = useMemo((): boolean => `${chainId}` === DEFAULT_CHAIN_ID, [chainId])
  const [unreadedNotifications, setUnreadedNotifications] = useState<string | number>(0)

  const { data: reserves } = useReserves()

  const addressProviderContract = useAddressProviderContract(LEND_POOL_ADDRESSES_PROVIDER)
  const { data: configResponse, isLoading: configLoading }: UseQueryResult<Config> = useQuery(['get bend addresses', chainId], async () => {
    if (addressProviderContract) {
      const punkGateway = await getAddress(addressProviderContract, PUNK_GATEWAY_ID)
      const wethGateway = await getAddress(addressProviderContract, WETH_GATEWAY_ID)

      return {
        PUNK_GATEWAY: get(punkGateway, '0') ?? '',
        WETH_GATEWAY: get(wethGateway, '0') ?? '',
        isLoadingConfig: false
      }
    }
    return {
      PUNK_GATEWAY: '',
      WETH_GATEWAY: '',
      isLoadingConfig: false
    }
  })

  const [activeReserve, setActiveReserve] = useState({ id: '', underlyingAsset: '' })

  useEffect(() => {
    if (!reserves || isEmpty(reserves) || activeReserve.id !== '' || activeReserve.underlyingAsset !== '') return
    setActiveReserve({
      id: reserves[0].id,
      underlyingAsset: reserves[0].underlyingAsset
    })
  }, [activeReserve.id, activeReserve.underlyingAsset, reserves])

  const handleSelectReserve = useCallback(
    (payload: ActiveReserve) => {
      if (payload.underlyingAsset === activeReserve.underlyingAsset) return
      setActiveReserve(payload)
    },
    [activeReserve.underlyingAsset]
  )

  const { t: tc } = useTranslation('common')
  const marketIcons = useMemo(
    (): Array<MarketIcon> => [
      {
        key: 'benddao',
        title: tc('label.benddao-url'),
        link: permalink.liquidity,
        image: bendXlImagePath
      },
      {
        key: 'x2y2',
        title: tc('label.x2y2-url'),
        link: X2Y2_LINK,
        image: x2y2ImagePath
      },
      {
        key: 'cryptopunks',
        title: tc('label.cryptopunks-url'),
        link: CRYPTOPUNKS_LINK,
        image: punksXlImagePath
      },
      {
        key: 'opensea',
        title: tc('label.opensea-url'),
        link: OPENSEA_LINK,
        image: openSeaImagePathXl
      }
    ],
    [tc]
  )

  return (
    <PlatformContext.Provider
      value={{
        breadcrumbs,
        isSupportedNetwork,
        activeReserve,
        setActiveReserve,
        handleSelectReserve,
        config: configLoading ? ConfigInit : configResponse,
        marketIcons,
        unreadedNotifications,
        setUnreadedNotifications
      }}
    >
      {children}
    </PlatformContext.Provider>
  )
}
