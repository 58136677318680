import { useMemo } from 'react'
import { FunctionFragment } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { useBlockNumber } from 'wallet-module'

import { Call, CallState, ListenerOptions, OptionalMethodInputs } from '../interfaces'
import { isValidMethodArgs, toCallState } from '../utils'

import { useCallsData } from '.'

export function checkInputErrors({ fragment, inputs }: { fragment: FunctionFragment; inputs?: OptionalMethodInputs }): boolean {
  if (fragment.inputs.length === 0) return false
  if (fragment?.inputs?.length !== inputs?.length) return true
  for (let i = 0; i < fragment.inputs.length; i++) {
    const element = fragment.inputs[i]
    if (element.type === 'address')
      if (inputs[i] === '') {
        return true
      }
    if (inputs[i] === undefined) {
      return true
    }
  }
  return false
}

export function useSingleCallResult(
  contract: Contract | null | undefined,
  methodName: string,
  inputs?: OptionalMethodInputs,
  options?: ListenerOptions,
  isLoading = false
): CallState {
  const { chainId } = useWeb3React()
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

  const calls = useMemo<Call[]>(() => {
    if (!fragment) return []
    if (isLoading || checkInputErrors({ fragment: fragment, inputs: inputs }) || chainId !== Number(DEFAULT_CHAIN_ID)) return []
    try {
      return contract && fragment && isValidMethodArgs(inputs)
        ? [
            {
              address: contract.address,
              callData: contract.interface.encodeFunctionData(fragment, inputs)
            }
          ]
        : []
    } catch (e) {
      console.log('e', e)
      return []
    }
  }, [chainId, contract, fragment, inputs, isLoading])

  const result = useCallsData(calls, options)[0]
  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return toCallState(result, contract?.interface, fragment, latestBlockNumber)
  }, [result, contract, fragment, latestBlockNumber])
}
