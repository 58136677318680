import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { useWeb3React } from '@web3-react/core'
import { Network } from '@web3-react/network'
import { COOKIE_USER_DENIED_LOGIN } from 'constants/index'
import { removeCookies } from 'cookies-next'

export function useCheckAccount() {
  const { connector, chainId, account } = useWeb3React()
  const queryClient = useQueryClient()
  const checkChainId = useRef<undefined | number>(undefined)
  const checkAccount = useRef<undefined | string>(undefined)

  useEffect(() => {
    if (!chainId || connector instanceof Network || connector instanceof CoinbaseWallet) return

    if (checkChainId.current === undefined) checkChainId.current = chainId
    if (checkAccount.current === undefined) checkAccount.current = account

    if (chainId !== checkChainId.current) {
      console.log('Chain changed. Refetching...')
      checkChainId.current = chainId
      queryClient.refetchQueries()
      removeCookies(COOKIE_USER_DENIED_LOGIN)
    }

    if (account !== checkAccount.current) {
      console.log('Account changed. Refetching...')
      checkAccount.current = account
      queryClient.refetchQueries()
      removeCookies(COOKIE_USER_DENIED_LOGIN)
    }
  }, [account, chainId, connector, queryClient])
}

type UseActionOnAccountChangeProps = {
  callback: () => void
}

export function useActionOnAccountChange({ callback }: UseActionOnAccountChangeProps) {
  const { account } = useWeb3React()
  const checkAccount = useRef<undefined | string>(undefined)
  useEffect(() => {
    if (checkAccount.current === undefined) checkAccount.current = account
    // Check if account changed and trigger callback

    if (account !== checkAccount.current) {
      console.log('Account changed. Triggering callback...')
      checkAccount.current = account
      callback()
    }
  }, [account, callback])
}
