import React from 'react'

interface IIconBorrow {
  color?: string
  size?: number
}

const IconPrivateNftCollection = React.forwardRef<SVGSVGElement, IIconBorrow>(({ color = 'currentColor', size = 38.456, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 32.422 38.456' {...rest}>
      <g id='Group_6788' data-name='Group 6788'>
        <path
          id='Path_2726'
          data-name='Path 2726'
          d='M81.169,417.733H65.481a1.127,1.127,0,0,0,0,2.253H81.169a1.127,1.127,0,0,0,0-2.253'
          transform='translate(-59.528 -386.358)'
          fill={color}
        />
        <path
          id='Path_2727'
          data-name='Path 2727'
          d='M28.882,0H8.367a3.538,3.538,0,0,0-3.54,3.54V4.827H3.54A3.538,3.538,0,0,0,0,8.367V34.915a3.538,3.538,0,0,0,3.54,3.54H24.055a3.538,3.538,0,0,0,3.54-3.54V33.629h1.287a3.544,3.544,0,0,0,3.54-3.54V3.54A3.538,3.538,0,0,0,28.882,0Zm-3.54,34.916A1.287,1.287,0,0,1,24.055,36.2H3.54a1.287,1.287,0,0,1-1.287-1.287V8.367A1.287,1.287,0,0,1,3.54,7.08H24.055a1.287,1.287,0,0,1,1.287,1.287Zm4.827-4.827a1.288,1.288,0,0,1-1.287,1.287H27.595V8.367a3.539,3.539,0,0,0-3.54-3.54H7.08V3.54A1.287,1.287,0,0,1,8.367,2.253H28.882A1.287,1.287,0,0,1,30.169,3.54Z'
          fill={color}
        />
        <path
          id='Path_2728'
          data-name='Path 2728'
          d='M64.829,136.1l7.844,4.529a1.126,1.126,0,0,0,1.127,0l7.844-4.529a1.127,1.127,0,0,0,.563-.976v-9.057a1.127,1.127,0,0,0-.563-.976L73.8,120.562a1.127,1.127,0,0,0-1.127,0l-7.844,4.529a1.127,1.127,0,0,0-.563.976v9.057A1.127,1.127,0,0,0,64.829,136.1Zm1.69-9.382,6.717-3.878,6.717,3.878v7.756l-6.717,3.878-6.717-3.878Z'
          transform='translate(-59.439 -111.367)'
          fill={color}
        />
        <path
          id='Path_2729'
          data-name='Path 2729'
          d='M136.088,195.016l2.816-4.224a1.127,1.127,0,0,0,0-1.25l-2.816-4.224a1.132,1.132,0,0,0-1.875,0l-2.816,4.224a1.127,1.127,0,0,0,0,1.25l2.816,4.224A1.132,1.132,0,0,0,136.088,195.016Zm-2.4-4.849,1.462-2.193,1.462,2.193-1.462,2.193Z'
          transform='translate(-121.353 -170.939)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconPrivateNftCollection
