import React from 'react'
import { Text } from 'rebass/styled-components'

import { IHeading2 } from './interfaces'

const Heading2: React.FC<IHeading2> = ({ text, ...restprops }) => (
  <Text as='h2' fontSize='xxl' fontWeight={700} mb={10} {...restprops}>
    {text}
  </Text>
)

export default Heading2
