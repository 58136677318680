import React from 'react'

interface IIconDashboard {
  color?: string
  size?: number
}

const IconDashboard = React.forwardRef<SVGSVGElement, IIconDashboard>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='dashboard'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M12.7,12c-0.2-0.1-0.3-0.1-0.5-0.2c0.7-0.3,1.4-0.7,1.9-1.3c1.1-1.1,1.7-2.6,1.7-4.1c0-1.6-0.6-3-1.7-4.1
          C13,1.2,11.5,0.6,10,0.6c0,0,0,0,0,0c-3.2,0-5.8,2.6-5.8,5.8c0,2.4,1.4,4.5,3.6,5.4C4.9,12.8,3,15.5,3,18.5c0,0.5,0.4,0.9,0.9,0.9
          s0.9-0.4,0.9-0.9c0-1.4,0.6-2.8,1.5-3.7c1-1,2.3-1.5,3.7-1.5c0,0,0,0,0,0c2.9,0,5.3,2.4,5.3,5.3c0,0.5,0.4,0.9,0.9,0.9h0
          c0.5,0,0.9-0.4,0.9-0.9C17,15.7,15.3,13.1,12.7,12z M14.1,6.5c0,2.2-1.8,4.1-4.1,4.1c0,0,0,0,0,0c-1.1,0-2.1-0.4-2.9-1.2
          C6.3,8.6,5.9,7.5,5.9,6.5c0-2.2,1.8-4.1,4.1-4.1C12.2,2.4,14.1,4.2,14.1,6.5z'
        />
      </g>
    </svg>
  )
})

export default IconDashboard
