import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconRepay = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 34, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 34.434 34.434'
      fill='none'
      stroke={color}
      strokeWidth='0.3'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g transform='translate(-276.856 -135.356)'>
        <g transform='translate(0 -0.226)'>
          <g>
            <path
              d='M107.319,210.265c-2.8,0-4.919-1.175-4.919-2.733s2.115-2.733,4.919-2.733,4.919,1.175,4.919,2.733-2.115,2.733-4.919,2.733m0-4.373c-2.19,0-3.826.866-3.826,1.64s1.636,1.64,3.826,1.64,3.826-.866,3.826-1.64-1.636-1.64-3.826-1.64'
              transform='translate(186.753 -58.518)'
              fill={color}
              stroke={color}
              strokeWidth='1.25'
            />
            <path
              d='M107.319,245.493c-2.8,0-4.919-1.175-4.919-2.733v-3.28a.547.547,0,0,1,1.093,0v3.28c0,.774,1.636,1.64,3.826,1.64s3.826-.866,3.826-1.64v-3.28a.547.547,0,0,1,1.093,0v3.28c0,1.558-2.115,2.733-4.919,2.733'
              transform='translate(186.753 -89.918)'
              fill={color}
              stroke={color}
              strokeWidth='1.25'
            />
            <path
              d='M107.319,245.493c-2.8,0-4.919-1.175-4.919-2.733v-3.28a.547.547,0,0,1,1.093,0v3.28c0,.774,1.636,1.64,3.826,1.64s3.826-.866,3.826-1.64v-3.28a.547.547,0,0,1,1.093,0v3.28c0,1.558-2.115,2.733-4.919,2.733'
              transform='translate(186.753 -86.176)'
              fill={color}
              stroke={color}
              strokeWidth='1.25'
            />
          </g>
        </g>
        <path
          d='M16.317,32.634A16.336,16.336,0,0,1,0,16.317a.628.628,0,0,1,1.255,0A15.07,15.07,0,1,0,4.87,6.533a.628.628,0,0,1-.954-.816,16.316,16.316,0,1,1,12.4,26.917'
          transform='translate(277.756 136.256)'
          fill={color}
          stroke={color}
          strokeWidth='1.8'
        />
        <path
          d='M56.848,7.531a.642.642,0,0,1-.1-.008l-5.021-.778a.628.628,0,0,1-.531-.62V.628a.628.628,0,0,1,1.255,0v4.96l4.49.7a.628.628,0,0,1-.1,1.248'
          transform='translate(230.32 136.256)'
          fill={color}
          stroke={color}
          strokeWidth='1.8'
        />
      </g>
    </svg>
  )
})

export default IconRepay
