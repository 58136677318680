import React from 'react'

interface IIconGridList {
  color?: string
  size?: number
}

const IconGridList = React.forwardRef<SVGSVGElement, IIconGridList>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='gridlist'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M6.9,2H4.5C3.1,2,2,3.1,2,4.5v2.5c0,1.4,1.1,2.5,2.5,2.5h2.5c1.4,0,2.5-1.1,2.5-2.5V4.5C9.4,3.1,8.3,2,6.9,2z M8.2,6.9
		    c0,0.7-0.6,1.2-1.2,1.2H4.5c-0.7,0-1.2-0.6-1.2-1.2V4.5c0-0.7,0.6-1.2,1.2-1.2h2.5c0.7,0,1.2,0.6,1.2,1.2V6.9z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M15.5,2h-2.5c-1.4,0-2.5,1.1-2.5,2.5v2.5c0,1.4,1.1,2.5,2.5,2.5h2.5c1.4,0,2.5-1.1,2.5-2.5V4.5C18,3.1,16.9,2,15.5,2z
            M16.8,6.9c0,0.7-0.6,1.2-1.2,1.2h-2.5c-0.7,0-1.2-0.6-1.2-1.2V4.5c0-0.7,0.6-1.2,1.2-1.2h2.5c0.7,0,1.2,0.6,1.2,1.2V6.9z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M6.9,10.6H4.5c-1.4,0-2.5,1.1-2.5,2.5v2.5C2,16.9,3.1,18,4.5,18h2.5c1.4,0,2.5-1.1,2.5-2.5v-2.5
            C9.4,11.7,8.3,10.6,6.9,10.6z M8.2,15.5c0,0.7-0.6,1.2-1.2,1.2H4.5c-0.7,0-1.2-0.6-1.2-1.2v-2.5c0-0.7,0.6-1.2,1.2-1.2h2.5
            c0.7,0,1.2,0.6,1.2,1.2V15.5z'
        />
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M15.5,10.6h-2.5c-1.4,0-2.5,1.1-2.5,2.5v2.5c0,1.4,1.1,2.5,2.5,2.5h2.5c1.4,0,2.5-1.1,2.5-2.5v-2.5
            C18,11.7,16.9,10.6,15.5,10.6z M16.8,15.5c0,0.7-0.6,1.2-1.2,1.2h-2.5c-0.7,0-1.2-0.6-1.2-1.2v-2.5c0-0.7,0.6-1.2,1.2-1.2h2.5
            c0.7,0,1.2,0.6,1.2,1.2V15.5z'
        />
      </g>
    </svg>
  )
})

export default IconGridList
