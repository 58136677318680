import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMint = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 14, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14.308 14.321' {...rest}>
      <g id='Group_7474' data-name='Group 7474' transform='translate(0.139 0.139)'>
        <g id='Group_7475' data-name='Group 7475' transform='translate(0 0)'>
          <path
            id='Path_2763'
            data-name='Path 2763'
            d='M36.668,29.768h0Zm-.556.743.258,2.165.087.726.726.087,2.164.258L37.6,35.052l-.586.438.288.672.859,2-2-.859-.672-.288-.438.586L33.744,39.35l-.258-2.165L33.4,36.46l-.726-.087-2.165-.258,1.745-1.305.586-.438-.288-.672-.859-2,2,.859.672.288.438-.586,1.305-1.745m.259-1.524a.559.559,0,0,0-.447.223L34.062,31.7,31.2,30.475a.558.558,0,0,0-.732.732L31.7,34.065l-2.49,1.862a.558.558,0,0,0,.268,1l3.087.368.368,3.087a.558.558,0,0,0,1,.268l1.862-2.49,2.858,1.225a.558.558,0,0,0,.732-.732L38.158,35.8l2.49-1.862a.558.558,0,0,0-.268-1l-3.087-.368-.368-3.087a.557.557,0,0,0-.554-.491'
            transform='translate(-27.906 -27.909)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2764'
            data-name='Path 2764'
            d='M280.887,48.333l.789-.789a.558.558,0,0,0-.789-.789l-.789.789a.558.558,0,1,0,.789.789'
            transform='translate(-269.528 -44.861)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2765'
            data-name='Path 2765'
            d='M50.887,278.333l.789-.789a.558.558,0,1,0-.789-.789l-.789.789a.558.558,0,0,0,.789.789'
            transform='translate(-48.078 -266.31)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2766'
            data-name='Path 2766'
            d='M128.269,2.174a.558.558,0,0,0,.394-.683L128.374.413A.558.558,0,1,0,127.3.7l.289,1.077a.558.558,0,0,0,.683.394'
            transform='translate(-122.546 0)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2767'
            data-name='Path 2767'
            d='M213.1,318.773a.558.558,0,0,0-.394.683l.289,1.077a.558.558,0,0,0,1.077-.289l-.289-1.077a.558.558,0,0,0-.683-.394'
            transform='translate(-204.781 -306.904)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2768'
            data-name='Path 2768'
            d='M1.78,127.589.7,127.3a.558.558,0,1,0-.289,1.077l1.077.289a.558.558,0,0,0,.289-1.077Z'
            transform='translate(0 -122.549)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2769'
            data-name='Path 2769'
            d='M318.776,213.105a.558.558,0,0,0,.394.683l1.077.289a.558.558,0,0,0,.289-1.077l-1.077-.289A.558.558,0,0,0,318.776,213.105Z'
            transform='translate(-306.907 -204.785)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconMint
