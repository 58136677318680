import React from 'react'

interface IIconBendApe {
  color?: string
  size?: number
}

const IconApePools = React.forwardRef<SVGSVGElement, IIconBendApe>(({ color = 'currentColor', size = 40 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 42.978 36.972' ref={ref}>
      <g id='Group_17223' data-name='Group 17223' transform='translate(0.124 0.125)'>
        <g id='Group_8647' data-name='Group 8647' transform='translate(0.001)'>
          <path
            id='Union_18'
            data-name='Union 18'
            d='M33.4,36.635l-12.037-2.9-12.037,2.9a2.483,2.483,0,0,1-3.02-1.844L.075,10.045C.069,10.022.064,10,.06,9.976A4.906,4.906,0,0,1,0,9.3,2.3,2.3,0,0,1,1.853,7.031l.016,0L9.263,5.188V2.5a2.507,2.507,0,0,1,2.5-2.5H30.961a2.507,2.507,0,0,1,2.5,2.5V5.188l7.41,1.843a2.613,2.613,0,0,1,1.277.747A2.219,2.219,0,0,1,42.728,9.3a3.7,3.7,0,0,1-.065.695l-.009.045-2.4,9.53a.855.855,0,0,1-1.014.6.835.835,0,0,1-.606-1.013l2.395-9.507c.11-.553-.051-.87-.58-1.015L33.465,6.908V8.485l3.292,3.685a.832.832,0,0,1,.166.83L35,18.507a.834.834,0,0,1-.616.542l-.922.195V31.207a2.507,2.507,0,0,1-2.5,2.5H28.389l5.4,1.3a.825.825,0,0,0,1.009-.622l2-7.942a.834.834,0,0,1,1.618.407l-2,7.934h0a2.543,2.543,0,0,1-2.426,1.929A2.1,2.1,0,0,1,33.4,36.635ZM2.28,8.645c-.8.274-.6.616-.578,1.03L7.927,34.391a.827.827,0,0,0,1.008.622l5.4-1.3H11.766a2.507,2.507,0,0,1-2.5-2.5V19.243l-.922-.195a.834.834,0,0,1-.616-.542L5.8,13a.835.835,0,0,1,.166-.831L9.263,8.485V6.908ZM10.932,2.5v28.7a.836.836,0,0,0,.834.835H30.961a.836.836,0,0,0,.834-.835V2.5a.835.835,0,0,0-.834-.834H11.766A.836.836,0,0,0,10.932,2.5ZM33.465,17.537l.118-.024,1.6-4.6-1.72-1.925ZM7.542,12.914l1.6,4.6.118.024V10.989ZM26.782,28.708V22.866h-.828a.835.835,0,0,1,0-1.669h3.338a.835.835,0,0,1,0,1.669h-.841v5.842a.835.835,0,0,1-1.669,0Zm-7.5,0V22.032a.835.835,0,0,1,.834-.835h2.5a.835.835,0,0,1,0,1.669H20.947v1.606h1.47a.834.834,0,0,1,0,1.668h-1.47v2.567a.835.835,0,0,1-1.669,0Zm-6.676,0V22.044a.834.834,0,0,1,1.583-.368l1.755,3.561v-3.2a.835.835,0,1,1,1.669,0v6.451a.928.928,0,0,1-.7.961.892.892,0,0,1-1.009-.507l-1.632-3.312v3.082a.835.835,0,0,1-1.669,0Zm25.722-4.884a.835.835,0,0,1,.2-1.644.854.854,0,0,1,.2.025.835.835,0,1,1-.407,1.619ZM20.945,19.082l-5.758-3.339a.832.832,0,0,1-.416-.722V8.345a.832.832,0,0,1,.416-.721l5.758-3.339a.836.836,0,0,1,.838,0L27.54,7.624a.832.832,0,0,1,.416.721v6.676a.832.832,0,0,1-.416.722l-5.757,3.339a.84.84,0,0,1-.838,0ZM16.44,8.826v5.715L21.364,17.4l4.923-2.854V8.826L21.364,5.972Z'
            fill={color}
            stroke={color}
            strokeMiterlimit='10'
            strokeWidth='0.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconApePools
