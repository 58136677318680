import React from 'react'

const IconHighlights2 = React.forwardRef<SVGSVGElement>(() => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='43.318' height='50.934' viewBox='0 0 43.318 50.934'>
      <g id='Group_5118' data-name='Group 5118' transform='translate(3.029 0.628)'>
        <path
          id='Path_2365'
          data-name='Path 2365'
          d='M41.288,6.269,21.423.059a1.309,1.309,0,0,0-.778,0L.779,6.269A1.053,1.053,0,0,0,0,7.246v12.42c0,16.073,2.691,21.947,20.508,29.9a1.3,1.3,0,0,0,1.052,0c17.817-7.957,20.508-13.831,20.508-29.9V7.246a1.053,1.053,0,0,0-.779-.976m-1.558,13.4c0,15.395-2.216,20.378-18.7,27.82-16.481-7.442-18.7-12.425-18.7-27.82V7.979l18.7-5.845,18.7,5.845V19.666Z'
          transform='translate(-2.404 0)'
          fill='#ef5350'
          stroke='#ef5350'
          strokeWidth='1.25'
        />
        <g id='Group_5143' data-name='Group 5143' transform='translate(7.046 12.253)'>
          <g id='Group_5144' data-name='Group 5144'>
            <path
              id='Path_2387'
              data-name='Path 2387'
              d='M11.586,0A11.586,11.586,0,1,0,23.173,11.586,11.6,11.6,0,0,0,11.586,0Zm7.991,12.785H20.7a9.143,9.143,0,0,1-1.824,4.393l-.789-.789a1.2,1.2,0,0,0-1.7,1.7l.789.789A9.143,9.143,0,0,1,12.785,20.7v-1.12a1.2,1.2,0,1,0-2.4,0V20.7a9.144,9.144,0,0,1-4.393-1.824l.789-.789a1.2,1.2,0,0,0-1.7-1.7l-.789.789a9.146,9.146,0,0,1-1.824-4.393H3.6a1.2,1.2,0,1,0,0-2.4H2.475A9.144,9.144,0,0,1,4.3,5.995l.789.789a1.2,1.2,0,0,0,1.7-1.7L5.995,4.3a9.145,9.145,0,0,1,4.393-1.824V3.6a1.2,1.2,0,1,0,2.4,0V2.475A9.143,9.143,0,0,1,17.178,4.3l-.789.789a1.2,1.2,0,0,0,1.7,1.7l.789-.789A9.144,9.144,0,0,1,20.7,10.388h-1.12a1.2,1.2,0,0,0,0,2.4'
              fill='#ef5350'
              stroke='#ef5350'
              strokeWidth='0.5'
            />
            <path
              id='Path_2388'
              data-name='Path 2388'
              d='M134.39,93.192h-2v-2a1.2,1.2,0,1,0-2.4,0v3.2a1.2,1.2,0,0,0,1.2,1.2h3.2a1.2,1.2,0,0,0,0-2.4Z'
              transform='translate(-119.607 -82.805)'
              fill='#ef5350'
              stroke='#ef5350'
              strokeWidth='0.5'
            />
          </g>
        </g>
      </g>
    </svg>
  )
})

export default IconHighlights2
