import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconAutoPairing = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 27, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.05 18.199' fill={color} {...rest}>
      <g id='Group_17231' data-name='Group 17231' transform='translate(0 0)'>
        <path
          id='Path_4475'
          data-name='Path 4475'
          d='M17.95,0a9.031,9.031,0,0,0-4.425,1.155A9.1,9.1,0,1,0,9.1,18.2a9.031,9.031,0,0,0,4.425-1.156A9.1,9.1,0,1,0,17.95,0M9.1,16.71A7.611,7.611,0,1,1,12.123,2.119a9.083,9.083,0,0,0,0,13.961,7.57,7.57,0,0,1-3.023.63M16.709,9.1a7.6,7.6,0,0,1-3.184,6.18,7.588,7.588,0,0,1,0-12.359A7.6,7.6,0,0,1,16.709,9.1M17.95,16.71a7.566,7.566,0,0,1-3.023-.63,9.082,9.082,0,0,0,0-13.961A7.609,7.609,0,1,1,17.95,16.71'
          transform='translate(0 0)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconAutoPairing
