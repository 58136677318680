import React, { forwardRef } from 'react'
import { Input as InputRebass, Label as LabelRebass, Select as SelectRebass } from '@rebass/forms'
// Hooks
import useTheme from 'hooks/common/useTheme'
import { Flex } from 'rebass/styled-components'

// Interfaces
import { InputProps, SelectOption, SelectProps } from './interfaces'

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      left,
      right,
      name,
      endAdornment,
      styles,
      labelStyles,
      size = 'sm',
      radiusSize = 8,
      borderColor,
      disabled = false,
      errors,
      inputNotActive = false,
      inputValue,
      placeholderColor,
      ...restprops
    },
    ref
  ): React.ReactElement => {
    const { darkMode, colors, borderRadius, fontSizes, fonts } = useTheme()

    switch (size) {
      default:
      case 'sm':
        return (
          <Flex flexDirection='column' width='100%'>
            {(left || right) && (
              <Flex alignItems='center' justifyContent='space-between'>
                {left && React.isValidElement(left) ? (
                  left
                ) : (
                  <LabelRebass
                    color={errors && errors[name!] ? colors['red.1000'] : 'grey.300'}
                    fontSize={fontSizes.lg}
                    ml={20}
                    mb='5px'
                    width='auto'
                    htmlFor={name}
                    sx={labelStyles}
                  >
                    {left}
                  </LabelRebass>
                )}
                {right && React.isValidElement(right) ? (
                  right
                ) : (
                  <LabelRebass color='grey.300' fontSize={fontSizes.lg} mr={20} width='auto' mb='5px' sx={labelStyles}>
                    {right}
                  </LabelRebass>
                )}
              </Flex>
            )}
            <Flex
              flexDirection='row'
              alignItems='center'
              sx={{
                width: '100%',
                borderColor: errors && errors[name!] ? 'red.1000' : borderColor,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: borderRadius[radiusSize],
                transition: 'all ease-in-out 0.2s',
                ...styles
              }}
            >
              <InputRebass
                flex={1}
                name={name}
                disabled={disabled}
                {...restprops}
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  borderColor: 'transparent',
                  borderRadius: 'sm',
                  borderWidth: '1px',
                  px: 20,
                  py: 13,
                  fontFamily: fonts.body,
                  fontSize: fontSizes.lg,
                  '&::placeholder': {
                    color: darkMode ? colors.grey[300] : colors.grey[300],
                    ...styles
                  },
                  '&:focus': {
                    outlineStyle: 'none'
                  },
                  '&:disabled': {
                    color: colors.label,
                    backgroundColor: 'transparent',
                    cursor: 'not-allowed'
                  },
                  ...restprops?.sx
                }}
                ref={ref}
              />
              {endAdornment && endAdornment}
            </Flex>
          </Flex>
        )
      case 'lg':
        return (
          <Flex
            flexDirection='column'
            justifyContent='center'
            sx={{
              pt: 15,
              pb: 10,
              width: '100%',
              borderColor: errors && errors[name!] ? 'red.1000' : borderColor ? borderColor : colors.black,
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: radiusSize || 'sm',
              transition: 'all ease-in-out 0.2s',
              ...styles
            }}
          >
            {(left || right) && (
              <Flex alignItems='center' justifyContent='space-between'>
                {left && (
                  <LabelRebass
                    color={errors && errors[name!] ? colors.red['1000'] : colors.grey['300']}
                    fontSize={fontSizes.sm}
                    width='auto'
                    ml={20}
                    mb={10}
                    htmlFor={name}
                    sx={{ lineHeight: '18px', ...labelStyles }}
                  >
                    {left}
                  </LabelRebass>
                )}
                {right && (
                  <LabelRebass color='grey.300' fontSize={fontSizes.sm} width='auto' mr={20} mb={10} sx={{ lineHeight: '18px', ...labelStyles }}>
                    {right}
                  </LabelRebass>
                )}
              </Flex>
            )}
            <Flex alignItems='center' justifyContent='space-between'>
              {inputNotActive ? (
                <Flex mx={20} py={5} flex={1} alignItems='center' justifyContent='space-between'>
                  {inputValue}
                  {endAdornment && endAdornment}
                </Flex>
              ) : (
                <>
                  <InputRebass
                    disabled={disabled}
                    flex={1}
                    name={name}
                    {...restprops}
                    sx={{
                      width: '100%',
                      textAlign: 'left',
                      borderColor: 'transparent',
                      borderRadius: radiusSize || 'sm',
                      borderWidth: '1px',
                      px: 20,
                      py: '4px',
                      fontFamily: fonts.body,
                      fontSize: fontSizes.lg,
                      '&::placeholder': {
                        color: placeholderColor ? placeholderColor : colors.grey[300]
                      },
                      '&:disabled': {
                        color: colors.label,
                        backgroundColor: 'transparent',
                        cursor: 'not-allowed'
                      },
                      '&:focus': {
                        outlineStyle: 'none'
                      },
                      ...restprops?.sx
                    }}
                    ref={ref}
                  />
                  {endAdornment && endAdornment}
                </>
              )}
            </Flex>
          </Flex>
        )
    }
  }
)

export const Select: React.FC<SelectProps> = ({ label, name, defaultValue, options, ...restprops }) => {
  const { darkMode, colors, fontSizes, fonts } = useTheme()
  return (
    <Flex flexDirection='column' width='100%'>
      {label && (
        <LabelRebass color='grey.300' fontSize={fontSizes.xl} mb='5px' ml={20} htmlFor={name}>
          {label}
        </LabelRebass>
      )}
      <Flex
        sx={{
          width: '100%',
          borderColor: darkMode ? colors.black : colors.black,
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 'sm',
          transition: 'all ease-in-out 0.2s',
          '& > div': {
            width: '100%'
          }
        }}
      >
        <SelectRebass
          name={name}
          defaultValue={defaultValue}
          {...restprops}
          sx={{
            width: '100%',
            textAlign: 'center',
            borderColor: 'transparent',
            borderRadius: 'sm',
            borderWidth: 1,
            px: 2,
            py: 1,
            fontFamily: fonts.body,
            fontSize: fontSizes.md,
            fontWeight: 200,
            '&:focus': {
              outlineStyle: 'none'
            }
          }}
        >
          {options.map((option: SelectOption) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectRebass>
      </Flex>
    </Flex>
  )
}

export default Input
