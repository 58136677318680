import React from 'react'
import Popover from 'rc-tooltip'
import { Flex, FlexProps, Link } from 'rebass/styled-components'
import { IconInfo } from 'theme/ui/icons'

interface TooltipProps extends FlexProps {
  text: string
  link?: string
  infoIconColor?: string
  size?: number
}

const CustomTooltip: React.FC<TooltipProps> = ({ text, link, infoIconColor, size = 12, ...restprops }) => {
  return (
    <Popover placement='top' overlay={text ? text : ''} {...restprops}>
      <Link href={link ? link : ''} target='_blank' rel='noopener noreferrer'>
        <Flex color={infoIconColor ? infoIconColor : 'primary'} sx={{ position: 'relative', size }}>
          <IconInfo size={size} />
        </Flex>
      </Link>
    </Popover>
  )
}

export default CustomTooltip
