import React from 'react'

interface IIconCircleQuestion {
  color?: string
  size?: number
}

const IconCircleQuestion = React.forwardRef<SVGSVGElement, IIconCircleQuestion>(({ color = 'currentColor', size = 56, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 56 56'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='question-circle-o' transform='translate(0.486)'>
        <path
          id='Shape'
          d='M8.2,47.8A28,28,0,0,1,47.8,8.2,28,28,0,1,1,8.2,47.8ZM18.631,5.828a24.072,24.072,0,1,0,26.386,5.149,24.293,24.293,0,0,0-7.654-5.156,24.138,24.138,0,0,0-18.732.006Zm7.831,38.288a2.374,2.374,0,1,1,1.184.317A2.371,2.371,0,0,1,26.462,44.116ZM27.52,36.3a1.973,1.973,0,0,1-1.766-2.155c.269-2.73,2.27-4.933,4.473-7.138,3.528-3.526,3.589-4.647,3.648-5.834a5.068,5.068,0,0,0-1.443-3.78A6.146,6.146,0,0,0,27.991,15.5h-.01a5.889,5.889,0,0,0-5.867,5.88,1.969,1.969,0,0,1-3.937,0,9.812,9.812,0,0,1,9.794-9.818,10.1,10.1,0,0,1,7.316,3.119,8.978,8.978,0,0,1,2.522,6.7c-.139,2.531-.913,4.528-4.8,8.411-1.97,1.968-3.211,3.446-3.337,4.742a1.97,1.97,0,0,1-1.957,1.777C27.652,36.306,27.585,36.3,27.52,36.3Z'
          transform='translate(-0.486 0)'
          fill='#0057ff'
        />
      </g>
    </svg>
  )
})

export default IconCircleQuestion
