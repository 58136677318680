import React from 'react'

interface IIconStake {
  color?: string
  size?: number
}

const IconStakeDash = React.forwardRef<SVGSVGElement, IIconStake>(({ color = 'currentColor', size = 48, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 48.759 48.759' fill={color} stroke={color} {...rest}>
      <g id='Group_6145' data-name='Group 6145' transform='translate(-1095.621 -752.376)'>
        <path
          id='Path_2481'
          data-name='Path 2481'
          d='M40.382,0H7.126A7.135,7.135,0,0,0,0,7.126V35.632a7.117,7.117,0,0,0,4.751,6.689v.437a4.751,4.751,0,1,0,9.5,0h19a4.751,4.751,0,1,0,9.5,0v-.437a7.117,7.117,0,0,0,4.751-6.689V7.126A7.135,7.135,0,0,0,40.382,0m2.375,35.632a2.379,2.379,0,0,1-2.375,2.375H7.126a2.379,2.379,0,0,1-2.375-2.375V7.126A2.379,2.379,0,0,1,7.126,4.751H40.382a2.379,2.379,0,0,1,2.375,2.375Z'
          transform='translate(1096.246 753.001)'
          fill={color}
          stroke='#fff'
          strokeWidth='1.25'
        />
        <path
          id='Path_2482'
          data-name='Path 2482'
          d='M146.98,85.333a8.291,8.291,0,0,0-2.375,16.241v5.138a2.375,2.375,0,1,0,4.751,0v-5.138a8.291,8.291,0,0,0-2.375-16.241m0,11.877a3.563,3.563,0,1,1,3.563-3.563,3.567,3.567,0,0,1-3.563,3.563'
          transform='translate(973.02 677.169)'
          fill={color}
          stroke='#fff'
          strokeWidth='1.25'
        />
      </g>
    </svg>
  )
})

export default IconStakeDash
