import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconReserve = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 26, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 27.834 26.094' {...rest}>
      <g id='Group_6663' data-name='Group 6663' transform='translate(0 0)'>
        <path
          id='Path_2719'
          data-name='Path 2719'
          d='M169.568,0C164.93,0,160,1.524,160,4.349V7.828a.87.87,0,0,0,1.74,0V6.922A15.783,15.783,0,0,0,169.568,8.7,15.783,15.783,0,0,0,177.4,6.922v.906c0,.851-2.14,2.208-6.162,2.543a.87.87,0,0,0,.071,1.736c.026,0,.049,0,.075,0a13.939,13.939,0,0,0,6.016-1.727v.931c0,.743-1.576,1.811-4.5,2.328a.87.87,0,0,0,.15,1.726.76.76,0,0,0,.153-.014,11.619,11.619,0,0,0,4.2-1.468v.908c0,.743-1.576,1.811-4.5,2.328a.87.87,0,0,0,.15,1.726.76.76,0,0,0,.153-.014,11.652,11.652,0,0,0,4.2-1.468v.908c0,.743-1.576,1.811-4.5,2.328a.87.87,0,0,0,.15,1.726.76.76,0,0,0,.153-.014,11.652,11.652,0,0,0,4.2-1.468v.908c0,.851-2.14,2.208-6.162,2.543a.87.87,0,0,0,.071,1.736c.026,0,.049,0,.075,0,3.86-.324,7.755-1.769,7.755-4.276V4.349c0-2.825-4.93-4.349-9.568-4.349m0,6.958c-4.779,0-7.828-1.547-7.828-2.609s3.049-2.609,7.828-2.609S177.4,3.286,177.4,4.349s-3.049,2.609-7.828,2.609'
          transform='translate(-151.302 0)'
          fill={color}
        />
        <path
          id='Path_2720'
          data-name='Path 2720'
          d='M9.568,192C4.93,192,0,193.524,0,196.349v6.958c0,2.825,4.93,4.349,9.568,4.349s9.568-1.524,9.568-4.349v-6.958c0-2.825-4.93-4.349-9.568-4.349M17.4,203.307c0,1.063-3.05,2.609-7.828,2.609S1.74,204.37,1.74,203.307V202.4a15.783,15.783,0,0,0,7.828,1.776A15.783,15.783,0,0,0,17.4,202.4Zm0-3.479c0,1.063-3.05,2.609-7.828,2.609s-7.828-1.547-7.828-2.609v-.906A15.783,15.783,0,0,0,9.568,200.7a15.783,15.783,0,0,0,7.828-1.776Zm-7.828-.87c-4.779,0-7.828-1.547-7.828-2.609s3.05-2.609,7.828-2.609,7.828,1.547,7.828,2.609-3.05,2.609-7.828,2.609'
          transform='translate(0 -181.562)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconReserve
