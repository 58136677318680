import React from 'react'

interface IIconOpenSea {
  color?: string
  size?: number
}

const IconOpenSea = React.forwardRef<SVGSVGElement, IIconOpenSea>(({ color = 'white', size = 35 }, ref) => {
  return (
    <svg
      ref={ref}
      id='Group_5104'
      color={color}
      data-name='Group 5104'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={size}
      height={size}
      viewBox='0 0 35.508 31.957'
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2034' data-name='Rectangle 2034' width='35.508' height='31.957' fill={color} />
        </clipPath>
      </defs>
      <g id='Group_5095' data-name='Group 5095'>
        <g id='Group_5094' data-name='Group 5094' transform='translate(0 0)' clipPath='url(clip-path)'>
          <path
            id='Path_2362'
            data-name='Path 2362'
            d='M3.278,21.743l.124-.2L10.894,9.827a.256.256,0,0,1,.45.033c1.252,2.805,2.332,6.294,1.826,8.465A13.237,13.237,0,0,1,11.7,21.548c-.086.163-.18.323-.281.476a.255.255,0,0,1-.213.113H3.5a.256.256,0,0,1-.219-.394'
            transform='translate(-1.165 -3.495)'
            fill={color}
          />
          <path
            id='Path_2363'
            data-name='Path 2363'
            d='M35.508,20.426v1.855a.267.267,0,0,1-.16.243,9.221,9.221,0,0,0-3.391,2.308c-2.107,2.933-3.717,7.125-7.315,7.125H9.632A9.647,9.647,0,0,1,0,22.293v-.172a.257.257,0,0,1,.257-.257H8.625a.284.284,0,0,1,.272.317,2.859,2.859,0,0,0,.3,1.606,2.944,2.944,0,0,0,2.639,1.639h4.143V22.192h-4.1a.264.264,0,0,1-.213-.414c.045-.068.095-.139.148-.219.387-.55.941-1.405,1.491-2.38a18.561,18.561,0,0,0,1.032-2.062c.06-.127.107-.257.154-.385.08-.225.163-.435.221-.645.06-.177.107-.364.154-.538a8.351,8.351,0,0,0,.2-1.888c0-.257-.012-.527-.036-.784-.012-.281-.047-.563-.083-.844-.024-.248-.068-.494-.116-.751-.059-.376-.141-.749-.237-1.124l-.032-.142c-.071-.257-.131-.5-.213-.76-.234-.808-.5-1.595-.787-2.332-.1-.293-.222-.574-.34-.855-.175-.424-.352-.808-.515-1.172-.083-.166-.154-.317-.225-.47-.08-.175-.163-.349-.246-.515-.06-.127-.127-.246-.175-.364l-.506-.936a.165.165,0,0,1,.187-.239l3.166.858h.009c.006,0,.008,0,.012,0l.417.116.459.13.168.047V1.645A1.636,1.636,0,0,1,17.606,0a1.6,1.6,0,0,1,1.151.483,1.654,1.654,0,0,1,.477,1.163V4.438l.337.095a.27.27,0,0,1,.077.038c.083.062.2.154.352.266.118.095.246.211.4.328.305.246.669.563,1.068.927.107.092.211.186.305.281.515.479,1.092,1.041,1.642,1.663.154.175.305.352.459.539s.317.376.459.562c.186.248.387.506.562.775.083.127.178.258.257.385.225.34.424.692.612,1.044.08.163.163.34.234.515a7.517,7.517,0,0,1,.482,1.429,1.8,1.8,0,0,1,.068.317v.024a2.7,2.7,0,0,1,.059.447,4.787,4.787,0,0,1-.083,1.477,5.453,5.453,0,0,1-.175.618c-.071.2-.142.412-.234.609a8.315,8.315,0,0,1-.636,1.208c-.08.142-.175.292-.269.435s-.21.293-.3.432c-.131.178-.269.364-.412.53a5.672,5.672,0,0,1-.4.5c-.2.234-.388.456-.586.669-.118.139-.245.281-.376.408s-.257.269-.376.387c-.2.2-.364.353-.5.479l-.325.3a.266.266,0,0,1-.175.065H19.234v3.234h3.172a2.966,2.966,0,0,0,1.929-.713A23.117,23.117,0,0,0,26.3,22.784a.243.243,0,0,1,.121-.074l8.762-2.533a.258.258,0,0,1,.328.248'
            transform='translate(0 0)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconOpenSea
