import React from 'react'

interface IIconHistory {
  color?: string
  size?: number
}

const IconHistory = React.forwardRef<SVGSVGElement, IIconHistory>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 28.294 26.501' fill={color} stroke={color} {...rest}>
      <g id='Group_7942' data-name='Group 7942' transform='translate(0.31 0.25)'>
        <g id='Group_7940' data-name='Group 7940' transform='translate(0)'>
          <path
            id='Path_2788'
            data-name='Path 2788'
            d='M248.332,97.364l5.2,3.9a.867.867,0,1,0,1.04-1.387l-4.814-3.644V88.867a.867.867,0,0,0-1.733,0v7.8A.9.9,0,0,0,248.332,97.364Z'
            transform='translate(-234.59 -83.234)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
          <path
            id='Path_2789'
            data-name='Path 2789'
            d='M14.734,0A13,13,0,0,0,1.917,10.833l-.329-.49a.868.868,0,1,0-1.439.971l1.733,2.6a.867.867,0,0,0,.633.386H2.6a.865.865,0,0,0,.611-.256l2.167-2.167a.867.867,0,0,0-1.226-1.226l-.537.542A11.266,11.266,0,1,1,14.734,24.266a10.664,10.664,0,0,1-8.827-4.4A.867.867,0,0,0,4.5,20.868,12.38,12.38,0,0,0,14.734,26a13,13,0,0,0,0-26'
            transform='translate(0 0)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconHistory
