import React from 'react'

interface IIconLiquidityFarming {
  color?: string
  size?: number
}

const IconLiquidityFarming = React.forwardRef<SVGSVGElement, IIconLiquidityFarming>(({ color = 'currentColor', size = 39, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 39.535 39.54' fill={color} stroke={color} {...rest}>
      <path
        id='Path_2687'
        data-name='Path 2687'
        d='M37.011,15.119c-.3,0-.526-.15-.526-.451A17.569,17.569,0,0,0,24.531,1.511a5.666,5.666,0,0,1,1.278,3.007.564.564,0,0,1-1.128,0c0-1.278-1.5-3.007-2.105-3.534-.526-.451.075-1.053.526-.977A18.4,18.4,0,0,1,37.537,14.442a.554.554,0,0,1-.526.677M18.742,18.126a7.5,7.5,0,0,1,.226-11.8.564.564,0,0,1,.677,0,7.621,7.621,0,0,1,3.007,6.015A7.447,7.447,0,0,1,19.869,18.2V21.66a8.115,8.115,0,0,1,10.074-3.233.67.67,0,0,1,.376.6,8.085,8.085,0,0,1-10.45,6.766v5.939a.564.564,0,1,1-1.128,0V25.795a8.088,8.088,0,0,1-10.45-6.766c-.075-.3.075-.451.3-.6a8.3,8.3,0,0,1,7.293.451,7.806,7.806,0,0,1,2.857,2.782ZM19.268,7.45a6.392,6.392,0,0,0,0,9.7,6.29,6.29,0,0,0,0-9.7m.6,17.142a6.9,6.9,0,0,0,9.247-5.263,6.979,6.979,0,0,0-9.247,5.263M.021,15.42A18.693,18.693,0,0,1,14.531.984a.575.575,0,1,1,.226,1.128A17.424,17.424,0,0,0,1.525,14.066a5.241,5.241,0,0,1,3.082-1.353.564.564,0,1,1,0,1.128c-1.353,0-3.082,1.579-3.609,2.18a.589.589,0,0,1-.977-.6M15.358,38.5A18.527,18.527,0,0,1,1.074,24.066.575.575,0,1,1,2.2,23.84,17,17,0,0,0,14.08,37a5.566,5.566,0,0,1-1.2-3.007.564.564,0,0,1,1.128,0c0,1.278,1.5,3.007,2.105,3.534.526.6-.15,1.128-.752.977m8.571-.977a.572.572,0,0,1-.075-1.128A17.424,17.424,0,0,0,37.086,24.441,5.562,5.562,0,0,1,34,25.72a.564.564,0,0,1,0-1.128c1.353,0,3.082-1.5,3.609-2.105.451-.451.977,0,.9.6A18.415,18.415,0,0,1,24.08,37.448c-.075.075-.075.075-.15.075M9.419,19.329a6.979,6.979,0,0,0,9.247,5.263,6.91,6.91,0,0,0-9.247-5.263'
        transform='translate(0.51 0.504)'
        fill={color}
        stroke={color}
        strokeWidth='1'
        fillRule='evenodd'
      />
    </svg>
  )
})

export default IconLiquidityFarming
