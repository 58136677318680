import React from 'react'

interface IIconDisconnect {
  color?: string
  size?: number
}

const IconDisconnect = React.forwardRef<SVGSVGElement, IIconDisconnect>(({ color = 'currentColor', size = 16, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 15.517 15.517'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='Group_119' data-name='Group 119' transform='translate(0.25 0.25)'>
        <g id='poweroff'>
          <path
            id='Shape'
            d='M.528,5.631A.528.528,0,0,0,1.056,5.1V.528A.528.528,0,0,0,0,.528V5.1A.528.528,0,0,0,.528,5.631Z'
            transform='translate(6.981)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
          <path
            id='Shape-2'
            data-name='Shape'
            d='M7.509,13.6A7.5,7.5,0,0,1,0,6.087,7.439,7.439,0,0,1,.807,2.7,7.572,7.572,0,0,1,2.965.108.527.527,0,1,1,3.6.947,6.519,6.519,0,0,0,1.75,3.171a6.45,6.45,0,0,0,5.759,9.368,6.445,6.445,0,0,0,6.453-6.453,6.378,6.378,0,0,0-.694-2.912A6.505,6.505,0,0,0,11.412.95a.527.527,0,0,1,.639-.839A7.605,7.605,0,0,1,14.211,2.7,7.515,7.515,0,0,1,10.431,13,7.436,7.436,0,0,1,7.509,13.6Z'
            transform='translate(0 1.422)'
            fill={color}
            stroke={color}
            strokeWidth='0.5'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconDisconnect
