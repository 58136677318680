import { isValidElement, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import { Box, Flex, Text } from 'rebass/styled-components'
import { cutZeroDecimals } from 'utils'

import { NUMBER_FORMAT } from '../../../constants'

import { NumberFormatProps } from './interfaces'

const NumberFormat: React.FC<NumberFormatProps> = ({
  number,
  hideMinus = false,
  preffix = '',
  preffixMargin = 5,
  suffix = '',
  suffixMargin,
  full = false,
  rewardInfo = false,
  decimals = null,
  shorten = false,
  ...restprops
}) => {
  const [bigBigBigNumber, setBigBigBigNumber] = useState('')
  const value = useMemo(() => {
    if (number === '-') {
      return '--'
    }
    if (BigNumber.isBigNumber(number)) {
      if (full) {
        return number
      }
      if (number.gt(1e18)) {
        setBigBigBigNumber('QT')
        return number.dividedBy(1e18)
      }

      if (number.gt(1e15)) {
        setBigBigBigNumber('Q')
        return number.dividedBy(1e15)
      }

      if (number.gt(1e12)) {
        setBigBigBigNumber('T')
        return number.dividedBy(1e12)
      }

      if (number.gt(1e9)) {
        setBigBigBigNumber('B')
        return number.dividedBy(1e9)
      }

      if (number.gte(1e6)) {
        setBigBigBigNumber('M')
        return number.dividedBy(1e6)
      }

      if (number.gt(10000) && shorten) {
        setBigBigBigNumber('K')
        return number.dividedBy(1000)
      }

      setBigBigBigNumber('')
      return number
    }

    if (full) {
      setBigBigBigNumber('')
      return new BigNumber(number)
    }

    if (new BigNumber(number).gt(1e18)) {
      setBigBigBigNumber('QT')
      return new BigNumber(number).dividedBy(1e18)
    }

    if (new BigNumber(number).gt(1e15)) {
      setBigBigBigNumber('Q')
      return new BigNumber(number).dividedBy(1e15)
    }

    if (new BigNumber(number).gt(1e12)) {
      setBigBigBigNumber('T')
      return new BigNumber(number).dividedBy(1e12)
    }

    if (new BigNumber(number).gt(1e9)) {
      setBigBigBigNumber('B')
      return new BigNumber(number).dividedBy(1e9)
    }

    if (new BigNumber(number).gte(1e6)) {
      setBigBigBigNumber('M')
      return new BigNumber(number).dividedBy(1e6)
    }

    if (new BigNumber(number).gte(10000) && shorten) {
      setBigBigBigNumber('K')
      return new BigNumber(number).dividedBy(1000)
    }

    setBigBigBigNumber('')
    return new BigNumber(number)
  }, [full, number, shorten])

  if (typeof value === 'string') {
    return (
      <Box sx={{ display: 'inline-block', width: 'auto' }}>
        <Flex display='flex' flexGrow={0} alignItems='center' flexDirection='row'>
          {preffix && (
            <Box as='span' mr={preffixMargin}>
              {preffix}
            </Box>
          )}
          <Box as='span' {...restprops}>
            {value}
          </Box>
          <Box as='span' {...restprops}>
            {isValidElement(suffix) ? suffix : <Text {...suffixMargin}>{suffix}</Text>}
          </Box>
        </Flex>
      </Box>
    )
  }

  if (rewardInfo) {
    return (
      <Box sx={{ display: 'inline-block', width: 'auto' }}>
        <Flex display='flex' flexGrow={0} alignItems='center' flexDirection='row'>
          {preffix && (
            <Box as='span' mr={preffixMargin}>
              {preffix}
            </Box>
          )}
          <Box as='span' {...restprops}>
            {numeral(value.toFixed(15)).format(
              NUMBER_FORMAT[value.dp(15, 1).eq(0) || value.isNaN() ? '0' : value.dp(15, 1).gt(0.01) ? '2' : value.dp() > 8 ? '8' : value.dp()]
            )}
            {bigBigBigNumber}
          </Box>
          <Box as='span' {...restprops}>
            {isValidElement(suffix) ? suffix : <Text {...suffixMargin}>{suffix}</Text>}
          </Box>
        </Flex>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'inline-block', width: 'auto' }}>
      <Flex display='flex' flexGrow={0} alignItems='center' flexDirection='row'>
        {preffix && (
          <Box as='span' mr={preffixMargin}>
            {preffix}
          </Box>
        )}
        <Box as='span' {...restprops}>
          {hideMinus && value.lt(0)
            ? numeral(value.toFixed(15))
                .format(NUMBER_FORMAT[bigBigBigNumber ? '0' : '3'])
                .substring(1)
            : decimals
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT[decimals]))
            : suffix !== '%' && value.gte(1) && value.dp() >= 2
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['2']))
            : suffix !== '%' && value.gte(1) && value.dp() === 0
            ? cutZeroDecimals(numeral(value).format(NUMBER_FORMAT['0']))
            : suffix !== '%' && value.gte(1) && value.dp() === 1
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['1']))
            : suffix !== '%' && value.gt(-1) && value.lt(1) && value.dp() >= 4 && Number(value.dp(4, 1)) !== 0
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['4']))
            : suffix !== '%' && value.gt(-1) && value.lt(1) && value.dp() === 3
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['3']))
            : suffix !== '%' && value.gt(-1) && value.lt(1) && value.dp() === 2
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['2']))
            : suffix !== '%' && value.gt(-1) && value.lt(1) && value.dp() === 1
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format('0.0'))
            : suffix !== '%' && value.gt(-1) && value.lt(1) && value.dp() === 0
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['0']))
            : suffix !== '%' && value.lt(-1) && value.dp() >= 2
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['2']))
            : suffix !== '%' && value.lt(-1) && value.dp() === 1
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format(NUMBER_FORMAT['1']))
            : suffix !== '%' && value.lt(-1) && value.dp() === 0
            ? cutZeroDecimals(numeral(value.dp(4, 1)).format('0'))
            : value.dp() > 4 && value.lt(0.0001) && Math.sign(Number(value)) !== -1
            ? '0'
            : cutZeroDecimals(
                numeral(value.dp(4, 1)).format(
                  NUMBER_FORMAT[
                    bigBigBigNumber
                      ? '2'
                      : value.gte(1) && value.dp() >= 2
                      ? '2'
                      : value.dp() === 0
                      ? '0'
                      : suffix && suffix === '%'
                      ? '2'
                      : Number(value.toFixed()) === 0
                      ? '2'
                      : Number(value.dp()) >= 4 && value.lt(1) && value.gt(-1) && !suffix
                      ? '4'
                      : Number(value.dp()) === 2
                      ? '2'
                      : '0'
                  ]
                )
              )}
          {bigBigBigNumber}
        </Box>
        <Box as='span' {...restprops}>
          {isValidElement(suffix) ? suffix : <Text {...suffixMargin}>{suffix}</Text>}
        </Box>
      </Flex>
    </Box>
  )
}

export default NumberFormat
