import React from 'react'
// import { useRouter } from 'next/router'
// import { pageview } from 'react-ga'
// Components
import { Flex, FlexProps } from 'rebass/styled-components'
import { MotionFlex } from 'theme/ui'
// import { AirdropBanner } from 'components/common/airdrop-banner'

interface IMain extends FlexProps {
  children?: React.ReactNode
}

const Main: React.FC<IMain> = ({ children, ...restprops }): React.ReactElement => {
  // const { events } = useRouter()
  // const handleRouteChange = React.useCallback((url: string) => {
  //   pageview(url)
  // }, [])

  // React.useEffect(() => {
  //   if (!events || !handleRouteChange) return

  //   events.on('routeChangeComplete', handleRouteChange)
  //   return () => {
  //     events.off('routeChangeComplete', handleRouteChange)
  //   }
  // }, [events, handleRouteChange])

  return (
    <MotionFlex flex='1' flexDirection='column'>
      {/* <AirdropBanner /> */}
      <Flex flexDirection='column' alignItems='center' width={['100%', '100%', '100%']} {...restprops}>
        {children}
      </Flex>
    </MotionFlex>
  )
}

export default Main
