import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
import { Flex } from 'rebass/styled-components'

export enum NotificationColor {
  green = 'green.1200',
  red = 'red.1000',
  yellow = 'yellow.1000',
  primary = 'primary'
}

type Notification = {
  key: string | number
  time?: number
  color: NotificationColor
  title: string
  content: string | ReactNode
  active: boolean
  link?: string
  linkLabel?: string
  external?: boolean
  close?: boolean
}

type InitialState = {
  notifications: Array<Notification>
  getTextColor: (background: NotificationColor) => string
}

type NotificationsProviderProps = {
  children: ReactNode
}

const initialState = {
  notifications: [],
  getTextColor: () => ''
}

const NotificationsContext = createContext<InitialState>(initialState)

export const useNotifications = () => useContext(NotificationsContext)

export default function NotificationsProvider({ children }: NotificationsProviderProps) {
  const notifications = useMemo(
    () => [
      {
        key: '846d3df0-d0cf-4991-860a-9bba6b97af27  ',
        time: 1706090400,
        color: NotificationColor.green,
        title: 'Feature',
        content: (
          <Flex
            sx={{
              color: 'white',
              alignItems: 'center',
              width: 938
            }}
          >
            Debt swap feature is live! Excited to announce users can now swap debt between existing ETH and USDT loans.
          </Flex>
        ),
        active: true,
        external: false,
        close: true
      }
    ],
    []
  )

  const getTextColor = useCallback((background: NotificationColor) => {
    switch (background) {
      default:
      case NotificationColor.green:
        return 'white'
      case NotificationColor.red:
        return 'white'
      case NotificationColor.primary:
        return 'white'
      case NotificationColor.yellow:
        return 'black'
    }
  }, [])

  return <NotificationsContext.Provider value={{ notifications, getTextColor }}>{children}</NotificationsContext.Provider>
}
