import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { ConnectionType, getConnectionName, gnosisSafeConnection } from 'wallet-module'

import Option from './option'

const BASE_PROPS = {
  color: '#4196FC',
  icon: '/images/gnosis-safe-wallet.svg',
  id: 'gnosis-safe'
}

export function GnosisSafeOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = gnosisSafeConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      active={isActive}
      onClick={() =>
        tryActivation({
          connector: gnosisSafeConnection.connector,
          type: ConnectionType.GNOSIS_SAFE
        })
      }
      header={getConnectionName({ connectionType: ConnectionType.GNOSIS_SAFE })}
    />
  )
}
