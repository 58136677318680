import { useCallback, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useMulticallContract, useSingleCallResult } from 'multicall-module'
import { useTranslation } from 'next-i18next'
import { useWallet } from 'wallet-module'

import { WETH_ADDRESS } from '../constants'
import { handleError } from '../utils/handleError'
import { deposit, withdraw } from '../utils/Weth'

import { useWethContract } from '.'

export interface useWethDataProps {
  account: string
}

export enum WethSteps {
  WALLET_BALLANCES = 1,
  WETH_WRAP_START = 2,
  WETH_WRAP_CONFIRM = 3,
  WETH_WRAP_PROCESSING = 4,
  WETH_WRAP_SUCCESS = 5,
  WETH_WRAP_FAIL = 6,
  WETH_UNWRAP_START = 7,
  WETH_UNWRAP_CONFIRM = 8,
  WETH_UNWRAP_PROCESSING = 9,
  WETH_UNWRAP_SUCCESS = 10,
  WETH_UNWRAP_FAIL = 11
}

export const useWethData = ({ account }: useWethDataProps) => {
  const { addTransaction } = useWallet()
  const [screenState, setScreenState] = useState(WethSteps.WALLET_BALLANCES)
  const [txHash, setTxHash] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [txAmount, setTxAmount] = useState('')

  const WethContract = useWethContract(WETH_ADDRESS)
  const multicallContract = useMulticallContract()

  const { result: wethResult } = useSingleCallResult(WethContract, 'balanceOf', [account ?? undefined])
  const WethBalance = wethResult?.[0].toString() ?? ''
  const formatedWethBalance = useMemo(() => new BigNumber(WethBalance).dividedBy(`1e18`), [WethBalance])

  const { result: ethBalance } = useSingleCallResult(account ? multicallContract : undefined, 'getEthBalance', [account], {})
  const formatedEthBalance = useMemo(() => new BigNumber(ethBalance?.[0].toString()).dividedBy(1e18), [ethBalance])

  const { t: tc } = useTranslation('common')

  const { onDeposit } = useDeposit(WETH_ADDRESS)
  const handleWrap = useCallback(
    async ({ amount }) => {
      setTxHash('')
      try {
        setScreenState(WethSteps.WETH_WRAP_CONFIRM)
        const tx = await onDeposit(new BigNumber(amount).multipliedBy(`1e18`).toFixed(0))
        setTxAmount(amount)
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setScreenState(WethSteps.WETH_WRAP_FAIL)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
        } else {
          setTxHash(tx.hash)
          setScreenState(WethSteps.WETH_WRAP_PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setScreenState(WethSteps.WETH_WRAP_SUCCESS)
            } else {
              setScreenState(WethSteps.WETH_WRAP_FAIL)
              setErrorMsg(tc('label.tx-failed'))
            }
          })
        }
      } catch (e: any) {
        setErrorMsg(e.msg)
        setScreenState(WethSteps.WETH_WRAP_FAIL)
      }
    },
    [onDeposit, addTransaction, tc]
  )

  const { onWithdraw } = useWithdraw(WETH_ADDRESS)
  const handleUnWrap = useCallback(
    async ({ amount }) => {
      setTxHash('')
      try {
        setScreenState(WethSteps.WETH_UNWRAP_CONFIRM)
        const tx = await onWithdraw(new BigNumber(amount).multipliedBy(`1e18`).toFixed(0))
        setTxAmount(amount)
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setScreenState(WethSteps.WETH_UNWRAP_FAIL)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
        } else {
          setTxHash(tx.hash)
          setScreenState(WethSteps.WETH_UNWRAP_PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setScreenState(WethSteps.WETH_UNWRAP_SUCCESS)
            } else {
              setScreenState(WethSteps.WETH_UNWRAP_FAIL)
              setErrorMsg(tc('label.tx-failed'))
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(e.msg)
        setScreenState(WethSteps.WETH_UNWRAP_FAIL)
      }
    },
    [onWithdraw, addTransaction, tc]
  )

  return {
    screenState,
    setScreenState,
    txHash,
    errorMsg,
    handleWrap,
    handleUnWrap,
    formatedWethBalance,
    formatedEthBalance,
    txAmount
  }
}

export const useDeposit = (Weth_address: string) => {
  const contract = useWethContract(Weth_address)

  const handleAction = useCallback(
    async (amount: string) => {
      try {
        const tx = await deposit(contract, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onDeposit: handleAction }
}

export const useWithdraw = (Weth_address: string) => {
  const contract = useWethContract(Weth_address)

  const handleAction = useCallback(
    async (asset_address: string) => {
      try {
        const tx = await withdraw(contract, asset_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onWithdraw: handleAction }
}
