import React from 'react'

interface IIconWithdraw {
  color?: string
  size?: number
}

const IconWithdraw = React.forwardRef<SVGSVGElement, IIconWithdraw>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='withdraw'>
        <path
          fill={color}
          stroke={color}
          strokeWidth='0.0'
          d='M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,18.2c-4.5,0-8.2-3.7-8.2-8.2S5.5,1.8,10,1.8
		      s8.2,3.7,8.2,8.2S14.5,18.2,10,18.2z'
        />
        <path fill={color} stroke={color} strokeWidth='0.0' d='M13,9.1h-3H7c-0.6,0-0.9,0.4-0.9,0.9S6.5,11,7,11h3h3c0.6,0,0.9-0.4,0.9-0.9S13.5,9.1,13,9.1z' />
      </g>
    </svg>
  )
})

export default IconWithdraw
