/* import { shortenTxHash } from 'modules/bend/utils/shortenTxHash'
import { getEtherscanLink } from 'wallet-module' */
import { useTranslation } from 'next-i18next'
import { Flex } from 'rebass'
import { Button, Notification } from 'theme/ui'

export const WrapStep10: React.FC<any> = ({ handleBack, txAmount /* txHash  */ }) => {
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')
  const unwrapAsset = 'WETH'
  const asset = 'ETH'

  return (
    <Flex flexDirection='column' flex={1} mt={20}>
      <Flex flex={1} mb={18} fontWeight='normal'>
        <Notification reducePadding type='success' noicon>
          <span dangerouslySetInnerHTML={{ __html: t('sidebar.unwrap.succesfull', { unwrapAsset, txAmount, asset }) }} />
          {/*           <Link href={getEtherscanLink(Number(DEFAULT_CHAIN_ID), txHash, 'transaction')} passHref>
            <Flex as='a' target='_blank' rel='noopener noreferrer' variant='link'>
              <Text fontWeight={'bold'} color='green.1000'>
                {shortenTxHash(txHash!)}
              </Text>
            </Flex>
          </Link> */}
        </Notification>
      </Flex>
      <Button color='black' outlined size='md' fontWeight='bold' onClick={() => handleBack()}>
        {tc('button.back')}
      </Button>
    </Flex>
  )
}
