import { gql } from '@apollo/client'

export const COLLECTIONS_FLOORPRICE_QUERY = gql`
  query NftCollections($address_in: [String!]) {
    nftCollections(address_in: $address_in) {
      symbol
      address
      floorPrice
    }
  }
`
