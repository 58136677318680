import { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useSingleCallResult } from 'multicall-module'
import { useContract } from 'wallet-module'

import { UNISWAP_PAIR_ABI } from '../constants'

type UseUniswapReservePool = {
  reserve0: BigNumber
  reserve1: BigNumber
}

/**
 * It returns the reserves of a Uniswap pool
 * @param {string} pool - The address of the Uniswap pool you want to get the reserves for.
 * @returns An object with two BigNumber properties, reserve0 and reserve1.
 */
const useUniswapReservePool = (pool: string): UseUniswapReservePool => {
  const contract = useContract(pool, UNISWAP_PAIR_ABI)
  const { result } = useSingleCallResult(contract, 'getReserves', [])
  const [reserve0, reserve1] = result || []

  return useMemo(() => {
    return {
      reserve0: new BigNumber(reserve0?._hex),
      reserve1: new BigNumber(reserve1?._hex)
    }
  }, [reserve0?._hex, reserve1?._hex])
}

export default useUniswapReservePool
