import { useCallback, useEffect, useState } from 'react'
import { OKXWallet } from '@donatello_benddao/web3-react-okxwallet'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { useWeb3React } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { removeCookies, setCookies } from 'cookies-next'
import { TrustWallet } from 'modules/wallet-module/connection/trustWallet'
import moment from 'moment'
import { Connection, usePrevious, useWallet } from 'wallet-module'

export enum WalletSteps {
  CONNECTED = 1,
  SELECT_PROVIDER = 2,
  PENDING = 3
}

export type ConnectorType = MetaMask | WalletConnectV2 | CoinbaseWallet | GnosisSafe | OKXWallet | TrustWallet | Network | Connection['connector'] | undefined

export type TryActivationArgs = {
  connector: ConnectorType
  type?: Connection['type']
}

export const useWalletDisplay = () => {
  // important that these are destructed from the account-specific web3-react context
  const { account, connector, chainId } = useWeb3React()
  const [screenState, setScreenState] = useState(WalletSteps.CONNECTED)
  const [errorMsg, setErrorMsg] = useState('')
  const [pendingWallet, setPendingWallet] = useState<ConnectorType>()

  const [pendingErrorWrongNetwork, setPendingWrongNetwork] = useState<boolean>(false)
  const [pendingError, setPendingError] = useState<boolean>(false)
  const { WalletDispatch } = useWallet()

  const previousAccount = usePrevious(account)
  useEffect(() => {
    if (!account) {
      setScreenState(WalletSteps.SELECT_PROVIDER)
    }
    if (chainId !== Number(DEFAULT_CHAIN_ID)) {
      setScreenState(WalletSteps.PENDING)
      setPendingWallet(connector as ConnectorType)
      setPendingError(true)
      setPendingWrongNetwork(true)
    }
  }, [account, chainId, connector])

  const activePrevious = usePrevious(account)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (account && connector && connector !== connectorPrevious) {
      setScreenState(WalletSteps.CONNECTED)
    }
  }, [connector, activePrevious, connectorPrevious, account, previousAccount])

  const reset = useCallback(() => {
    setErrorMsg('')
    setPendingError(false)
    setPendingWrongNetwork(false)
  }, [])
  /*
  const tryActivation = useCallback(
    async (connector: AbstractConnector | undefined) => {
      Object.keys(SUPPORTED_WALLETS).map(key => {
        if (connector === SUPPORTED_WALLETS[key].connector) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return SUPPORTED_WALLETS[key].name
        }
        return true
      })
      reset()
      setPendingWallet(connector) // set wallet for pending view
      setScreenState(WalletSteps.PENDING)

      // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
      if (connector instanceof WalletConnectConnector) {
        window.localStorage.removeItem('walletconnect')
        connector.walletConnectProvider = undefined
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      connector &&
        activate(
          connector,
          (error: any) => {
            setErrorMsg(error)
            setPendingError(true)
            setPendingWrongNetwork(error instanceof UnsupportedChainIdError)
          },
          false
        ).catch(async (error: any) => {
          if (error) {
            console.log('connector error', error)
            setErrorMsg(error)
            setPendingError(true)
            setPendingWrongNetwork(error instanceof UnsupportedChainIdError)

          } else if (error?.code === -32002) {
            console.log('connector error - Already processing eth_requestAccounts.', error.code)
          }

          // if (error instanceof UnsupportedChainIdError) {
          //   const hasSetup = await setupNetwork()
          //   if (hasSetup) {
          //     activate(connector)
          //   }
          //   // activate(connector) // a little janky...can't use setError because the connector isn't set
          // } else {
          //   setPendingError(true)
          // }
        })
    },
    [activate, reset]
  )
*/
  const tryActivation = useCallback(
    async ({ connector, type }: TryActivationArgs) => {
      reset()
      // if (getConnection(connector).type === 'WALLET_CONNECT') {
      //   window.localStorage.removeItem('walletconnect')
      // }
      try {
        setPendingWallet(connector)
        setScreenState(WalletSteps.PENDING)

        await connector?.activate()

        WalletDispatch({
          type: 'UPDATE',
          payload: {
            selectedWalletType: type
          }
        })
        setCookies('selectedWalletType', type, {
          expires: moment().add(1, 'month').toDate()
        })
      } catch (error) {
        console.debug(`web3-react connection error: ${error}`)
        setErrorMsg(String(error))
        setPendingError(true)
        WalletDispatch({
          type: 'UPDATE',
          payload: {
            selectedWalletType: undefined
          }
        })
        removeCookies('selectedWalletType')
      }
    },
    [WalletDispatch, reset]
  )

  return {
    screenState,
    setScreenState,
    errorMsg,
    tryActivation,
    connector,
    account,
    pendingError,
    setPendingError,
    pendingErrorWrongNetwork,
    setPendingWrongNetwork,
    pendingWallet
  }
}
