import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconExplorePairingListings = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 18, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18.256 17.142' stroke={color} fill={color} {...rest}>
      <g id='surface1' transform='translate(0.127 -14.858)'>
        <path
          id='Path_3841'
          data-name='Path 3841'
          d='M15.757,23.077l2.229-2.068L12.1,15l-1.226,1.221H8.413a2.306,2.306,0,0,0-1.224.356l-1.66-1.249L0,20.7l1.748,2.916a1.655,1.655,0,0,0-.255.208l-.867.865a1.651,1.651,0,0,0,.961,2.807c0,.032,0,.064,0,.1a1.649,1.649,0,0,0,1.59,1.651,1.647,1.647,0,0,0,1.649,1.593,1.662,1.662,0,0,0,.537-.089,1.652,1.652,0,0,0,2.742.676l.487-.486a1.683,1.683,0,0,0,.15-.172l.276.244a1.681,1.681,0,0,0,1.183.482h0a1.694,1.694,0,0,0,1.621-1.211,1.7,1.7,0,0,0,.556.093h0a1.689,1.689,0,0,0,1.649-1.327,1.7,1.7,0,0,0,.357.038h0a1.691,1.691,0,0,0,1.668-1.956,1.692,1.692,0,0,0,.892-2.862ZM7.533,17.64a1.247,1.247,0,0,1,.879-.366h2.9l.782-.778,4.385,4.476-1.466,1.36-3.7-3.7H8.559L6.708,20.519a.72.72,0,1,1-1.025-1.012ZM1.369,26.277a.6.6,0,0,1,0-.845l.867-.865a.6.6,0,0,1,.844.846l-.867.865A.6.6,0,0,1,1.369,26.277Zm1.443,1.736a.6.6,0,0,1,0-.845l1.012-1.009a.593.593,0,0,1,.422-.175h0A.6.6,0,0,1,4.669,27L3.657,28.014A.6.6,0,0,1,2.812,28.013Zm2.433,1.593a.6.6,0,1,1-.844-.846L5.413,27.75a.6.6,0,0,1,.844.846Zm2.6.582-.487.486a.6.6,0,1,1-.844-.846L7,29.342a.6.6,0,1,1,.844.846ZM16.2,25.912a.634.634,0,0,1-.451.187h0a.634.634,0,0,1-.451-.186l-2.544-2.539-.744.746,2.823,2.817a.638.638,0,0,1-.45,1.09h0a.634.634,0,0,1-.451-.186l-.362-.361h0L11.11,25.024l-.744.746,2.462,2.457a.638.638,0,0,1-.9.9L9.464,26.673l-.744.746,1.929,1.925a.638.638,0,0,1-.45,1.09h0a.634.634,0,0,1-.451-.186l-.011-.011-.666-.588a1.649,1.649,0,0,0-1.585-1.533A1.647,1.647,0,0,0,5.9,26.524a1.648,1.648,0,0,0-1.588-1.592,1.647,1.647,0,0,0-1.491-1.585L1.333,20.871l4.283-4.159.776.584L4.934,18.765a1.774,1.774,0,0,0,1.259,3.023h0a1.761,1.761,0,0,0,1.261-.531L9,19.687h1.873L16.2,25.009A.639.639,0,0,1,16.2,25.912Z'
          fill={color}
          stroke={color}
          strokeWidth='0.2'
        />
      </g>
    </svg>
  )
})

export default IconExplorePairingListings
