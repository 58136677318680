import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconSaleCart = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 10, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13.412 12.294' fill='none' stroke={color} {...rest}>
      <g id='Group_7464' data-name='Group 7464' transform='translate(0.125 0.125)'>
        <path
          id='Path_2760'
          data-name='Path 2760'
          d='M343.188,340.907a1.641,1.641,0,1,0,1.641,1.641,1.643,1.643,0,0,0-1.641-1.641m0,2.3a.656.656,0,1,1,.656-.656.657.657,0,0,1-.656.656'
          transform='translate(-332.768 -332.145)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2761'
          data-name='Path 2761'
          d='M13.055,2.42a.492.492,0,0,0-.388-.189H3.039L2.6.378A.492.492,0,0,0,2.117,0H.492a.492.492,0,1,0,0,.984H1.728l1.6,6.694a.492.492,0,0,0,.479.378H11.47a.492.492,0,0,0,.478-.374l1.2-4.841a.493.493,0,0,0-.09-.422M11.085,7.072H4.2L3.274,3.216h8.765Z'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2762'
          data-name='Path 2762'
          d='M111.447,340.907a1.641,1.641,0,1,0,1.641,1.641,1.643,1.643,0,0,0-1.641-1.641m0,2.3a.656.656,0,1,1,.656-.656.657.657,0,0,1-.656.656'
          transform='translate(-106.984 -332.145)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconSaleCart
