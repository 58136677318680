import React from 'react'

interface IIconElement {
  color?: string
  size?: number
}

const IconElementGridDark = React.forwardRef<SVGSVGElement, IIconElement>(({ size = 32, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 32 32'
      stroke='white'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='Group_3273' data-name='Group 3273' transform='translate(-905 -308)'>
        <g id='Group_3090' data-name='Group 3090' transform='translate(156 180)'>
          <g id='Rectangle_10' data-name='Rectangle 10' transform='translate(749 128)' fill='#0057ff' stroke='#0057ff' strokeWidth='1'>
            <rect width='32' height='32' rx='16' stroke='none' />
            <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' fill='none' />
          </g>
        </g>
        <g id='Group_3091' data-name='Group 3091' transform='translate(914.863 318)'>
          <g id='Rectangle_32' data-name='Rectangle 32' transform='translate(0.137)' fill='none' stroke='#fff' strokeWidth='1'>
            <rect width='6' height='6' rx='2' stroke='none' />
            <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
          </g>
          <g id='Rectangle_35' data-name='Rectangle 35' transform='translate(0.137 7)' fill='none' stroke='#fff' strokeWidth='1'>
            <rect width='6' height='6' rx='2' stroke='none' />
            <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
          </g>
          <g id='Rectangle_33' data-name='Rectangle 33' transform='translate(7.137)' fill='none' stroke='#fff' strokeWidth='1'>
            <rect width='6' height='6' rx='2' stroke='none' />
            <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
          </g>
          <g id='Rectangle_34' data-name='Rectangle 34' transform='translate(7.137 7)' fill='none' stroke='#fff' strokeWidth='1'>
            <rect width='6' height='6' rx='2' stroke='none' />
            <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
          </g>
        </g>
      </g>
    </svg>
  )
})

export default IconElementGridDark
