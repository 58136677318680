import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { Network } from '@web3-react/network'
import { DEFAULT_CHAIN_ID } from 'constants/index'

import { ERC20_ABI, ERC20_BYTES32_ABI, getContract, useWallet } from '../index'

// returns null on errors
export function useContract(address: string | undefined | null, ABI: any, withSignerIfPossible = true): Contract | null {
  const { provider, account, connector, chainId } = useWeb3React()
  const { wsProvider } = useWallet()
  return useMemo(() => {
    if (!address || !ABI || !connector) return null
    if (connector instanceof Network || chainId !== Number(DEFAULT_CHAIN_ID)) {
      try {
        return getContract(address, ABI, wsProvider, undefined)
      } catch (error) {
        console.error('Failed to get contract', error)
        return null
      }
    }
    if (!provider) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account, wsProvider, connector, chainId])
}
export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}
