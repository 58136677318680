import React from 'react'

interface IIconBendAssets {
  color?: string
  size?: number
}

const IconBendAssets = React.forwardRef<SVGSVGElement, IIconBendAssets>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 19 20.996' fill={color} {...rest}>
      <path
        id='Union_9'
        data-name='Union 9'
        d='M14825.014,20934a3.037,3.037,0,0,1-3-3.074v-2.309h-2.266a.76.76,0,0,1-.75-.77v-4.613a.757.757,0,0,1,.75-.77h2.285v-5.387a3.039,3.039,0,0,1,3-3.074H14831c.8,0,1.561.762,2.846,2.1.18.188.361.379.551.57s.371.375.553.563c1.313,1.316,2.053,2.105,2.053,2.918v10.77a3.039,3.039,0,0,1-3,3.074Zm-1.539-3.074a1.523,1.523,0,0,0,1.5,1.539H14834a1.522,1.522,0,0,0,1.5-1.539v-10.008c0-.77-.75-.77-1.5-.77h-2.25a.764.764,0,0,1-.748-.773v-2.336c0-.684,0-1.367-.535-1.512l-5.494.008a1.52,1.52,0,0,0-1.5,1.535v5.395h9.773a.757.757,0,0,1,.75.77v4.613a.76.76,0,0,1-.75.77h-9.773Zm5.432-5.34a2.508,2.508,0,0,0,.57,1.691,2.107,2.107,0,0,0,1.639.625,4.112,4.112,0,0,0,1.326-.254.129.129,0,0,0,.076-.117h0v-2.316a.122.122,0,0,0-.119-.125h-1.426a.122.122,0,0,0-.119.125v.781a.118.118,0,0,0,.119.117h.473v.715a1.384,1.384,0,0,1-.3.027c-.674,0-1.094-.508-1.094-1.32s.43-1.3,1.15-1.3a1.608,1.608,0,0,1,.834.18.162.162,0,0,0,.105,0,.126.126,0,0,0,.068-.082l.18-.8a.129.129,0,0,0-.062-.137,2.587,2.587,0,0,0-1.115-.223A2.217,2.217,0,0,0,14828.906,20925.582Zm-3.205.5.818,1.7a.123.123,0,0,0,.107.074h.908a.126.126,0,0,0,.121-.125v-4.395a.124.124,0,0,0-.121-.125h-.795a.121.121,0,0,0-.119.125v1.277c0,.406.006.766.018,1.094-.119-.293-.24-.574-.359-.816l-.795-1.609a.122.122,0,0,0-.107-.07h-1.014a.122.122,0,0,0-.117.125v4.395a.125.125,0,0,0,.117.125h.8a.122.122,0,0,0,.119-.125v-1.324c0-.48,0-.9-.012-1.281C14825.414,20925.484,14825.574,20925.828,14825.7,20926.086Zm-5.109-2.816a.124.124,0,0,0-.094.117v4.34a.123.123,0,0,0,.119.129h.859a.123.123,0,0,0,.117-.129v-1.434c.051,0,.109.008.178.008a1.76,1.76,0,0,0,1.328-.5,1.615,1.615,0,0,0,.385-1.125,1.469,1.469,0,0,0-.449-1.109,1.778,1.778,0,0,0-1.248-.383A6.765,6.765,0,0,0,14820.592,20923.27Zm1,2.012v-1.094c.051,0,.123-.012.229-.012a.493.493,0,0,1,.563.527.55.55,0,0,1-.621.586A1.039,1.039,0,0,1,14821.594,20925.281Z'
        transform='translate(-14818.5 -20913.5)'
        fill={color}
        stroke='rgba(0,0,0,0)'
        strokeMiterlimit='10'
        strokeWidth='1'
      />
    </svg>
  )
})

export default IconBendAssets
