import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDoc2 = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 14, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12.657 16.9' color={color} fill={color} {...rest}>
      <g id='copy' transform='translate(-0.003 -0.001)'>
        <path
          id='Shape'
          d='M1.2,16.9A1.19,1.19,0,0,1,0,15.719V3.308A1.2,1.2,0,0,1,1.2,2.114h.915V1.188A1.2,1.2,0,0,1,3.316,0H7.953a1.211,1.211,0,0,1,.48.1l.006,0h.005a.157.157,0,0,1,.034.016A.627.627,0,0,1,8.55.161a1.316,1.316,0,0,1,.242.191L12.32,3.923a1.178,1.178,0,0,1,.337.838V13.6a1.167,1.167,0,0,1-1.172,1.188h-.94v.932A1.182,1.182,0,0,1,9.365,16.9Zm-.01-1.19H9.356v-.923H3.316a1.2,1.2,0,0,1-1.2-1.188V3.3H1.186ZM3.3,13.6h8.169V5.281H8.581a1.2,1.2,0,0,1-1.2-1.188v-2.9H3.3ZM8.564,4.093h2.258L8.564,1.823ZM4.817,11.75a.594.594,0,0,1,0-1.188H8.91a.594.594,0,1,1,0,1.188Zm0-2.113a.594.594,0,0,1,0-1.188H9.966a.594.594,0,1,1,0,1.188Zm0-2.112a.594.594,0,0,1,0-1.188H7.854a.594.594,0,0,1,0,1.188Z'
          transform='translate(0.003 0.001)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconDoc2
