import React from 'react'

interface IIconChartColor {
  color?: string
  size?: number
}

const IconChartColor = React.forwardRef<SVGSVGElement, IIconChartColor>(({ color = 'currentColor', size = 16, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 16' {...rest}>
      <g id='Group_7520' data-name='Group 7520' transform='translate(-597 -664)'>
        <g id='Group_7259' data-name='Group 7259' transform='translate(597 664)'>
          <g id='Rectangle_2706' data-name='Rectangle 2706' fill='#fff' stroke='#dcdcdd' strokeWidth='1'>
            <rect width='16' height='16' rx='8' stroke='none' />
            <rect x='0.5' y='0.5' width='15' height='15' rx='7.5' fill='none' />
          </g>
        </g>
        <line
          id='Line_2620'
          data-name='Line 2620'
          y1='9'
          x2='9'
          transform='translate(600.5 667.5)'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
})

export default IconChartColor
