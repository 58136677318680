import React from 'react'
import useTheme from 'hooks/common/useTheme'
import { Flex } from 'rebass/styled-components'
import { AnimatePresence, MotionBox } from 'theme/ui'

import { MotionFlex } from '../motion-box'

// Interfaces
import { IModalsContext } from './interfaces'

export const Context = React.createContext<IModalsContext>({
  onPresent: () => null,
  onDismiss: () => null,
  isOpen: false
})

const Modals: React.FC = ({ children }): React.ReactElement => {
  const { colors } = useTheme()
  const [isOpen, setIsOpen] = React.useState(false)
  const [content, setContent] = React.useState<React.ReactNode>()
  const [options, setOptions] = React.useState<{ hideClose: boolean; onClose?: () => void; preventBgClose?: boolean }>({
    hideClose: false,
    preventBgClose: false
  })

  const handlePresent = React.useCallback(
    (modalContent: React.ReactNode, opts: { hideClose: boolean; onClose: () => void; preventBgClose?: boolean } | undefined) => {
      setContent(modalContent)
      setIsOpen(true)
      if (opts) {
        setOptions({ ...opts })
      }
    },
    [setContent, setIsOpen]
  )

  const handleDismiss = React.useCallback(() => {
    setContent(undefined)
    setIsOpen(false)
    if (options?.onClose) options.onClose()
    setOptions({ hideClose: false, preventBgClose: false })
  }, [options])

  return (
    <Context.Provider
      value={{
        content,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss
      }}
    >
      {children}
      <AnimatePresence>
        {isOpen && (
          <Flex
            alignItems='center'
            justifyContent='center'
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 2005
            }}
          >
            <MotionBox
              key='modal'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.3 } }}
              onClick={() => {
                if (!options.hideClose || !options.preventBgClose) return
                handleDismiss()
              }}
              backgroundColor={colors.backdrop}
              sx={{
                position: 'absolute',
                // backdropFilter: 'blur(2px)',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
              }}
            />
            <MotionFlex
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.3,
                  type: 'tween',
                  ease: 'easeOut'
                }
              }}
              exit={{
                opacity: 0,
                transition: {
                  delay: 0,
                  type: 'tween',
                  ease: 'easeIn'
                }
              }}
              width={['90%', '90%', 'auto']}
              justifyContent='center'
              // onClick={!options.hideClose ? handleDismiss : () => {}}
            >
              {React.isValidElement(content) &&
                React.cloneElement(content, {
                  isOpen,
                  onDismiss: handleDismiss,
                  hideClose: options.hideClose
                })}
            </MotionFlex>
          </Flex>
        )}
      </AnimatePresence>
    </Context.Provider>
  )
}
export default Modals
