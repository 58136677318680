import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconTokenomics = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 16, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16.182 16.183'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Path_1662'
        data-name='Path 1662'
        d='M1.789,7.108a.624.624,0,0,1,0-.883L5.444,2.57a.624.624,0,0,1,.883,0l2.3,2.3,3.62-3.62h-1.6a.624.624,0,1,1,0-1.249h3.111a.625.625,0,0,1,.625.624V3.735a.624.624,0,1,1-1.249,0v-1.6L9.067,6.193a.624.624,0,0,1-.883,0l-2.3-2.3L2.672,7.108A.624.624,0,0,1,1.789,7.108ZM4.46,11.675v3.683a.624.624,0,0,1-.624.624H.624A.624.624,0,0,1,0,15.358V11.675a.624.624,0,0,1,.624-.624H3.836a.624.624,0,0,1,.624.624M3.212,12.3H1.249v2.435H3.212Zm7.01-3v6.058a.624.624,0,0,1-.624.624H6.385a.624.624,0,0,1-.624-.624V9.3a.624.624,0,0,1,.624-.624H9.6a.624.624,0,0,1,.624.624m-1.249.624H7.01v4.81H8.973Zm7.01-3v8.433a.624.624,0,0,1-.624.624H12.146a.624.624,0,0,1-.624-.624V6.925a.624.624,0,0,1,.624-.624h3.212a.624.624,0,0,1,.624.624m-1.249.624H12.771v7.184h1.963Z'
        transform='translate(0.1 0.1)'
        fill='#fff'
        stroke='#fff'
        strokeWidth='0.2'
      />
    </svg>
  )
})

export default IconTokenomics
