import React from 'react'

interface IIconInfo {
  color?: string
  size?: number
}

const IconInfoXl = React.forwardRef<SVGSVGElement, IIconInfo>(({ color = 'currentColor', size = 52, ...rest }, ref) => {
  return (
    <svg x='0px' y='0px' width={size} height={size} viewBox='0 0 52.001 52.002' fill={color} ref={ref} {...rest}>
      <g id='Group_6283' data-name='Group 6283' transform='translate(207.003 147.779) rotate(180)'>
        <path
          id='Subtraction_128'
          data-name='Subtraction 128'
          d='M26,52A26.008,26.008,0,0,1,15.881,2.041,26.008,26.008,0,0,1,36.123,49.957,25.842,25.842,0,0,1,26,52ZM26,3.654a22.352,22.352,0,0,0-8.694,42.937A22.353,22.353,0,0,0,34.7,5.406,22.217,22.217,0,0,0,26,3.654Z'
          transform='translate(155 95.779)'
          fill={color}
        />
        <path
          id='Path'
          d='M2.023,22.7A2.022,2.022,0,0,1,0,20.682V8.023a2.023,2.023,0,0,1,4.045,0V20.682A2.022,2.022,0,0,1,2.023,22.7Z'
          transform='translate(178.867 103.533)'
          fill={color}
        />
        <path
          id='Path-2'
          data-name='Path'
          d='M0,2.276A2.276,2.276,0,1,0,2.276,0,2.276,2.276,0,0,0,0,2.276Z'
          transform='translate(178.614 129.474)'
          fill={color}
        />
      </g>
    </svg>
  )
})

export default IconInfoXl
