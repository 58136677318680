import React from 'react'

interface IIconMagicWand {
  color?: string
  size?: number
}

const IconMagicWandHeader = React.forwardRef<SVGSVGElement, IIconMagicWand>(({ color = 'currentColor', size = 60, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 60 60'
      fill='none'
      stroke={color}
      strokeWidth='0.3'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='Group_3175' data-name='Group 3175' transform='translate(0 0)'>
        <path
          id='Path_1152'
          data-name='Path 1152'
          d='M5.487,85.07a1.758,1.758,0,0,0,2.486,0L31.206,61.836l.366,3.069a1.758,1.758,0,0,0,3.153.845L40.6,57.9,49.6,61.763a1.758,1.758,0,0,0,2.308-2.308l-3.861-9.008L55.9,44.576a1.758,1.758,0,0,0-.845-3.153l-9.732-1.161-1.161-9.732a1.758,1.758,0,0,0-3.153-.845l-5.871,7.847L26.13,33.673a1.758,1.758,0,0,0-2.308,2.308l3.861,9.008L19.835,50.86a1.758,1.758,0,0,0,.845,3.153l3.069.366L.515,77.612a1.758,1.758,0,0,0,0,2.486ZM30.9,46.975a1.758,1.758,0,0,0,.563-2.1l-2.674-6.24,6.24,2.674a1.758,1.758,0,0,0,2.1-.563l4.067-5.436.8,6.741a1.758,1.758,0,0,0,1.537,1.537l6.741.8L44.838,48.46a1.758,1.758,0,0,0-.563,2.1l2.674,6.24-6.24-2.674a1.758,1.758,0,0,0-2.1.563l-4.067,5.436-.8-6.741A1.758,1.758,0,0,0,32.2,51.846l-6.741-.8ZM28.19,54.909l2.221.265.265,2.221L6.729,81.341,4.243,78.855Z'
          transform='translate(0 -25.585)'
          fill='#fff'
        />
        <path
          id='Path_1153'
          data-name='Path 1153'
          d='M417.2,47.105l-2.486,2.486a1.758,1.758,0,1,0,2.486,2.486l2.486-2.486a1.758,1.758,0,0,0-2.486-2.486Z'
          transform='translate(-365.657 -41.131)'
          fill='#fff'
        />
        <path
          id='Path_1154'
          data-name='Path 1154'
          d='M262.842.06A1.758,1.758,0,0,0,261.6,2.213l.91,3.4a1.758,1.758,0,0,0,3.4-.91L265,1.3A1.758,1.758,0,0,0,262.842.06Z'
          transform='translate(-230.89 0)'
          fill='#fff'
        />
        <path
          id='Path_1155'
          data-name='Path 1155'
          d='M348.252,318.814a1.758,1.758,0,0,0-1.243,2.153l.91,3.4a1.758,1.758,0,0,0,3.4-.91l-.91-3.4A1.758,1.758,0,0,0,348.252,318.814Z'
          transform='translate(-306.291 -281.4)'
          fill='#fff'
        />
        <path
          id='Path_1156'
          data-name='Path 1156'
          d='M136.473,127.339a1.758,1.758,0,1,0-.91,3.4l3.4.91a1.758,1.758,0,0,0,.91-3.4Z'
          transform='translate(-118.527 -112.363)'
          fill='#fff'
        />
        <path
          id='Path_1157'
          data-name='Path 1157'
          d='M458.623,213.658l-3.4-.91a1.758,1.758,0,1,0-.91,3.4l3.4.91a1.758,1.758,0,1,0,.91-3.4Z'
          transform='translate(-399.927 -187.763)'
          fill='#fff'
        />
      </g>
    </svg>
  )
})

export default IconMagicWandHeader
