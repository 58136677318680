import React from 'react'

interface IIconActiveAuctions {
  color?: string
  width?: number
  height?: number
}

const IconActiveAuctions = React.forwardRef<SVGSVGElement, IIconActiveAuctions>(({ color = 'currentColor', width = 20, height = 32, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 19.667 33.209'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g id='Group_5039' data-name='Group 5039' transform='translate(-0.57 -1.15)'>
        <g id='Group_4545' data-name='Group 4545' transform='translate(0.82 1.4)'>
          <g id='Group_4546' data-name='Group 4546' transform='translate(0)'>
            <path
              id='Path_1753'
              data-name='Path 1753'
              d='M17.557,22.931a2.082,2.082,0,0,0-1.844-3.049H12.943V16.321a8.29,8.29,0,1,0-4.165-.009v2.921H8.672a6.722,6.722,0,0,0-4.92,2.142H.639A.639.639,0,0,0,0,22.013l0,7.915a.639.639,0,0,0,.639.638H3.755a6.73,6.73,0,0,0,4.917,2.142H15.22a2.082,2.082,0,0,0,1.834-3.068,2.081,2.081,0,0,0,.873-3.069,2.082,2.082,0,0,0-.37-3.639M3.887,8.29a6.991,6.991,0,1,1,6.991,6.991A6.991,6.991,0,0,1,3.887,8.29m6.991,8.29c.266,0,.528-.013.788-.038v3.34H10.056V16.539q.405.04.822.041m5.875,9.077H16.2a.639.639,0,0,0,0,1.278.8.8,0,0,1,0,1.609H15.22a.639.639,0,0,0,0,1.278.8.8,0,1,1,0,1.609H8.672a5.458,5.458,0,0,1-4.146-1.918.639.639,0,0,0-.485-.223H1.282l0-6.638h2.76a.639.639,0,0,0,.486-.224A5.449,5.449,0,0,1,8.672,20.51h.106v.011a.639.639,0,0,0,.639.639h6.3a.8.8,0,1,1,0,1.609.639.639,0,1,0,0,1.278h1.04a.8.8,0,0,1,0,1.61'
              transform='translate(0)'
              fill={color}
              stroke={color}
              strokeWidth='0.25'
            />
            <path
              id='Path_1754'
              data-name='Path 1754'
              d='M134.841,70.847V69.671a2.428,2.428,0,0,0,1.334-.57,1.606,1.606,0,0,0,.539-1.24,1.54,1.54,0,0,0-.292-.974,1.847,1.847,0,0,0-.692-.549,8.961,8.961,0,0,0-1.017-.372c-.329-.1-.588-.2-.771-.273a1.273,1.273,0,0,1-.44-.3h0a.645.645,0,0,1-.167-.464.545.545,0,0,1,.275-.484,1.475,1.475,0,0,1,.8-.19,2.925,2.925,0,0,1,.818.132,3.45,3.45,0,0,1,.864.4l.092.058.431-.927-.069-.045a3.863,3.863,0,0,0-1.708-.594V62.216h-.831v1.07a2.351,2.351,0,0,0-1.281.534,1.656,1.656,0,0,0-.235,2.14,1.825,1.825,0,0,0,.688.545,8.214,8.214,0,0,0,1.01.364c.328.1.591.188.783.268a1.273,1.273,0,0,1,.452.309.662.662,0,0,1,.167.469.654.654,0,0,1-.313.573,1.617,1.617,0,0,1-.913.224,2.631,2.631,0,0,1-1.026-.224,3.077,3.077,0,0,1-.955-.641l-.092-.091-.469.906.053.05a3.7,3.7,0,0,0,1.02.674,3.556,3.556,0,0,0,1.112.3v1.163Z'
              transform='translate(-123.404 -58.241)'
              fill={color}
              stroke={color}
              strokeWidth='0.25'
            />
          </g>
        </g>
      </g>
    </svg>
  )
})

export default IconActiveAuctions
