import React from 'react'

interface IIconOffer {
  color?: string
  size?: number
}

const IconOffer = React.forwardRef<SVGSVGElement, IIconOffer>(({ color = 'currentColor', size = 15, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14.832 14.642' stroke={color} {...rest}>
      <path
        id='Union_22'
        data-name='Union 22'
        d='M-12770.512-783.538a10.179,10.179,0,0,1-1.115-.446.389.389,0,1,1,.3-.718,8.991,8.991,0,0,0,1.1.433l4.591-.266c.119-.058,2.693-1.292,4.383-1.946a.8.8,0,0,0-.118-.295,31.409,31.409,0,0,0-3.168.628.389.389,0,1,1-.18-.757,28.38,28.38,0,0,1,3.373-.657c.709-.083,1.286,1.443.513,1.754-1.642.625-4.533,2.007-4.533,2.007a.4.4,0,0,1-.147.039l-4.681.276h-.02A.968.968,0,0,1-12770.512-783.538Zm-4.129.051a.39.39,0,0,1-.392-.388V-789.6a.393.393,0,0,1,.392-.391h.778a.374.374,0,0,1,.395.391.375.375,0,0,1-.395.391h-.391v4.941h1.042v-4.553a.389.389,0,0,1,.391-.388h1.559a3.2,3.2,0,0,1,1.5.568c.35.189.824.5.949.545.365.119,1.51.147,2.481.3.625.112.834.789.847,1.587a.393.393,0,0,1-.392.382h-2.879a.374.374,0,0,1-.395-.391.373.373,0,0,1,.395-.388h2.453a.8.8,0,0,0-.145-.417c-.885-.138-1.882-.125-2.581-.321a7.281,7.281,0,0,1-1.119-.616,4.267,4.267,0,0,0-1.115-.468h-1.17v4.553a.388.388,0,0,1-.389.388Zm5.54-6.826a.2.2,0,0,1-.017-.013.39.39,0,0,1-.08-.545.389.389,0,0,1,.545-.08,3.366,3.366,0,0,0,1.943.612,3.374,3.374,0,0,0,3.382-3.379,3.377,3.377,0,0,0-3.382-3.382,3.375,3.375,0,0,0-3.38,3.382,3.353,3.353,0,0,0,.613,1.94.391.391,0,0,1-.1.545.387.387,0,0,1-.542-.1,4.14,4.14,0,0,1-.757-2.389,4.167,4.167,0,0,1,4.162-4.161,4.167,4.167,0,0,1,4.161,4.161,4.169,4.169,0,0,1-4.161,4.162A4.175,4.175,0,0,1-12769.1-790.313Zm2.122-.987-1.529-1.988a.368.368,0,0,1-.02-.388l1.529-2.443a.354.354,0,0,1,.577,0l1.529,2.443a.369.369,0,0,1,.042.231.391.391,0,0,1-.061.157l-1.529,1.988a.34.34,0,0,1-.27.131A.333.333,0,0,1-12766.979-791.3Zm.27-.766.612-.8-.517.154a.337.337,0,0,1-.2,0l-.517-.154Zm-1-1.626,1,.3,1-.3-1-1.6Z'
        transform='translate(12775.157 798.004)'
        fill={color}
        stroke={color}
        strokeMiterlimit='10'
        strokeWidth='0.25'
      />
    </svg>
  )
})

export default IconOffer
