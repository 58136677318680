import { TryActivationArgs } from 'modules/bend/hooks/useWallet'
import { rabbyWalletConnection } from 'modules/wallet-module/connection' // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
import { ConnectionType, getConnectionName } from 'wallet-module'

import Option from './option'

const INJECTED_PROPS = {
  color: '#010101',
  icon: '/images/rabby.webp',
  id: 'rabby'
}

export function RabbyWalletOption({ tryActivation }: { tryActivation: (props: TryActivationArgs) => void }) {
  const isActive = rabbyWalletConnection.hooks.useIsActive() // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
  return (
    <Option
      {...INJECTED_PROPS}
      active={isActive}
      header={getConnectionName({ connectionType: ConnectionType.RABBY_WALLET, isMetaMask: false })} // temporary changed to injectedConnection, use okxConnection when okx wallet is ready
      onClick={() => {
        tryActivation({
          connector: rabbyWalletConnection.connector,
          type: ConnectionType.RABBY_WALLET
        })
      }}
    />
  )
}
