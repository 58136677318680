import React from 'react'

interface IIconBorrow {
  color?: string
  size?: number
}

const IconIdo = React.forwardRef<SVGSVGElement, IIconBorrow>(({ color = 'currentColor', size = 40, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 43.142 43.144'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <defs>
        <clipPath id='clipPath'>
          <rect id='Rectangle_1910' data-name='Rectangle 1910' width='43.142' height='43.144' fill={color} stroke={color} strokeWidth='0.25' />
        </clipPath>
      </defs>
      <g id='Group_4684' data-name='Group 4684' clipPath='url(#clipPath)'>
        <path
          id='Path_1781'
          data-name='Path 1781'
          d='M24.1,241.238l-5.677-3.777a.853.853,0,0,0-.708-.106L3.65,241.123c-.156.042-.545.25-.545.456l-3.02,6.093a.853.853,0,0,0,.248,1.047l15.305,11.538a.841.841,0,0,0,.507.17.842.842,0,0,0,.775-.514l7.486-17.645a.843.843,0,0,0-.309-1.031m-5.461-1.609,2.975,1.979-3.3.883ZM9.119,241.4l-2.06,3.44L5.28,242.431Zm2.1-.219,3.9,2.168-6.186,1.658Zm-7.2,2.365,1.711,2.318-3.3.883ZM2.737,248.41l4.038-1.082,5.889,8.576Zm12.711,8.571L8.5,246.866l7.9-2.118Zm1.195-14.707-3.5-1.949,3.839-1.029Zm.678,12.383.812-10.372,4.048-1.085Z'
          transform='translate(0 -217.283)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1782'
          data-name='Path 1782'
          d='M245.5,10.352l-3.028-6.11a.852.852,0,0,0-.537-.44L227.848.028a.854.854,0,0,0-.685.112l-5.677,3.777a.853.853,0,0,0-.309,1.031l7.486,17.645a.843.843,0,0,0,1.283.344L245.251,11.4a.971.971,0,0,0,.169-.171.851.851,0,0,0,.079-.876m-5.2-5.241-1.779,2.411-2.06-3.44Zm-3.644,2.577L230.473,6.03l3.9-2.168Zm-9.712-5.379.322,2.863-3.3-.883ZM223.4,5.881l4.048,1.085.812,10.372Zm5.2-3.9L232.444,3l-3.5,1.949Zm.572,5.452,7.9,2.118-6.946,10.116Zm3.741,11.157,5.889-8.576,4.048,1.085Zm6.944-10.038,1.711-2.318,1.587,3.2Z'
          transform='translate(-202.443 -0.001)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1783'
          data-name='Path 1783'
          d='M111.755,109.368a.843.843,0,0,0,.843-.843v-1.074a.843.843,0,0,0-1.685,0v1.074a.843.843,0,0,0,.843.843'
          transform='translate(-101.548 -97.607)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1784'
          data-name='Path 1784'
          d='M111.755,166.506a.843.843,0,0,0,.843-.843v-1.074a.843.843,0,1,0-1.685,0v1.074a.843.843,0,0,0,.843.843'
          transform='translate(-101.548 -149.921)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1785'
          data-name='Path 1785'
          d='M76.82,143.224h1.075a.843.843,0,0,0,0-1.685H76.82a.843.843,0,0,0,0,1.685'
          transform='translate(-69.562 -129.589)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1786'
          data-name='Path 1786'
          d='M133.962,143.224h1.074a.843.843,0,1,0,0-1.685h-1.074a.843.843,0,0,0,0,1.685'
          transform='translate(-121.88 -129.589)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1787'
          data-name='Path 1787'
          d='M385.224,338.158a.843.843,0,0,0-.843.843v1.074a.843.843,0,0,0,1.685,0V339a.843.843,0,0,0-.843-.843'
          transform='translate(-351.927 -309.607)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1788'
          data-name='Path 1788'
          d='M385.224,395.3a.843.843,0,0,0-.843.843v1.074a.843.843,0,1,0,1.685,0V396.14a.843.843,0,0,0-.843-.843'
          transform='translate(-351.927 -361.922)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1789'
          data-name='Path 1789'
          d='M351.362,373.09h-1.074a.843.843,0,1,0,0,1.685h1.074a.843.843,0,1,0,0-1.685'
          transform='translate(-319.941 -341.59)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_1790'
          data-name='Path 1790'
          d='M408.5,373.09H407.43a.843.843,0,1,0,0,1.685H408.5a.843.843,0,0,0,0-1.685'
          transform='translate(-372.259 -341.59)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconIdo
