import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconDelist = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 13, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12.817 12.817' {...rest}>
      <g id='Group_5151' data-name='Group 5151' transform='translate(0.125 0.125)'>
        <path
          id='Path_2391'
          data-name='Path 2391'
          d='M6.284,0a6.284,6.284,0,1,0,6.284,6.284A6.3,6.3,0,0,0,6.284,0m0,11.436a5.153,5.153,0,1,1,5.153-5.153,5.176,5.176,0,0,1-5.153,5.153'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
        <path
          id='Path_2392'
          data-name='Path 2392'
          d='M10.293,7.923c-2.074-.006-3.637-.006-3.646,0A.51.51,0,0,0,6.1,8.47a.6.6,0,0,0,.547.608c.025,0,3.634,0,3.646,0a.51.51,0,0,0,.547-.547.6.6,0,0,0-.547-.608'
          transform='translate(-2.186 -2.186)'
          fill={color}
          stroke={color}
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconDelist
