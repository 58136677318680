import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconCollateralStaking = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 16 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20.895 16.22' ref={ref}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2063' data-name='Rectangle 2063' width='20.895' height='16.22' fill={color} stroke={color} strokeWidth='0.25' />
        </clipPath>
      </defs>
      <g id='Group_5202' data-name='Group 5202' clipPath='url(#clip-path)'>
        <path
          id='Path_2397'
          data-name='Path 2397'
          d='M7.348,4.54V6.094H8.554V5.236L9.3,4.807l-.6-1.044Zm0,3.16H8.554V9.2H7.348Zm1.206,3.212H7.348v1.554l1.346.777L9.3,12.2l-.743-.429Zm-6.848.858V5.235L7.364,1.969l3.41,1.969.007.013.007,0,2.233,1.29V11.77L7.364,15.036ZM7.364.577.5,4.54v7.926l6.864,3.963,3.424-1.978.594.343.594-1.03,2.251-1.3V4.54L11.994,3.25l.085-.049-.6-1.044-.688.4Zm6.848,14.459-.743-.429-.6,1.044,1.346.777,1.346-.777-.6-1.044ZM16.346,13.8l1.3-.749.6,1.044-1.3.749Zm3.525-2.892v.858l-.743.429.6,1.044,1.346-.777V10.912Zm0-3.1h1.206v1.5H19.87Zm-.743-3,.743.429v.858h1.206V4.54L19.73,3.763ZM16.439,3.255l.6-1.044,1.3.749L17.737,4ZM14.212.577l-1.346.777.6,1.044.743-.429.743.429.6-1.044Z'
          transform='translate(-0.34 -0.393)'
          fill={color}
          stroke={color}
          strokeMiterlimit='10'
          strokeWidth='0.25'
        />
      </g>
    </svg>
  )
})

export default IconCollateralStaking
