import React, { useCallback, useMemo } from 'react'

interface timeLeft {
  d: number
  h: number
  m: number
  s: number
}

export function useTimeCountdown(date: number): timeLeft {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(date) - +new Date()

    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }, [date])

  const [timeLeft, setTimeLeft] = React.useState<any>(calculateTimeLeft())

  React.useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  return useMemo(() => timeLeft, [timeLeft])
}

export function secondsToDHMS(date: number): timeLeft {
  let timeLeft = {
    d: 0,
    h: 0,
    m: 0,
    s: 0
  }

  if (date > 0) {
    timeLeft = {
      d: Math.floor(date / (60 * 60 * 24)),
      h: Math.floor((date / (60 * 60)) % 24),
      m: Math.floor((date / 60) % 60),
      s: Math.floor(date % 60)
    }
  }
  return timeLeft
}

export function useTimeCountUp(date: number, timeout?: number): timeLeft {
  //console.log('date', date)
  const calculateTimePassed = useCallback(() => {
    const difference = +new Date() - +new Date(date)
    let timeSince = {}

    if (difference > 0) {
      timeSince = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60)
      }
    }

    return timeSince
  }, [date])

  const [timeSince, setTimeLeft] = React.useState<any>(calculateTimePassed())

  React.useEffect(() => {
    setTimeout(
      () => {
        setTimeLeft(calculateTimePassed())
      },
      timeout ? timeout : 1000
    )
  })

  React.useEffect(() => {
    return () => {
      setTimeLeft(calculateTimePassed())
    }
  }, [calculateTimePassed])

  return useMemo(() => timeSince, [timeSince])
}
