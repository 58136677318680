import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import { getSigner } from 'wallet-module'

export default function useSignature() {
  const { t: tc } = useTranslation('common')
  const { account, provider } = useWeb3React()

  const signMessage = useCallback(async () => {
    try {
      const message = tc('label.verify-address', { account, time: moment().format() })
      const signer = getSigner(provider!, account!)
      const signature = await signer.signMessage(message)

      return {
        message,
        signature,
        address: account?.toLocaleLowerCase()
      }
    } catch (err: any) {
      return {
        error: err.message
      }
    }
  }, [account, provider, tc])

  return {
    signMessage
  }
}
