import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconEmail = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 20, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 17.85 12.699'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Path_2477'
        data-name='Path 2477'
        d='M15.825,0H1.525A1.527,1.527,0,0,0,0,1.525v9.15A1.527,1.527,0,0,0,1.525,12.2h14.3a1.527,1.527,0,0,0,1.525-1.525V1.525A1.527,1.527,0,0,0,15.825,0m-.234,1.017L9.069,7.56a.594.594,0,0,1-.788,0L1.759,1.017ZM1.017,10.488V1.712L5.39,6.1Zm.742.7L6.108,6.82,7.561,8.278a1.609,1.609,0,0,0,2.228,0L11.242,6.82l4.349,4.363Zm14.574-.7L11.96,6.1l4.374-4.388Z'
        transform='translate(0.25 0.25)'
        fill={color}
        stroke={color}
        strokeWidth='0.5'
      />
    </svg>
  )
})

export default IconEmail
