import React from 'react'

interface IIconContract {
  color?: string
}

const IconContract = React.forwardRef<SVGSVGElement, IIconContract>(({ color = 'currentColor', ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={15.825} height={16.126} viewBox='0 0 15.825 16.126' fill={color} {...rest}>
      <path
        id='Path_2552'
        data-name='Path 2552'
        d='M.946,13.159v-3.2l2.774,1.6v3.2Zm7.44,0v-3.2l2.774,1.6v3.2Zm-3.72-1.6,2.774-1.6v3.2l-2.774,1.6Zm7.44,0,2.774-1.6v3.2l-2.774,1.6ZM1.419,9.137l2.774-1.6,2.774,1.6-2.774,1.6Zm7.44,0,2.774-1.6,2.774,1.6-2.774,1.6ZM4.666,6.716v-3.2l2.774,1.6v3.2Zm3.72-1.6,2.774-1.6v3.2l-2.774,1.6ZM5.139,2.694l2.774-1.6,2.774,1.6L7.913,4.3ZM7.913,0,3.72,2.421v4.3L0,8.864v4.841l4.193,2.421,3.72-2.148,3.72,2.148,4.193-2.421V8.864l-3.72-2.148v-4.3Z'
        fill={color}
      />
    </svg>
  )
})

export default IconContract
