import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconSecurity = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 18, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16.66 18.237'
      fill={color}
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path
        id='Path_1661'
        data-name='Path 1661'
        d='M16.444,3.21a.617.617,0,0,0-.449-.578L8.42,0H8.035L.4,2.568A.754.754,0,0,0,.01,3.21c-.193,6.355,2.375,11.106,7.9,14.7a.576.576,0,0,0,.706,0c5.457-3.595,8.089-8.41,7.832-14.7M8.227,16.562A15.854,15.854,0,0,1,2.642,10.4l2.183.706a.65.65,0,1,0,.449-1.22L2.064,8.859a13.942,13.942,0,0,1-.449-1.541l3.21,1.027a.65.65,0,0,0,.449-1.22L1.422,5.906c-.064-.449-.064-.9-.128-1.412L4.889,5.649a.7.7,0,0,0,.835-.385.668.668,0,0,0-.385-.835L2,3.4,8.227,1.348,15.16,3.723A14.3,14.3,0,0,1,8.227,16.562'
        transform='translate(0.099 0.1)'
        fill={color}
        stroke={color}
        strokeWidth='0.2'
      />
    </svg>
  )
})

export default IconSecurity
