import { useCallback, useContext } from 'react'
import { COOKIE_USER_DENIED_LOGIN } from 'constants/index'
import { setCookies } from 'cookies-next'
import { Modal, ModalContext } from 'theme/ui'

import { VerifyAddress } from 'components/common/verify-address'

type HandleVerifyModalProps = {
  expired?: boolean
}

const useVerifyAddressModal = () => {
  const { onPresent } = useContext(ModalContext)
  const handleVerifyModal = useCallback(
    ({ expired = false }: HandleVerifyModalProps) => {
      onPresent(
        <Modal noLayoutStyle width={360} padding={10}>
          <VerifyAddress mt={-20} mb={20} mx={-10} expired={expired} />
        </Modal>,
        {
          onClose: () => {
            const now = new Date()
            setCookies(COOKIE_USER_DENIED_LOGIN, 'true', { expires: new Date(now.setDate(now.getDate() + 30)) })
          },
          preventBgClose: true
        }
      )
    },
    [onPresent]
  )

  return handleVerifyModal
}

export default useVerifyAddressModal
