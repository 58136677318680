import React from 'react'

interface IIconBendApe {
  color?: string
  size?: number
}

const IconStatistics = React.forwardRef<SVGSVGElement, IIconBendApe>(({ color = 'currentColor', size = 15 }, ref) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 15.462 15.462' ref={ref}>
      <g id='Group_16769' data-name='Group 16769' transform='translate(-0.418 2.076)'>
        <g id='Group_12284' data-name='Group 12284' transform='translate(0.543 -1.951)'>
          <path
            id='Path_2752'
            data-name='Path 2752'
            d='M2.049,293.776A2.051,2.051,0,0,0,0,295.825v2.386a2.049,2.049,0,1,0,4.1,0v-2.386a2.051,2.051,0,0,0-2.049-2.049m.86,4.435a.86.86,0,1,1-1.721,0v-2.386a.86.86,0,0,1,1.721,0v2.386'
            transform='translate(0 -285.048)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2753'
            data-name='Path 2753'
            d='M189.086,0a2.051,2.051,0,0,0-2.049,2.049V13.163a2.049,2.049,0,1,0,4.1,0V2.049A2.051,2.051,0,0,0,189.086,0m.861,13.163a.861.861,0,0,1-1.721,0V2.049a.861.861,0,0,1,1.721,0Z'
            transform='translate(-181.48)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
          <path
            id='Path_2754'
            data-name='Path 2754'
            d='M376.124,146.888a2.051,2.051,0,0,0-2.049,2.049v6.75a2.049,2.049,0,0,0,4.1,0v-6.75a2.051,2.051,0,0,0-2.049-2.049m.861,8.8a.86.86,0,1,1-1.721,0v-6.75a.86.86,0,1,1,1.721,0Z'
            transform='translate(-362.961 -142.524)'
            fill={color}
            stroke={color}
            strokeWidth='0.25'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconStatistics
