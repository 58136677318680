import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconViewCard = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 13 }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13 13' fill={color}>
      <g id='Group_3091' data-name='Group 3091' transform='translate(-0.137)'>
        <g id='Rectangle_32' data-name='Rectangle 32' transform='translate(0.137)' fill='none' stroke={color} strokeWidth='1'>
          <rect width='6' height='6' rx='2' stroke='none' />
          <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
        </g>
        <g id='Rectangle_35' data-name='Rectangle 35' transform='translate(0.137 7)' fill='none' stroke={color} strokeWidth='1'>
          <rect width='6' height='6' rx='2' stroke='none' />
          <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
        </g>
        <g id='Rectangle_33' data-name='Rectangle 33' transform='translate(7.137)' fill='none' stroke={color} strokeWidth='1'>
          <rect width='6' height='6' rx='2' stroke='none' />
          <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
        </g>
        <g id='Rectangle_34' data-name='Rectangle 34' transform='translate(7.137 7)' fill='none' stroke={color} strokeWidth='1'>
          <rect width='6' height='6' rx='2' stroke='none' />
          <rect x='0.5' y='0.5' width='5' height='5' rx='1.5' fill='none' />
        </g>
      </g>
    </svg>
  )
})

export default IconViewCard
