import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconViewRow = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 13 }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 13.998 13.999' fill={color}>
      <g id='Group_3092' data-name='Group 3092' transform='translate(-757.5 -137.501)'>
        <path
          id='Union_14'
          data-name='Union 14'
          d='M-22375,15448.749a1.248,1.248,0,0,1,1.252-1.246,1.244,1.244,0,0,1,1.246,1.246,1.249,1.249,0,0,1-1.246,1.252A1.253,1.253,0,0,1-22375,15448.749Zm5.316.578a.714.714,0,0,1-.713-.713.716.716,0,0,1,.713-.719h6.969a.714.714,0,0,1,.713.719.714.714,0,0,1-.719.713Zm-5.316-5.828a1.248,1.248,0,0,1,1.252-1.247,1.245,1.245,0,0,1,1.246,1.247,1.249,1.249,0,0,1-1.246,1.252A1.253,1.253,0,0,1-22375,15443.5Zm5.316.572a.714.714,0,0,1-.713-.713.716.716,0,0,1,.713-.719h6.969a.714.714,0,0,1,.713.719.714.714,0,0,1-.719.713Zm-5.316-5.823a1.248,1.248,0,0,1,1.252-1.246,1.244,1.244,0,0,1,1.246,1.246,1.249,1.249,0,0,1-1.246,1.253A1.253,1.253,0,0,1-22375,15438.248Zm5.316.579a.719.719,0,0,1-.713-.719.715.715,0,0,1,.713-.714h6.969a.713.713,0,0,1,.713.714.716.716,0,0,1-.719.719Z'
          transform='translate(23133 -15299)'
          fill={color}
          stroke='rgba(0,0,0,0)'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconViewRow
