import React from 'react'

interface IIconMarketplaceListed {
  listed: boolean
  size?: number
}

const IconMarketplaceListed = React.forwardRef<SVGSVGElement, IIconMarketplaceListed>(({ listed = true, size = 17, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 17 17' {...rest}>
      <circle id='Ellipse_405' data-name='Ellipse 405' cx='8.5' cy='8.5' r='8.5' fill={listed ? '#00BC64' : '#B1B1B1'} />
      <g id='Group_7389' data-name='Group 7389' transform='translate(5.933 3.546)'>
        <g id='Group_7390' data-name='Group 7390'>
          <path
            id='Path_2758'
            data-name='Path 2758'
            d='M3.968,4.527A12,12,0,0,0,2.75,3.976,2.735,2.735,0,0,1,2.081,3.6a.582.582,0,0,1,.144-1.035,1.174,1.174,0,0,1,.41-.084,3.109,3.109,0,0,1,1.54.3c.243.117.323.08.406-.173s.159-.539.239-.809a.283.283,0,0,0-.183-.377,3.914,3.914,0,0,0-.972-.29c-.441-.068-.441-.07-.443-.513C3.219,0,3.219,0,2.593,0,2.5,0,2.412,0,2.322,0c-.292.008-.342.06-.35.354,0,.132,0,.263,0,.4,0,.391,0,.385-.379.521A2.088,2.088,0,0,0,.065,3.208,1.983,1.983,0,0,0,1.187,5.1a12.4,12.4,0,0,0,1.4.63,2.082,2.082,0,0,1,.511.3.7.7,0,0,1-.167,1.227,1.679,1.679,0,0,1-.9.117,4.015,4.015,0,0,1-1.371-.41c-.253-.132-.327-.1-.414.177-.074.237-.14.476-.206.714-.089.321-.056.4.251.548a4.378,4.378,0,0,0,1.235.354c.333.054.344.068.348.414,0,.156,0,.315,0,.471a.28.28,0,0,0,.3.317c.231,0,.463,0,.694,0a.264.264,0,0,0,.286-.3c0-.214.01-.43,0-.644a.34.34,0,0,1,.294-.387A2.326,2.326,0,0,0,4.67,7.854a2.188,2.188,0,0,0-.7-3.327'
            transform='translate(-0.015 -0.029)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconMarketplaceListed
