import { useContext } from 'react'
import { nativeSwapImagePath } from 'constants/index'
import { permalink } from 'constants/routes'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { Box, Flex, Image, Text } from 'rebass/styled-components'
import { Avatar, IconBNFT, IconDashboard, IconLiquidate, IconMarketplace, IconSettings, Label } from 'theme/ui'

import Link from 'components/common/link'

import { SidebarContext } from '..'

export const QuickLinks: React.FC = () => {
  const { t } = useTranslation('common')
  const { onDismiss } = useContext(SidebarContext)
  const { colors } = useTheme()
  return (
    <Flex fontWeight='bold' color='black' flexDirection='column'>
      <Text fontSize={13}>{t('label.quicklinks')}</Text>
      <Box
        mt={20}
        mb={10}
        fontSize={13}
        sx={{
          display: 'grid',
          gridGap: '15px 10px',
          gridTemplateColumns: `repeat(2, minmax(150px, 1fr))`
        }}
      >
        <Link href={permalink.dashboard} passHref>
          <Avatar
            icon={<IconDashboard size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            text={<Text>{t('title.dashboard')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.nfts} passHref>
          <Avatar
            icon={<IconBNFT size={18} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            text={<Text>{t('label.my-nfts')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.auctionAvailableToAuction} passHref>
          <Avatar
            icon={<IconLiquidate size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{t('label.auctions')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.liquidityExplore} passHref>
          <Avatar
            icon={<IconMarketplace size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{t('buy-nfts.title')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.dashboardSettings} passHref>
          <Avatar
            icon={<IconSettings size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{t('label.settings')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        {/*         <Link href={permalink.myNotifications} passHref>
          <Avatar
            icon={<IconNotification size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{t('label.notifications')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link> */}

        <Link href={permalink.nativeSwap} passHref>
          <Flex onClick={() => onDismiss!()} sx={{ alignItems: 'center', gap: 20 }} variant='link'>
            <Flex sx={{ position: 'absolute', mt: -35 }}>
              <Label
                sx={{
                  ml: 15,
                  background: colors.green[1000],
                  border: 0,
                  py: 2,
                  px: 4,
                  fontSize: rem('8px'),
                  borderRadius: 'xs',
                  fontWeight: 'bold',
                  zIndex: 300
                }}
              >
                {t('label.new').toUpperCase()}
              </Label>
            </Flex>
            <Flex sx={{ width: 32, height: 32, overflow: 'hidden', borderRadius: 'sm' }}>
              <Image src={nativeSwapImagePath} />
            </Flex>
            <Text>{t('label.native-swap')}</Text>
          </Flex>
        </Link>
      </Box>
    </Flex>
  )
}
