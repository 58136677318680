import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMyStakings = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 17, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 17.016 16.955' fill={color} {...rest}>
      <path
        id='Path_3837'
        data-name='Path 3837'
        d='M38.2,25.963a.761.761,0,0,0-.419-.678l-7.613-3.806a.761.761,0,0,0-.685,0L21.87,25.286a.761.761,0,0,0,0,1.355l2.444,1.226L21.87,29.092a.761.761,0,0,0,0,1.355l2.444,1.226L21.87,32.9a.761.761,0,0,0,0,1.355l7.613,3.806a.761.761,0,0,0,.685,0l7.613-3.806a.761.761,0,0,0,0-1.355l-2.444-1.226,2.444-1.226a.761.761,0,0,0,0-1.355l-2.444-1.226,2.444-1.226A.761.761,0,0,0,38.2,25.963Zm-2.467,7.613L29.826,36.53l-5.908-2.954,2.1-1.051,3.464,1.728a.761.761,0,0,0,.685,0l3.464-1.728Zm0-3.806-5.908,2.954L23.918,29.77l2.1-1.051,3.464,1.728a.761.761,0,0,0,.685,0l3.464-1.728Zm-5.908-.853-5.908-2.954,5.908-2.954,5.908,2.954Z'
        transform='translate(-21.318 -21.292)'
        fill={color}
        strokeWidth='0'
      />
    </svg>
  )
})

export default IconMyStakings
