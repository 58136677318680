import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react'

export enum NotificationState {
  CONFIRM_AWAIT = 0,
  TX_CONFIRMING = 1,
  SUCCESS = 2,
  ERROR = 3,
  AWAIT_ACTION = 4
}

type State = {
  notificationState: NotificationState
  txAwaitText?: string
  txConfirmingText?: string
  txErrorText?: string
  txSuccessText?: string
  txId?: string
  firstActionSuccess?: boolean
}

type ReducerAction = {
  type: 'CONFIRM_AWAIT' | 'TX_CONFIRMING' | 'SUCCESS' | 'ERROR' | 'RESET' | 'AWAIT_ACTION' | 'FIRST_ACTION_SUCCESS'
  payload: {
    message?: string
    txId?: string
    firstActionSuccess?: boolean
  }
}

type TransactionModalContextType = State & {
  dispatch: Dispatch<any>
  action: {
    awaitAction: () => { type: ReducerAction['type'] }
    confirmAwait: (payload: ReducerAction['payload']) => { type: ReducerAction['type']; payload: ReducerAction['payload'] }
    txConfirming: (payload: ReducerAction['payload']) => { type: ReducerAction['type']; payload: ReducerAction['payload'] }
    error: (payload: ReducerAction['payload']) => { type: ReducerAction['type']; payload: ReducerAction['payload'] }
    success: (payload: ReducerAction['payload']) => { type: ReducerAction['type']; payload: ReducerAction['payload'] }
    reset: () => { type: ReducerAction['type'] }
    setFirstActionSuccess: (payload: ReducerAction['payload']) => { type: ReducerAction['type']; payload: ReducerAction['payload'] }
  }
}

const initialState: State = {
  notificationState: NotificationState.CONFIRM_AWAIT,
  txAwaitText: '',
  txConfirmingText: '',
  txErrorText: '',
  txSuccessText: '',
  txId: '',
  firstActionSuccess: undefined
}

const TransactionModalCustomStepContext = createContext<TransactionModalContextType | undefined>({
  ...initialState,
  dispatch: () => {},
  action: {
    awaitAction: () => ({ type: 'AWAIT_ACTION', payload: {} }),
    confirmAwait: () => ({ type: 'CONFIRM_AWAIT', payload: {} }),
    txConfirming: () => ({ type: 'TX_CONFIRMING', payload: {} }),
    error: () => ({ type: 'ERROR', payload: {} }),
    success: () => ({ type: 'SUCCESS', payload: {} }),
    reset: () => ({ type: 'RESET' }),
    setFirstActionSuccess: () => ({ type: 'FIRST_ACTION_SUCCESS', payload: {} })
  }
})

export const useTransactionModal = () => {
  const context = useContext(TransactionModalCustomStepContext)
  if (context === undefined) {
    throw new Error('useTransactionModal must be used within a TransactionModalProvider')
  }
  return context
}

const reducer = (state: State, { type, payload }: ReducerAction): State => {
  switch (type) {
    case 'AWAIT_ACTION':
      return {
        ...state,
        notificationState: NotificationState.AWAIT_ACTION,
        txAwaitText: '',
        txConfirmingText: '',
        txErrorText: '',
        txSuccessText: '',
        txId: ''
      }
    case 'CONFIRM_AWAIT':
      return {
        ...state,
        notificationState: NotificationState.CONFIRM_AWAIT,
        txAwaitText: payload?.message,
        txConfirmingText: '',
        txErrorText: '',
        txSuccessText: '',
        txId: ''
      }
    case 'TX_CONFIRMING':
      return {
        ...state,
        notificationState: NotificationState.TX_CONFIRMING,
        txAwaitText: '',
        txConfirmingText: payload?.message,
        txErrorText: '',
        txSuccessText: '',
        txId: ''
      }
    case 'SUCCESS':
      return {
        ...state,
        notificationState: NotificationState.SUCCESS,
        txAwaitText: '',
        txConfirmingText: '',
        txErrorText: '',
        txSuccessText: payload?.message,
        txId: payload?.txId,
        firstActionSuccess: payload?.firstActionSuccess
      }
    case 'FIRST_ACTION_SUCCESS':
      return {
        ...state,
        firstActionSuccess: payload?.firstActionSuccess
      }
    case 'ERROR':
      return {
        ...state,
        notificationState: NotificationState.ERROR,
        txAwaitText: '',
        txConfirmingText: '',
        txErrorText: payload?.message,
        txSuccessText: '',
        txId: ''
      }
    case 'RESET':
      return {
        ...initialState
      }
    default:
      throw new Error('TransactionModalContext reducer error')
  }
}

export default function TransactionModalCustomStepProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const action = {
    awaitAction: () => ({ type: 'AWAIT_ACTION', payload: {} } as ReducerAction),
    success: (payload: ReducerAction['payload']) => ({ type: 'SUCCESS', payload } as ReducerAction),
    error: (payload: ReducerAction['payload']) => ({ type: 'ERROR', payload } as ReducerAction),
    confirmAwait: (payload: ReducerAction['payload']) => ({ type: 'CONFIRM_AWAIT', payload } as ReducerAction),
    txConfirming: (payload: ReducerAction['payload']) => ({ type: 'TX_CONFIRMING', payload } as ReducerAction),
    reset: () => ({ type: 'RESET', payload: {} } as ReducerAction),
    setFirstActionSuccess: (payload: ReducerAction['payload']) => ({ type: 'FIRST_ACTION_SUCCESS', payload } as ReducerAction)
  }

  return <TransactionModalCustomStepContext.Provider value={{ ...state, dispatch, action }}>{children}</TransactionModalCustomStepContext.Provider>
}
